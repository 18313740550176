import React from 'react'
import styled from 'styled-components'
import { Button, FormControlLabel, IconButton, Switch, TextField } from '@mui/material'
import { styled as styledMui } from '@mui/material/styles'
import ClearIcon from '@mui/icons-material/Clear';
import { Show } from '../SearchModal';
import { useTranslation } from 'react-i18next';

const SearchModalHeader = ({
  searchTerm,
  setSearchTerm,
  show,
  setShow,
  includeCompleted,
  setIncludeCompleted,
  onClose,
}: {
  searchTerm: string
  setSearchTerm: (value: string) => void
  show: Show,
  setShow: (value: Show) => void
  includeCompleted: boolean
  setIncludeCompleted: (value: boolean) => void
  onClose: () => void
}) => {
  const { t } = useTranslation()

  const renderButton = (type: Show) => <StyledButton
    selected={show == type}
    variant="contained"
    onClick={() => {
      if (show == type) setShow(null)
      else setShow(type)
    }}>
    {t(type)}
  </StyledButton>

  return <ModalHeader>
    <SearchRow>
      <StyledTextField
        id="search"
        label={t('enterSearchTerm')}
        variant="standard"
        fullWidth
        onChange={(event) => {
          setSearchTerm(event.target.value.toLowerCase())
        }}
        value={searchTerm}
      />
      <StyledIconButton onClick={onClose} ><ClearIcon /></StyledIconButton>
    </SearchRow>

    <ButtonRow>
      <div>
        {renderButton('workflows')}
        {renderButton('sections')}
        {renderButton('resources')}
        {renderButton('tasks')}
      </div>

      <StyledFormControlLabel control={<Switch value={includeCompleted} onChange={(event) => setIncludeCompleted(event.target.checked)} />} label="Include completed" />
    </ButtonRow>
  </ModalHeader>
}

const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`

const StyledFormControlLabel = styledMui(FormControlLabel)`
  & .MuiFormControlLabel-label {
    font-family: 'Poppins_400Regular';
    font-size: 12px;
    color: #000000;
    margin-left: 6px;
  }
`

const StyledTextField = styledMui(TextField)`
  & .MuiInputBase-input {
    font-family: 'Poppins_400Regular';
    font-size: 24px;
    color: #333333;
  }

  & .MuiInputLabel-root {
    font-family: 'Poppins_400Regular';
  }
`

const StyledButton = styledMui(Button) <{ selected: boolean }>`
  font-family: 'Poppins_400Regular';
  font-size: 12px;
  color: ${props => props.selected ? '#FFFFFF' : '#000000'};
  margin-right: 24px;
  background-color: ${props => props.selected ? '#243162' : '#EEEEEE'};
  border-radius: 8px;

  &:hover, &:active {
    color: #FFFFFF;
    background-color: #243162;
  }
`

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 42px;
  margin-top: 20px;
`

const SearchRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledIconButton = styled(IconButton)`
  margin-left: 15px !important;
  align-self: end;
`

export default SearchModalHeader