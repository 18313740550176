import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/native'
import format from 'utils/date-fns'

import DefaultButton from '../buttons/DefaultButton'

const Results = (
  { percentage,
    submittedAt,
    hidePercentage,
    onRetakeQuiz,
    survey,
  }: {
    percentage: number
    submittedAt: string
    hidePercentage: boolean
    onRetakeQuiz?: () => void
    survey: boolean
  }
) => {
  const { t, i18n } = useTranslation()
  const monthDayYear = format(
    new Date(submittedAt),
    t('monthDayYearDateFormat'),
    i18n.resolvedLanguage,
  )

  const isRetakeAllowed = useMemo(() => !!onRetakeQuiz && (survey || (!hidePercentage && percentage < 100)), [percentage, hidePercentage, onRetakeQuiz])

  return <Container>
    {isRetakeAllowed && <Title>You need a score of 100% to complete this task</Title>}
    <SubmitContainer>
      <Container>
        <Subtitle>Submitted {monthDayYear}</Subtitle>
        {!hidePercentage && !survey && <Percentage isDone={!isRetakeAllowed}>Score: {percentage}%</Percentage>}
      </Container>
      {isRetakeAllowed && <DefaultButton title={`Retake ${survey ? 'Survey' : 'Quiz'}`} onPress={onRetakeQuiz} />}
    </SubmitContainer>
  </Container>
}

const Container = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const SubmitContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Title = styled.Text`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #5F5F5F;
  margin-bottom: 13.5px;
`

const Subtitle = styled.Text`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #8C8C8C;
`

const Percentage = styled.Text<{ isDone: boolean }>`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ isDone }) => (isDone ? '#35928C' : '#3B3B3B')};
`

export default Results