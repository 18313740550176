import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import WelcomeWizardCommonStep from './WelcomeWizardCommonStep'

const WelcomeWizardStep3 = () => {
  const { t } = useTranslation()

  return <WelcomeWizardCommonStep
    title={'Resources'}
    description={<Trans i18nKey='welcomeStep3Description' />}
    imageSrc={require('assets/images/png/OnboardingStep3.png')}
  />
}

export default WelcomeWizardStep3