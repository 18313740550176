import React, { useMemo } from 'react'

import { Text, ScrollView, RefreshControl } from 'react-native'
import useLocations from 'hooks/useLocations'
import LocationElement from './LocationElement'
import ManagerLocationsGroup from './ManagerLocationsGroup'
import { Loader } from 'components/common/Loader'

const LocationElements = ({ parentId, hideCompletedWorkflows, searchTerm = null }) => {
  const { myLocations, myTeamLocations, error, loading, fetchNextPage, refetch } = useLocations(
    parentId,
    hideCompletedWorkflows,
    {
      enablePagination: false,
      searchTerm,
    },
  )

  const renderNormalLocationElements = (
    normalLocations: LocationInterface[],
  ) => {
    return normalLocations.map((location: LocationInterface) => (
      <LocationElement
        key={location.id}
        {...location}
      />
    ))
  }

  const renderManagerLocationElements = (myTeamLocations: any) => {
    return myTeamLocations.map((team: any, i: number) => {
      return (
        <ManagerLocationsGroup
          key={i}
          fullName={team.owner?.fullName}
          color={team.color}
          employeeLocations={team.locations}
        />
      )
    })
  }

  const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }: any) => {
    const paddingToBottom = 100;
    return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
  };

  if (error || (!myLocations && !myTeamLocations)) {
    return (
      <Text>An error occurred while fetching tasks ...{error.message}</Text>
    )
  }

  return <ScrollView
    refreshControl={
      <RefreshControl refreshing={loading} onRefresh={refetch} />
    }
    onScroll={({ nativeEvent }) => {
      if (isCloseToBottom(nativeEvent)) {
        fetchNextPage();
      }
    }}
    scrollEventThrottle={400}
  >
    {renderNormalLocationElements(myLocations)}
    {!parentId && renderManagerLocationElements(myTeamLocations)}
    {loading && <Loader />}
  </ScrollView>
}

export default LocationElements