import React from 'react'
import { ROUTE_NAMES } from 'navigation/constants'
import { Redirect, Route } from 'react-router-native'
import useUser from 'hooks/useUser'

function ManagerRoute({ component: Component, ...restOfProps }) {
  const { user, loading } = useUser()
  const isManager = user?.isManager

  return (
    <Route
      {...restOfProps}
      render={(props) => {
        if (loading) {
          return null
        }

        if (isManager) {
          return <Component {...props} />
        }

        return <Redirect to={ROUTE_NAMES.LOGIN} />
      }}
    />
  )
}

export default ManagerRoute
