import React from 'react'
import styled from 'styled-components'
import COLORS from 'constants/colors'
import LocationEmployee from './ManagerLocation'

const ManagerLocationsGroup = ({
  employeeLocations,
  fullName,
  color,
}: {
  employeeLocations: LocationInterface[]
  onFolderChange: (
    newParentId: string,
    owner?: User,
  ) => void
  hideCompletedWorkflows: boolean
  fullName: string
  color: string
}) => {
  if (!employeeLocations.length) {
    return null
  }

  return (
    <StyledManagerLocationsGroup color={color}>
      <div className="employee-name">{fullName}</div>
      <div className="locations">
        {employeeLocations.map((location) => (
          <LocationEmployee
            location={location}
          />
        ))}
      </div>
    </StyledManagerLocationsGroup>
  )
}

const StyledManagerLocationsGroup = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 12px;
  padding: 12px;
  background: white;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  color: ${COLORS.NAVY_BLUE};
  border-left: 16px solid ${({ color }) => color ?? '#2E4785'};
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;

  .employee-name {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }

  .locations {
    display: flex;
    flex-direction: column;
  }
`

export default ManagerLocationsGroup
