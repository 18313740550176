import React, { useState } from 'react'
import styled from 'styled-components'
import { TangeloIcon } from './custom-icons'
import COLORS from 'constants/colors'
import { useTranslation } from 'react-i18next'
import useClientConfig from 'hooks/useClientConfig'
import useUser from 'hooks/useUser'
import EmployeeWelcomeWizardModal from 'components/welcome-wizard/EmployeeWelcomeWizardModal.web'
import ManagerWelcomeWizardModal from 'components/welcome-wizard/ManagerWelcomeWizardModal.web'

function Footer({ expanded = false }) {
  const { t } = useTranslation()
  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false)
  const [isManagerModalOpen, setIsManagerModalOpen] = useState(false)
  const { enableNewHome, enableWelcomeModal } = useClientConfig()
  const { user } = useUser()

  return (
    <StyledPoweredBy>
      <Link href="https://www.tangelo.ai" target="_blank">
        {!expanded && <PoweredByText>{t('poweredBy')}</PoweredByText>}
        <Icon>
          <TangeloIcon />
        </Icon>
        <TangeloText>Tangelo</TangeloText>
      </Link>
      {enableNewHome && enableWelcomeModal && user && <>
        <WelcomeMessageText onClick={() => {
          if (user?.isManager) {
            setIsManagerModalOpen(true)
          } else {
            setIsEmployeeModalOpen(true)
          }
        }}>{t('viewWelcomeMessage')}</WelcomeMessageText>
        <EmployeeWelcomeWizardModal isOpen={isEmployeeModalOpen} onDone={() => setIsEmployeeModalOpen(false)} />
        <ManagerWelcomeWizardModal isOpen={isManagerModalOpen} onDone={() => setIsManagerModalOpen(false)} />
      </>}
    </StyledPoweredBy>
  )
}

const WelcomeMessageText = styled.div`
  display: flex;
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.NAVY_BLUE};
  justify-content: end;
`

const Icon = styled.div`
  vertical-align: middle;
  margin-right: 2px;
  margin-left: 4px;
`

const TangeloText = styled.div`
  font-weight: bold;
  font-size: 12px;
  color: black;
`

const PoweredByText = styled.div`
  font-weight: 300;
  font-size: 10px;
  color: ${COLORS.SIXES_GREY};
`

const Link = styled.a`
  position: absolute;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledPoweredBy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background-color: white;
  cursor: pointer;
  border-top: 1px solid rgba(204, 204, 204, 1);
  font-family: Poppins_400Regular;
  z-index: 99;
  padding: 0px 36px 0px 36px;
`

export default Footer
