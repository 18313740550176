import React, { useMemo } from 'react'
import { View, Text } from 'react-native'
import Image from 'components/common/Image'
import EStyleSheet from 'react-native-extended-stylesheet'
import COLORS from 'constants/colors'
import format from 'utils/date-fns'
import { isToday } from 'utils/date'
import { EVENT_TYPES, TASK_TYPES } from 'constants/types'
import { useTranslation } from 'react-i18next'
import { AccessTimeIcon } from 'components/common/custom-icons'
import { useQuery } from '@apollo/react-hooks'
import { GET_DEPENDENT_TASK_COMPLETED_INFO } from 'graphql/queries'
import TaskModalDependency from './TaskModalDependency'
import ImportantLabel from 'components/common/ImportantLabel'
import useUser from 'hooks/useUser'
import useCurrentLocation from 'hooks/useCurrentLocation'

const TaskModalHeader = ({
  completedEvent,
  task,
  snippetsMapping,
}: {
  completedEvent: TaskCompleteType
  task: TaskInterface
  snippetsMapping?: StringMap
}) => {
  const { t, i18n } = useTranslation()
  const { locationOwner } = useCurrentLocation()
  const { user } = useUser()
  const isCompleted = !!completedEvent?.createdAt
  const dueDate = getDateFromString(
    isCompleted ? completedEvent.createdAt : task.dueDate,
  )
  const dueDateText = format(
    dueDate,
    t('monthDayYearDateFormat'),
    i18n.resolvedLanguage,
  )
  const isDueToday = isToday(dueDate)
  const shouldShowDate = !!task.dueDate || isCompleted

  const { data: dependentTaskCompletedInfoData } = useQuery<{ dependentTaskCompletedInfo: DependentTaskInfo }>(GET_DEPENDENT_TASK_COMPLETED_INFO, {
    variables: {
      dependentTaskId: task.dependsOnTaskId
    },
    skip: !task.dependsOnTaskId
  })

  const notOwnerTask = useMemo(() => {
    if (!user?.id || !locationOwner?.id) {
      return false
    }
    return user?.id !== locationOwner?.id
  }, [user, locationOwner])

  function getDateFromString(date: string) {
    if (date?.length == 10) {
      // then it is in YYYY-MM-DD format, so we add the time
      return new Date(date.replace(/-/g, '/'))
    }

    return new Date(date)
  }

  const dependentTaskCompletedInfo = useMemo(() => {
    return dependentTaskCompletedInfoData?.dependentTaskCompletedInfo
  }, [dependentTaskCompletedInfoData])

  function renderEventText() {
    const { eventType } = completedEvent

    switch (eventType) {
      case EVENT_TYPES.ADD_CANT_TEXT:
        return t('markedCantComplete')
      case EVENT_TYPES.NOT_APPLICABLE:
        return t('markedNotApplicable')
      case EVENT_TYPES.TASK_READ:
        return t('markedRead')
      default:
        return t('completed')
    }
  }

  const Separator = () => (
    <Text
      style={{
        marginHorizontal: 16,
        color: '#7C8DA3',
      }}
    >
      |
    </Text>
  )

  const EstimateTime = () => (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Separator />
      {t('timeToComplete')}{' '}
      <AccessTimeIcon
        width={18}
        height={18}
        style={{
          marginLeft: 10,
          marginRight: 6,
        }}
      />
      {task.estimateInMins} {t('min')}
    </View>
  )

  return (
    <View style={[styles.headerContainer]}>
      <Image
        source={{
          uri:
            task.mediaUrl ??
            require('assets/images/png/default_task_image.png'),
        }}
        containerStyle={{
          width: 48,
          height: 48,
          marginRight: 36,
          alignSelf: 'center',
        }}
      />
      <View style={{ flex: 1 }}>
        {shouldShowDate && (
          <View style={styles.title}>
            <Text style={styles.titleDue}>
              {isCompleted
                ? renderEventText() : isDueToday
                  ? t('dueToday')
                  : t('due')}
            </Text>
            <Text style={styles.titleDate}>{dueDateText}</Text>
            {completedEvent?.completedBy && (
              <Text style={styles.titleDate}>
                {`${t('by')} ${completedEvent?.completedBy.fullName}`}
              </Text>
            )}
            {!!task.estimateInMins && <EstimateTime />}
            {!!task.important && <ImportantLabel withSeparator />}
          </View>
        )}
        <Text style={styles.titleText}>{task.title}</Text>
        {task.taskType == TASK_TYPES.INFO && (
          <Text style={styles.infoSubtitle}>{t('infoOnly')}</Text>
        )}
        {notOwnerTask && (
          <>
            <Text style={styles.title}>Task for: <Text style={{ fontWeight: '600' }}>{locationOwner?.fullName}</Text></Text>
            <View style={styles.ownerEmailHolder}>
              <Text style={styles.title}>{locationOwner?.email}</Text></View>
          </>
        )}
        {dependentTaskCompletedInfo && <TaskModalDependency completedInfo={dependentTaskCompletedInfo} snippetsMapping={snippetsMapping} />}
      </View>
    </View>
  )
}


const styles = EStyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    borderBottomColor: '#E6EAF1',
    borderBottomWidth: 1,
    paddingBottom: 24,
    marginLeft: 40,
    marginRight: 40,
    minHeight: 80,
  },
  title: {
    flexDirection: 'row',
    color: COLORS.FIVES_GREY,
    fontSize: 14,
    lineHeight: 21,
    alignItems: 'baseline',
  },
  titleText: {
    flex: 1,
    flexWrap: 'wrap',
    color: COLORS.NAVY_BLUE,
    fontSize: 22,
    fontWeight: '600',
    lineHeight: 33,
  },
  titleDue: {
    fontWeight: '600',
    marginRight: 4,
  },
  titleDate: {
    fontWeight: '300',
    whiteSpace: 'nowrap',
    marginRight: 4,
  },
  infoSubtitle: {
    flexDirection: 'row',
    color: COLORS.INFO_BLUE,
    fontSize: 14,
    lineHeight: 21,
    alignItems: 'baseline',
  },
  ownerEmailHolder: {
    paddingLeft: 60
  }
})

export default TaskModalHeader
