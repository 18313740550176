{
  "monthDayYearDateFormat": "LLLL d, y",
  "monthDayDateFormat": "LLLL d",
  "welcome": "Bem-vindo(a),\n{{name}}!",
  "clickOnTasksSection": "Clique na seção de tarefas para começar",
  "search": "Buscar",
  "typeHereToAddText": "Digite aqui...",
  "complete": "Concluir",
  "completed": "Concluída",
  "cantComplete": "Não é possível concluir",
  "cantCompleteTask": "Não é possível concluir a tarefa",
  "notes": "Notas da tarefa",
  "back": "Voltar",
  "sessionNotes": "Notas da sessão",
  "confirm": "Confirmar",
  "notApplicable": "Não aplicável",
  "logout": "Sair",
  "teamMetrics": "Métricas da equipe",
  "taskNotesDescription": "Use este espaço para adicionar notas relacionadas a esta tarefa",
  "comment": "Comentário",
  "pleaseSelect": "Por favor, selecione...",
  "eSignature": "Assinatura eletrônica",
  "takePicture": "Tirar foto",
  "accept": "Aceitar",
  "home": "Início",
  "tasksFor": "Tarefas para",
  "due": "Prazo",
  "dueToday": "Vence hoje",
  "tasks": "Tarefas",
  "task": "Tarefa",
  "tasksToDo": "{{tasks}} Tarefas pendentes",
  "taskToDo": "{{tasks}} Tarefa pendente",
  "infoTasksToDo": "{{numberInfoTasks}} Tarefas Somente de Informação",
  "infoTaskToDo": "{{numberInfoTasks}} Tarefa Somente de Informação",
  "infoTasks": "{{numberInfoTasks}} Tarefas de Informação",
  "infoTask": "{{numberInfoTasks}} Tarefa de Informação",
  "by": "por",
  "markedCantComplete": "marcada como Não é possível concluir",
  "markedNotApplicable": "marcada como Não aplicável",
  "markedRead": "Lido",
  "allTasksComplete": "Tudo {{tasks}} Tarefas concluídas",
  "someTasksComplete": "{{numberOfCompletedTasks}} de {{numberOfTasks}} Tarefas concluídas",
  "upNextTitle": "A seguir",
  "upNextSubtitle": "Acesse rapidamente suas próximas tarefas a partir de seu fluxo de trabalho",
  "upNextDoneTitle": "Por enquanto, está tudo feito!",
  "upNextDoneSubtitle": "Ótimo trabalho! Você não tem mais nada para fazer. Caso receba novas tarefas, você será informado(a) por e-mail!",
  "progress": "Progresso",
  "ago": "atrás",
  "dueIn": "Vence em",
  "days": "dias",
  "resources": "Recursos",
  "resourcesSubtitle": "Use esta lista selecionada de recursos em destaque",
  "yourResources": "Seus recursos",
  "yourResourcesSubtitle": "Acesso rápido a todos os seus links úteis",
  "reports": "Relatórios",
  "signIn": "Entrar",
  "forgotPassword": "Esqueceu a sua senha?",
  "emailAddress": "Endereço de e-mail",
  "password": "Senha",
  "newPassword": "Nova Senha",
  "repeatPassword": "Repetir senha",
  "passwordError": "As senhas devem ser iguais",
  "passwordLengthError": "A senha deve ter pelo menos 8 caracteres",
  "resetPassword": "Criar nova senha",
  "resetPasswordInfo": "Login com senha temporária foi bem-sucedido. \nPor favor, crie uma senha permanente",
  "forgottenPassword": "Esqueceu sua senha?",
  "forgottenPasswordExplanationWithMagicLink": "Digite seu endereço de e-mail abaixo e escolha entre enviar um e-mail de redefinição de senha ou um link mágico único que fará seu login instantaneamente!",
  "forgottenPasswordExplanation": "Digite seu endereço de e-mail abaixo para receber um e-mail de redefinição de senha e recuperar o acesso à sua conta.",
  "signInWithGoogle": "Entrar com o Google",
  "checkSignInLink": "Email enviado, verifique sua caixa de entrada",
  "next": "Próximo",
  "login": "Entrar",
  "sendSignInLink": "Enviar link de login",
  "en-US": "English",
  "en": "English",
  "fr-CA": "Français (Canada)",
  "fr": "Français",
  "es-ES": "Español",
  "es": "Español",
  "pt-BR": "Português",
  "pt": "Português",
  "de-DE": "Deutsch",
  "de": "Deutsch",
  "ja-JP": "日本",
  "ja": "日本",
  "zh-CN": "中文(简体)",
  "zh-TW": "中文(繁体)",
  "timeToComplete": "Tempo para completar",
  "min": "min",
  "high": "Alta",
  "poweredBy": "fornecido por",
  "uploading": "Enviando...",
  "uploadMedia": "Carregar mídia",
  "admin": "Admin",
  "showWorkflows": "Mostrar concluído",
  "showCompletedWorkflows": "Mostrar fluxos de trabalho concluídos",
  "infoOnly": "Apenas informações, nenhuma ação necessária para concluir",
  "delegate": "Pessoa Designada",
  "getOurApp": "Obtenha nosso aplicativo",
  "continueToApp": "Continuar para o aplicativo",
  "promptMessage": "Você tem alterações não salvas, tem certeza que deseja sair",
  "onboardingBuddy": "Amigo De Integração",
  "delegateAssignment": "Atribuição da pessoa designada",
  "onboardingBuddyAssignment": "Atribuição do amigo de integração",
  "assignments": "Atribuições",
  "delegateMainCopy": "Veja abaixo uma lista de todos os novos integrantes e a pessoa designada para concluir as tarefas de integração do Gerente de contratação, se selecionado.",
  "delegateEmailDescription": "Você pode usar o espaço abaixo para enviar uma mensagem sobre a delegação de tarefas.",
  "newHire": "Nova Contratação",
  "assignedDelegate": "delegado designado",
  "assignedOnboardingBuddy": "Amigo De Integração Atribuído",
  "activeAssignments": "Atribuições Ativas",
  "actions": "Ações",
  "add": "Adicionar",
  "remove": "Remover",
  "taskDelegation": "Delegação de tarefa",
  "delegateConfirmationCopy": "<p>As tarefas de integração do gerente de contratação agora serão delegadas ao indivíduo selecionado.</p><p>Observe que as tarefas de gerenciamento de desempenho não são elegíveis para delegação.</p>",
  "okay": "Ok",
  "activeAssignmentsCount": "Este usuário tem {{count}} atribuições ativas no momento.",
  "assign": "Designar",
  "name": "Nome",
  "assignDelegate": "Atribuir pessoa designada",
  "assignOnboardingBuddy": "Atribuir amigo de integração",
  "onboardingBuddyModalMainCopy": "Veja abaixo uma lista de todos os novos integrantes e seus amigos de integração atribuídos, se selecionados.",
  "removeDelegateUser": "Tem certeza de que deseja excluir o delegado de {{userName}}?",
  "removeOnboardingUser": "Tem certeza de que deseja excluir o amigo de integração de {{userName}}?",
  "refreshPage": "Atualizar página",
  "newAppVersionTitle": "Nova versão do aplicativo",
  "newAppVersionContent": "Há uma nova versão do aplicativo. Para garantir que o aplicativo funcione sem problemas, atualize a página.",
  "commentAdded": "Comentário adicionado:",
  "optionSelected": "Opção selecionada:",
  "mediaUploaded": "Mídia carregada:",
  "dependedOn": "Dependia de",
  "loading": "Carregando...",
  "fetchingResults": "Estamos buscando seus resultados, aguarde...",
  "workflows": "Fluxos de trabalho",
  "sections": "Seções",
  "seeAllMatching": "Veja todos os {{length}} correspondentes",
  "matches": "{{comprimento}} corresponde",
  "enterSearchTerm": "Digite o termo de busca...",
  "oops": "Ops, algo deu errado.",
  "upNextError": "Não foi possível carregar suas próximas tarefas. Tente novamente mais tarde.",
  "myTasks": "Minhas tarefas",
  "pastDue": "Atrasado",
  "tomorrow": "Amanhã",
  "dueYesterday": "Devido Ontem",
  "dueTomorrow": "Devido Amanhã",
  "aboutTheOrg": "Sobre a Empresa",
  "seeAll": "Ver Todos",
  "myRole": "Meu Cargo",
  "manager": "Gerente",
  "buddy": "Amigo",
  "myTeam": "Minha Equipe",
  "seeAllTasks": "Ver Todas as Tarefas",
  "viewWelcomeMessage": "Ver mensagem de boas-vindas",
  "calendar": "Calendário",
  "dueInSixMonthsPlus": "Vence em mais de 6 meses"
}