import React from 'react'
import { ImageBackground, StyleSheet, View } from 'react-native'

import useClientConfig from 'hooks/useClientConfig'

const Background = (props: { children: {} }) => {
  const { backgroundUrl } = useClientConfig()

  if (!!backgroundUrl) {
    return (
      <ImageBackground
        source={backgroundUrl}
        resizeMode="cover"
        style={styles.background}
      >
        {props.children}
      </ImageBackground>
    )
  }

  return <View style={styles.background}>{props.children}</View>
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
    justifyContent: 'center',
  },
})

export default Background
