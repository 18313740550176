import { AccessTimeIcon, PriorityIcon } from 'components/common/custom-icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'react-native'
import ImportantLabel from 'components/common/ImportantLabel'
import DueDateText, { DueDateTextMode } from 'components/common/DueDateText'

const TaskSubtitle = ({
  estimateInMins,
  important,
  dueDate,
}: {
  estimateInMins?: number
  important: boolean
  dueDate?: string
}) => {
  const { t } = useTranslation()

  const EstimateTime = () => (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 12,
      }}
    >
      <AccessTimeIcon
        style={{
          marginRight: 6,
        }}
        width={16}
        height={16}
      />
      <Text
        style={{
          fontSize: 12,
        }}
      >
        {estimateInMins} {t('min')}
      </Text>
    </View>
  )



  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {!!dueDate && <DueDateText date={dueDate} textMode={DueDateTextMode.MEDIUM} />}
      {!!dueDate && (!!estimateInMins || !!important) && <Text style={{ marginRight: 6, marginLeft: 6 }}>•</Text>}
      {!!estimateInMins && <EstimateTime />}
      {!!important && <ImportantLabel fontSize={12} />}
    </View>
  )
}

export default TaskSubtitle
