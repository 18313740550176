import React, { ReactNode, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-native'

import Header from 'components/header/Header'
import Breadcrumbs from 'components/navigator/Breadcrumbs'
import COLORS from 'constants/colors'
import {
  ExpandArrowRightIcon,
} from 'components/common/custom-icons'
import { ROUTE_NAMES } from 'navigation/constants'
import Background from './Background'
import Footer from './Footer.web'

const ScreenWrapper = ({
  menuComponent,
  contentComponent,
  currentUser,
}: {
  menuComponent?: ReactNode
  contentComponent?: ReactNode
  currentUser: User
}) => {
  const [expanded, setExpanded] = useState(false)
  const [showExpandIcon, setShowExpandIcon] = useState(false)
  const location = useLocation()

  const handleExpandClick = () => {
    setExpanded((prevValue) => !prevValue)
  }

  useEffect(() => {
    if (location.pathname !== ROUTE_NAMES.TASKS_TODAY) {
      setExpanded(false)
      setShowExpandIcon(false)
    } else {
      setShowExpandIcon(true)
    }
  }, [location.pathname])


  return (
    <Background>
      <StyledScreenWrapper>
        <Header currentUser={currentUser} />
        <div className="content-wrapper">
          <div
            className="categories-wrapper"
            style={expanded ? { maxWidth: '150px' } : { minWidth: '500px' }}
          >
            <Breadcrumbs
              showLocationName={!expanded}
            />
            {menuComponent && menuComponent}
          </div>
          <div id="screen-wrapper-content" className="content">
            {showExpandIcon && (
              <div
                className="expand-icon"
                onClick={handleExpandClick}
                style={!expanded ? { transform: 'rotate(180deg)' } : {}}
              >
                <ExpandArrowRightIcon />
              </div>
            )}
            {contentComponent && contentComponent}
          </div>
        </div>
        <Footer />
      </StyledScreenWrapper>
    </Background>
  )
}

const StyledScreenWrapper = styled.div`
  @media (max-width: 1400px) {
    width: 1200px;
  }
  @media (min-width: 1400px) {
    width: 1420px;
  }
  flex: 1;
  align-self: center;
  width: 100%;
  height: 100%;
  font-family: Poppins_400Regular;
  overflow: hidden;

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: auto;
    margin-right: auto;
  }

  .categories-wrapper {
    display: flex;
    flex-direction: column;
    background-color: #EEF4FF;
    width: 100%;
    max-width: 500px;
    padding: 6px 12px 0px 12px;
  }

  .content-wrapper {
    height: calc(100% - 108px);
    display: flex;

    .content {
      background-color: white;
      display: flex;
      width: 100%;
      border-right: 1px solid ${COLORS.borderForm};
      overflow-y: scroll;
      padding-left: 12px;
      margin-left: -12px;
      .expand-icon {
        height: fit-content;
        position: absolute;
        margin-top: 15px;
        margin-left: -20px;
        cursor: pointer;
        z-index: 999;
      }
    }
  }
`

export default ScreenWrapper
