import React, { useContext, useState } from 'react'
import styled from 'styled-components';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import SearchIcon from '@mui/icons-material/Search';
import COLORS from 'constants/colors';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MyTeamContext } from 'components/my-team/MyTeamContext';
import { debounce } from 'lodash';

const MyTeamSidebarHeader = () => {
  const [searchOpen, setSearchOpen] = useState(false);
  const { setSearchTerm, setSelectedReportee } = useContext(MyTeamContext)

  const debouncedSetSearchTerm = debounce((value) => {
    setSearchTerm(value);
  }, 500);

  return <Header>
    <PeopleOutlineIcon sx={{ color: COLORS.TURQUOISE, marginRight: '16px' }} />
    <HeaderTitle>My Team</HeaderTitle>
    {searchOpen && <TextField
      size='small'
      variant="standard"
      fullWidth
      onChange={(event) => {
        debouncedSetSearchTerm(event.target.value.toLowerCase())
      }}
      placeholder="Search…"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setSearchOpen(!searchOpen)
                setSearchTerm('')
              }}
            >
              <CloseIcon sx={{ fontSize: '14px' }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />}
    {!searchOpen && <SearchIcon
      onClick={() => {
        setSelectedReportee(null)
        setSearchOpen(!searchOpen)
      }}
      sx={{ color: COLORS.NAVY_BLUE }}
    />}
  </Header>
}

const HeaderTitle = styled.div`
  display: flex;
  flex: 1;
  font-size: 24px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  min-width: 150px;
  color: ${COLORS.NAVY_BLUE};
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 36px 24px 36px;
  border-bottom: 1px solid #CCCCCC;
`

export default MyTeamSidebarHeader