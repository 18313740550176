import COLORS from 'constants/colors'

const myTeamStringAvatar = (name: string, color?: string, colorBrightness = 'light') => {
  return {
    sx: {
      backgroundColor: `${color || COLORS.TURQUOISE} !important`,
      color: colorBrightness == 'light' ? 'white !important' : ' black !important',
      width: '36px',
      height: '36px',
      fontSize: '12px',
      fontWeight: '600',
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  }
}

export default myTeamStringAvatar
