import React, { useEffect, useMemo } from 'react'
import { Menu, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/react-hooks'
import { UPDATE_USER_LOCALE } from 'graphql/mutations'
import { useHistory } from 'react-router-native'
import AsyncStorage from '@react-native-async-storage/async-storage'

import { CACHE_KEYS } from 'constants/types'
import useUser from 'hooks/useUser'
import useClientConfig from 'hooks/useClientConfig'
import useLanguages from 'hooks/useLanguages'

const LanguageMenu = () => {
  const [updateUserLocale] = useMutation(UPDATE_USER_LOCALE)
  const { user, loading } = useUser()
  const { enableWelcomeModal } = useClientConfig()
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const languages = useLanguages()
  const selectedLanguage = useMemo(() => languages.find(({ language }) => language === i18n.resolvedLanguage), [languages])
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);


  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!loading && !user?.preferredLocale && !enableWelcomeModal) {
      updateUserLocale({
        variables: {
          attributes: {
            preferredLocale: i18n.resolvedLanguage,
          },
        },
      })
    }
  }, [loading, user, enableWelcomeModal])

  const changeLanguage = async (language: string) => {
    i18n.changeLanguage(language)

    await AsyncStorage.setItem(CACHE_KEYS.SELECTED_LANGUAGE, language)

    await updateUserLocale({
      variables: {
        attributes: {
          preferredLocale: language,
        },
      },
    })

    history.push('/')
  }

  if (!selectedLanguage) {
    return null
  }

  return (
    <>
      <div
        onClick={handleClick}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        {selectedLanguage.title}
        {selectedLanguage.flag}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {languages.map(({ language, flag, title }) => (
          <MenuItem
            key={language}
            onClick={() => changeLanguage(language)}
            selected={selectedLanguage.language === language}
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
              }}
            >
              {title}
              {flag}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default LanguageMenu
