import * as React from "react"
import Svg, { SvgProps, Circle, Path } from "react-native-svg"

const VisibilityIcon = (props: SvgProps) => (
  <Svg
    fill="none"
    viewBox="0 0 36 36"
    {...props}
  >
    <Circle cx={18} cy={18} r={18} fill={props.color} />
    <Path
      d="M8.886 19.927C10.814 21.844 14.176 24.5 18 24.5s7.186-2.656 9.114-4.573c.509-.505.764-.759.925-1.255.116-.354.116-.99 0-1.344-.161-.496-.416-.75-.925-1.255C25.186 14.156 21.825 11.5 18 11.5s-7.187 2.656-9.114 4.573c-.509.506-.763.759-.925 1.255-.116.354-.116.99 0 1.344.162.496.416.75.925 1.255z"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
    <Path
      d="M15.834 18a2.167 2.167 0 1 0 4.333 0 2.167 2.167 0 0 0-4.333 0z"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </Svg>
)

export default VisibilityIcon
