import React from 'react'
import styled from 'styled-components'
import MyTeamSidebarHeader from './MyTeamSidebarHeader';
import MyTeamSidebarList from './MyTeamSidebarList';

const MyTeamSidebar = () => {
  return <Container>
    <MyTeamSidebarHeader />
    <MyTeamSidebarList />
  </Container>
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 430px;
`

export default MyTeamSidebar