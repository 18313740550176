import { useQuery } from '@apollo/react-hooks'
import { FETCH_POLICIES } from 'constants/api'
import COLORS from 'constants/colors'
import { GET_TEMPLATE_ROLES, GET_TEMPLATE_ROLE_TEXTS } from 'graphql/queries'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Platform } from 'react-native'
import { SearchBar } from '@rneui/themed'
import styled from 'styled-components'
import { CheckBox } from '@rneui/themed'

import Breadcrumbs from 'components/navigator/Breadcrumbs'
import { IS_WEB } from 'constants/static'
import { chain, debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import EStyleSheet from 'react-native-extended-stylesheet'
import { useHeaderContext } from 'utils/header-context'
import UserRoleAssigneesTable from './UserRoleAssigneesTable.web'
import { useHistory, useLocation } from 'react-router-native'
import { ROUTE_NAMES } from 'navigation/constants'
import useCopyByRole from './useCopyByRole'
import MESSAGES from 'constants/messages'
import useUser from 'hooks/useUser'
import PageFrame from 'components/common/PageFrame'

const SearchInput = ({
  searchText,
  setSearchText,
}: {
  searchText: string
  setSearchText: (s: string) => void
}) => {
  const { t } = useTranslation()
  const [searchTextInternal, setSearchTextInternal] = useState(searchText)
  const handleSearch = (text: string) => {
    setSearchTextInternal(text)
    setSearchText(text)
  }

  return (
    <SearchBar
      lightTheme={true}
      platform={Platform.OS}
      placeholder={t('search')}
      onChangeText={handleSearch}
      value={searchTextInternal}
      clearIcon={{
        iconStyle: [styles.searchCancelButton],
      }}
      containerStyle={{
        backgroundColor: 'transparent',
        width: '200px',
        borderBottomWidth: 0,
        borderTopWidth: 0,
        paddingHorizontal: 0,
        paddingTop: 18,
        paddingBottom: 18,
      }}
      inputContainerStyle={{
        backgroundColor: 'white',
        borderRadius: 16,
        shadowColor: 'rgba(0, 0, 0, 0.08)',
      }}
      inputStyle={[
        {
          fontSize: IS_WEB ? 18 : 14,
          paddingLeft: 6,
          color: COLORS.MIDDLE_GREY,
        },
        IS_WEB ? { outline: 'none' } : {},
      ]}
      searchIcon={{
        iconStyle: { fontSize: 24, color: COLORS.NAVY_BLUE },
      }}
    />
  )
}
function useLocationQuery() {
  const location = useLocation()
  const { search } = location

  return React.useMemo(() => new URLSearchParams(search), [search])
}
const UserRoleAssigneesScreen = () => {
  const history = useHistory()
  const locationQuery = useLocationQuery()
  const { setOptions } = useHeaderContext()
  const { t } = useTranslation()
  const { copyByRole } = useCopyByRole()
  const [selectedTemplateRole, setSelectedTemplateRole] =
    useState<TemplateRole | null>(null)
  const { user } = useUser()
  const [searchText, setSearchText] = useState('')
  const [filterByHasRoleAssignments, setFilterByHasRoleAssignments] = useState(false)

  const { data: templateRolesData } = useQuery<{
    templateRoles: TemplateRole[]
  }>(GET_TEMPLATE_ROLES, {
    variables: { showHidden: true, onlyAssignable: true },
    fetchPolicy: FETCH_POLICIES.CACHE_AND_NETWORK,
  })

  const { data: templateRoleTextsData, loading: isLoadingTemplateRoleTexts } = useQuery<{ templateRoleTexts: TemplateRoleText[] }>(GET_TEMPLATE_ROLE_TEXTS, {
    fetchPolicy: FETCH_POLICIES.CACHE_AND_NETWORK,
  })
  const debounceSearch = useCallback(
    debounce((nextValue: string) => setSearchText(nextValue), 300),
    [],
  )

  const templateRolesCopiesMap = useMemo(() => {
    if (!templateRoleTextsData?.templateRoleTexts || !templateRolesData?.templateRoles) {
      return {}
    }

    const { templateRoleTexts } = templateRoleTextsData

    const copiesMap = chain(templateRoleTexts)
      .groupBy('templateRoleId')
      .mapValues((value) => chain(value)
        .keyBy('textType')
        .mapValues('text')
        .value()
      )
      .value()

    templateRolesData.templateRoles.forEach(({ name, id }) => {
      const defaultValues = {
        modal_header: MESSAGES.TEMPLATE_ROLE_USER_ASSIGNMENT_TEXT(name),
        email: MESSAGES.TEMPLATE_ROLE_USER_ASSIGNMENT_EMAIL_TEXT(name),
      }
      copiesMap[id] = Object.assign(defaultValues, copiesMap[id] || {})
    })

    return copiesMap

  }, [templateRoleTextsData])


  useEffect(() => {
    setOptions({
      title: [t('assignments')],
      showBack: true,
    })
  }, [])

  useEffect(() => {
    if (templateRolesData?.templateRoles?.length) {
      const templateRoleId = locationQuery.get('roleId')

      const newSelectedTemplateRole =
        templateRolesData.templateRoles.find(
          ({ id }) => id.toString() === templateRoleId,
        ) || templateRolesData.templateRoles[0]
      setSelectedTemplateRole(newSelectedTemplateRole)
    }
  }, [templateRolesData, locationQuery])

  return <PageFrame>
    <Breadcrumbs
      showLocationName={true}
    ></Breadcrumbs>
    <ContentWrap>
      <p>
        {selectedTemplateRole?.name &&
          copyByRole(selectedTemplateRole.name, 'main')}
      </p>
      <SearchInput
        searchText={searchText}
        setSearchText={debounceSearch}
      ></SearchInput>
      <div>
        <CheckBox
          checked={filterByHasRoleAssignments}
          onPress={() => setFilterByHasRoleAssignments(!filterByHasRoleAssignments)}
          title={`Users with ${selectedTemplateRole?.name} tasks assigned`}
        />


      </div>
      <TabsAndTableWrap>
        <TabsListWrap>
          {!!templateRolesData?.templateRoles?.length && (
            <TabsList>
              {templateRolesData?.templateRoles?.map((templateRole) => {
                return (
                  <TabItem
                    key={templateRole.id}
                    className={
                      templateRole.id == selectedTemplateRole?.id
                        ? 'selected'
                        : ''
                    }
                    onClick={() => {
                      history.push({
                        pathname: ROUTE_NAMES.USER_ROLE_ASSIGNEES,
                        search: `?roleId=${templateRole.id}`,
                      })
                    }}
                  >
                    {copyByRole(templateRole.name, 'roleName')}
                  </TabItem>
                )
              })}
            </TabsList>
          )}
        </TabsListWrap>
        <UserRoleAssigneesTable
          templateRoleCopiesMap={templateRolesCopiesMap[selectedTemplateRole?.id] || {}}
          selectedTemplateRole={selectedTemplateRole}
          query={searchText}
          filterByHasRoleAssignments={filterByHasRoleAssignments}
        />
      </TabsAndTableWrap>
    </ContentWrap>
  </PageFrame>
}

const styles = EStyleSheet.create({
  container: {
    paddingTop: 0,
    flex: 1,
    paddingBottom: IS_WEB ? 0 : 10,
  },
  searchCancelButton: {
    paddingHorizontal: 12,
    paddingVertical: 10,
  },
  locationSearchWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  scrollView: {
    paddingBottom: 12,
  },
  switchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 5,
    paddingVertical: 10,
  },
  switchText: {
    paddingLeft: 5,
    fontWeight: 600,
  },
})

const ContentWrap = styled.div`
  margin: 0 12px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const TabsAndTableWrap = styled.div`
  border-radius: 16px 16px 0 0;
  background: #fff;
  flex-grow: 1;
`
const TabsListWrap = styled.div`
  background: #ffffff;
  /* scroll under shadow */

  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
`
const TabsList = styled.ul`
  margin-top: 0;
  list-style-type: none;
  overflow: hidden;
  padding: 0 26px;
`
const TabItem = styled.li`
  float: left;
  border-bottom: 6px solid transparent;
  margin-right: 45px;
  font-size: 16px;
  padding: 12px 0 6px 0;
  font-family: 'Poppins_500Medium';
  cursor: pointer;
  color: #8d8d8d;
  text-transform: uppercase;
  &.selected,
  &:hover {
    color: ${COLORS.GRAYDARK3};
    border-bottom-color: ${COLORS.TURQUOISE};
  }

  &:last-child {
    margin-right: 0;
  }
`

export default UserRoleAssigneesScreen
