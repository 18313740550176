import React from 'react'
import { TouchableOpacity, Text } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { useTranslation } from 'react-i18next'

function ForgotPasswordButton({ onPress }: { onPress: () => void }) {
  const { t } = useTranslation()

  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <Text style={styles.text}>{t('forgotPassword')}</Text>
    </TouchableOpacity>
  )
}

const styles = EStyleSheet.create({
  container: {
    marginTop: 12,
    width: '100%',
  },
  text: {
    textAlign: 'right',
    color: '#2A3749',
    fontWeight: '400',
    fontFamily: 'Poppins_400Regular',
  },
})

export default ForgotPasswordButton
