import { useLazyQuery } from "@apollo/react-hooks";
import { GET_TASK_FROM_TEMPLATE } from "graphql/queries";
import { ROUTE_NAMES } from "navigation/constants";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-native";

const TaskRedirect = () => {
  const history = useHistory()
  const location = useLocation()
  const params = new URLSearchParams(location.search);
  const templateTaskId = params.get('templateTaskId');

  const [getTaskFromTemplate, { data }] = useLazyQuery(GET_TASK_FROM_TEMPLATE)

  const redirectToHome = () => {
    history.push({
      pathname: ROUTE_NAMES.HOME
    })
  }

  useEffect(() => {
    if (templateTaskId) {
      getTaskFromTemplate({
        variables: {
          attributes: {
            templateTaskId
          }
        }
      })
    } else {
      redirectToHome()
    }
  }, [templateTaskId])

  useEffect(() => {
    if (data) {
      const locationHierarchyId = data.getTaskFromTemplate?.locationHierarchyId
      const taskId = data.getTaskFromTemplate?.taskId

      if (!locationHierarchyId || !taskId) {
        redirectToHome()
      } else {
        history.push({
          pathname: ROUTE_NAMES.TASKS_TODAY,
          search: `?locationHierarchyId=${locationHierarchyId}&taskId=${taskId}`
        })
      }
    }
  }, [data])

  return <div>Loading...</div>
}

export default TaskRedirect
