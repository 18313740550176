import React, { useMemo } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GET_TAXONOMY_TERM_HIERARCHY, GET_USER } from 'graphql/queries'
import { TextField } from '@mui/material';
import styled from 'styled-components';
import COLORS from 'constants/colors'

const findChildById = (taxonomyTerm, id) => {
  for (const child of taxonomyTerm) {
    if (child.id === id) {
      return child;
    }

    if (child.children) {
      const foundChild = findChildById(child.children, id);
      if (foundChild) {
        return foundChild;
      }
    }
  }

  return null;
};


const QuestionSubmittedUpdateEmployee = ({
  answers,
}: {
  answers?: SubmittedQuestionOptionType[]
}) => {
  const taxonomyId = useMemo(() => {
    return answers[0].taxonomyIdSnapshot
  }, [answers])

  const { data: taxonomiesTermData } = useQuery(GET_TAXONOMY_TERM_HIERARCHY, {
    variables: {
      taxonomyId: taxonomyId,
    },
    skip: !taxonomyId,
  })

  const roleUserId = useMemo(() => {
    return answers[0].roleIdSnapshot
  }, [answers])

  const { data: usersData } = useQuery(GET_USER, {
    variables: {
      id: answers[0].comment,
    },
    skip: !roleUserId,
  })

  const answer = useMemo(() => {
    if (taxonomiesTermData) {
      return findChildById(taxonomiesTermData.taxonomyTermHierarchy, answers[0].comment).title
    }

    if (usersData) {
      return `${usersData.user.firstName} ${usersData.user.lastName} - ${usersData.user.email}`
    }

    return answers[0].comment
  }, [taxonomiesTermData, usersData, taxonomyId])

  return <>
    <Title>{answers[0].titleSnapshot}</Title>
    <TextField
      fullWidth
      value={answer}
      disabled
      sx={{
        backgroundColor: 'white',
        borderColor: COLORS.POWDER_BLUE,
        borderWidth: '2px',
        borderRadius: '6px',
      }}
    />
  </>
}

const Title = styled.div`
  font-size: 16px;
  line-height: 27px;
  color: ${COLORS.NAVY_BLUE};
  margin-bottom: 4px;
  margin-left: 4px;
  overflow-wrap: break-word;
`

export default QuestionSubmittedUpdateEmployee

