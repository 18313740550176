import React from 'react'
import { ImageBackground } from 'react-native'
import styled from 'styled-components'
import COLORS from 'constants/colors'
const barsImage = require('assets/images/png/bars.png')

const WorkflowsProgress = ({ titles, bars }: { titles: string[], bars: any }) => {

  return <Container>
    <WorkflowProgressTitles>
      {titles.map((title, index) => <WorkflowProgressTitle key={index}>{title}</WorkflowProgressTitle>)}
    </WorkflowProgressTitles>

    <WorkflowProgressBars>
      <ImageBackground source={barsImage} style={{ width: 'auto', height: '132px' }}>
        <ProgressBars>
          {bars}
        </ProgressBars>
      </ImageBackground>

      <PercentageScale>
        <span>0%</span>
        <span>50%</span>
        <span>100%</span>
      </PercentageScale>
    </WorkflowProgressBars>
  </Container>
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const WorkflowProgressTitle = styled.div`
  font-size: 12px;
  color: ${COLORS.GRAYDARK3};
  margin-bottom: 20px;
`

const WorkflowProgressTitles = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 35px;
  margin-top: 16px;
`

const ProgressBars = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`

const PercentageScale = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const WorkflowProgressBars = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export default WorkflowsProgress