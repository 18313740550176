import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import WelcomeWizardCommonStep from './WelcomeWizardCommonStep'

const WelcomeWizardStep2 = () => {
  const { t } = useTranslation()

  return <WelcomeWizardCommonStep
    title={'My Tasks'}
    description={<Trans i18nKey='welcomeStep2Description' />}
    imageSrc={require('assets/images/png/OnboardingStep2.png')}
  />
}

export default WelcomeWizardStep2