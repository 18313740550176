import React, {
  useEffect,
  useMemo,
} from 'react'
import { Text, View } from 'react-native'
import { useLazyQuery } from '@apollo/react-hooks'
import { Loader } from 'components/common/Loader'
import { IS_WEB } from 'constants/static'

import {
  GET_USER_SNIPPET_VALUES
} from 'graphql/queries'
import TaskListMapping from './TaskListMapping'
import GetAppModal from 'components/pop-ups/GetAppModal.web'
import useCurrentLocation from 'hooks/useCurrentLocation'
import useTasks from 'hooks/useTasks'

// component to render the task list
export default function TaskTodayScreen() {
  const { currentLocationHierarchyId, locationOwner } = useCurrentLocation()

  const { tasks, completedTaskEvents, loading, error, refetch } = useTasks(currentLocationHierarchyId)

  const [getUserSnippetValues, { data: userSnippetValuesData }] = useLazyQuery(
    GET_USER_SNIPPET_VALUES,
  )

  useEffect(() => {
    if (!locationOwner) {
      return
    }

    getUserSnippetValues({ variables: { userId: locationOwner.id } })
  }, [locationOwner])

  const snippetsMapping = useMemo(() => {
    if (!userSnippetValuesData) {
      return {}
    }
    return userSnippetValuesData.userSnippetValues
  }, [userSnippetValuesData])

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <Text>An error occurred while fetching tasks ...</Text>
  }

  return (
    <View style={{ flex: 1 }}>
      {IS_WEB && <GetAppModal />}

      <TaskListMapping
        tasks={tasks}
        locationHierarchyId={currentLocationHierarchyId}
        completedTaskEvents={completedTaskEvents}
        loading={loading}
        refetch={refetch}
        snippetsMapping={snippetsMapping}
      />
    </View>
  )
}
