import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import WelcomeWizardCommonStep from './WelcomeWizardCommonStep'

const WelcomeWizardStep4 = () => {
  const { t } = useTranslation()

  return <WelcomeWizardCommonStep
    title={'Buddies'}
    description={<Trans i18nKey='welcomeStep5Description' />}
    imageSrc={require('assets/images/png/OnboardingStep5.png')}
  />
}

export default WelcomeWizardStep4