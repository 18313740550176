import React from 'react'
import styled from 'styled-components/native'
import VideoSource from './VideoSource'
import useCreateEvent from 'components/task-complete/hooks/useCreateEvent'
import { EVENT_TYPES } from 'constants/types'

const VideoTask = ({ task,
  locationHierarchyId,
  completedEvent,
}: {
  task: TaskInterface,
  locationHierarchyId: string,
  completedEvent: TaskCompleteType
}) => {
  const createEvent = useCreateEvent({
    taskId: task.id,
    locationHierarchyId,
  })

  const onEnded = () => {
    createEvent({ eventType: EVENT_TYPES.VIDEO_WATCHED, createEventData: { eventType: EVENT_TYPES.VIDEO_WATCHED } })
  }

  return <Container>
    <VideoSource
      src={task.videoUrl}
      onEnded={onEnded}
      completedEvent={completedEvent}
      completeAtPercentage={task.videoPercentageComplete}
    />
  </Container>
}

const Container = styled.View`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`

export default VideoTask