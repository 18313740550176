import React from 'react'
import { View, Text } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import Icon from 'components/common/Icon'
import { ICON_NAMES } from 'constants/icons'
import COLORS from 'constants/colors'

const ExpiredLabel = () => (
    <View style={styles.container}>
        <Icon name={ICON_NAMES.EXPIRED} size={14} color={COLORS.ORANGE} />
        <Text style={styles.text}>Expired</Text>
    </View>
)

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 8,
        gap: 4,
    },
    text: {
        fontSize: 12,
        color: COLORS.ORANGE,
        fontWeight: '500',
    }
})

export default ExpiredLabel