import React, { useMemo } from 'react'
import styled from 'styled-components/native'

import COLORS from 'constants/colors'
import { replaceSnippetsInText } from 'utils/helpers/snippets-helper'
import { useTranslation } from 'react-i18next'

const QuestionHeader = (
  {
    index,
    total,
    title,
    description,
    required,
    survey = false,
    snippetsMapping,
  }: {
    index: number,
    total: number,
    title: string,
    description?: string,
    required: boolean,
    survey?: boolean,
    snippetsMapping?: StringMap,
  }
) => {
  const { i18n } = useTranslation()

  const handleMissingSnippet = () => (key: string) => {
    console.error(`Missing snippet: ${key}`)
  }

  const titleWithSnippets = useMemo(
    () =>
      replaceSnippetsInText(
        title,
        snippetsMapping,
        handleMissingSnippet,
        i18n.resolvedLanguage,
      ),
    [title, snippetsMapping],
  )

  const descriptionWithSnippets = useMemo(
    () => {
      if (!description || description.length == 0) return null

      return replaceSnippetsInText(
        description,
        snippetsMapping,
        handleMissingSnippet,
        i18n.resolvedLanguage
      )
    },
    [description, snippetsMapping],
  )

  return <>
    {!survey && <Subtitle>Question {index} of {total}</Subtitle>}
    <Title>{titleWithSnippets}{required && <Required>*</Required>}</Title>
    {descriptionWithSnippets && <Description>{descriptionWithSnippets}</Description>}
  </>
}

const Required = styled.Text`
  color: #D30000;
`

const Subtitle = styled.Text`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #64748B;
`

const Title = styled.Text`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: ${COLORS.NAVY_BLUE};
  margin-bottom: 20px;
  overflow-wrap: break-word;
`

const Description = styled.Text`
  font-weight: 400;
  font-size: 16px;
  color: ${COLORS.NAVY_BLUE};
  margin-bottom: 20px;
  overflow-wrap: break-word;
`

export default QuestionHeader