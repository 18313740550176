import React, { useState } from 'react'
import { View, Text, TouchableOpacity, Linking, Image, ScrollView } from 'react-native'
import Modal from 'react-native-modal/dist/modal'
import { stylesGlobal } from 'constants/es-style'
import COLORS from 'constants/colors'
import { Icon } from 'components/Index'
import { ICON_NAMES } from 'constants/icons'
import { EVENT_TYPES } from 'constants/types'
import { useTranslation } from 'react-i18next'
import EStyleSheet from 'react-native-extended-stylesheet'
import SubmittedQuestions from '../questions/SubmittedQuestions'

const TaskModalDependency = ({
  completedInfo,
  snippetsMapping,
}: {
  completedInfo: DependentTaskInfo
  snippetsMapping: StringMap
}) => {
  const { t } = useTranslation()
  const [isDependsOnModalOpen, setIsDependsOnModalOpen] = useState(false)

  const Result = () => {
    switch (completedInfo.eventType) {
      case EVENT_TYPES.COMPLETE_TASK:
        return <Text>{t('completed')}</Text>
      case EVENT_TYPES.ADD_CANT_TEXT:
        return <Text>{t('markedCantComplete')}</Text>
      case EVENT_TYPES.NOT_APPLICABLE:
        return <Text>{t('markedNotApplicable')}</Text>
      case EVENT_TYPES.ADD_TEXT:
        return <Text>{t('commentAdded')} {completedInfo.text}</Text>
      case EVENT_TYPES.MULTIPLE_OPTION:
        return <Text>{t('optionSelected')} <Text style={{ fontWeight: '600' }}>{completedInfo.text}</Text></Text>
      case EVENT_TYPES.UPLOAD_MEDIA:
      case EVENT_TYPES.SEND_SAFELY:
        return <View>
          <Text>{t('mediaUploaded')}</Text>
          {completedInfo.mediaUrls.map(url => {
            const split = url.split('/')
            const fileName = split[split.length - 1]

            return <Text
              style={{
                color: COLORS.TURQUOISE,
                textDecorationLine: 'underline',
              }}
              onPress={() => Linking.openURL(url)}
            >
              {fileName}
            </Text>
          })

          }
        </View>
      case EVENT_TYPES.TAKE_PHOTO:
        return <View
          style={{
            alignItems: 'center',
          }}
        >
          <Image
            style={{
              width: 500,
              height: 400,
            }}
            source={{ uri: completedInfo.imageUrl }}
          />
        </View>
      case EVENT_TYPES.QUIZ_COMPLETED:
        return <SubmittedQuestions
          lastSubmission={completedInfo.lastQuestionsSubmission}
          survey={false}
          snippetsMapping={snippetsMapping}
        />
      case EVENT_TYPES.SURVEY_SUBMITTED:
        return <SubmittedQuestions
          lastSubmission={completedInfo.lastQuestionsSubmission}
          survey={true}
          snippetsMapping={snippetsMapping}
        />
      case EVENT_TYPES.SCORECARD_SUBMITTED:
        return <SubmittedQuestions
          lastSubmission={completedInfo.lastQuestionsSubmission}
          scorecard={true}
          snippetsMapping={snippetsMapping}
        />
    }
  }

  return <>
    <View>
      <TouchableOpacity onPress={() => setIsDependsOnModalOpen(true)}>
        <Text style={styles.title}>
          {t('dependedOn')} <Text style={{ fontWeight: '600' }}>{completedInfo.taskTitle}</Text>

          <Icon
            name={ICON_NAMES.ARROW_EXPAND}
            size={14}
            style={{ marginLeft: 6 }}
          />
        </Text>
      </TouchableOpacity>
    </View>
    <Modal style={stylesGlobal.modal} isVisible={isDependsOnModalOpen}>
      <View style={[stylesGlobal.modalContent, { maxHeight: 500 }]}>
        <View style={stylesGlobal.modalContentHeader}>
          <Text style={stylesGlobal.modalContentHeaderText}>
            {completedInfo.taskTitle}
          </Text>
          <TouchableOpacity onPress={() => setIsDependsOnModalOpen(false)}>
            <Icon name={ICON_NAMES.CLOSE} size={30} onPress={() => setIsDependsOnModalOpen(false)} />
          </TouchableOpacity>
        </View>
        <ScrollView>
          <Result />
        </ScrollView>
      </View>
    </Modal>
  </>
}

const styles = EStyleSheet.create({
  title: {
    flexDirection: 'row',
    color: COLORS.FIVES_GREY,
    fontSize: 14,
    lineHeight: 21,
    alignItems: 'baseline',
  },
})

export default TaskModalDependency
