import React from 'react'
import { TouchableOpacity, Text } from 'react-native'
import { Icon } from 'components/Index'
import { ICON_NAMES } from 'constants/icons'
import EStyleSheet from 'react-native-extended-stylesheet'
import COLORS from 'constants/colors'

function LoginButton({
  disabled,
  onPress,
  text,
  showNextArrow,
}: {
  disabled: boolean
  text: string
  onPress: () => void
  showNextArrow: boolean
}) {
  return (
    <TouchableOpacity
      style={[styles.loginButton, disabled ? styles.disabled : {}]}
      onPress={onPress}
      activeOpacity={0.8}
      disabled={disabled}
      testID="button-login"
    >
      <Text style={[styles.loginButtonText]}>{text}</Text>
      {showNextArrow && (
        <Icon name={ICON_NAMES.GO_BACK} style={styles.nextArrow} />
      )}
    </TouchableOpacity>
  )
}

const styles = EStyleSheet.create({
  loginButton: {
    marginTop: 48,
    width: '100%',
    height: 56,
    backgroundColor: COLORS.TURQUOISE,
    padding: 10,
    borderRadius: 12,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loginButtonText: {
    textAlign: 'center',
    color: 'white',
    fontWeight: '600',
    fontFamily: 'Poppins_400Regular',
  },
  nextArrow: {
    paddingVertical: 10,
    paddingRight: 16,
    fontSize: 14,
    color: 'white',
    transform: [{ rotateY: '180deg' }],
  },
  disabled: {
    backgroundColor: '#CCCCD6',
  },
})

export default LoginButton
