import { createContext } from "react"

export const MyTeamContext = createContext<{
  reportees?: User[],
  loading: boolean,
  selectedReportee?: User | null,
  setSelectedReportee: (reportee: User) => void
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
  reporteesWorkflows: any,
  setReporteesWorkflows: (reporteesWorkflows: any) => void
}>({
  reportees: [],
  loading: true,
  selectedReportee: null,
  setSelectedReportee: () => { },
  searchTerm: '',
  setSearchTerm: () => { },
  reporteesWorkflows: {},
  setReporteesWorkflows: () => { },
})