import React, { useEffect, useMemo, useState } from 'react'
import { View } from 'react-native';
import { Helmet } from 'react-helmet';

import useCompleteElementStates from './hooks/useCompleteElementStates';
import UploadIcon from './components/UploadIcon';
import EStyleSheet from 'react-native-extended-stylesheet';
import completeButtonStyles from './constants/completeButtonStyles';
import useScript from './hooks/useScript';
import useCreateEvent from './hooks/useCreateEvent';
import { EVENT_TYPES } from 'constants/types';
import styled from 'styled-components';
import useClientConfig from 'hooks/useClientConfig';
import useUser from 'hooks/useUser';

const TaskSendSafelyToComplete = (props: CompleteElementProps) => {
  const { user } = useUser()

  const { completedEvent, id, locationHierarchyId, completed } = props

  const {
    isNotApplicableCompletedEvent,
    isCantComplete,
    completeState,
  } = useCompleteElementStates({
    completedEvent,
  })

  const createEvent = useCreateEvent({
    taskId: id,
    locationHierarchyId,
  })

  const { sendSafely } = useClientConfig()
  const [isJqueryLoaded, setJqueryLoaded] = useState(false)
  const [isSendSafelyLoaded, setSendSafelyLoaded] = useState(false)
  const isLoaded = useMemo(() => isJqueryLoaded && isSendSafelyLoaded, [isJqueryLoaded, isSendSafelyLoaded])

  useScript('https://app.sendsafely.com/js/external/SendSafelyDropzone.min.js', () => setJqueryLoaded(true));
  useScript('https://code.jquery.com/jquery-3.6.4.min.js', () => setSendSafelyLoaded(true));

  const onFinalizePackage = (url: string) => {
    createEvent({
      eventType: EVENT_TYPES.SEND_SAFELY,
      createEventData: {
        text: url,
      },
    })
  }

  useEffect(() => {
    if (!isLoaded) {
      window.sendSafelyDropzoneId = sendSafely?.dropzoneId
      window.sendSafelyUrl = sendSafely?.url
      window.sendSafelyOnFinalizePackage = onFinalizePackage
      window.sendSafelyUnconfirmedSender = user?.email
    }
  }, [isLoaded])

  if (!props.showAsButton) {
    return <View style={styles.iconContainer}>
      <UploadIcon
        isComplete={(completeState || completed)}
        isCantComplete={isCantComplete}
        isNotApplicable={isNotApplicableCompletedEvent}
      />
    </View>
  }

  return <>
    {isJqueryLoaded && isSendSafelyLoaded &&
      <Helmet>
        <script>{`
          var placeholderElement = $('#dropzone-replacement-div')

          var widget = new SendSafelyDropzone(
            sendSafelyDropzoneId,
            placeholderElement,
          )

          // Set the URL for your SendSafely portal.
          // If you are a PRO TRIAL user, you should use https://www.sendsafely.com.
          widget.url = sendSafelyUrl

          // Disable the auto-submit feature.
          widget.disableAutoSubmit = true

          widget.fileUploadedCallback = function (file) {
            widget.setUnconfirmedSender(sendSafelyUnconfirmedSender)

            // Finalize, sends package
            widget.finalizePackage(sendSafelyOnFinalizePackage)
          }

          widget.initialize()
        `}</script>
      </Helmet>
    }
    <Dropzone id="dropzone-replacement-div">Loading...</Dropzone>
  </>
}

const Dropzone = styled.div`
  min-height: 200px;
`

const styles = EStyleSheet.create({
  iconContainer: {
    ...completeButtonStyles.iconContainer,
  },
})

export default TaskSendSafelyToComplete
