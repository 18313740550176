{
  "monthDayYearDateFormat": "M月d日, y",
  "monthDayDateFormat": "M月d日",
  "welcome": "欢迎,\n{{name}}!",
  "clickOnTasksSection": "点击任务栏开始",
  "search": "搜索",
  "typeHereToAddText": "在这里输入......",
  "complete": "完成",
  "completed": "已完成",
  "cantComplete": "不能完成",
  "cantCompleteTask": "不能完成任务",
  "notes": "任务备注",
  "back": "返回",
  "sessionNotes": "会话备注",
  "confirm": "确认",
  "notApplicable": "不适用",
  "logout": "退出",
  "teamMetrics": "团队指标",
  "taskNotesDescription": "使用此处添加与此任务相关的备注",
  "comment": "评论",
  "pleaseSelect": "请选择......",
  "eSignature": "电子签名",
  "takePicture": "拍照",
  "accept": "接受",
  "home": "首页",
  "tasksFor": "任务接收者",
  "due": "到期",
  "dueToday": "今天到期",
  "tasks": "任务",
  "task": "任务",
  "tasksToDo": "{{tasks}}项任务待完成",
  "taskToDo": "{{tasks}}项任务待完成",
  "infoTasksToDo": "{{numberInfoTasks}} 信息任务",
  "infoTaskToDo": "{{numberInfoTasks}} 信息任务",
  "infoTasks": "{{numberInfoTasks}} 信息任务",
  "infoTask": "{{numberInfoTasks}} 信息任务",
  "by": "完成人",
  "markedCantComplete": "已标注的无法完成",
  "markedNotApplicable": "已标注的不适用",
  "markedRead": "读",
  "of": "共",
  "tasksComplete": "已完成任务",
  "allTasksComplete": "所有 {{tasks}} 任务已完成",
  "someTasksComplete": "{{numberOfCompletedTasks}}项 (共{{numberOfTasks}}项) 任务已完成",
  "all": "所有",
  "upNextTitle": "接下来",
  "upNextSubtitle": "快速访问您工作流中接下来需要完成的任务",
  "upNextDoneTitle": "您现在完成了！",
  "upNextDoneSubtitle": "太棒了，超级巨星！您没有任何剩余要做的任务了！如果您被分配了新任务，会有电子邮件通知！",
  "progress": "进度",
  "ago": "前",
  "dueIn": "到期还有",
  "days": "天",
  "resources": "资源",
  "resourcesSubtitle": "使用此重要资源的精选列表",
  "yourResources": "你的资源",
  "yourResourcesSubtitle": "快速访问所有有用的链接",
  "reports": "报告",
  "signIn": "登录",
  "forgotPassword": "忘记密码？",
  "emailAddress": "电子邮件地址",
  "password": "密码",
  "newPassword": "新密码",
  "repeatPassword": "重复密码",
  "passwordError": "密码必须匹配",
  "passwordLengthError": "密码长度必须至少为 8 个字符",
  "resetPassword": "创建新密码",
  "resetPasswordInfo": "使用临时密码登录成功。\n请创建一个永久密码",
  "forgottenPassword": "忘记密码了？",
  "forgottenPasswordExplanationWithMagicLink": "在下方输入您的电子邮件地址，然后选择发送重置密码电子邮件或一次性魔术链接，让您立即登录！",
  "forgottenPasswordExplanation": "在下方输入您的电子邮件地址，以接收重置密码的电子邮件并恢复对您账户的访问。",
  "signInWithGoogle": "使用 Google 登录",
  "checkSignInLink": "已发送电子邮件，请检查您的收件箱",
  "next": "下一步",
  "login": "登录",
  "sendSignInLink": "发送登录链接",
  "en-US": "English",
  "en": "English",
  "fr-CA": "Français (Canada)",
  "fr": "Français",
  "es-ES": "Español",
  "es": "Español",
  "pt-BR": "Português",
  "pt": "Português",
  "de-DE": "Deutsch",
  "de": "Deutsch",
  "ja-JP": "日本",
  "ja": "日本",
  "zh-CN": "中文(简体)",
  "zh-TW": "中文(繁体)",
  "timeToComplete": "完成时间",
  "min": "min",
  "high": "高的",
  "poweredBy": "由...提供",
  "uploading": "正在上传...",
  "uploadMedia": "上传媒体",
  "admin": "Admin",
  "showWorkflows": "显示完成",
  "showCompletedWorkflows": "显示已完成的工作流程",
  "infoOnly": "仅供参考，无需完成任何操作",
  "delegate": "代表",
  "getOurApp": "获取我们的应用程序",
  "continueToApp": "继续应用",
  "promptMessage": "您有未保存的更改，您确定要离开吗",
  "onboardingBuddy": "入职伙伴",
  "delegateAssignment": "代表分配",
  "onboardingBuddyAssignment": "入职伙伴分配",
  "assignments": "分配",
  "delegateMainCopy": "请查看下列所有新员工，以及分配完成招聘经理入职任务的代表名单（如已选择）。",
  "delegateEmailDescription": "您可通过下方空白处发送有关任务分配的消息。",
  "newHire": "新员工",
  "assignedDelegate": "指定代表",
  "assignedOnboardingBuddy": "指定的入职伙伴",
  "activeAssignments": "有效任务",
  "actions": "操作",
  "add": "添加",
  "remove": "删除",
  "taskDelegation": "任务分配",
  "delegateConfirmationCopy": "<p>招聘经理的入职任务现将分配给选定的个人。</p><p>请注意，绩效管理任务不适合分配。</p>",
  "okay": "好的",
  "activeAssignmentsCount": "此用户当前已有{{count}}个有效任务。",
  "assign": "分配",
  "name": "姓名",
  "assignDelegate": "指定代表",
  "assignOnboardingBuddy": "指定入职伙伴",
  "onboardingBuddyModalMainCopy": "请查看下列所有新员工，以及为他们分配的入职伙伴名单（如已选择）。",
  "removeDelegateUser": "你确定要移除{{userName}}的代表吗？",
  "removeOnboardingUser": "你确定要移除{{userName}}的入职伙伴吗？",
  "refreshPage": "刷新页面",
  "newAppVersionTitle": "应用程序的新版本",
  "newAppVersionContent": "应用有新版本，为保证应用正常运行，请刷新页面。",
  "commentAdded": "评论已添加：",
  "optionSelected": "选择的选项：",
  "mediaUploaded": "媒体上传：",
  "dependedOn": "取决于",
  "loading": "加载中...",
  "fetchingResults": "我们正在获取您的结果，请稍等...",
  "workflows": "工作流程",
  "sections": "章节",
  "seeAllMatching": "查看所有 {{length}} 匹配项",
  "matches": "{{length}} 匹配",
  "enterSearchTerm": "输入搜索词...",
  "oops": "哎呀，出了点问题。",
  "upNextError": "我们无法加载您的下一个任务。请稍后再试。",
  "myTasks": "我的任务",
  "pastDue": "过期",
  "tomorrow": "明天",
  "dueYesterday": "昨天到期",
  "dueTomorrow": "明天到期",
  "aboutTheOrg": "关于公司",
  "seeAll": "查看全部",
  "myRole": "我的角色",
  "manager": "经理",
  "buddy": "伙伴",
  "myTeam": "我的团队",
  "seeAllTasks": "查看所有任务",
  "viewWelcomeMessage": "查看欢迎消息",
  "calendar": "日历",
  "dueInSixMonthsPlus": "6个月以上到期"
}