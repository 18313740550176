import { useQuery } from '@apollo/react-hooks'
import { NavigationContainer } from '@react-navigation/native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import {
  LoginScreen,
  TaskCompleteByPhotoScreen,
  TaskTodayScreen,
  TaskTodayScreenWeb,
  LocationScreenWrapper,
  ResetPasswordScreen,
  ResourcesScreen,
  ReportsScreen,
} from 'components/Index'
import { ERROR_DATA } from 'graphql/queries'
import { createBrowserHistory } from 'history'
import { ROUTE_NAMES } from 'navigation/constants'
import React, { useEffect, useRef, useState } from 'react'
import { Platform, StatusBar, View } from 'react-native'
import Toast from 'react-native-easy-toast'
import EStyleSheet from 'react-native-extended-stylesheet'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { useApolloClient } from '@apollo/react-hooks';
import {
  NativeRouter,
  Redirect,
  Route,
  Router,
  Switch,
  useRouteMatch,
} from 'react-router-native'
import { AppContext, initialAppContextValue } from 'utils/app-context'
import { CACHE_KEYS } from 'constants/types'
import { clearErrorData } from 'utils/error-handling'

import Header from '../header/Header'
import ManagerRoute from './ManagerRoute'
import SingleSignOnRedirect from 'components/login/sso/SingleSignOnRedirect'
import UserRoleAssigneesScreen from 'components/user-role-assignees/UserRoleAssigneesScreen'
import LocationTextAndPhotos from 'components/locations/LocationTextAndPhotos'
import HomeScreenContainer from 'components/home/HomeScreenContainer'
import OktaLogin from 'components/login/sso/OktaLogin'
import HomeScreen from 'components/home/HomeScreen'
import TaskRedirect from 'components/task-redirect/TaskRedirect'
import MyTeamScreen from 'components/my-team/MyTeamScreen'

const TaskTodayComponent = Platform.select({
  web: TaskTodayScreenWeb,
  default: TaskTodayScreen,
})

const history: any = Platform.OS === 'web' ? createBrowserHistory() : {}
const AppRouter = ({ children }: any) => {
  if (Platform.OS === 'web') {
    return <Router history={history}>{children}</Router>
  }

  return <NativeRouter>{children}</NativeRouter>
}

function RedirectBlock() {
  const [redirectToLogin, setRedirectToLogin] = useState(false)
  const client = useApolloClient()
  const { data: errorCache } = useQuery(ERROR_DATA)
  const errorData = errorCache?.errorData
  const noRedirectRoute = useRouteMatch(ROUTE_NAMES.SSO) || useRouteMatch(ROUTE_NAMES.OKTA_LOGIN)

  useEffect(() => {
    if (errorData?.status === '401') {
      AsyncStorage.removeItem(CACHE_KEYS.TOKEN).then(() => {
        clearErrorData(client)

        if (!noRedirectRoute) {
          setRedirectToLogin(true)
        }
      })
    } else {
      setRedirectToLogin(false)
    }
  }, [errorData?.status])

  return <>{redirectToLogin && <Redirect to={ROUTE_NAMES.LOGIN} />}</>
}

// build global styles
function NavigatorScreens() {
  const [appContextValue, setAppContextValue] = useState<AppContextValue>(
    initialAppContextValue,
  )

  const toastRef = useRef<Toast>()

  const appContextValueFinal = { ...appContextValue, setAppContextValue }
  return (
    <SafeAreaProvider>
      {Platform.OS === 'ios' && <StatusBar barStyle="default" />}
      <AppContext.Provider value={appContextValueFinal}>
        <NavigationContainer
          documentTitle={{
            formatter: () => {
              return 'Tangelo'
            },
          }}
        >
          <AppRouter>
            <RedirectBlock></RedirectBlock>
            <View style={{ position: 'relative', height: '100%' }}>
              <View
                style={{
                  display: 'flex',
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                }}
              >
                <>
                  <View style={styles.container}>
                    {Platform.OS !== 'web' && <Header />}
                    <Switch>
                      <Route exact path="/" component={LoginScreen} />
                      <Route
                        exact
                        path={ROUTE_NAMES.LOGIN}
                        component={LoginScreen}
                      />
                      <Route
                        exact
                        path={ROUTE_NAMES.SSO}
                        component={SingleSignOnRedirect}
                      />
                      <Route
                        exact
                        path={ROUTE_NAMES.RESET_PASSWORD}
                        component={ResetPasswordScreen}
                      />
                      <Route
                        exact
                        path={ROUTE_NAMES.TASKS_TODAY}
                        component={TaskTodayComponent}
                      />
                      <Route
                        exact
                        path={ROUTE_NAMES.LOCATIONS}
                        component={LocationScreenWrapper}
                      />
                      <Route
                        exact
                        path={ROUTE_NAMES.CAMERA_COMPLETE_TASK}
                        component={TaskCompleteByPhotoScreen}
                      />
                      <Route
                        exact
                        path={ROUTE_NAMES.LOCATION_TEXT_AND_PHOTOS}
                        component={LocationTextAndPhotos}
                      />
                      <Route
                        exact
                        path={ROUTE_NAMES.USER_ROLE_ASSIGNEES}
                        component={UserRoleAssigneesScreen}
                      />
                      <Route
                        exact
                        path={ROUTE_NAMES.RESOURCES}
                        component={ResourcesScreen}
                      />
                      <Route
                        exact
                        path={ROUTE_NAMES.HOME}
                        component={HomeScreenContainer}
                      />
                      <Route
                        exact
                        path={ROUTE_NAMES.OKTA_LOGIN}
                        component={OktaLogin}
                      />
                      <Route
                        exact
                        path={ROUTE_NAMES.HOME_PREVIEW}
                        component={HomeScreen}
                      />
                      <Route
                        exact
                        path={ROUTE_NAMES.TASK_REDIRECT}
                        component={TaskRedirect}
                      />
                      <ManagerRoute
                        exact
                        path={ROUTE_NAMES.REPORTS}
                        component={ReportsScreen}
                      />
                      <ManagerRoute
                        exact
                        path={ROUTE_NAMES.MY_TEAM}
                        component={MyTeamScreen}
                      />
                    </Switch>
                  </View>
                </>
              </View>
            </View>
          </AppRouter>
        </NavigationContainer>
      </AppContext.Provider>
      <Toast ref={toastRef} />
    </SafeAreaProvider>
  )
}

// styles
const styles = EStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    position: 'relative',
    ...(Platform.OS === 'web'
      ? {
        alignSelf: 'center',
        height: '100%',
      }
      : {}),
    width: '100%',
  },
})

export default NavigatorScreens
