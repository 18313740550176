{
  "monthDayYearDateFormat": "LLLL d, y",
  "monthDayDateFormat": "LLLL d",
  "welcome": "Bienvenue,\n{{name}}!",
  "clickOnTasksSection": "Cliquer sur la section des tâches pour commencer",
  "search": "Rechercher",
  "typeHereToAddText": "Tapez ici...",
  "complete": "Compléter ",
  "completed": "Complété ",
  "cantComplete": "Impossible de compléter ",
  "cantCompleteTask": "Impossible de compléter la tâche ",
  "notes": "Prendre des notes ",
  "back": "Retour",
  "sessionNotes": "Notes de la session",
  "confirm": "Confirmer ",
  "notApplicable": "Non applicable ",
  "logout": "Se déconnecter ",
  "teamMetrics": "Métriques de l'équipe ",
  "taskNotesDescription": "Utiliser cet espace pour ajouter des notes liées à cette tâche ",
  "comment": "Commenter ",
  "pleaseSelect": "Veuillez sélectionner...",
  "eSignature": "Signature en ligne",
  "takePicture": "Prendre une photo",
  "accept": "Accepter ",
  "home": "Accueil",
  "tasksFor": "Tâches pour",
  "due": "Dû ",
  "dueToday": "Dû aujourd'hui",
  "tasks": "Tâches ",
  "task": "Tâche",
  "tasksToDo": "{{tasks}} Tâches à faire",
  "taskToDo": "{{tasks}} Tâche à faire",
  "infoTasksToDo": "{{numberInfoTasks}} Tâches d'informations uniquement",
  "infoTaskToDo": "{{numberInfoTasks}} Tâche d'informations uniquement",
  "infoTasks": "{{numberInfoTasks}} tâches d'informations",
  "infoTask": "{{numberInfoTasks}} Tâche d'informations",
  "by": "par ",
  "markedCantComplete": "Marqué impossible à compléter ",
  "markedNotApplicable": "Marqué non applicable ",
  "markedRead": "Lire",
  "allTasksComplete": "Tous {{tasks}} Tâches complets",
  "someTasksComplete": "{{numberOfCompletedTasks}} de {{numberOfTasks}} Tâches complets",
  "upNextTitle": "Ensuite ",
  "upNextSubtitle": "Accéder rapidement vos tâches suivantes depuis votre flux de travail. ",
  "upNextDoneTitle": "C'est fini pour le moment !",
  "upNextDoneSubtitle": "Excellent travail ! Vous avez réalisé toutes les démarches ! Si de nouvelles tâches vous sont assignées, vous serez informé par un e-mail !",
  "progress": "Progrès",
  "ago": "Déjà",
  "dueIn": "Du le ",
  "days": "jours ",
  "resources": "Ressources ",
  "resourcesSubtitle": "Utiliser cette liste choisie avec soin des ressources mises en évidence",
  "yourResources": "Vos ressources",
  "yourResourcesSubtitle": "Accès rapide à tous vos liens utiles",
  "reports": "Rapports ",
  "signIn": "Se connecter ",
  "forgotPassword": "Mot de passe oublié ?",
  "emailAddress": "Adresse e-mail",
  "password": "Mot de passe ",
  "newPassword": "Nouveau Mot de passe",
  "repeatPassword": "Répéter le mot de passe",
  "passwordError": "Les mots de passe doivent correspondre",
  "passwordLengthError": "Le mot de passe doit comporter au moins 8 caractères",
  "resetPassword": "Créer un nouveau mot de passe",
  "resetPasswordInfo": "La connexion avec un mot de passe temporaire a réussi. \nVeuillez créer un mot de passe permanent",
  "forgottenPassword": "Mot de passe oublié?",
  "forgottenPasswordExplanationWithMagicLink": "Entrez votre adresse e-mail ci-dessous et choisissez entre l'envoi d'un e-mail de réinitialisation du mot de passe ou un lien magique unique qui vous connectera instantanément !",
  "forgottenPasswordExplanation": "Entrez votre adresse e-mail ci-dessous pour recevoir un e-mail de réinitialisation du mot de passe et retrouver l'accès à votre compte.",
  "signInWithGoogle": "Se connecter avec Google",
  "checkSignInLink": "E-mail envoyé, veuillez vérifier votre boîte de réception",
  "next": "Suivant ",
  "login": "Se connecter ",
  "sendSignInLink": "Envoyer le lien d'identification ",
  "en-US": "English",
  "en": "English",
  "fr-CA": "Français (Canada)",
  "fr": "Français",
  "es-ES": "Español",
  "es": "Español",
  "pt-BR": "Português",
  "pt": "Português",
  "de-DE": "Deutsch",
  "de": "Deutsch",
  "ja-JP": "日本",
  "ja": "日本",
  "zh-CN": "中文(简体)",
  "zh-TW": "中文(繁体)",
  "timeToComplete": "Temps pour terminer",
  "min": "min",
  "high": "Haut",
  "poweredBy": "fourni par",
  "uploading": "Téléchargement...",
  "uploadMedia": "Télécharger le média",
  "admin": "Admin",
  "showWorkflows": "Afficher terminé",
  "showCompletedWorkflows": "Afficher les workflows terminés",
  "infoOnly": "Information uniquement, aucune action nécessaire pour terminer",
  "delegate": "Délégué",
  "getOurApp": "Obtenir notre application",
  "continueToApp": "Continuer vers l'application",
  "promptMessage": "Vous avez des modifications non enregistrées, êtes-vous sûr de vouloir quitter",
  "onboardingBuddy": "Collègue D’Intégration",
  "delegateAssignment": "Affectation Des Délégués",
  "onboardingBuddyAssignment": "Affectation D’Un Collègue D’Intégration",
  "assignments": "Affectations",
  "delegateMainCopy": "Vous trouverez ci-dessous la liste de tous vos nouveaux collègues ainsi que le délégué responsable des tâches d’intégration du gestionnaire recruteur, s’il a été sélectionné.",
  "delegateEmailDescription": "Vous pouvez envoyer un message concernant la délégation de tâches à partir de l’espace ci-dessous.",
  "newHire": "Nouvel Employé",
  "assignedDelegate": "Affecter un délégué",
  "assignedOnboardingBuddy": "Collègue Délégué Affecté",
  "activeAssignments": "Affectations Actives",
  "actions": "Actions",
  "add": "Ajouter",
  "remove": "Retirer",
  "taskDelegation": "Délégation de tâches",
  "delegateConfirmationCopy": "<p>Les tâches relatives à l’intégration du gestionnaire recruteur seront désormais déléguées à la personne sélectionnée.</p><p>Veuillez noter qu’il n’est pas possible de déléguer la gestion de tâches relatives au rendement.</p>",
  "okay": "D’accord",
  "activeAssignmentsCount": "Cet utilisateur a actuellement {{count}} affectations actives.",
  "assign": "Affecter",
  "name": "Nom",
  "assignDelegate": "Affecter un délégué",
  "assignOnboardingBuddy": "Affecter un collègue d’intégration",
  "onboardingBuddyModalMainCopy": "Vous trouverez ci-dessous la liste de tous vos nouveaux collègues ainsi que leur collègue d’intégration, s’il a été sélectionné.",
  "removeDelegateUser": "Voulez-vous vraiment supprimer le délégué pour {{userName}}?",
  "removeOnboardingUser": "Voulez-vous vraiment supprimer le collègue d’intégration pour {{userName}}?",
  "refreshPage": "Actualiser la page",
  "newAppVersionTitle": "Nouvelle version de l'application",
  "newAppVersionContent": "Il existe une nouvelle version de l'application. Pour garantir le bon fonctionnement de l'application, veuillez actualiser la page.",
  "commentAdded": "Commentaire ajouté:",
  "optionSelected": "Option sélectionnée:",
  "mediaUploaded": "Média téléchargé:",
  "dependedOn": "Dépend de",
  "loading": "Chargement...",
  "fetchingResults": "Nous récupérons vos résultats, veuillez patienter...",
  "workflows": "Flux de travail",
  "sections": "Sections",
  "seeAllMatching": "Voir tous les {{length}} correspondants",
  "matches": "{{longueur}} correspondances",
  "enterSearchTerm": "Entrez le terme de recherche...",
  "oops": "Oups, quelque chose s'est mal passé.",
  "upNextError": "Nous n'avons pas pu charger vos prochaines tâches. Veuillez réessayer plus tard.",
  "myTasks": "Mes tâches",
  "pastDue": "En retard",
  "tomorrow": "Demain",
  "dueYesterday": "Dû Hier",
  "dueTomorrow": "Dû Demain",
  "aboutTheOrg": "À propos de l'entreprise",
  "seeAll": "Tout voir",
  "selectLanguage": "Sélectionner la langue :",
  "myRole": "Mon Rôle",
  "manager": "Manager",
  "buddy": "Ami",
  "myTeam": "Mon Équipe",
  "seeAllTasks": "Voir Toutes les Tâches",
  "viewWelcomeMessage": "Afficher le message de bienvenue",
  "calendar": "Calendrier",
  "dueInSixMonthsPlus": "Dû dans plus de 6 mois"
}