import React, { useMemo, useState } from 'react'
import { View, Text, Linking } from 'react-native'
import { Button } from '@rneui/themed'
import Modal from 'react-native-modal/dist/modal'
import { useTranslation } from 'react-i18next'
import { stylesGlobal } from 'constants/es-style'

const GetAppModal = () => {
  const shouldShowModal = useMemo(() => {
    const system = getMobileOperatingSystem()

    return system == 'Android' || system == 'iOS'
  }, [])

  const [isVisible, setIsVisible] = useState(shouldShowModal)
  const { t } = useTranslation()


  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  const onContinueToApp = () => {
    const iosUrl = 'https://apps.apple.com/es/app/tangelo-ai/id1524764098'
    const androidUrl = 'https://play.google.com/store/apps/details?id=ai.tangelo.teamapp'
    const url = getMobileOperatingSystem() == 'iOS' ? iosUrl : androidUrl

    Linking.openURL(url)
  }

  return (
    <Modal style={stylesGlobal.modal} isVisible={isVisible}>
      <View style={stylesGlobal.modalContent}>
        <View style={stylesGlobal.modalContentHeader}>
          <Text style={stylesGlobal.modalContentHeaderText}>
            {t('getOurApp')}
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-evenly',
          }}
        >
          <Button onPress={onContinueToApp}
          >
            {t('continueToApp')}
          </Button>
        </View>
      </View>
    </Modal>
  )
}

export default GetAppModal
