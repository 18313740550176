import { useQuery } from "@apollo/react-hooks"
import { FETCH_POLICIES } from "constants/api"
import { GET_PAGES } from "graphql/queries"
import useUser from "./useUser"

const useContentPages = () => {
  const { user } = useUser()

  const { data, loading } = useQuery(GET_PAGES, {
    variables: { showAll: user?.clientId == 150 },
    skip: user == null,
  })

  const contentPages: any[] = data?.contentPages || []

  return { loading, contentPages }
}

export default useContentPages