import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const DescriptionIcon = (props: SvgProps) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={36}
        height={36}
        fill="none"
        {...props}
    >
        <Path
            stroke="#D30000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M11 11h1M16 11h9M11 18h1M16 18h9M11 25h1M16 25h3M23 33v-8h8"
        />
        <Path
            stroke="#D30000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M23 33H5V3h26v22l-8 8Z"
        />
    </Svg>
)
export default DescriptionIcon
