import { useState } from 'react'
import { Platform } from 'react-native'
import { useLocation } from 'react-router-native'
import * as WebBrowser from 'expo-web-browser'

import { useMutation } from '@apollo/react-hooks'
import { makeRedirectUri } from 'expo-auth-session'
import { parse } from 'query-string'

import {
  LOGIN_WITH_WORKOS_TOKEN,
  LOGIN_WITH_WORKOS_REDIRECT_URL,
} from 'graphql/mutations'

interface Params {
  setInfoMessage: DispatchType<string>
  setUseSSOSignIn: DispatchType<boolean>
  setLoginError: DispatchType<string>
  setShowLoginForm: DispatchType<boolean>
  checkTokenAndNavigate: (
    token: string,
    isTemporaryPassword?: boolean,
  ) => Promise<void>
}

const useWorkOSLogin = ({
  setInfoMessage,
  setUseSSOSignIn,
  setLoginError,
  setShowLoginForm,
  checkTokenAndNavigate,
}: Params) => {
  const location = useLocation()
  const [ssoToken] = useState(() => parse(location.search)['code'] as string)

  const [getSSORedirectUrl] = useMutation(LOGIN_WITH_WORKOS_REDIRECT_URL, {
    onError: (err) => {
      setUseSSOSignIn(false)
      console.error(err)
    },
  })

  const [workosTokenLogin] = useMutation(LOGIN_WITH_WORKOS_TOKEN, {
    onError: (err) => {
      if (err.graphQLErrors[0]?.message) {
        if (err.graphQLErrors[0]?.message === 'User not found') {
          let userNotFoundMessage =
            "Hello and welcome to Tangelo.\n\nYou haven't been assigned any workflow. Please contact your HR/admin if you think this is a mistake."
          if (err.graphQLErrors[0].extensions?.customMessage) {
            userNotFoundMessage = err.graphQLErrors[0].extensions?.customMessage
          }
          setInfoMessage(userNotFoundMessage)
        } else {
          setLoginError(err.graphQLErrors[0].message)
        }
      }
      setShowLoginForm(true)
    },
  })

  const validateSSOTokenAndNavigate = async (token: string) => {
    const workosTokenLoginResponse = await workosTokenLogin({
      variables: { attributes: { token } },
    })
    if (!workosTokenLoginResponse?.data?.workosTokenLogin) {
      return
    }

    const { data: result } = workosTokenLoginResponse
    const authenticationToken = result?.workosTokenLogin?.authenticationToken
    checkTokenAndNavigate(authenticationToken)
  }

  const handleWorkOSflow = async (variables: { attributes: any }) => {
    const returnUrl = makeRedirectUri({ native: 'teamapp://' })

    const redirectUrlResponse = await getSSORedirectUrl({
      variables: {
        attributes: { ...variables.attributes, redirectUrl: returnUrl },
      },
    })

    const workosAuthUrl =
      redirectUrlResponse?.data?.workosRedirectUrl?.redirectUrl

    if (!workosAuthUrl) {
      return false
    }

    // redirect website tab
    if (Platform.OS === 'web') {
      window.location = workosAuthUrl
      return true
    }

    let result = await WebBrowser.openBrowserAsync(workosAuthUrl.toString())

    return true
  }

  return {
    handleWorkOSflow,
    validateSSOTokenAndNavigate,
  }
}

export default useWorkOSLogin
