import { useLocation } from "react-router-native"
import { useMemo } from "react"
import { LOCATION_SCREEN_QUERY } from "graphql/queries"
import { useQuery } from "@apollo/react-hooks"

const useCurrentLocation = (): {
  currentLocation?: LocationInterface
  parentLocation?: LocationInterface
  locationOwner?: User | null
  loading: boolean
  currentLocationHierarchyId?: string
} => {
  const location = useLocation()
  const params = new URLSearchParams(location.search);
  const currentLocationHierarchyId = params.get('locationHierarchyId');
  const { data, loading } = useQuery(LOCATION_SCREEN_QUERY, {
    variables: {
      parentId: currentLocationHierarchyId,
    },
    skip: !currentLocationHierarchyId,
  })
  const locations = useMemo(() => data?.locationHierarchiesNew?.data, [data])
  const currentLocation = useMemo(() => locations?.find(
    (location: LocationInterface) => location.id == currentLocationHierarchyId,
  ), [locations, currentLocationHierarchyId])
  const parentLocation = useMemo(() => locations?.find(
    (location: LocationInterface) => location.id == currentLocation?.parentId,
  ), [locations, currentLocation])
  const locationOwner = useMemo(() => getOwner(currentLocation, parentLocation), [currentLocation, parentLocation])

  return {
    currentLocation,
    parentLocation,
    locationOwner,
    loading,
    currentLocationHierarchyId,
  }
}

const getOwner = (currentLocation?: LocationInterface, parentLocation?: LocationInterface) => {
  const location = (parentLocation?.title == "__root__" ? null : parentLocation) || currentLocation

  return location?.owner
}

export default useCurrentLocation
