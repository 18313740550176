import { IS_WEB } from "constants/static"

const commonButtonStyle = {
  width: IS_WEB ? 192 : 140,
  height: 48,
  paddingHorizontal: 8,
  borderRadius: 8,
  fontSize: IS_WEB ? 18 : 14,
}

export default commonButtonStyle
