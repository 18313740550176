import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-native'
import SearchIcon from '@mui/icons-material/Search';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { useTranslation } from 'react-i18next';

import { ROUTE_NAMES } from 'navigation/constants'
import OptionsMenu from './OptionsMenu'
import LanguageMenu from './LanguageMenu'
import useClientConfig from 'hooks/useClientConfig'
import SearchModal from './SearchModal/SearchModal'
import COLORS from 'constants/colors'
import { Divider } from '@mui/material';
import useUser from 'hooks/useUser';

const Header = ({ currentUser }: { currentUser: User }) => {
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()
  const { clientLogo, showLocale, enableNewHome } = useClientConfig()
  const [isSearchOpen, setSearchOpen] = useState(false)
  const { user } = useUser()

  return (
    <>
      <StyledHeader>
        <HeaderContent>
          <HeaderContentLeft>
            {!!clientLogo && (
              <HeaderLogo onClick={() => {
                history.push({
                  pathname: ROUTE_NAMES.HOME,
                })
              }}>
                <img src={clientLogo} />
              </HeaderLogo>
            )}
          </HeaderContentLeft>
          <Menu>

            {enableNewHome && <MenuItem
              active={location.pathname == ROUTE_NAMES.HOME}
              onClick={() => {
                history.push({
                  pathname: ROUTE_NAMES.HOME,
                })
              }}
            ><HomeOutlinedIcon sx={{ paddingRight: '12px' }} />{t('home')}</MenuItem>}

            <MenuItem
              active={location.pathname == ROUTE_NAMES.LOCATIONS || location.pathname == ROUTE_NAMES.TASKS_TODAY}
              onClick={() => {
                history.push({
                  pathname: ROUTE_NAMES.LOCATIONS,
                })
              }}
            ><TaskAltIcon sx={{ paddingRight: '12px' }} />{t('myTasks')}</MenuItem>

            {user?.isManager && <MenuItem
              active={location.pathname == ROUTE_NAMES.MY_TEAM}
              onClick={() => {
                history.push({
                  pathname: ROUTE_NAMES.MY_TEAM,
                })
              }}
            ><PeopleOutlineIcon sx={{ paddingRight: '12px' }} />{t('myTeam')}</MenuItem>}


            <MenuItem
              active={location.pathname == ROUTE_NAMES.RESOURCES}
              onClick={() => {
                history.push({
                  pathname: ROUTE_NAMES.RESOURCES,
                })
              }}
            ><BookmarkBorderIcon sx={{ paddingRight: '12px' }} />{t('resources')}</MenuItem>

            {currentUser?.isManager && <MenuItem
              active={location.pathname == ROUTE_NAMES.REPORTS}
              onClick={() => {
                history.push({
                  pathname: ROUTE_NAMES.REPORTS,
                })
              }}
            ><SignalCellularAltOutlinedIcon sx={{ paddingRight: '12px' }} />{t('reports')}</MenuItem>}

            <MenuItem
              active={isSearchOpen}
              onClick={() => setSearchOpen(true)}
            >
              <SearchIcon sx={{ paddingRight: '12px' }} />{t('search')}
            </MenuItem>
          </Menu>
          <HeaderContentRight>
            {showLocale && (
              <>
                <LanguageMenuContainer>
                  <LanguageMenu />
                </LanguageMenuContainer>
                <Divider orientation="vertical" flexItem sx={{ marginRight: '20px' }} />
              </>
            )}
            <UserName>
              {`${currentUser?.firstName || ''} ${currentUser?.lastName || ''}`}
            </UserName>
            <OptionsMenu />
          </HeaderContentRight>
        </HeaderContent>
      </StyledHeader>
      {isSearchOpen && <SearchModal onClose={() => setSearchOpen(false)} />}
    </>
  )
}

const Menu = styled.div`
  display: flex;
`

const MenuItem = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 18px 0px 18px;
  font-weight: ${({ active }) => active ? 600 : 200};
  font-size: 14px;
  color: ${COLORS.NAVY_BLUE};
  cursor: pointer;
`

const StyledHeader = styled.div`
  position: relative;
  z-index: 1;
  height: 60px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
  background-color: white;
  padding: 0px 36px 0px 36px;
`

const HeaderContent = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
`

const HeaderContentLeft = styled.div`
  display: flex;
  align-items: center;
`

const HeaderLogo = styled.div`
  height: 48px;
  box-sizing: border-box;
  img {
    height: 100%;
    width: auto;
  }
  cursor: pointer;
`

const HeaderContentRight = styled.div`
  display: flex;
  align-items: center;
`

const UserName = styled.div`
  font-weight: 400;
  font-size: 12px;
  margin-right: 15px;
`

const LanguageMenuContainer = styled.div`
  font-weight: 400;
  font-size: 12px;
  margin-right: 15px;
`

export default Header
