import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  View,
  Text,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import LoginTextInput from './LoginTextInput'

function PasswordField({
  value,
  setValue,
  handleOnSubmit,
  testID,
  isRepeatPassword = false,
  label = 'password',
}: {
  value: string
  setValue: DispatchType<string>
  handleOnSubmit: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void
  testID?: String
  isRepeatPassword?: Boolean
  label?: 'password' | 'newPassword'
}) {
  const { t } = useTranslation()
  return (
    <View>
      <Text style={styles.container}>
        {t(isRepeatPassword ? 'repeatPassword' : label)}
      </Text>
      <LoginTextInput
        value={value}
        setValue={setValue}
        placeholder="Minimum 8 characters"
        secureTextEntry
        testID={testID}
        handleOnSubmit={handleOnSubmit}
        autoFocus={!isRepeatPassword}
      />
    </View>
  )
}

const styles = EStyleSheet.create({
  container: {
    marginBottom: 8,
    marginTop: 32,
    fontFamily: 'Poppins_400Regular',
  },
})

export default PasswordField
