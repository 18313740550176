import React from 'react'
import styled from 'styled-components'

import COLORS from 'constants/colors'

const WelcomeWizardCommonStep = ({ title, description, imageSrc = '' }) => {
  return <>
    <LeftColumn>
      <TextContainer>
        <Title>{title}</Title>

        <Description>
          {description}
        </Description>
      </TextContainer>
    </LeftColumn>

    <Column>
      <StyledImage src={imageSrc} />
    </Column>
  </>
}

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 365px;
  margin-bottom: 28px;
`

const StyledImage = styled.img`
  height: 530px;
  margin-top: 24px;
  margin-right: -24px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const LeftColumn = styled(Column)`
  flex: 1;
  padding-left: 50px;
`

const Title = styled.div`
  font-size: 32px;
  font-weight: 600;
  color: ${COLORS.NAVY_BLUE}
`

const Description = styled.div`
  margin-top: 24px;
  font-size: 18px;
  font-weight: 400;
  color: ${COLORS.FIVES_GREY};
`

export default WelcomeWizardCommonStep