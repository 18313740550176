import { Platform } from 'react-native'
import { err } from 'react-native-svg/lib/typescript/xml'
import { Browser, Native } from 'sentry-expo'

const isWeb = Platform.OS === 'web'

export const captureException = (
  error: any,
  severity: 'error' | 'WARNING' = 'error',
) => {
  if (severity == 'WARNING') {
    isWeb ? Browser.captureMessage(error) : Native.captureMessage(error)
  } else {
    isWeb ? Browser.captureException(error) : Native.captureException(error)
  }
}

export const setSentryUser = (user: User) => {
  const { id, email, personalEmailSession } = user
  if (isWeb) {
    Browser.setUser({ id, email, personalEmailSession })
  } else {
    Native.setUser({ id, email, personalEmailSession })
  }
}
export default isWeb ? Browser : Native
