import React from 'react'

import styled from 'styled-components'
import ScreenWrapper from 'components/common/ScreenWrapper'
import { default as MobileLocationScreen } from './LocationScreen'
import OnboardingProgress from './onboarding-progress/OnboardingProgress.web'
import useUser from 'hooks/useUser'
import UpNextContainer from './up-next/UpNextContainer'
import useClientConfig from 'hooks/useClientConfig'
import WelcomeMessage from './WelcomeMessage.web'
import useCurrentLocation from 'hooks/useCurrentLocation'

const LocationScreenWeb = () => {
  const { user } = useUser()
  const { enableNewHome } = useClientConfig()
  const { currentLocation, currentLocationHierarchyId } = useCurrentLocation()
  const isHome = !currentLocation

  function getProgress() {
    return {
      title: currentLocation?.title || '',
      numberOfTasks: currentLocation?.tasks || 0,
      numberOfCompletedTasks:
        currentLocation?.completed +
        currentLocation?.cantComplete +
        currentLocation?.notApplicable || 0,
    }
  }

  return (
    <ScreenWrapper
      currentUser={user}
      menuComponent={<MobileLocationScreen />}
      contentComponent={
        <StyledContent>
          {!enableNewHome && <WelcomeMessage />}
          {!isHome && <OnboardingProgress {...getProgress()} />}

          <UpNextContainer
            parentId={currentLocationHierarchyId}
            onlyPast
          />
          <br />
          <UpNextContainer
            parentId={currentLocationHierarchyId}
            onlyFuture
          />
        </StyledContent>
      }
    />
  )
}

const StyledContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 36px;
`

export default LocationScreenWeb
