import React from 'react'
import COLORS from 'constants/colors'
import styled from 'styled-components'
import { Card } from '@mui/material'
import useClientConfig from 'hooks/useClientConfig'

const TaskListCard = ({ modalOpen, children }: {
  modalOpen: boolean,
  children: any
}) => {
  const { iconColor } = useClientConfig()

  return <StyledCard
    isSelected={modalOpen}
    color={iconColor || COLORS.TURQUOISE}
  >
    {children}
  </StyledCard>
}

const StyledCard = styled(Card) <{ isSelected: boolean, color: string }>`
  margin: ${({ isSelected }) => !isSelected ? '3px' : '0px'} !important;
  border: ${({ isSelected, color }) => isSelected ? `3px solid ${color}` : '0px'} !important;
  border-radius: 8px !important;
  animation-name: ${(props) => props.isSelected ? 'highlight' : 'none'};;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  @keyframes highlight {
    0% {
      border-color: ${({ color }) => color};
    }
    25% {
      border-color: white;
    }
    50% {
      border-color: ${({ color }) => color};
    }
    75% {
      border-color: white;
    }
    100% {
      border-color: ${({ color }) => color};
    }
  }
`

export default TaskListCard
