import React from 'react'

import styled from 'styled-components'
import COLORS from 'constants/colors'
import useClientConfig from 'hooks/useClientConfig'
import { Popover } from '@mui/material'
import ReporteeMarkerCard from './ReporteeMarkerCard'

const ReporteeProgressBar = ({ completedTasksCount = 0, pastDueTasksCount = 0, tasksCount = 0, percentage = 0, color, height }:
  { completedTasksCount: number, pastDueTasksCount: number, tasksCount: number, percentage: number, color?: string, height?: string }) => {
  const { iconColor } = useClientConfig()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <StyledLinearProgressBar>
      <svg width="100%" height={height || "16"} fill="none">
        <rect width="100%" height={height || "16"} rx={(parseInt(height) || 16) / 2} fill="#F5F7FB" />
        <rect
          width={`${percentage}%`}
          height={height || "16"}
          rx={(parseInt(height) || 16) / 2}
          fill={color || iconColor || COLORS.TURQUOISE}
        />
      </svg>


      <StyledProgressMarker
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        percentage={percentage}
      >
        <StyledCircleMarker
          markerColor={color || iconColor || COLORS.TURQUOISE}
        />

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          slotProps={{
            paper: {
              sx: {
                borderRadius: '16px',
              }
            }
          }}
        >
          <ReporteeMarkerCard
            progress={percentage}
            completedTasksCount={completedTasksCount}
            pastDueTasksCount={pastDueTasksCount}
            tasksCount={tasksCount}
          />
        </Popover>
      </StyledProgressMarker>
    </StyledLinearProgressBar>
  )
}

const StyledLinearProgressBar = styled.div`
  position: relative;
`

const StyledProgressMarker = styled.div`
  position: absolute;
  left: ${(props: { percentage: number }) =>
    `calc(${props.percentage}% - 12px)`}; // minus half the circle marker width + border size
  top: 0px;
  height: 16px;
  display: flex;
  justify-content: left;
`

const StyledCircleMarker = styled.div`
  width: 16px;
  height: 16px;
  border: 4px solid white;
  border-radius: 50%;
  background-color: ${(props: { markerColor: string }) =>
    `${props.markerColor}`};
  margin-top: -2px; // minus half the border, to align center of circle with progress bar
`

export default ReporteeProgressBar
