import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper'
import COLORS from 'constants/colors'

const TangeloTheme = {
  ...DefaultTheme,
  roundness: 16,
  colors: {
    ...DefaultTheme.colors,
    primary: COLORS.TURQUOISE,
    background: 'white',
    placeholder: '#DBDBDB',
  },
  fonts: {
    bold: {
      fontFamily: 'Poppins_700Regular',
      fontWeight: 'bold',
    },
    regular: {
      fontFamily: 'Poppins_400Regular',
      fontWeight: 'normal',
    },
    medium: {
      fontFamily: 'Poppins_500Medium',
      fontWeight: 'normal',
    },
    light: {
      fontFamily: 'Poppins_300Light',
      fontWeight: 'normal',
    },
    thin: {
      fontFamily: 'Poppins_100Thin',
      fontWeight: 'normal',
    },
  },
}
const ThemeProvider = ({ children }: { children: any }) => {
  return <PaperProvider theme={TangeloTheme}>
    {children}
  </PaperProvider>
}

export default ThemeProvider
