import React from 'react'
import { CheckBox } from '@rneui/themed'
import styled from 'styled-components/native'

import COLORS from 'constants/colors'
import { IS_WEB } from 'constants/static'

const QuestionOption = ({
  answer,
  isCheckbox = false,
  isSelected = false,
  title,
  id,
  handleChange,
  trailing,
  disabled = false,
}: {
  answer?: boolean
  isCheckbox: boolean
  isSelected?: boolean
  title: string
  id: string
  handleChange?: any
  trailing?: any,
  disabled?: boolean
}) => {
  const isRightAnswer = answer == null ? (isSelected ? true : null) : answer

  return <AnswerContainer
    isRightAnswer={isRightAnswer}
    onPress={disabled ? null : () => handleChange(id)}
    disabled={disabled}
  >
    <CheckboxContainer>
      <CheckBox
        onPress={disabled ? null : () => handleChange(id)}
        disabled={disabled}
        checked={isSelected}
        containerStyle={{ backgroundColor: 'transparent' }}
        style={{ margin: 0 }}
        checkedIcon={isCheckbox ? 'check-square-o' : 'dot-circle-o'}
        uncheckedIcon={isCheckbox ? 'square-o' : 'circle-o'}
        checkedColor={isRightAnswer == false ? '#D30000' : COLORS.DARK_TURQUOISE}
      />
      <CheckboxText isSelected={isSelected}>{title}</CheckboxText>
    </CheckboxContainer>
    {trailing}
  </AnswerContainer>
}

const CheckboxText = styled.Text<{ isSelected: boolean }>`
  font-size: ${IS_WEB ? '16px' : '12px'};
  font-weight: ${({ isSelected }) => isSelected ? '700' : '500'};
  line-height: 24px;
  color: #243162;
  margin: ${IS_WEB ? '10px 0px 10px -10px' : '10px 50px 10px -10px'};
`

const CheckboxContainer = styled.View`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`

// true -> green
// false -> red
// null -> white
const AnswerContainer = styled.TouchableOpacity<{ isRightAnswer: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  min-height: 56px;
  cursor: pointer;
  background: ${({ isRightAnswer }) => isRightAnswer == null ? '#FFFFFF' : (isRightAnswer ? '#D0F5E8' : '#FFE0E2')};
  border: 1px solid ${({ isRightAnswer }) => isRightAnswer == null ? '#D5E5F4' : (isRightAnswer ? '#50CEA2' : '#D30000')};
  border-radius: 12px;
  padding: 0px 20px 0px 0px;
  margin-bottom: 12px;
`

export default QuestionOption