import { STATUS_CODES } from 'constants/api'

const MESSAGES = {
  NO_SESSION:
    'Go to the previous screen and click on the start button to start a session',
  IMAGE_UPLOAD_STARTED: 'image upload started',
  CURRENTLY_OFFLINE_IMAGE_UPLOAD:
    'currently offline, upload will start when online again',
  NO_TASKS_OR_SUBLOCATIONS:
    'no tasks or sublocations yet present in this location',
  ERROR_FIRST_LOGIN: 'error, you need to be online for the first login',
  NO_IMAGE: 'no image provided',
  CURRENTLY_OFFLINE: 'you are currently offline',
  NOT_AVAILABLE: 'Action not available.',
  START_SESSION_TO_INTERACT:
    'Go to the previous screen and click on the start button to start a session',
  SERVER_NOT_ALIVE: 'warning, server is down, please contact admin',
  DATE_TODAY_OFFLINE: 'you can only choose today when being offline',
  OPERATION_FAILURE: 'queue operation(s) failed',
  TEMPLATE_ROLE_USER_ASSIGNMENT_TEXT: (name: string) =>
    `Use the drop-down menu below to assign an ${name} to help your team members adjust to the company.`,
  TEMPLATE_ROLE_USER_ASSIGNMENT_EMAIL_TEXT: (name: string) =>
    `<p>Hi,</p><p>To help <b>{{employee.fullName}}</b> understand our organization I have recommended you to be their ${name}.</p><p>To help you succeed in your role, you will be assigned ${name} tasks in Tangelo to help guide you.</p><p>Thank you for all your help making <b>{{employee.fullName}}</b> feel welcomed!</p><p>&nbsp</p><p>Best Regards,</p><p>&nbsp</p><p>{{manager.fullName}}</p>`,
  TASK_CREATE_UPDATE: (task: any) =>
    task ? 'task has been updated' : 'task has been created',
  CONFIRM_DELETE_TASK: (name: string) => `delete task ${name}?`,
  SESSION_BUTTON_TEXT: (open: boolean) => (open ? 'end' : 'start'),
}

export const ERROR_CODE_MESSAGES = {
  [STATUS_CODES.UNAUTHORIZED]: 'unauthorized, please re-login',
}

export default MESSAGES
