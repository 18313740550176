import React from 'react'
import styled from 'styled-components'

import UpNextCard from './UpNextCard.web'
import UpNextDone from './UpNextDone.web'
import UpNextCardLite from './UpNextCardLite.web'

const UpNext = ({
  tasks,
  compact = false,
  isManager = false,
  refetch,
}: {
  tasks: TaskUncompleteType[],
  compact?: boolean
  isManager?: boolean
  refetch: () => void
}) => {
  function renderContent() {
    if (tasks.length === 0) {
      return <UpNextDone />
    }

    return tasks
      .sort(sortByImportanceAndDueDate)
      .slice(0, 10)
      .map((task) => {
        if (!compact) {
          return <UpNextCard key={task.id} task={task} refetch={refetch} />
        }

        return <UpNextCardLite key={task.id} task={task} smallIcon={!isManager} />
      })
  }

  function sortByImportanceAndDueDate(a: TaskUncompleteType, b: TaskUncompleteType) {
    if (a.important && !b.important) {
      return -1;
    }
    if (!a.important && b.important) {
      return 1;
    }
    if (!a.dueDate) {
      return 1;
    }
    if (!b.dueDate) {
      return -1;
    }
    return new Date(a.dueDate) - new Date(b.dueDate);
  }

  return <Container>{renderContent()}</Container>
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`

export default UpNext