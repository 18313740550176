import React from 'react'
import COLORS from 'constants/colors'
import { View, Text } from 'react-native'
import CustomProgressCircle from 'components/common/CustomProgressCircle'
import {
  ArrowRightRoundedIcon,
  CheckmarkIcon,
} from 'components/common/custom-icons'
import { IS_WEB } from 'constants/static'

const LocationProgressCircle = ({
  numberOfTasks,
  numberOfCompletedTasks,
  radius = IS_WEB ? 48 : 42,
  circleMarkerColor = 'white',
  fontColor = COLORS.GRAYDARK3,
  fontSize = 18,
  progressColor,
  addMarginToCheckmark = false,
}: {
  numberOfTasks: number
  numberOfCompletedTasks: number
  radius?: number
  circleMarkerColor?: string
  fontColor?: string
  fontSize?: number
  progressColor?: string
  addMarginToCheckmark?: boolean
}) => {
  const checkmarkSize = (radius * 2) - 12

  const percent =
    numberOfCompletedTasks > 0
      ? Math.round((numberOfCompletedTasks / numberOfTasks) * 100)
      : 0

  function renderChild() {
    if (percent == 0 && radius) {
      return <ArrowRightRoundedIcon color={progressColor || COLORS.TURQUOISE} height={24} width={24} />
    }

    return <Text style={{
      fontSize: IS_WEB ? fontSize : 18,
      fontWeight: IS_WEB ? 300 : 500,
      color: IS_WEB ? fontColor : 'black',
      fontFamily: 'Poppins_300Light',
    }}>{`${percent}%`}</Text>
  }

  const Checkmark = () => <CheckmarkIcon color={progressColor || COLORS.TURQUOISE} width={checkmarkSize} height={checkmarkSize} />

  const CheckmarkContainer = () => {
    if (addMarginToCheckmark) {
      return <View style={{ marginTop: 4, marginLeft: 4 }}>
        <Checkmark />
      </View>
    }

    return <Checkmark />
  }

  return (
    <View>
      {percent == 100 ? (
        <CheckmarkContainer />
      ) : (
        <CustomProgressCircle
          percent={percent}
          radius={radius}
          borderWidth={12}
          color={progressColor || COLORS.TURQUOISE}
          shadowColor="#f5f7fb"
          bgColor="transparent"
          markerColor={progressColor || COLORS.TURQUOISE}
          circleMarkerColor={circleMarkerColor}
        >
          {renderChild()}
        </CustomProgressCircle>
      )}
    </View>
  )
}

export default LocationProgressCircle
