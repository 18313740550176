import React from 'react'
import styled from 'styled-components'

import useClientConfig from 'hooks/useClientConfig'

const TaskDescription = ({
  descriptionHtml,
  contentWidth,
}: {
  descriptionHtml: string,
  contentWidth: number
}) => {
  const { editorName } = useClientConfig()

  return <Container
    isTinyMce={editorName === 'tinymce'}
    dangerouslySetInnerHTML={{
      __html: descriptionHtml,
    }}
  />
}

const Container = styled.div<{ isTinyMce: boolean }>`
  user-select: text;
  height: 100%;

  ${(props) =>
    props.isTinyMce
      ? 'iframe {min-height: 466px;}'
      : 'iframe {width: 100%; height: auto; min-height: 466px;}'}
  

  ${(props) =>
    props.isTinyMce
      ? ''
      : 'img {display: block;margin-left: auto;margin-right: auto;}'}
`

export default TaskDescription
