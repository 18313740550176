import React from 'react'
import { Avatar, ListItemAvatar, ListItemText } from '@mui/material'
import COLORS from 'constants/colors'
import myTeamStringAvatar from 'components/my-team/utils/stringAvatar'

const UserHeaderItem = ({ reportee, selected = false }: { reportee: User, selected?: boolean }) => {
  return <>
    <ListItemAvatar>
      <Avatar {...myTeamStringAvatar(reportee.fullName, reportee.color, reportee.colorBrightness)} src={reportee?.avatarUrl} />
    </ListItemAvatar>
    <ListItemText
      primary={reportee.fullName}
      primaryTypographyProps={{ style: { color: COLORS.NAVY_BLUE, fontSize: '16px', fontWeight: selected ? '600' : 400 } }}
      secondary={reportee.jobTitle}
      secondaryTypographyProps={{ style: { color: COLORS.SIXES_GREY, fontSize: '14px', fontWeight: '300' } }}
    />
  </>
}

export default UserHeaderItem