import React, { useMemo } from 'react'
import styled from 'styled-components'
import Carousel from 'react-material-ui-carousel'

import COLORS from 'constants/colors'
import LocationProgressCircle from 'components/locations/LocationProgressCircle'
import UpNextDone from 'components/locations/up-next/UpNextDone.web'
import useLocations from 'hooks/useLocations'
import { chunk } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-native'
import { ROUTE_NAMES } from 'navigation/constants'
import { GeneralActivityIndicator } from 'components/Index'
import { Image } from 'react-native'

const ARROW_RIGHT_IMG = require('assets/images/png/arrow_right_nav.png')
const ARROW_LEFT_IMG = require('assets/images/png/arrow_left_nav.png')

const MyTasks = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { myLocations, loading } = useLocations(null, false, {
    enablePagination: true,
  })

  const chunks = useMemo(() => chunk(myLocations.slice(0, 9), 3), [myLocations])

  const getWorkflowPercentText = (tasks: any, numberOfCompletedTasks: number) => {
    let tasksText

    if (!tasks) {
      tasksText = null
    } else if (tasks == numberOfCompletedTasks) {
      tasksText = t('allTasksComplete', { tasks })
    } else if (numberOfCompletedTasks == 0) {
      tasksText = t(tasks == 1 ? 'taskToDo' : 'tasksToDo', { tasks })
    } else {
      tasksText = t('someTasksComplete', {
        numberOfCompletedTasks,
        numberOfTasks: tasks,
      })
    }

    return tasksText
  }

  const Workflow = ({ location, height = 340 }: { location: any, height?: number }) => <TaskCard
    backgroundColor={location.color}
    height={height}
    onClick={() => {
      if (location.children.length > 0) {
        history.push({
          pathname: ROUTE_NAMES.LOCATIONS,
          search: `?locationHierarchyId=${location.id}`,
        })
      } else {
        history.push({
          pathname: ROUTE_NAMES.TASKS_TODAY,
          search: `?locationHierarchyId=${location.id}`,
        })
      }
    }}
  >
    <TaskCardTop>
      <LocationProgressCircle
        numberOfTasks={location.numberTasks}
        numberOfCompletedTasks={location.completedTasksCount}
        radius={65}
        circleMarkerColor={location.color}
        fontSize={24}
        fontColor={location.colorBrightness == 'light' ? 'white' : ' black'}
        progressColor={location.progressColor}
        addMarginToCheckmark={true}
      />
    </TaskCardTop>
    <TaskCardBottom>
      <WorkflowTitle>{location.title}</WorkflowTitle>
      <WorkflowPercent>{getWorkflowPercentText(location.numberTasks, location.completedTasksCount)}</WorkflowPercent>
    </TaskCardBottom>
  </TaskCard>

  const renderTasks = () => {
    if (myLocations.length <= 3) {
      return <div>
        {chunks.map((locations) =>
          <Tasks>
            {locations.map(location => <Workflow key={location.id} location={location} height={365} />)}
          </Tasks>
        )}
      </div>
    }

    return myLocations.length > 0 && <StyledCarousel
      className='root-carousel'
      fullHeightHover={false}
      navButtonsAlwaysVisible
      nextNavButtonWrapperProps={{
        style: {
          top: '-24px',
          bottom: 'unset',
          cursor: 'pointer'
        }
      }}
      prevNavButtonWrapperProps={{
        style: {
          top: '-24px',
          bottom: 'unset',
          left: 'unset',
          right: '36px',
          cursor: 'pointer'
        }
      }}

      NavButton={({ onClick, className, style, next, prev }: any) => {
        // Other logic
        return (
          <div className={className} style={style} onClick={onClick} >
            {next && <Image
              source={ARROW_RIGHT_IMG}
              style={{ width: 24 }}
              alt='next'
            />}
            {prev && <Image
              source={ARROW_LEFT_IMG}
              style={{ width: 24 }}
              alt="previous"
            />}
          </div>
        )
      }}
      autoPlay={false}
    >
      {chunks.map((locations) =>
        <Tasks>
          {locations.map(location => <Workflow key={location.id} location={location} />)}
        </Tasks>
      )}
    </StyledCarousel>

  }

  return <Column>
    <Title>{t('myTasks')}</Title>

    {loading && <GeneralActivityIndicator size="large" />}

    {myLocations.length == 0 && !loading && <UpNextDone />}

    {renderTasks()}
  </Column>
}

const StyledCarousel = styled(Carousel)`
  width: 100%;
  paddingTop: 50px;
  &.root-carousel {
    overflow: visible;
  }
`

const WorkflowTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  text-align: left;
`

const WorkflowPercent = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
`

const TaskCardTop = styled.div`
  display: flex;
`

const TaskCardBottom = styled.div`
  display: flex;
  flex-direction: column;
`

const TaskCard = styled.div<{ backgroundColor?: string, height?: number }>`
  max-width: 162px;
  height: ${({ height }) => `${height}px` ?? '340px'};
  margin-right: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  border-radius: 32px;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.06);
  background-color: ${({ backgroundColor }) => backgroundColor ?? '#243162'};
  color: white;
  cursor: pointer;
  :last-child {
    margin-right: 0px;
  }
`

const Tasks = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 12px;
`

const Title = styled.div`
  display: flex;
  font-size: 24px;
  font-weight: 600;
  color: ${COLORS.GRAYDARK3};
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 24px;
`

export default MyTasks