import React, { useEffect, useMemo, useState } from 'react'
import * as Linking from 'expo-linking'
import { Redirect, useHistory, useParams } from 'react-router-native'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import { DiscoveryDocument, fetchDiscoveryAsync } from 'expo-auth-session'
import * as WebBrowser from 'expo-web-browser'
import { Loader } from 'components/common/Loader'

import { OKTA_CONFIGURATION } from 'graphql/mutations'
import { GET_CURRENT_USER } from 'graphql/queries'

import styled from 'styled-components'
import { IS_WEB } from '../../../constants/static';
import { ROUTE_NAMES } from 'navigation/constants'
import useOktaLogin from '../form/hooks/useOktaLogin'
import { FETCH_POLICIES } from 'constants/api'

const nextPageNavigate = ROUTE_NAMES.HOME
WebBrowser.maybeCompleteAuthSession()


// login component
const OktaLogin = () => {
    const history = useHistory()
    const [email, setEmail] = useState('')
    const [triggerOktaLogin, setTriggerOktaLogin] = useState(false)
    const [loginError, setLoginError] = useState('')
    const [oktaClientId, setOktaClientId] = useState('')
    const [oktaDiscovery, setOktaDiscovery] = useState<DiscoveryDocument>(null)
    const [getCurrentUser, { data: currentUserData }] = useLazyQuery(
        GET_CURRENT_USER,
        {
            onError: () => { },
            fetchPolicy: FETCH_POLICIES.NO_CACHE,
        },
    )
    useOktaLogin({
        clientId: oktaClientId,
        discovery: oktaDiscovery,
        email,
        setLoginError,
        triggerOktaLogin,
        setTriggerOktaLogin,
    })

    const [
        oktaConfiguration,
        { data: oktaConfigurationData, loading },
    ] = useMutation(OKTA_CONFIGURATION)

    const setUpOkta = async (oktaClientId: string, issuer: string) => {
        setOktaClientId(oktaClientId)

        const discovery = await fetchDiscoveryAsync(issuer)
        setOktaDiscovery(discovery)
        setTimeout(() => {
            setTriggerOktaLogin(true)
        }, 0)
    }

    useEffect(() => {
        if (currentUserData?.user) {
            // Navigate only after user data is loaded
            history.push({ pathname: nextPageNavigate })
        }
    }, [currentUserData])

    useEffect(() => {
        getCurrentUser()
    }, [])

    const url = Linking.useURL()

    const issuer = useMemo(() => {
        if (!url || !IS_WEB) {
            return
        }
        const { queryParams } = Linking.parse(url)

        return queryParams?.iss
    }, [url])

    useEffect(() => {
        if (issuer) {
            oktaConfiguration({ variables: { issuer } })
        }
    }, [issuer])


    useEffect(() => {
        if (oktaConfigurationData?.oktaConfiguration?.issuer) {
            const { issuer, mobileOktaClientId } = oktaConfigurationData?.oktaConfiguration

            setUpOkta(mobileOktaClientId, issuer)
        }
    }, [oktaConfigurationData])

    return (
        <StyledLoginScreen>
            <Loader />
        </StyledLoginScreen>
    )
}

export default OktaLogin

const StyledLoginScreen = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  font-family: Poppins;
`
