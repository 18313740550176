import React, { useMemo } from 'react'
import { Text } from 'react-native'
import styled from 'styled-components/native'
import * as DocumentPicker from 'expo-document-picker'
import * as ImagePicker from 'expo-image-picker'
import UploadMediaButton from 'components/task-list/buttons/UploadMediaButton'
import { createImageFile } from 'components/task-complete/helpers/image-helpers'
import { IS_WEB } from 'constants/static'
import COLORS from 'constants/colors'
import { ReactNativeFile } from 'apollo-upload-client'

const QuestionUpload = ({
  questionId,
  media,
  onOptionSelected,
}: {
  questionId: string
  media: (File | ReactNativeFile)[]
  onOptionSelected: (currentState: any) => void
}) => {
  const pickDocumentWeb = async () => {
    if (!IS_WEB) {
      return
    }
    let documents = await DocumentPicker.getDocumentAsync({
      copyToCacheDirectory: false,
      multiple: true,
      type: ['image/*', 'application/pdf'],
    })

    if ((documents?.output && documents.canceled == false)) {
      const fileList = documents.output

      onOptionSelected((currentState: any) => {
        currentState[questionId].media = fileList

        return {
          ...currentState
        }
      })
    }
  }

  const pickImage = async () => {
    const imageResult = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      aspect: [4, 3],
      quality: 1,
    })

    if (imageResult.canceled == false) {
      const reactNativeImage = await createImageFile(imageResult.assets[0])

      onOptionSelected((currentState: any) => {
        currentState[questionId].media = reactNativeImage

        return {
          ...currentState
        }
      })
    }
  }

  const handleUploadDocument = useMemo(() => {
    return IS_WEB ? pickDocumentWeb : pickImage
  }, [IS_WEB])

  return <Container>
    <UploadMediaButton
      selected={false}
      onPress={handleUploadDocument}
      loading={false}
    />

    {media.map(file => <Text
      style={{
        marginTop: 15,
        color: COLORS.TURQUOISE,
        textDecorationLine: 'underline',
      }}
    >
      {file?.name}
    </Text>)}
  </Container>

}

const Container = styled.View`
  display: flex;
  justify-content: center;
`

export default QuestionUpload