import { isValid, parseISO } from 'date-fns'
import { replace } from 'lodash'
import format from 'utils/date-fns'

// Using a custom implementation for matchAll because JS engine on android uses an older JS version
const matchAll = (re: RegExp, str: string) => {
  let match
  const matches = []

  while ((match = re.exec(str))) {
    matches.push(match)
  }

  return matches
}

type placeholderCallback = (
  offset: number,
  length: number,
  snippetName: string,
) => void

const findPlaceholders = (text: string, callback: placeholderCallback) => {
  let offset, length
  const matches = matchAll(/{{[a-zA-Z0-9._-]+}}/g, text)

  for (const match of matches) {
    offset = match.index
    length = match[0].length
    callback(offset, length, match[0].slice(2, -2))
  }
}

/*
  Takes in draft-js raw content and updates each block
  with the correct value for the snippet placeholder.
  Also updates the styles and entities offset after the text length change.
*/
const replaceSnippetsInDescription = (
  rawContent: string,
  snippetsMapping: StringMap,
  onError: (key: string) => void,
  locale?: string,
): string => {
  if (!rawContent) {
    return rawContent
  }

  let htmlContent = rawContent
  for (const match of matchAll(/{{[a-zA-Z0-9._-]+}}/g, htmlContent)) {
    const placeholder = match[0]

    htmlContent = replace(
      htmlContent,
      placeholder,
      getPlaceholderValue(placeholder, snippetsMapping, onError, locale),
    )
  }
  return htmlContent
}

const getPlaceholderValue = (
  placeholder: string,
  snippetsMapping: StringMap,
  onNotFound: (key: string) => void,
  locale?: string,
) => {
  placeholder = placeholder.replace(/{{|}}/g, '').toLowerCase()

  if (!(placeholder in snippetsMapping)) {
    onNotFound(placeholder)
    return '[Invalid Snippet]'
  }

  let snippetValue = snippetsMapping[placeholder]
  if (snippetValue == null) {
    onNotFound(placeholder)
    return '[Invalid Snippet]'
  }

  if (placeholder.includes('date')) {
    const date = parseISO(snippetValue)

    if (isValid(date)) {
      snippetValue = format(date, 'LLLL d, y', locale || 'en-US')
    }
  }
  return snippetValue
}

const replaceSnippetsInText = (
  text: string,
  snippetsMapping: StringMap = {},
  onError?: (key: string) => void,
  locale?: string,
) => {
  let newText = text
  findPlaceholders(text, (start: number, end: number, key: string) => {
    let snippetValue = snippetsMapping[key]
    if (!snippetValue) {
      if (onError) {
        onError(key)
      }
      return
    }

    // convert date snippets to locale
    if (key.includes('_date')) {
      const date = parseISO(snippetValue)

      if (isValid(date)) {
        snippetValue = format(date, 'LLLL d, y', locale || 'en-US')
      }
    }

    newText = newText.replace(`{{${key}}}`, snippetValue)
  })

  return newText
}

export { replaceSnippetsInDescription, replaceSnippetsInText }
