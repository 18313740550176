import React from 'react'
import styled from 'styled-components'
import { sortBy } from 'lodash'
import Resource from './Resource'

const Level2ResourcesSection = ({
  resources,
  title,
}: {
  resources: [ResourceInterface]
  title: string
}) => {
  return (
    <Container>
      <Title>{title}</Title>
      {resources && (
        <ResourcesListStyled>
          {sortBy(resources, ['position']).map(
            (resource) => (
              <Resource
                key={resource.id}
                title={resource.title}
                url={resource.url}
              />
            ),
          )}
        </ResourcesListStyled>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #F5F7FBCC;
  border-radius: 22px;
  padding: 25px;
  width: 260px;
`

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: black;
  margin-bottom: 12px;
`

const ResourcesListStyled = styled.ul`
  list-style: none;
  padding-left: 0;
`

export default Level2ResourcesSection
