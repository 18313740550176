import React, { useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { UPDATE_USER_LOCALE } from 'graphql/mutations'
import { Trans, useTranslation } from 'react-i18next'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { CACHE_KEYS } from 'constants/types'
import styled from 'styled-components'
import COLORS from 'constants/colors'
import { ToggleButton } from '@mui/material'
import useUser from 'hooks/useUser'
import useLanguages from 'hooks/useLanguages'
import { GET_CURRENT_USER } from 'graphql/queries'

const WelcomeWizardStep1 = () => {
  const { t, i18n } = useTranslation()
  const languages = useLanguages()
  const [updateUserLocale] = useMutation(UPDATE_USER_LOCALE)
  const { user } = useUser()

  const changeLanguage = async (language: string) => {
    i18n.changeLanguage(language)

    await AsyncStorage.setItem(CACHE_KEYS.SELECTED_LANGUAGE, language)

    await updateUserLocale({
      variables: {
        attributes: {
          preferredLocale: language,
        },
      },
      refetchQueries: [
        {
          query: GET_CURRENT_USER,
        }
      ],
    })
  }

  useEffect(() => {
    if (!user.preferredLocale) {
      changeLanguage('en-US')
    }
  }, [user])

  return <>
    <Column>
      <StyledImage src={require('assets/images/png/OnboardingStep1.png')} />
    </Column>

    <Column style={{ marginLeft: 64, width: '500px' }}>
      <TextContainer>
        <Title>{t('welcomeStep1Title')}</Title>

        <Description><Trans i18nKey="welcomeStep1Description" /></Description>
      </TextContainer>

      <Subtitle>{t('selectLanguage')}</Subtitle>

      <LanguageContainer>
        {languages.map(({ language, flag, title }) =>
          <ToggleButton
            value=""
            selected={i18n.resolvedLanguage == language}
            sx={{ width: '220px', marginBottom: '24px', marginRight: '36px', color: 'black', fontWeight: 400 }}
            onClick={() => changeLanguage(language)}
          >
            <ButtonText>{title} {flag}</ButtonText>
          </ToggleButton>)}
      </LanguageContainer>
    </Column>
  </>
}

const ButtonText = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`

const LanguageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 360px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
`

const StyledImage = styled.img`
  margin-top: 24px;
  height: 530px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Subtitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${COLORS.NAVY_BLUE};
  margin-bottom: 16px;
`

const Title = styled.div`
  font-size: 32px;
  font-weight: 600;
  color: ${COLORS.NAVY_BLUE}
`

const Description = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: ${COLORS.FIVES_GREY};
`

export default WelcomeWizardStep1