import React, { useMemo } from 'react'
import styled from 'styled-components/native'
import COLORS from 'constants/colors'

const PartialScore = (
  {
    questions = [],
    filledQuestions = [],
  }: {
    questions: QuestionType[],
    filledQuestions: any,
  }
) => {
  const filledQuestionIds = useMemo(() => Object.keys(filledQuestions), [filledQuestions])

  const currentScore = useMemo(() => {
    let score = 0
    questions?.forEach(question => {
      if (filledQuestionIds.includes(question.id)) {
        question.options.forEach(option => {
          if (filledQuestions[question.id].optionsIds.has(option.id)) {
            score += option.score
          }
        })
      }
    });

    if (score == 0) {
      return '-'
    }

    return score;
  }, [questions, filledQuestions])

  const totalScore = useMemo(() =>
    questions?.reduce((acc, question) => {
      const maxScoreOption = Math.max(...question.options.map(option => option.score));
      return acc + maxScoreOption;
    }, 0),
    [questions, filledQuestions]);

  return <Container>
    <Title>Total Weighted Score</Title>
    <Percentage>{currentScore}/{totalScore} points</Percentage>
  </Container>
}

const Container = styled.View`
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background-color: #F3F3F3;
`

const Title = styled.Text`
  font-weight: 700;
  font-size: 18px;
  color: ${COLORS.NAVY_BLUE};
`

const Percentage = styled.Text<{ isDone: boolean }>`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.NAVY_BLUE};
`

export default PartialScore