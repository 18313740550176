import { useLocation } from "react-router-native"
import useTasks from "./useTasks"
import useCurrentLocation from "./useCurrentLocation"

const useCurrentTask = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search);
  const taskId = params.get('taskId');
  const { currentLocationHierarchyId } = useCurrentLocation()
  const { tasks } = useTasks(currentLocationHierarchyId)

  return tasks.find(task => task.id === taskId)
}


export default useCurrentTask
