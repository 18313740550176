import React, { useState, useCallback, useMemo } from 'react'
import { View, KeyboardAvoidingView, Platform, Text } from 'react-native'
import { useHistory, useLocation } from 'react-router-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { useMutation } from '@apollo/react-hooks'
import { parse } from 'query-string'
import AsyncStorage from '@react-native-async-storage/async-storage'

import GeneralActivityIndicator from 'components/common/GeneralActivityIndicator'
import { RESET_PASSWORD_VIA_TOKEN } from 'graphql/mutations'
import COLORS from 'constants/colors'
import { useHeaderContext } from 'utils/header-context'
import { CACHE_KEYS } from 'constants/types'
import { ROUTE_NAMES } from 'navigation/constants'
import LoginButton from '../common/sign-in/LoginButton'
import { useTranslation } from 'react-i18next'
import PasswordField from 'components/common/fields/PasswordField'
import { IS_WEB } from 'constants/static'

enum PasswordFieldEnum {
  password = 'password',
  repeatPassword = 'repeatPassword',
}

const ResetPasswordForm = () => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const [{ password, repeatPassword }, setPasswordValues] = useState({
    password: '',
    repeatPassword: '',
  })
  const [resetPasswordError, setResetPasswordError] = useState('')
  const [showPasswordsMustMatchError, setShowPasswordsMustMatchError] =
    useState(false)
  const [showPasswordMinLengthError, setShowPasswordMinLengthError] =
    useState(false)

  const token = useMemo(() => parse(location.search)['token'], [location])

  const [resetPasswordViaToken, { loading: isUpdatingPassword }] = useMutation(
    RESET_PASSWORD_VIA_TOKEN,
    {
      onCompleted: async (data) => {
        const authToken = data.resetPasswordViaToken.authenticationToken
        await AsyncStorage.setItem(CACHE_KEYS.TOKEN, authToken)
        history.push(ROUTE_NAMES.HOME)
      },
      onError: (err) => {
        setResetPasswordError(err.graphQLErrors[0].message)
        setTimeout(() => {
          history.push(ROUTE_NAMES.LOGIN)
        }, 6000)
      },
    },
  )

  const onChangeText = (field: PasswordFieldEnum, text: string) => {
    setShowPasswordsMustMatchError(false)
    setPasswordValues((preValues) => ({ ...preValues, [field]: text }))
  }

  const handleSubmit = useCallback(() => {
    setResetPasswordError('')
    if (password !== repeatPassword) {
      setShowPasswordsMustMatchError(true)
      return
    }

    if (password.length < 8 || repeatPassword.length < 8) {
      setShowPasswordMinLengthError(true)
      return
    }

    resetPasswordViaToken({
      variables: {
        token: token,
        attributes: {
          password,
        },
      },
    })
  }, [password, repeatPassword, token])

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : null}
    >
      <View
        style={styles.container}
      >
        {isUpdatingPassword ? (
          <GeneralActivityIndicator size="large" />
        ) : (
          <>
            <PasswordField
              value={password}
              setValue={(newValue: string) =>
                onChangeText(PasswordFieldEnum.password, newValue)
              }
              handleOnSubmit={() => { }}
              label="newPassword"
            />

            <PasswordField
              value={repeatPassword}
              setValue={(newValue: string) =>
                onChangeText(PasswordFieldEnum.repeatPassword, newValue)
              }
              handleOnSubmit={() => { }}
              isRepeatPassword={true}
            />
            <View style={styles.errorContainer}>
              {showPasswordsMustMatchError && (
                <Text style={styles.error}>{t('passwordError')}</Text>
              )}
              {showPasswordMinLengthError && (
                <Text style={styles.error}>{t('passwordLengthError')}</Text>
              )}
              {resetPasswordError && (
                <Text style={styles.error}>{resetPasswordError}</Text>
              )}
            </View>
            <LoginButton
              disabled={!password || !repeatPassword}
              text={t('resetPassword')}
              onPress={handleSubmit}
              showNextArrow={false}
            />
          </>
        )}
      </View>
    </KeyboardAvoidingView>
  )
}

const styles = EStyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: IS_WEB ? 1 : 0,
  },
  errorContainer: {
    marginTop: 30,
    padding: 5,
    maxWidth: 400,
  },
  error: {
    color: COLORS.RED_TRANSPARENT_75,
    textAlign: 'left',
  },
})

export default ResetPasswordForm
