import CommentOutlinedIcon from './CommentOutlined'
import CommentIcon from './Comment'
import OptionOutlineIcon from './OptionOutlined'
import OptionIcon from './Option'
import SignatureOutlinedIcon from './SignatureOutlined'
import SignatureIcon from './Signature'
import CameraOutlinedIcon from './CameraOutlined'
import CameraIcon from './Camera'
import CheckmarkOutlinedIcon from './CheckmarkOutlined'
import CheckmarkIcon from './Checkmark'
import CalendarIcon from './Calendar'
import ExpandArrowRightIcon from './ExpandArrowRight'
import ChartIcon from './Chart'
import TangeloIcon from './TangeloIcon'
import ChevronDown from './ChevronDown'
import WelcomeIcon from './WelcomeIcon'
import NopeIcon from './Nope'
import NotApplicableIcon from './NotApplicable'
import HouseIcon from './House'
import ArrowRightIcon from './ArrowRight'
import OptionArrow from './OptionArrow'
import ArrowRightRoundedIcon from './ArrowRightRounded'
import MedalIcon from './Medal'
import DocuSignIcon from './DocuSign'
import DocuSignOutlinedIcon from './DocuSignOutlined'
import AccessTimeIcon from './AcessTime'
import PriorityIcon from './Priority'
import ResourceIcon from './ResourceIcon'
import VisibilityIcon from './VisibilityIcon'
import DescriptionIcon from './DescriptionIcon'

export {
  CommentOutlinedIcon,
  CommentIcon,
  OptionOutlineIcon,
  OptionIcon,
  SignatureOutlinedIcon,
  SignatureIcon,
  CameraOutlinedIcon,
  CameraIcon,
  CheckmarkOutlinedIcon,
  CheckmarkIcon,
  CalendarIcon,
  ExpandArrowRightIcon,
  ChartIcon,
  TangeloIcon,
  ChevronDown,
  WelcomeIcon,
  NopeIcon,
  NotApplicableIcon,
  HouseIcon,
  ArrowRightIcon,
  OptionArrow,
  ArrowRightRoundedIcon,
  MedalIcon,
  DocuSignIcon,
  DocuSignOutlinedIcon,
  AccessTimeIcon,
  PriorityIcon,
  ResourceIcon,
  VisibilityIcon,
  DescriptionIcon
}
