import React from 'react';

import styled from 'styled-components'
import Modal from 'react-native-modal'
import ContentPage from 'components/pages/ContentPage.web'


const AboutTheOrgModal = ({ currentPageId, isOpen, onClose }: { currentPageId: string, isOpen: boolean, onClose: () => void }) => {
  return <Modal
    isVisible={!!currentPageId}
    hasBackdrop={false}
    style={{ margin: 0 }}
  >
    <ModalContent>
      <ContentPage
        pageId={currentPageId}
        close={onClose}
      />
    </ModalContent>
  </Modal>
};


const ModalContent = styled.div`
  @media (min-width: 992px) {
    width: 782px;
  }
  @media (min-width: 1200px) {
    width: 982px;
  }
  @media (min-width: 1400px) {
    width: 1420px;
  }
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-height: 100%;
  overflow-y: scroll;
  background-color: #eeeeee;
`

export default AboutTheOrgModal;
