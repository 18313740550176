import React from 'react'
import styled from 'styled-components'

const CustomProgressCircle = ({
  children,
  percent,
  radius,
  borderWidth,
  bgColor,
  shadowColor,
  color,
  markerColor,
  circleMarkerColor,
}: CustomProgressCirclePropsType) => {
  let val = Math.min(Math.max(percent, 0), 100)
  const c = Math.PI * ((radius - borderWidth) * 2)
  const size = radius * 2
  const strokeOffset = ((100 - val) / 100) * c

  return (
    <CustomProgressCircleWrap size={size}>
      <svg height={size} width={size}>
        <circle
          r={radius - borderWidth}
          cx={radius}
          cy={radius}
          fill={bgColor}
          stroke={shadowColor}
          stroke-width={borderWidth}
          stroke-dasharray={c}
          stroke-dashoffset={0}
        ></circle>
        <circle
          r={radius - borderWidth}
          cx={radius}
          cy={radius}
          fill="transparent"
          stroke={color}
          stroke-linecap="round"
          stroke-width={borderWidth}
          stroke-dasharray={c}
          stroke-dashoffset={strokeOffset}
          transform={`rotate(-90 ${radius} ${radius})`}
        ></circle>
      </svg>
      {percent > 0 && (
        <ProgressCircleMarker percentage={percent}>
          <CircleMarker circleMarkerColor={circleMarkerColor} markerColor={markerColor}></CircleMarker>
        </ProgressCircleMarker>
      )}
      <CustomProgressCircleChildrenWrap>
        {children}
      </CustomProgressCircleChildrenWrap>
    </CustomProgressCircleWrap>
  )
}

const CustomProgressCircleWrap = styled.div`
  position: relative;
  height: ${(props: { size: number }) => props.size}px;
  width: ${(props: { size: number }) => props.size}px;
`

const CustomProgressCircleChildrenWrap = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  transform: translateY(-50%);
`

const ProgressCircleMarker = styled.div`
  position: absolute;
  left: calc(50% - 10px); // minus half the circle marker width + border size
  top: 0px;
  height: 100%;
  display: flex;
  justify-content: left;
  transform: ${(props: { percentage: number }) =>
    `rotate(${(props.percentage * 360) / 100}deg)`};
`

const CircleMarker = styled.div<{circleMarkerColor: string, markerColor: string}>`
  width: 12px;
  height: 12px;
  border: 4px solid ${({circleMarkerColor}) => `${circleMarkerColor}`};
  border-radius: 50%;
  background-color: ${({ markerColor}) => `${markerColor}`};
  margin-top: 2px;
`

export default CustomProgressCircle
