import React from 'react'
import { get } from 'lodash'

import TaskPhotoToComplete from 'components/task-complete/TaskPhotoToComplete'
import TaskSignToComplete from 'components/task-complete/TaskSignToComplete'
import TaskDocuSignToComplete from 'components/task-complete/TaskDocuSignToComplete'
import TaskTextToComplete from 'components/task-complete/TaskTextToComplete'
import TaskToggleToComplete from 'components/task-complete/TaskToggleToComplete'
import TaskMultipleOptionToComplete from 'components/task-complete/TaskMultipleOptionToComplete'
import TaskUploadMediaToComplete from 'components/task-complete/TaskUploadMediaToComplete'
import TaskInfoToComplete from 'components/task-complete/TaskInfoToComplete'
import TaskQuestionToComplete from 'components/task-complete/TaskQuestionToComplete'
import TaskSendSafelyToComplete from 'components/task-complete/TaskSendSafelyToComplete'
import TaskVideoToComplete from 'components/task-complete/TaskVideoToComplete'
import TaskScormCourseComplete from 'components/task-complete/TaskScormCourseComplete'

// map for task completion elements
const taskCompletionRenderMap = {
  PHOTO: TaskPhotoToComplete,
  TOGGLE: TaskToggleToComplete,
  SIGN: TaskSignToComplete,
  DOCUSIGN: TaskDocuSignToComplete,
  TEXT: TaskTextToComplete,
  MULTIPLE_OPTION: TaskMultipleOptionToComplete,
  UPLOAD_MEDIA: TaskUploadMediaToComplete,
  INFO: TaskInfoToComplete,
  QUIZ: TaskQuestionToComplete,
  SURVEY: TaskQuestionToComplete,
  SEND_SAFELY: TaskSendSafelyToComplete,
  VIDEO: TaskVideoToComplete,
  SCORM_COURSE: TaskScormCourseComplete,
  DOCEBO: TaskScormCourseComplete,
  SCORECARD: TaskQuestionToComplete,
}

// elements for task completition
//It takes the task_type and returns the corresponding component from the map above
//It then passes all the params to the component - task, completed, locationHierarchyId, sessionUuid
const TaskCompletionElement = (p: CompleteElementProps) => {
  const { taskType } = p
  const completionElementType = get(taskCompletionRenderMap, taskType)

  return completionElementType && React.createElement(completionElementType, p)
}

export default TaskCompletionElement
