import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-native'

import COLORS from 'constants/colors'
import useClientConfig from 'hooks/useClientConfig'
import { useTranslation } from 'react-i18next'
import { ROUTE_NAMES } from 'navigation/constants'
import LaunchIcon from './LaunchIcon.web'
import { GeneralActivityIndicator } from 'components/Index'
import useContentPages from 'hooks/useContentPages'
import AboutTheOrgModal from './AboutTheOrgModal.web'

const AboutTheOrg = ({ liteVersion = false, amount = 6 }) => {
  const { t } = useTranslation()
  const { clientLogo } = useClientConfig()
  const history = useHistory()
  const [currentPageId, setCurrentPageId] = useState(null)

  const { contentPages, loading } = useContentPages()

  if (!contentPages || contentPages.length == 0) {
    return null
  }

  return <Container>
    <TitleContainer>
      <Title>{t('aboutTheOrg')}</Title>
      {liteVersion && <Subtitle
        onClick={() => {
          history.push({
            pathname: ROUTE_NAMES.RESOURCES,
          })
        }}
      >{t('seeAll')}</Subtitle>}
    </TitleContainer>

    <List compact={liteVersion}>
      {loading && <GeneralActivityIndicator size="large" />}

      {contentPages
        .slice(0, liteVersion ? amount : contentPages.length)
        .map((page) =>
          <LaunchIcon
            compact={liteVersion}
            onClick={() => {
              setCurrentPageId(page.id)
            }}
            title={page.title}
            imgSrc={page.iconUrl ?? clientLogo}
          />
        )
      }
    </List>

    <AboutTheOrgModal
      currentPageId={currentPageId}
      isOpen={!!currentPageId}
      onClose={() => setCurrentPageId(null)}
    />
  </Container>
}

const List = styled.div<{ compact: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  ${({ compact }) => compact ? 'max-width: 300px;' : ''}
  ${({ compact }) => compact ? 'justify-content: center;' : ''}
  ${({ compact }) => compact ? 'align-items: center;' : ''}
`

const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.NAVY_BLUE};
  cursor: pointer;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLORS.LIGHT_POWDER_BLUE};
  border-radius: 22px;
  padding: 24px 24px 12px 24px;
`

export default AboutTheOrg