import React, { useState } from 'react'
import { View, Text } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import ReactLinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

import COLORS from 'constants/colors'
import TaskSubtitle from './TaskSubtitle'
import DueDateText, { DueDateTextMode } from 'components/common/DueDateText'

const ResponsiveEllipsis = responsiveHOC()(ReactLinesEllipsis)

type TaskTitleProps = {
  title?: string
  taskType: string
  frequencyType: string
  completedEvent: TaskCompleteType
  additionalNotesText: string
  onOptionTaskClick?: (value: string) => void
  important: boolean
  estimateInMins?: number
  dueDate?: string
  completed?: boolean
}

const TaskTitle = ({
  title,
  additionalNotesText,
  important,
  estimateInMins,
  dueDate,
  completed,
}: TaskTitleProps) => {
  const renderSubtitle = () => {
    if (additionalNotesText) {
      return (
        <Text
          style={{ color: COLORS.BLUEDARK, fontSize: 11 }}
          numberOfLines={1}
        >
          {additionalNotesText.replace(/[\n\r]/g, ' ')}
        </Text>
      )
    }

    return <TaskSubtitle dueDate={!completed && dueDate} estimateInMins={estimateInMins} important={important} />
  }

  return (
    <View style={styles.task}>
      <ResponsiveEllipsis
        text={title || ''}
        maxLine={1}
        ellipsis='...'
        trimRight
        basedOn='letters'
      />

      {renderSubtitle()}
    </View>
  )
}

const styles = EStyleSheet.create({
  task: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 320,
  },
  taskText: {
    fontSize: 16,
    lineHeight: 18,
    color: COLORS.NAVY_BLUE,
  },
  taskIcons: {
    display: 'flex',
    flexDirection: 'row',
    left: 10,
    bottom: 0,
    right: 0,
    maxHeight: 17,
    overflow: 'hidden',
  },
  taskIcon: {
    marginRight: 5,
    color: COLORS.BLUEDARK,
    fontSize: 15,
  },
})

export default TaskTitle
