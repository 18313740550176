import { ReactNativeFile } from 'apollo-upload-client'
import { EventTypesUnion, EVENT_TYPES } from 'constants/types'
import { CameraCapturedPicture } from 'expo-camera'
import { FileInfo } from 'expo-file-system'
import { createImageFile, resizeAndOverwriteImage } from './image-helpers'

export interface CreateEventData {
  sessionUuid?: string
  taskId?: string
  text?: string
  imageString?: string
  cantCompleteText?: string
  eventType?: string
  image?: CameraCapturedPicture
  file?: FileInfo
  user?: User
  media?: FileList | ReactNativeFile
}

export interface CreateEventProps {
  eventType: EventTypesUnion
  createEventData?: CreateEventData
}

interface CreateEventMutationVariables {
  eventType: string
  sessionUuid?: string
  taskId?: string
  imageFile?: ReactNativeFile
  text?: string
  cantCompleteText?: string
  user?: User
}

interface CreateEventMutationResponse {
  data: {
    createEvent: {
      imageUrl: string
      text: string
      optimisticResponse: boolean
    }
  }
}

interface CreateHandleCreateEventProps {
  client: ClientType
  selectedDay: string
  taskId: string
  locationHierarchyId: string
}

const processImage = async (image: CameraCapturedPicture) => {
  await resizeAndOverwriteImage(image)

  return await createImageFile(image)
}

const processFile = async (file: FileInfo) => {
  return await createImageFile(file)
}

export const buildCreateEventMutationVariables = async (
  eventType: EventTypesUnion,
  createEventData: CreateEventData,
): Promise<CreateEventMutationVariables> => {
  const { image, file } = createEventData

  delete createEventData.image
  delete createEventData.file

  const mutationVariables: CreateEventMutationVariables = {
    eventType,
    ...createEventData,
  }

  if (image) {
    mutationVariables.imageFile = await processImage(image)
  }
  if (file) {
    mutationVariables.imageFile = await processFile(file)
  }

  return mutationVariables
}

export const isCantCompleteEvent = (
  event?: { cantCompleteText?: string | null } | null,
) => {
  if (!event) {
    return false
  }

  return event.cantCompleteText !== null
}

export const isNotApplicable = (completeEvent?: TaskCompleteType) => {
  if (!completeEvent) {
    return false
  }

  return completeEvent?.eventType === EVENT_TYPES.NOT_APPLICABLE
}
