import AsyncStorage from '@react-native-async-storage/async-storage'
import { createUploadLink } from 'apollo-upload-client'
import ApolloClient from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { captureException } from 'utils/sentry'

import { GRAPHQL_END_POINT } from 'constants/api'
import introspectionQueryResultData from 'constants/fragment-types.json'
import { CACHE_KEYS } from 'constants/types'
import { writeErrorData } from 'utils/error-handling'

import i18n from 'i18n/setup'

// fragment matcher for cache
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

const authLinkContextSetter = (_: any, { headers }: any) =>
  new Promise(async (resolve) => {
    // get the authentication token from local storage if it exists
    const token = await AsyncStorage.getItem(CACHE_KEYS.TOKEN)
    // return the headers to the context so httpLink can read them
    resolve({
      headers: {
        ...headers,
        'Accept-Language': i18n.languages.toString(),
        authorization: token || '',
      },
    })
  })

const authLink = setContext(authLinkContextSetter)

const uploadLink: ApolloLink = createUploadLink({
  uri: GRAPHQL_END_POINT,
})

// apollo cache
const apolloCache = new InMemoryCache({ fragmentMatcher })

apolloCache.writeData({
  data: {
    errorData: {
      __typename: 'error',
      hasError: false,
      status: '',
      message: '',
    },
    appData: {
      taskDescriptionModalId: '',
      __typename: 'AppData',
    },
  },
})

// apollo input mapper
export const apolloInputMapper = {
  deserialize: (variables: any) => variables.attributes,
  serialize: (variables: any) => ({ attributes: variables }),
}

const middlewareLink = new ApolloLink((operation, forward) => {
  if (operation.variables?.attributes?.imageFile) {
    operation.variables.attributes.imageFile.__proto__ = File.prototype
  }
  return forward(operation)
})

const client = new ApolloClient({
  cache: apolloCache,
  link: ApolloLink.from([
    authLink,
    onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors) {
        graphQLErrors.map(
          ({
            message,
            locations,
            path,
            status,
            extensions: { level = 'error' } = {},
          }: any) => {
            const errorMessage = `[GraphQL error]: ${message}, Location: ${JSON.stringify(
              locations,
            )}, Path: ${path}`

            // Don't log authentication errors
            if (status !== '401' && level !== 'WARNING') {
              captureException(errorMessage, level)
            }

            writeErrorData(client, {
              message: 'An error occurred. Admins have been notified',
              status,
            })
          },
        )
      }
    }),
    middlewareLink,
    uploadLink,
  ]),
  // @ts-ignore
  inputMapper: apolloInputMapper,
})

export default client
