import React from 'react'
import styled from 'styled-components'

const Resource = ({ title, url }: { title: string; url: string }) => {
  return (
    <ResourceStyled>
      {!!url && url !== '#' ? (
        <a href={url} target="_blank">
          {title}
        </a>
      ) : (
        title
      )}
    </ResourceStyled>
  )
}

const ResourceStyled = styled.li`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;

  &:before {
    color: black;
    content: '•';
    display: inline-block;
    width: 1em;
  }

  a {
    color: inherit;
  }
`

export default Resource
