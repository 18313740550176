import React, { useEffect, useMemo, useState } from 'react'

import styled from 'styled-components/native'
import { useLazyQuery } from '@apollo/react-hooks'
import { GET_TASKS_COMPLETE, SCORM_COURSE_URL } from 'graphql/queries'
import { FETCH_POLICIES } from 'constants/api'
import DefaultButton from '../buttons/DefaultButton'
import { TouchableOpacity, View, Text } from 'react-native'
import Modal from 'react-native-modal'
import { ICON_NAMES } from 'constants/icons'
import Icon from 'components/common/Icon'
import { useAppContext } from 'utils/app-context'
import { getTaskCompleteVariables } from 'utils/apollo'
import WebView from 'react-native-webview'
import { IS_WEB } from 'constants/static'
import { detectIncognito } from "detectincognitojs";
import { set } from 'lodash'

const ScormCourseTask = ({ task,
  locationHierarchyId,
  completedEvent,
}: {
  task: TaskInterface,
  locationHierarchyId: string,
  completedEvent: TaskCompleteType
}) => {
  const appContextValue = useAppContext()
  const taskCompleteVariables = getTaskCompleteVariables(
    locationHierarchyId,
    appContextValue,
  )
  const [canViewCourse, setCanViewCourse] = useState<boolean | null>(null)

  const [fetchCourseUrl, { data: scormCourseUrlData, loading: isLoading }] = useLazyQuery<{
    scormCourseUrl: string
  }>(SCORM_COURSE_URL, {
    variables: { taskId: task.id },
    fetchPolicy: FETCH_POLICIES.NETWORK_ONLY,
  })

  const [fetchCompletedTasks] = useLazyQuery<{
    scormCourseUrl: string
  }>(GET_TASKS_COMPLETE, {
    variables: { ...taskCompleteVariables },
    fetchPolicy: FETCH_POLICIES.NETWORK_ONLY,
  })

  const [showCourse, setShowCourse] = useState(false)

  const buttonDisabled = useMemo(() => {
    if (canViewCourse === null) return true
    return !canViewCourse
  }, [canViewCourse])


  const onShowCourse = () => {
    fetchCourseUrl()
    setShowCourse(true)
  }

  const onHideCourse = () => {
    fetchCompletedTasks()
    setShowCourse(false)
  }

  const renderIFrame = () => {
    if (IS_WEB) {
      return <iframe src={scormCourseUrlData.scormCourseUrl} width="100%" height="100%" style={{ border: 'none' }} />
    }

    return <WebView
      source={{ html: `<iframe width="100%" height="50%" src="${scormCourseUrlData.scormCourseUrl}" frameborder="0"></iframe>` }}
      style={{ marginTop: 20 }}
    />
  }

  useEffect(() => {
    if (IS_WEB) {
      detectIncognito().then((result: { browserName: string, isPrivate: boolean }) => {
        if (result.isPrivate || result.browserName.toLowerCase().includes('safari')) {
          setCanViewCourse(false)
        } else {
          setCanViewCourse(true)
        }
      })
    } else {
      setCanViewCourse(true)
    }
  }, [])




  return <Container>
    <CourseLaunchContainer>
      <DefaultButton title="Launch Course" onPress={onShowCourse}></DefaultButton>
      {canViewCourse === false && <Text>Please use Chrome and enable third-party cookies to ensure that the Course works.</Text>}
    </CourseLaunchContainer>

    <Modal isVisible={showCourse} style={{ width: '100%', height: '100%', margin: 0, }}>
      <View style={{ backgroundColor: 'white', position: 'absolute', left: 12, top: 12, right: 10, bottom: 10, borderRadius: 10, overflow: 'hidden' }}>
        <View style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: 44, zIndex: 2, boxShadow: '0px 4px 16px 0px #0000001F' }}>
          <TouchableOpacity onPress={
            () => onHideCourse()
          }
            style={{ position: 'absolute', right: 10, top: 8 }}>
            <Icon name={ICON_NAMES.CLOSE} size={20} onPress={() => onHideCourse()} />
          </TouchableOpacity>
        </View>
        <View style={{ position: 'absolute', top: 44, right: 0, bottom: 0, left: 0, overflow: 'hidden' }}>
          {scormCourseUrlData?.scormCourseUrl ? renderIFrame() : <Text>Loading...</Text>}
        </View>
      </View>
    </Modal>
  </Container>
}

const Container = styled.View`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`

const CourseLaunchContainer = styled.View`
  display: flex;
  width: 100%;
  padding: 40px 98px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
background: #F1F5F9;
`

export default ScormCourseTask
