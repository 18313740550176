import Constants from 'expo-constants'
export const END_POINT = Constants.expoConfig.extra.END_POINT
export const JLL_CLIENT_ID = Constants.expoConfig.extra.JLL_CLIENT_ID
export const JLL_OKTA_DOMAIN = Constants.expoConfig.extra.JLL_OKTA_DOMAIN
export const JLL_DOMAINS_ENV = Constants.expoConfig.extra.JLL_DOMAINS
export const JLL_DOMAINS = JLL_DOMAINS_ENV?.split(' ') || []

export const ZOOM_INFO_OKTA_CLIENT_ID =
  Constants.expoConfig.extra.ZOOM_INFO_OKTA_CLIENT_ID
export const ZOOM_INFO_OKTA_DOMAIN =
  Constants.expoConfig.extra.ZOOM_INFO_OKTA_DOMAIN
export const ZOOM_INFO_DOMAINS_ENV =
  Constants.expoConfig.extra.ZOOM_INFO_DOMAINS
export const ZOOM_INFO_DOMAINS = ZOOM_INFO_DOMAINS_ENV?.split(' ') || []

export const ENVIRONMENT = Constants.expoConfig.extra.ENVIRONMENT
export const TEAM_METRICS_URL = Constants.expoConfig.extra.TEAM_METRICS_URL
export const FULLSTORY_ORG_ID = Constants.expoConfig.extra.FULLSTORY_ORG_ID
export const SENTRY_DSN = Constants.expoConfig.extra.SENTRY_DSN
