import React from 'react'
import styled from 'styled-components/native'

const QuestionExplanation = ({ explanation }: { explanation: string }) =>
  <ExplanationContainer>
    <ExplanationTitle>
      Answer explanation:
    </ExplanationTitle>
    <ExplanationContent>
      {explanation}
    </ExplanationContent>
  </ExplanationContainer>

const ExplanationContainer = styled.View`
  background: #FFFFFF;
  border: 1px solid #B4C5E8;
  border-radius: 10px;
  padding: 15px;
`

const ExplanationTitle = styled.Text`
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #243162;
`

const ExplanationContent = styled.Text`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #243162;
`

export default QuestionExplanation