import React, { FC } from 'react'
import { View, Text } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { useTranslation } from 'react-i18next'
import { IS_WEB } from 'constants/static'

const ForgotPasswordExplanation: FC = () => {
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{t('forgottenPassword')}</Text>
      <Text style={styles.text}>{IS_WEB ? t('forgottenPasswordExplanationWithMagicLink') : t('forgottenPasswordExplanation')}</Text>
    </View>
  )
}

const styles = EStyleSheet.create({
  title: {
    textAlign: 'left',
    fontWeight: '600',
    fontFamily: 'Poppins_400Regular',
    fontSize: 16,
    paddingBottom: 10,
  },
  text: {
    textAlign: 'left',
    color: '#2A3749',
    fontWeight: '400',
    fontFamily: 'Poppins_400Regular',
  },
  container: {
    marginBottom: 20,
  },
})

export default ForgotPasswordExplanation
