import React, { useEffect, useRef } from 'react'
import { Button, Text } from '@rneui/themed'
import EStyleSheet from 'react-native-extended-stylesheet'
import SignCanvas from 'react-signature-canvas'
import styled from 'styled-components'
import COLORS from 'constants/colors'

const DEFAULT_WIDTH = 500
const SignatureCanvas = (props: SignatureViewProps) => {
  const refElement = useRef(null)
  const canvasRef = useRef(null)

  const width = refElement.current?.offsetWidth || DEFAULT_WIDTH
  const height = Math.min(
    (refElement.current?.offsetHeight || width) - 100,
    width,
  )

  const handleConfirm = () => {
    if (canvasRef.current) {
      if (canvasRef.current.isEmpty()) {
        props.onEmpty()
      } else {
        props.onOK(canvasRef.current.toDataURL())
      }
    }
  }

  const handleClear = () => {
    if (canvasRef.current) {
      canvasRef.current.clear()
    }
  }

  useEffect(() => {
    if (canvasRef) {
      canvasRef.current.clear()
    }
  }, [canvasRef])

  return (
    <Wrap ref={refElement}>
      <SignCanvasWrap>
        <SignCanvas
          ref={(ref) => {
            if (ref) {
              ref.clear()
            }
            canvasRef.current = ref
          }}
          backgroundColor="white"
          canvasProps={{
            width: width,
            height: height,
          }}
        />
      </SignCanvasWrap>
      <div>
        <ButtonsWrap>
          <Button
            buttonStyle={styles.button}
            title="Clear"
            style={{ justifyContent: 'space-between' }}
            onPress={() => handleClear()}
          ></Button>
          <TextWrap>
            <Text style={styles.text}>Sign above</Text>
          </TextWrap>
          <Button
            buttonStyle={styles.button}
            title="Confirm"
            style={{ justifyContent: 'space-between' }}
            onPress={() => handleConfirm()}
          ></Button>
        </ButtonsWrap>
      </div>
    </Wrap>
  )
}

const styles = EStyleSheet.create({
  button: {
    paddingHorizontal: 24,
    paddingVertical: 6,
  },
  text: {
    color: COLORS.GRAYDARK1,
  },
})

const Wrap = styled.div`
  flex-grow: 1;
  display: 'flex';
  flex-direction: 'column';
  overflow: 'hidden';
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: space-around;
`

const SignCanvasWrap = styled.div`
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  margin-bottom: 15px;
`

const TextWrap = styled.div`
  align-items: center;
  display: flex;
`

export default SignatureCanvas
