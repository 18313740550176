import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const NopeIcon = (props: SvgProps) => (
  <Svg fill="none" viewBox="0 0 36 36" {...props} data-testid="nope-icon">
    <Path
      d="M36 18c0 9.941-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0s18 8.059 18 18z"
      fill="#C90953"
    />
    <Path
      d="M24.382 11.618 11.618 24.382M18 27a9 9 0 1 0 0-18 9 9 0 0 0 0 18z"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </Svg>
)

export default NopeIcon
