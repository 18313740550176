import React from 'react'
import styled from 'styled-components'
import { Show } from '../SearchModal'
import { useTranslation } from 'react-i18next'

const SearchModalResultHeader = ({
  type,
  show,
  length,
  onClick,
}: {
  type: Show,
  show: Show,
  length: number
  onClick: () => void
}) => {
  const { t } = useTranslation()

  const label = () => {
    switch (type) {
      case 'workflows':
        return t('workflows')
      case 'sections':
        return t('sections')
      case 'resources':
        return t('resources')
      default:
        return t('tasks')
    }
  }

  const renderAllMatching = () => <SeeAllMatching onClick={onClick}>
    {t('seeAllMatching', { length })}
  </SeeAllMatching>

  return <ResultsHeader>
    <Title>{label()}</Title>
    <Matches>{length > 3 && show == null ? renderAllMatching() : t('matches', { length })}</Matches>
  </ResultsHeader>
}

const SeeAllMatching = styled.div`
  cursor: pointer;
`

const ResultsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.div`
  font-weight: 200;
  font-size: 24px;
  color: #000000;
`

const Matches = styled.div`
  font-weight: 200;
  font-size: 16px;
  color: #0056D8;
`

export default SearchModalResultHeader