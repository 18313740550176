import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import LeftSide from 'components/common/sign-in/LeftSide'
import RightSide from 'components/common/sign-in/RightSide'
import { FETCH_POLICIES } from 'constants/api'
import COLORS from 'constants/colors'
import { IS_WEB } from 'constants/static'
import { DiscoveryDocument, fetchDiscoveryAsync } from 'expo-auth-session'
import * as WebBrowser from 'expo-web-browser'
import { GET_CURRENT_USER } from 'graphql/queries'
import { ROUTE_NAMES } from 'navigation/constants'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  KeyboardAvoidingView,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { Redirect, useHistory, useParams } from 'react-router-native'
import useOktaLogin from '../form/hooks/useOktaLogin'
import { LOGIN_WITH_WORKOS_REDIRECT_URL, OKTA_CONFIGURATION } from 'graphql/mutations'

// serves for web only
WebBrowser.maybeCompleteAuthSession()

// config, next page after login
const nextPageNavigate = ROUTE_NAMES.HOME

// login form
const SingleSignOnRedirect = () => {
  const history = useHistory()
  const btnRef = useRef<any>()
  const { company } = useParams<{ company: string }>()
  const [triggerOktaLogin, setTriggerOktaLogin] = useState(false)
  const [loginError, setLoginError] = useState('')
  const [redirectToLogin, setRedirectToLogin] = useState(false)
  const [oktaClientId, setOktaClientId] = useState('')
  const [oktaDiscovery, setOktaDiscovery] = useState<DiscoveryDocument>(null)
  const { t } = useTranslation()
  const [oktaConfiguration] = useMutation(OKTA_CONFIGURATION)
  const [getSSORedirectUrl] = useMutation(LOGIN_WITH_WORKOS_REDIRECT_URL)

  const [getCurrentUser, { data: currentUserData }] = useLazyQuery(
    GET_CURRENT_USER,
    {
      onError: () => { },
      fetchPolicy: FETCH_POLICIES.NO_CACHE,
    },
  )
  useOktaLogin({
    clientId: oktaClientId,
    discovery: oktaDiscovery,
    email: company,
    setLoginError,
    triggerOktaLogin,
    setTriggerOktaLogin,
  })

  const handleWorkOSflow = async (ssoIdentifier: string) => {
    const variables = {
      attributes: {
        ssoIdentifier,
        isMobile: ['ios', 'android'].includes(Platform.OS),
        isAdminApp: false,
      },
    }

    const redirectUrlResponse = await getSSORedirectUrl({ variables })

    const workosAuthUrl =
      redirectUrlResponse?.data?.workosRedirectUrl?.redirectUrl

    if (!workosAuthUrl) {
      return false
    }

    window.location = workosAuthUrl
    return true
  }

  // handle Okta login
  const handleOktaLogin = async () => {
    setTriggerOktaLogin(true)
  }

  const setUpOkta = async () => {
    if (!company) {
      setRedirectToLogin(true)
      return
    }

    const oktaConfigurationResult = await oktaConfiguration({
      variables: { ssoIdentifier: company },
    })

    const clientOktaConfig =
      oktaConfigurationResult?.data?.oktaConfiguration || {}

    if (clientOktaConfig.mobileOktaClientId) {
      setOktaClientId(clientOktaConfig.mobileOktaClientId)

      const discovery = await fetchDiscoveryAsync(clientOktaConfig.issuer)
      setOktaDiscovery(discovery)

      return
    }

    await handleWorkOSflow(company)

    return
  }


  useEffect(() => {
    if (currentUserData?.user) {
      // Navigate only after user data is loaded
      history.push({ pathname: nextPageNavigate })
    }
  }, [currentUserData])

  useEffect(() => {
    getCurrentUser()
    const tmp = async () => {
      await setUpOkta()
    }
    tmp()
  }, [])

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === 'ios' ? 'padding' : null}
    >
      <LeftSide>
        <View>
          <Text style={styles.errorMessage}>{loginError}</Text>
        </View>

        <TouchableOpacity
          style={styles.loginButton}
          onPress={handleOktaLogin}
          ref={btnRef}
        >
          <Text style={styles.loginButtonText}>{t('Login')}</Text>
        </TouchableOpacity>
      </LeftSide>
      {IS_WEB && <RightSide />}
      {redirectToLogin && <Redirect to={ROUTE_NAMES.LOGIN} />}
    </KeyboardAvoidingView>
  )
}

const styles = EStyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    marginVertical: 20,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    flexDirection: 'row',
  },
  loginForm: {
    width: '100%',
    alignItems: 'center',
    marginTop: 10,
  },
  loginButton: {
    marginTop: 48,
    width: IS_WEB ? 424 : 300,
    height: 56,
    backgroundColor: COLORS.TURQUOISE,
    padding: 10,
    borderRadius: 12,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loginButtonText: {
    textAlign: 'center',
    color: 'white',
    fontWeight: '600',
    fontFamily: 'Poppins_400Regular',
  },
})

export default SingleSignOnRedirect
