import React from 'react'
import styled from 'styled-components'

import COLORS from 'constants/colors'
import { useHistory } from 'react-router-native'
import { ROUTE_NAMES } from 'navigation/constants'
import { useTranslation } from 'react-i18next'
import useClientConfig from 'hooks/useClientConfig'
import LaunchIcon from 'components/resources/components/LaunchIcon.web'
import useResources from 'hooks/useResources'
import { GeneralActivityIndicator } from 'components/Index'

const FavoriteResources = () => {
  const { favorites, loading } = useResources()
  const history = useHistory()
  const { t } = useTranslation()
  const { clientLogo } = useClientConfig()

  return <Container>
    <TitleContainer>
      <Title>{t('resources')}</Title>
      {<Subtitle
        onClick={() => {
          history.push({
            pathname: ROUTE_NAMES.RESOURCES,
          })
        }}
      >{t('seeAll')}</Subtitle>}
    </TitleContainer>

    <Favorites>
      {loading && <GeneralActivityIndicator size="large" />}

      {favorites.slice(0, 4).map(favorite =>
        <LaunchIcon
          onClick={() => {
            window.open(favorite.url, "_blank", "noreferrer");
          }}
          title={favorite.title}
          imgSrc={favorite.iconUrl ?? clientLogo}
        />
      )}
    </Favorites>
  </Container>
}

const Favorites = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`

const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.NAVY_BLUE};
  cursor: pointer;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${COLORS.LIGHT_POWDER_BLUE};
  border-radius: 22px;
  padding: 24px 24px 12px 24px;
`

export default FavoriteResources