{
  "monthDayYearDateFormat": "LLLL d, y",
  "monthDayDateFormat": "LLLL d",
  "welcome": "Bienvenido,\n{{name}}!",
  "clickOnTasksSection": "Haz clic en la sección de tareas para comenzar",
  "search": "Buscar",
  "typeHereToAddText": "Escribe aquí…",
  "complete": "Completar",
  "completed": "Completado",
  "cantComplete": "No se puede completar",
  "cantCompleteTask": "No se puede completar la tarea",
  "notes": "Notas de la tarea",
  "back": "Atrás",
  "sessionNotes": "Notas de la sesión",
  "confirm": "Confirmar",
  "notApplicable": "No aplica",
  "logout": "Cerrar sesión",
  "teamMetrics": "Métricas del equipo",
  "taskNotesDescription": "Utiliza este espacio para agregar notas relacionadas con esta tarea",
  "comment": "Comentar",
  "pleaseSelect": "Por favor selecciona…",
  "eSignature": "Firma electrónica",
  "takePicture": "Tomar foto",
  "accept": "Aceptar",
  "home": "Inicio",
  "tasksFor": "Tareas para",
  "due": "Pendiente para",
  "dueToday": "Pendiente para hoy",
  "tasks": "Tareas",
  "task": "Tarea",
  "tasksToDo": "{{tasks}} Tareas a realizar",
  "taskToDo": "{{tasks}} Tarea a realizar",
  "infoTasksToDo": "{{numberInfoTasks}} Tareas solo de información",
  "infoTaskToDo": "{{numberInfoTasks}} Tarea de solo información",
  "infoTasks": "{{numberInfoTasks}} Tareas de Información",
  "infoTask": "{{numberInfoTasks}} Tarea de información",
  "by": "por",
  "markedCantComplete": "Marcado no se puede completar",
  "markedNotApplicable": "Marcado no aplica",
  "markedRead": "Leer",
  "tasksComplete": "Tareas completadas",
  "allTasksComplete": "Todo {{tasks}} Tareas completadas",
  "someTasksComplete": "{{numberOfCompletedTasks}} de {{numberOfTasks}} Tareas completadas",
  "upNextTitle": "Siguiente",
  "upNextSubtitle": "Accede rápidamente a tus próximas tareas desde tus flujos de trabajo",
  "upNextDoneTitle": "¡Terminaste por ahora!",
  "upNextDoneSubtitle": "¡Bien hecho, super estrella! ¡No te queda nada por hacer! ¡Si se te asignan nuevas tareas, recibirás un correo electrónico!",
  "progress": "Progreso",
  "ago": "Hace",
  "dueIn": "Pendiente en",
  "days": "días",
  "resources": "Recursos",
  "resourcesSubtitle": "Usa esta lista curada de recursos destacados",
  "yourResources": "Tus recursos",
  "yourResourcesSubtitle": "Acceso rápido a todos sus enlaces útiles",
  "reports": "Informes",
  "signIn": "Iniciar sesión",
  "forgotPassword": "¿Olvidaste tu contraseña?",
  "emailAddress": "Correo electrónico",
  "password": "Contraseña",
  "newPassword": "Nueva Contraseña",
  "repeatPassword": "Repetir contraseña",
  "passwordError": "Las contraseñas deben coincidir",
  "passwordLengthError": "La contraseña debe tener al menos 8 caracteres",
  "resetPassword": "Crear nueva contraseña",
  "resetPasswordInfo": "El inicio de sesión con la contraseña temporal fue exitoso. \nPor favor cree una contraseña permanente",
  "forgottenPassword": "¿Olvidaste tu contraseña?",
  "forgottenPasswordExplanationWithMagicLink": "Ingrese su dirección de correo electrónico a continuación y elija entre enviar un correo electrónico para restablecer la contraseña o un enlace mágico único que lo iniciará al instante.",
  "forgottenPasswordExplanation": "Ingresa tu dirección de correo electrónico a continuación para recibir un correo electrónico de restablecimiento de contraseña y recuperar el acceso a tu cuenta.",
  "signInWithGoogle": "Iniciar sesión con Google",
  "checkSignInLink": "Por favor busca en tu buzón el correo con el enlace de Inicio de Sesión",
  "next": "Siguiente",
  "login": "Iniciar sesión",
  "sendSignInLink": "Enviar enlace de inicio de sesión",
  "en-US": "English",
  "en": "English",
  "fr-CA": "Français (Canada)",
  "fr": "Français",
  "es-ES": "Español",
  "es": "Español",
  "pt-BR": "Português",
  "pt": "Português",
  "de-DE": "Deutsch",
  "de": "Deutsch",
  "ja-JP": "日本",
  "ja": "日本",
  "zh-CN": "中文(简体)",
  "zh-TW": "中文(繁体)",
  "timeToComplete": "Tiempo para completar",
  "min": "min",
  "high": "Alta",
  "poweredBy": "proporcionado por",
  "uploading": "Subiendo...",
  "uploadMedia": "Subir medios",
  "admin": "Admin",
  "showWorkflows": "Mostrar completada",
  "showCompletedWorkflows": "Mostrar flujos de trabajo completados",
  "infoOnly": "Solo información, no se necesita ninguna acción para completar",
  "delegate": "Delgado",
  "getOurApp": "Obtén nuestra aplicación",
  "continueToApp": "Continuar a la aplicación",
  "promptMessage": "Tienes cambios sin guardar, ¿estás seguro de que quieres salir?",
  "onboardingBuddy": "Compañero de incorporación",
  "delegateAssignment": "Asignación de delegado",
  "onboardingBuddyAssignment": "Asignación de compañero de incorporación",
  "assignments": "Asignaciones",
  "delegateMainCopy": "Siga leyendo para ver una lista con todas sus nuevas incorporaciones y el delegado asignado para completar las tareas de incorporación del director de contratación, si está seleccionado.",
  "delegateEmailDescription": "Puede utilizar el espacio a continuación para enviar un mensaje relacionado con la delegación de tareas.",
  "newHire": "Nueva contratación",
  "assignedDelegate": "Compañero de delegado asignado",
  "assignedOnboardingBuddy": "Compañero de incorporacion asignado",
  "activeAssignments": "Asignaciones activas",
  "actions": "Acciones",
  "add": "Añadir",
  "remove": "Eliminar",
  "taskDelegation": "Delegación de tareas",
  "delegateConfirmationCopy": "Las tareas de incorporación del director de contratación se delegarán ahora al individuo seleccionado.\n\nTenga en cuenta que las tareas de gestión del rendimiento no pueden delegarse.",
  "okay": "Entendido",
  "activeAssignmentsCount": "Este usuario tiene actualmente {{count}} asignaciones activas.",
  "assign": "Asignar",
  "name": "Nombre",
  "assignDelegate": "Asignar Delegado",
  "assignOnboardingBuddy": "Asignar Compañero de Incorporación",
  "onboardingBuddyModalMainCopy": "Siga leyendo para ver una lista con todas sus nuevas incorporaciones y su compañero de incorporación asignado, si está seleccionado.",
  "removeDelegateUser": "¿Está seguro de que desea eliminar el delegado de {{userName}}?",
  "removeOnboardingUser": "¿Está seguro de que desea eliminar el compañero de incorporación para {{userName}}?",
  "refreshPage": "Actualizar página",
  "newAppVersionTitle": "Nueva versión de la Aplicación",
  "newAppVersionContent": "Hay una nueva versión de la aplicación. Para garantizar que la aplicación funcione sin problemas, actualice la página.",
  "commentAdded": "Comentario agregado:",
  "optionSelected": "Opción seleccionada:",
  "mediaUploaded": "Medios cargados:",
  "dependedOn": "Depende de",
  "loading": "Cargando...",
  "fetchingResults": "Estamos obteniendo sus resultados, espere...",
  "workflows": "Flujos de trabajo",
  "sections": "Secciones",
  "seeAllMatching": "Ver todas las {{longitud}} coincidencias",
  "matches": "{{longitud}} coincidencias",
  "enterSearchTerm": "Ingrese el término de búsqueda...",
  "oops": "Ups, algo salió mal.",
  "upNextError": "No pudimos cargar sus próximas tareas. Vuelva a intentarlo más tarde.",
  "myTasks": "Mis Tareas",
  "pastDue": "Atrasado",
  "tomorrow": "Mañana",
  "dueYesterday": "Vencido Ayer",
  "dueTomorrow": "Vence Mañana",
  "aboutTheOrg": "Acerca de la Empresa",
  "seeAll": "Ver Todo",
  "myRole": "Mi Rol",
  "manager": "Gerente",
  "buddy": "Amigo",
  "myTeam": "Mi Equipo",
  "seeAllTasks": "Ver Todas las Tareas",
  "viewWelcomeMessage": "Ver mensaje de bienvenida",
  "calendar": "Calendario",
  "dueInSixMonthsPlus": "Vence en más de 6 meses"
}