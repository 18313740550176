import React, { useEffect, useState, useCallback } from 'react'
import { RefreshControl, Button, Text, View, ScrollView } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { useQuery } from '@apollo/react-hooks'
import { useApolloClient } from '@apollo/react-hooks';
import { APP_DATA } from 'graphql/queries'

import { onRefreshCall } from 'utils/navigation'
import { compareNumbers } from 'utils/calculations'
import { IS_WEB } from 'constants/static'
import useNetworkStatus from 'components/common/hooks/useNetworkStatus'

import TaskListElement from './TaskListElement'
import useCurrentTask from 'hooks/useCurrentTask'

const findCompleted = (id: string, completedList: TaskCompleteType[]) => {
  return (completedList || []).find(({ taskId }) =>
    compareNumbers(taskId, id),
  )
}

// click on refresh button TODO: remove
// tslint:disable-next-line no-empty
const handleRefreshPress = () => { }

// component to render the task list
export default function TaskListMapping({
  tasks,
  locationHierarchyId,
  completedTaskEvents: completedList,
  loading,
  refetch,
  additionalPadding,
  snippetsMapping,
}: TaskListMappingPropsType) {
  const client = useApolloClient()
  // online status
  const online = useNetworkStatus()
  // state to show refreshing indicators
  const [refreshing, setRefreshing] = useState(false)
  // getter
  const getRefreshing = useCallback(() => refreshing, [refreshing])
  // on refresh
  const onRefresh = useCallback(() => {
    onRefreshCall(online, getRefreshing, setRefreshing, refetch)
  }, [refreshing, online])

  const nextUncompletedTask = () =>
    tasks.find((task) => !findCompleted(task.id, completedList))

  const { data } =
    useQuery<{
      appData: AppDataType
    }>(APP_DATA)

  const selectedTaskFromUrl = useCurrentTask()

  useEffect(() => {
    if (IS_WEB && !!tasks?.length) {
      const taskAlreadySelected = tasks.find(
        (task) => task.id === data?.appData?.taskDescriptionModalId,
      )
      if (taskAlreadySelected) {
        return
      }

      const selectedTask = selectedTaskFromUrl || nextUncompletedTask() || tasks[0]

      client.writeData({
        data: {
          appData: {
            taskDescriptionModalId: selectedTask.id,
            __typename: 'AppData',
          },
        },
      })
    }
  }, [tasks, data])

  return (
    <View style={{ flex: 1 }}>
      <View
        style={additionalPadding ? styles.container : styles.containerNoPadding}
      >
        {refreshing && (
          <Button onPress={handleRefreshPress} title="refreshing..." />
        )}
        <ScrollView
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewStyle}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
        >
          {(tasks || []).map((task: TaskInterface) => {
            const completedEvent = findCompleted(task.id, completedList)
            return (
              <TaskListElement
                // @ts-ignore
                key={task.id}
                {...task}
                {...{
                  locationHierarchyId,
                  completedEvent,
                  snippetsMapping,
                }}
              />
            )
          })}
          {!Boolean(tasks?.length) && !loading && (
            <Text>no task to execute for this date</Text>
          )}
        </ScrollView>
      </View>
    </View>
  )
}

// styles
const styles = EStyleSheet.create({
  parentContainer: {
    flex: 1,
  },
  container: {
    paddingTop: 0,
    flex: 1,
  },
  containerNoPadding: {
    paddingTop: 0,
    flex: 1,
  },
  scrollViewStyle: {
    paddingLeft: IS_WEB ? 0 : 10,
    paddingRight: IS_WEB ? 0 : 10,
    marginBottom: IS_WEB ? 0 : 10,
  },
})
