import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import LinearProgressGroupBar from './LinearProgressGroupBar'
import WorkflowsProgress from '../common/WorkflowsProgress'
import { MyTeamContext } from 'components/my-team/MyTeamContext'

const OverallWorkflowProgress = ({ reporteesWorkflows, allTemplatesCounter }: any) => {
  const { reportees } = useContext(MyTeamContext)

  const threeMostAssignedTemplates = useMemo(() => {
    const sortedTemplates = Object.values(allTemplatesCounter).sort((a: any, b: any) => b.count - a.count)
    return sortedTemplates.slice(0, 3)
  }, [allTemplatesCounter])

  const threeMostAssignedTemplatesProgress = useMemo(() => {
    let result: any = {}
    const threeMostAssignedTemplatesIds = threeMostAssignedTemplates.map((template: any) => template.id)

    for (const userId in reporteesWorkflows) {
      reporteesWorkflows[userId].forEach((workflow: any) => {
        if (threeMostAssignedTemplatesIds.includes(workflow.templateCategoryId)) {
          result[workflow.templateCategoryId] = [
            ...(result[workflow.templateCategoryId] || []),
            {
              completedTasksCount: workflow.completedTasksCount,
              tasksCount: workflow.tasks,
              progress: workflow.percent,
              owner: reportees.find((reportee: any) => reportee.id == workflow.owner.id),
            },
          ]
        }
      })
    }

    return result
  }, [threeMostAssignedTemplates])

  return (
    <WorkflowsProgress
      titles={threeMostAssignedTemplates.map((template: any) => template.title)}
      bars={threeMostAssignedTemplates.map((template: any) => {
        return <ProgressBar key={template.id}>
          <LinearProgressGroupBar
            markers={threeMostAssignedTemplatesProgress[template.id]}
            color={template.color}
          />
        </ProgressBar>
      })}
    />
  )
}

const ProgressBar = styled.div`
  padding-bottom: 15px;
`


export default OverallWorkflowProgress