import React, { useMemo } from 'react'
import { View, Text } from 'react-native';
import { PriorityIcon } from 'components/common/custom-icons';
import COLORS from 'constants/colors';
import { useTranslation } from 'react-i18next';

type Props = {
    width?: number,
    height?: number
    fontSize?: number
    withSeparator?: boolean
}

const Separator = () => (
    <Text
        style={{
            marginHorizontal: 16,
            color: '#7C8DA3',
        }}
    >
        |
    </Text>
)

const ImportantLabel = ({ width = 14, height = 14, fontSize, withSeparator = false }: Props) => {
    const { t } = useTranslation()

    const textStyle = useMemo(() => {
        const fontSizeStyle = fontSize ? { fontSize } : {}
        return {
            color: COLORS.SECONDARY,
            ...fontSizeStyle
        }
    }, [fontSize])

    return (
        <View
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            {withSeparator && <Separator />}
            <PriorityIcon
                width={width}
                height={14}
                style={{
                    marginRight: 4,
                }}
            />
            <Text
                style={textStyle}
            >
                {t('high')}
            </Text>
        </View>
    )
}

export default ImportantLabel