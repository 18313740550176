import React from 'react';
import { View, Platform } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import EStyleSheet from 'react-native-extended-stylesheet';

const IS_WEB = Platform.OS === 'web';

interface Client {
    id: string;
    label: string;
}

interface ClientsSelectorProps {
    clients: Client[];
    clientId: string;
    setClientId: (id: string) => void;
    placeholder?: string;
    editable?: boolean;
    testID?: string;
}

const ClientsSelector: React.FC<ClientsSelectorProps> = ({
    clients = [],
    clientId,
    setClientId,
    placeholder = "Select Client",
    editable = true,
    testID,
}) => {
    if (clients.length === 0) return null;

    return (
        <View style={styles.formElement}>
            <View style={[
                styles.pickerContainer,
                !editable && styles.pickerContainerDisabled
            ]}>
                <Picker
                    selectedValue={clientId}
                    onValueChange={(itemValue: string) => setClientId(itemValue)}
                    enabled={editable}
                    testID={testID}
                    style={styles.picker}
                >
                    <Picker.Item
                        label={placeholder}
                        value=""
                    />
                    {clients.map((client) => (
                        <Picker.Item
                            key={client.id}
                            label={client.label}
                            value={client.id}
                        />
                    ))}
                </Picker>
            </View>
        </View>
    );
};

const styles = EStyleSheet.create({
    formElement: {
        width: IS_WEB ? 424 : 300,
        height: 56,
        marginTop: 25,
    },
    pickerContainer: {
        backgroundColor: '#fff',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    pickerContainerDisabled: {
        backgroundColor: '#EEF4FF',
    },
    picker: {
        height: 56,
        width: IS_WEB ? 424 : 300,
        borderColor: '#ccccd6',
        borderRadius: 14,
    }
});

export default ClientsSelector;