import React, { FC, useState } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { useTranslation } from 'react-i18next'
import EStyleSheet from 'react-native-extended-stylesheet'
import { useMutation } from '@apollo/react-hooks'

import Separator from './Separator'
import COLORS from 'constants/colors'

import {
  LOGIN_WITH_WORKOS_MAGIC_LINK,
  RESET_PASSWORD_INSTRUCTIONS,
} from 'graphql/mutations'
import { IS_WEB } from 'constants/static'

type ResetPasswordFormProps = {
  email: string,
  clientId?: string
}

const ForgotPasswordActions: FC<ResetPasswordFormProps> = ({ email, clientId }) => {
  const [emailSent, setEmailSent] = useState(false)
  const [actionError, setActionError] = useState('')

  const { t } = useTranslation()

  const [resetPasswordInstructions] = useMutation(RESET_PASSWORD_INSTRUCTIONS, {
    onError: (err) => {
      if (err.graphQLErrors[0]?.message) {
        setActionError(err.graphQLErrors[0].message)
      }
    },
    onCompleted: (data) => {
      setEmailSent(true)
    },
  })

  const [sendWorkOSMagicLink] = useMutation(LOGIN_WITH_WORKOS_MAGIC_LINK, {
    onError: (err) => {
      if (err.graphQLErrors[0]?.message) {
        setActionError(err.graphQLErrors[0].message)
      }
    },
    onCompleted: (data) => {
      if (data?.workosMagicLink?.email === email) {
        setEmailSent(true)
      }
    },
  })

  const handleResetPasswordPress = () => {
    resetPasswordInstructions({
      variables: {
        attributes: {
          email,
          clientId
        },
      },
    })
  }

  const handleMagicLinkPress = () => {
    const variables = {
      attributes: {
        email,
      },
    }
    sendWorkOSMagicLink({ variables })
  }

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={handleResetPasswordPress}
        activeOpacity={0.8}
        style={[styles.button, !email ? styles.disabledButton : {}]}
        disabled={!email}
      >
        <Text style={styles.buttonText}>Send Reset Password Email</Text>
      </TouchableOpacity>
      {IS_WEB && <>
        <Separator />
        <TouchableOpacity
          onPress={handleMagicLinkPress}
          activeOpacity={0.8}
          style={[styles.buttonOutlined, !email ? styles.disabledButton : {}]}
          disabled={!email}
        >
          <Text style={styles.buttonOutlinedText}>Send Magic Link</Text>
        </TouchableOpacity>
      </>}

      {emailSent && (
        <View style={styles.messageWrapper}>
          <Text style={{ fontFamily: 'Poppins_400Regular' }}>
            {t('checkSignInLink')}
          </Text>
        </View>
      )}
      {!!actionError && (
        <View style={styles.messageWrapper}>
          <Text style={styles.errorMessage}>{actionError}</Text>
        </View>
      )}
    </View>
  )
}

const styles = EStyleSheet.create({
  container: {
    width: '100%',
  },
  button: {
    marginTop: 22,
    width: '100%',
    height: 56,
    backgroundColor: COLORS.TURQUOISE,
    padding: 10,
    borderRadius: 12,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonText: {
    textAlign: 'center',
    color: 'white',
    fontWeight: '600',
    fontFamily: 'Poppins_400Regular',
  },
  buttonOutlined: {
    marginTop: 22,
    width: '100%',
    height: 56,
    backgroundColor: 'white',
    padding: 0,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: '#DBDBDB',
  },
  buttonOutlinedText: {
    textAlign: 'center',
    lineHeight: 42,
    color: COLORS.GRAYDARK1,
    fontFamily: 'Poppins_400Regular',
  },
  messageWrapper: {
    marginTop: 15,
  },
  errorMessage: {
    fontFamily: 'Poppins_400Regular',
    color: COLORS.REDDARK,
  },
  disabledButton: {
    opacity: 0.6,
  },
})

export default ForgotPasswordActions
