import React from 'react'
import { View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { Icon } from 'components/Index'
import completeButtonStyles from './constants/completeButtonStyles'
import COLORS from 'constants/colors'
import { ICON_NAMES } from 'constants/icons'

const ICON_SIZE = 42

const TaskVideoToComplete = (props: CompleteElementProps) => {
  const { completedEvent, completed } = props

  return (
    <View style={styles.container}>
      <Icon
        name={ICON_NAMES.VIDEO}
        size={ICON_SIZE}
        style={{ color: COLORS.TURQUOISE, opacity: completedEvent || completed ? 1 : 0.2 }}
      />
    </View>
  )
}

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    ...completeButtonStyles.iconContainer,
  },
})

export default TaskVideoToComplete
