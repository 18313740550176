import React from 'react'
import styled from 'styled-components'

import COLORS from 'constants/colors'
import { Avatar } from '@mui/material'

const LaunchIcon = ({ onClick, imgSrc, title, compact = true }) => {
  return <Container onClick={onClick} compact={compact} >
    <Avatar
      src={imgSrc}
      variant="rounded"
      sx={{
        background: 'white',
        width: '64px',
        height: '64px',
        borderRadius: '16px',
        border: '1px solid rgba(204, 204, 204, 1)',
        boxShadow: '0px 2px 24px 0px rgba(0, 0, 0, 0.06)',
      }}
    />
    <Title>{title}</Title>
  </Container>
}

const Container = styled.div<{ compact: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  width: 100px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin: ${({compact}) => compact ? '0px' : '0px 25px 0px 25px'};
`

const Title = styled.div`
  font-size: 14px;
  color: ${COLORS.GRAYDARK3};
  margin-top: 4px;
  text-align: center;
  height: 42px;
  width: 90px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export default LaunchIcon