import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Text, View, TouchableOpacity } from 'react-native'
import { useApolloClient } from '@apollo/react-hooks';
import EStyleSheet from 'react-native-extended-stylesheet'
import { CREATE_DOCUSIGN_ENVELOPE } from 'graphql/mutations'
import COLORS from 'constants/colors'
import { STYLE_CONSTANTS } from 'constants/es-style'
import { checkIsLocalFile } from 'utils/phone'
import completeButtonStyles from './constants/completeButtonStyles'
import useCompleteElementStates from './hooks/useCompleteElementStates'
import DocuSignButton from 'components/task-list/buttons/DocuSignButton'
import useNetworkStatus from 'components/common/hooks/useNetworkStatus'
import {
  DocuSignIcon,
  NopeIcon,
  NotApplicableIcon,
} from 'components/common/custom-icons'

// photo icon to take picture for task completition
const TaskDocuSignToComplete = (props: CompleteElementProps) => {
  const { isConnected: online } = useNetworkStatus()
  const client = useApolloClient()
  const {
    sessionUuid,
    id: taskId,
    completedEvent,
    completed
  } = props

  const {
    isNotApplicableCompletedEvent,
    isCantComplete,
    completeState,
    taskDone,
  } = useCompleteElementStates({
    completedEvent,
  })

  const [showCompleteButton, setShowCompleteButton] = useState<boolean | null>(
    completeState,
  )
  const [docusignUrlLoading, setDocusignUrlLoading] = useState<boolean | null>(
    false,
  )
  const [docusignRequestFailed, setDocusignRequestFailed] = useState<
    boolean | null
  >(false)

  const imageUrl = props?.completedEvent?.imageUrl
  const isLocalImage = checkIsLocalFile(imageUrl)

  const [getDocusignEnvelopeUrl] = useMutation(CREATE_DOCUSIGN_ENVELOPE, {
    variables: {
      attributes: {
        taskId,
        returnEndpoint: 'LOCATIONS?signedTaskId=' + taskId,
        sessionUuid,
      },
    },
    onCompleted: (response) => {
      setDocusignUrlLoading(false)
      if (response.createDocusignEnvelope.url) {
        window.open(response.createDocusignEnvelope.url, '_blank')
        setShowCompleteButton(true)
      } else {
        setDocusignRequestFailed(true)
      }
    },
    onError: (err) => {
      setDocusignUrlLoading(false)
      setDocusignRequestFailed(true)
      setShowCompleteButton(false)
      console.error(err)
    },
  })

  const onButtonClick = () => {
    client.writeData({
      data: {
        appData: {
          taskDescriptionModalId: taskId,
          __typename: 'AppData',
        },
      },
    })
  }

  const openDocusignSign = async () => {
    if (docusignUrlLoading) {
      return
    }

    setDocusignUrlLoading(true)
    setDocusignRequestFailed(false)
    await getDocusignEnvelopeUrl({})
  }

  const renderIcon = () => {
    const ICON_SIZE = 36

    if (!(completeState || completed)) {
      return (
        <DocuSignIcon height={ICON_SIZE} width={ICON_SIZE} opacity={0.16} />
      )
    } else if (isCantComplete) {
      return <NopeIcon height={ICON_SIZE} width={ICON_SIZE} />
    } else if (isNotApplicableCompletedEvent) {
      return <NotApplicableIcon height={ICON_SIZE} width={ICON_SIZE} />
    }

    return <DocuSignIcon height={ICON_SIZE} width={ICON_SIZE} />
  }

  const renderSignButton = () => {
    if (!props.showAsButton) {
      return (
        <View style={styles.container}>
          {!!imageUrl?.length && (
            <View style={styles.imageModalThumbnailWrapper}>
              {(online || isLocalImage) && (
                <ImageModal
                  swipeToDismiss={false}
                  resizeMode="contain"
                  imageBackgroundColor={COLORS.TRANSPARENT}
                  style={styles.imageModalThumbnail}
                  source={{ uri: imageUrl }}
                />
              )}
              <ImageVisibleWhenOnline {...{ online, isLocalImage }} />
            </View>
          )}
          <TouchableOpacity
            onPress={props.isFromUpNext ? openDocusignSign : onButtonClick}
            style={styles.iconContainer}
          >
            {renderIcon()}
          </TouchableOpacity>
        </View>
      )
    } else if (showCompleteButton) {
      return <DocuSignButton loading={docusignUrlLoading} selected={taskDone} onPress={openDocusignSign} />

    } else {
      return <DocuSignButton loading={docusignUrlLoading} onPress={openDocusignSign} />
    }
  }

  return <>
    {renderSignButton()}
    {docusignRequestFailed && <Text style={{ marginTop: '10px', color: 'red' }} >An error occurred, please try again later.</Text>}
  </>
}

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    ...completeButtonStyles.iconContainer,
  },
  imageModalThumbnailWrapper: {
    ...STYLE_CONSTANTS.FLEX_CENTER_HORIZ,
  },
  offlineImageTextWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  offlineImageText: {
    fontSize: 8,
  },
  imageModalThumbnail: {
    width: 30,
    height: 40,
  },
  image: {
    width: 30,
    height: 40,
    marginRight: 5,
  },
})

export default TaskDocuSignToComplete
