import React, { useState, useMemo } from 'react'
import { View, TouchableOpacity } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import * as DocumentPicker from 'expo-document-picker'
import * as ImagePicker from 'expo-image-picker'

import UploadMediaButton from 'components/task-list/buttons/UploadMediaButton'
import useCompleteElementStates from './hooks/useCompleteElementStates'
import useCreateEvent from './hooks/useCreateEvent'
import completeButtonStyles from './constants/completeButtonStyles'
import { EVENT_TYPES } from 'constants/types'
import { IS_WEB } from 'constants/static'
import { createImageFile } from './helpers/image-helpers'
import UploadIcon from './components/UploadIcon'

const TaskUploadMediaToComplete = (props: CompleteElementProps) => {
  const { completedEvent, id, locationHierarchyId, completed } = props

  const {
    isNotApplicableCompletedEvent,
    isCantComplete,
    completeState,
    taskDone,
  } = useCompleteElementStates({
    completedEvent,
  })

  const [isUploading, setIsUploading] = useState(false)

  const createEvent = useCreateEvent({
    taskId: id,
    locationHierarchyId,
  })

  const pickImage = async () => {
    const imageResult = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      aspect: [4, 3],
      quality: 1,
    })

    if (imageResult.canceled == false) {
      setIsUploading(true)
      const reactNativeImage = await createImageFile(imageResult.assets[0])

      await createEvent({
        eventType: EVENT_TYPES.UPLOAD_MEDIA,
        createEventData: {
          media: reactNativeImage,
        },
      })

      setIsUploading(false)
    }
  }

  const pickDocumentWeb = async () => {
    if (!IS_WEB) {
      return
    }
    let documents = await DocumentPicker.getDocumentAsync({
      copyToCacheDirectory: false,
      multiple: true,
      type: [
        'image/*',
        'video/*',
        'application/pdf',
        'text/csv',
        'text/plain',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.oasis.opendocument.presentation',
        'application/vnd.oasis.opendocument.spreadsheet',
        'application/vnd.oasis.opendocument.text',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ],
    })

    if (documents?.output && documents.canceled == false) {
      setIsUploading(true)

      await createEvent({
        eventType: EVENT_TYPES.UPLOAD_MEDIA,
        createEventData: {
          media: documents.output,
        },
      })

      if (props.onComplete) {
        props.onComplete()
      }

      setIsUploading(false)
    }
  }

  const handleUploadDocument = useMemo(() => {
    return IS_WEB ? pickDocumentWeb : pickImage
  }, [IS_WEB])

  return !props.showAsButton ? (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={handleUploadDocument}
        style={styles.iconContainer}
      >
        <UploadIcon
          isComplete={(completeState || completed)}
          isCantComplete={isCantComplete}
          isNotApplicable={isNotApplicableCompletedEvent}
        />
      </TouchableOpacity>
    </View>
  ) : (
    <UploadMediaButton
      selected={taskDone}
      onPress={handleUploadDocument}
      loading={isUploading}
    />
  )
}

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    ...completeButtonStyles.iconContainer,
  },
})

export default TaskUploadMediaToComplete
