import React from 'react'
import styled from 'styled-components'

import COLORS from 'constants/colors'

const ReporteeMarkerCard = ({ completedTasksCount, pastDueTasksCount, tasksCount, progress }:
  { completedTasksCount: number, pastDueTasksCount: number, tasksCount: number, progress: number }) => {
  return <GroupMarkerContainer>
    <Column>
      <MarkerRow>
        <MarkerCount>{completedTasksCount}/{tasksCount}</MarkerCount>
        <MarkerProgress>{progress}%</MarkerProgress>
      </MarkerRow>
      <MarkerRow>
        <PastDueText> {pastDueTasksCount > 0 && `${pastDueTasksCount} Tasks Past Due`}</PastDueText>
      </MarkerRow>
    </Column>

  </GroupMarkerContainer>
}

const PastDueText = styled.div`
  color: #EF492D;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const MarkerCount = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 12px;
`

const MarkerProgress = styled.div`
  font-weight: 600;
`

const MarkerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: ${COLORS.NAVY_BLUE};
  font-size: 16px;
  font-weight: 300;
`

const GroupMarkerContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 15px 5px 15px;
  font-family: Poppins_400Regular;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  color: ${COLORS.NAVY_BLUE};
`

export default ReporteeMarkerCard