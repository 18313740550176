{
  "monthDayYearDateFormat": "LLLL d, y",
  "monthDayDateFormat": "LLLL d",
  "welcome": "ようこそ！\n{{name}}さん",
  "clickOnTasksSection": "タスクセクションをクリックして開始する",
  "search": "検索",
  "typeHereToAddText": "こちらに入力してください…",
  "complete": "完了",
  "completed": "完了済み",
  "cantComplete": "完了できません",
  "cantCompleteTask": "タスクを完了できません",
  "notes": "タスクメモ",
  "back": "戻る",
  "sessionNotes": "セッションメモ",
  "confirm": "確認",
  "notApplicable": "適用外",
  "logout": "ログアウト",
  "teamMetrics": "チームメトリクス",
  "taskNotesDescription": "このスペースにタスク関連のメモを追加してください",
  "comment": "コメント",
  "pleaseSelect": "選択してください…",
  "eSignature": "電子署名",
  "takePicture": "写真を作成する",
  "accept": "同意する",
  "home": "ホーム",
  "tasksFor": "～のタスク",
  "due": "締め切り",
  "dueToday": "今日が締切日です",
  "tasks": "タスク",
  "task": "タスク",
  "tasksToDo": "{{tasks}} 実施するタスク",
  "taskToDo": "{{tasks}} 実施するタスク",
  "infoTasksToDo": "{{numberInfoTasks}} 情報のみのタスク",
  "infoTaskToDo": "{{numberInfoTasks}} 情報のみのタスク",
  "infoTasks": "{{numberInfoTasks}} 情報タスク",
  "infoTask": "{{numberInfoTasks}} 情報タスク",
  "by": "実施者",
  "markedCantComplete": "完了できないとしてマークする",
  "markedNotApplicable": "適用されないとしてマークする",
  "markedRead": "読む",
  "tasksComplete": "タスクが完了",
  "allTasksComplete": "全て {{tasks}} タスクが完了",
  "someTasksComplete": "{{numberOfCompletedTasks}} ～の {{numberOfTasks}} タスクが完了",
  "upNextTitle": "次",
  "upNextSubtitle": "ワークフローから次のアクセスにクイックアクセス",
  "upNextDoneTitle": "これで今のところは終わりです！",
  "upNextDoneSubtitle": "グッドジョブ！今のところ作業はこれだけです！新たにタスクが割り当てられたらメールでお知らせします。",
  "progress": "進捗",
  "ago": "～前",
  "dueIn": "期限まであと",
  "days": "日",
  "resources": "リソース",
  "resourcesSubtitle": "ハイライトされたリソースの厳選されたリストを使用する",
  "yourResources": "あなたのリソース",
  "yourResourcesSubtitle": "すべての役に立つリンクへのクイック アクセス",
  "reports": "レポート",
  "signIn": "サインイン",
  "forgotPassword": "パスワードをお忘れですか？",
  "emailAddress": "メールアドレス",
  "password": "パスワード",
  "newPassword": "新しいパスワード",
  "repeatPassword": "パスワードを繰り返す",
  "passwordError": "パスワードは一致する必要があります",
  "passwordLengthError": "パスワードは8文字以上である必要があります",
  "resetPassword": "新しいパスワードを作成する",
  "resetPasswordInfo": "一時パスワードでのログインに成功しました。\n永続パスワードを作成してください",
  "forgottenPassword": "パスワードをお忘れですか？",
  "forgottenPasswordExplanationWithMagicLink": "以下にメール アドレスを入力して、パスワードのリセット メールを送信するか、すぐにサインインできるワンタイム マジック リンクを送信するかを選択してください。",
  "forgottenPasswordExplanation": "下にメールアドレスを入力して、パスワードリセットのメールを受け取り、アカウントへのアクセスを回復してください。",
  "signInWithGoogle": "Googleでサインインする",
  "checkSignInLink": "メールを送信しました。受信トレイを確認してください",
  "next": "次",
  "login": "ログイン",
  "sendSignInLink": "サインインのリンクが記載されたメールを送信",
  "en-US": "English",
  "en": "English",
  "fr-CA": "Français (Canada)",
  "fr": "Français",
  "es-ES": "Español",
  "es": "Español",
  "pt-BR": "Português",
  "pt": "Português",
  "de-DE": "Deutsch",
  "de": "Deutsch",
  "ja-JP": "日本",
  "ja": "日本",
  "zh-CN": "中文(简体)",
  "zh-TW": "中文(繁体)",
  "timeToComplete": "完了する時間",
  "min": "min",
  "high": "高い",
  "poweredBy": "によって提供された",
  "uploading": "アップロード中...",
  "uploadMedia": "メディアのアップロード",
  "admin": "Admin",
  "showWorkflows": "完了を表示",
  "showCompletedWorkflows": "完了したワークフローを表示",
  "infoOnly": "情報のみ。完了するために必要なアクションはありません",
  "delegate": "代理人",
  "getOurApp": "アプリを取得",
  "continueToApp": "アプリに進む",
  "promptMessage": "保存されていない変更があります。終了してもよろしいですか",
  "onboardingBuddy": "入社バディ",
  "delegateAssignment": "代理人の割り当て",
  "onboardingBuddyAssignment": "入社バディの割り当て",
  "assignments": "割り当て",
  "delegateMainCopy": "担当新入社員全員のリストと、採用マネジャーの入社タスクを完了するために割り当てられた代理人（選択されている場合）については、以下を参照してください。",
  "delegateEmailDescription": "以下のスペースを使用して、タスク委任に関するメッセージを送信できます。",
  "newHire": "新入社員",
  "assignedDelegate": "割り当てられた代理人",
  "assignedOnboardingBuddy": "割り当てられる入社バディ",
  "activeAssignments": "アクティブな割り当て",
  "actions": "アクション",
  "add": "追加",
  "remove": "削除",
  "taskDelegation": "タスクの委任",
  "delegateConfirmationCopy": "<p>採用マネジャーの入社タスクは、選択した個人に委任されるようになります。</p><p>パフォーマンス管理タスクは委任の対象外であることにご注意ください。</p>",
  "okay": "Ok",
  "activeAssignmentsCount": "このユーザーには現在、{{count}}件のアクティブな割り当てがあります。",
  "assign": "割り当て",
  "name": "名前",
  "assignDelegate": "代理人の割り当て",
  "assignOnboardingBuddy": "入社バディの割り当て",
  "onboardingBuddyModalMainCopy": "担当新入社員全員のリストと、割り当てられる入社バディ（選択されている場合）については、以下を参照してください。",
  "removeDelegateUser": "本当に{{userName}}の権限を外しますか？",
  "removeOnboardingUser": "本当に{{userName}}を新規採用者受け入れ担当から外しますか？",
  "refreshPage": "ページを更新",
  "newAppVersionTitle": "アプリケーションの新しいバージョン",
  "newAppVersionContent": "アプリケーションの新しいバージョンがあります。アプリケーションがスムーズに動作するように、ページを更新してください。",
  "commentAdded": "コメントが追加されました:",
  "optionSelected": "選択したオプション:",
  "mediaUploaded": "アップロードされたメディア:",
  "dependedOn": "依存",
  "loading": "読み込み中...",
  "fetchingResults": "結果を取得しています。お待ちください...",
  "workflows": "ワークフロー",
  "sections": "セクション",
  "seeAllMatching": "すべての {{length}} の一致を表示",
  "matches": "{{length}} 一致",
  "enterSearchTerm": "検索語を入力してください",
  "oops": "おっと、何か問題が発生しました。",
  "upNextError": "次のタスクを読み込めませんでした。後でもう一度お試しください。",
  "myTasks": "私のタスク",
  "pastDue": "期限切れ",
  "tomorrow": "明日",
  "dueYesterday": "昨日の締切",
  "dueTomorrow": "明日の締切",
  "aboutTheOrg": "会社について",
  "seeAll": "すべて表示",
  "myRole": "私の役割",
  "manager": "マネージャー",
  "buddy": "バディ",
  "myTeam": "私のチーム",
  "seeAllTasks": "すべてのタスクを表示",
  "viewWelcomeMessage": "ウェルカム メッセージを表示",
  "calendar": "カレンダー",
  "dueInSixMonthsPlus": "6ヶ月以上後に期限が切れます"
}