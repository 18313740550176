{
  "monthDayYearDateFormat": "LLLL d, y",
  "monthDayDateFormat": "LLLL d",
  "welcome": "Welcome,\n{{name}}!",
  "clickOnTasksSection": "Click on the task section to get started",
  "search": "Search",
  "typeHereToAddText": "Type here...",
  "complete": "Complete",
  "completed": "Completed",
  "cantComplete": "Can't Complete",
  "cantCompleteTask": "Can't Complete task",
  "notes": "Task Notes",
  "back": "Back",
  "sessionNotes": "Session Notes",
  "confirm": "Confirm",
  "notApplicable": "Not Applicable",
  "logout": "Logout",
  "teamMetrics": "Team Metrics",
  "taskNotesDescription": "Use this space to add notes related to this task",
  "comment": "Comment",
  "pleaseSelect": "Please Select...",
  "eSignature": "E-Signature",
  "takePicture": "Take Picture",
  "accept": "Accept",
  "home": "Home",
  "tasksFor": "Tasks for",
  "due": "Due",
  "dueToday": "Due Today",
  "tasks": "Tasks",
  "task": "Task",
  "tasksToDo": "{{tasks}} Tasks to Do",
  "taskToDo": "{{tasks}} Task to Do",
  "infoTasksToDo": "{{numberInfoTasks}} Info Only Tasks",
  "infoTaskToDo": "{{numberInfoTasks}} Info Only Task",
  "infoTasks": "{{numberInfoTasks}} Info Tasks",
  "infoTask": "{{numberInfoTasks}} Info Task",
  "by": "by",
  "markedCantComplete": "Marked Can't Complete",
  "markedNotApplicable": "Marked Not Applicable",
  "markedRead": "Read",
  "allTasksComplete": "All {{tasks}} Tasks Complete",
  "someTasksComplete": "{{numberOfCompletedTasks}} of {{numberOfTasks}} Tasks Complete",
  "upNextTitle": "Up Next",
  "upNextSubtitle": "Quickly access your upcoming tasks from your workflows",
  "upNextDoneTitle": "You're to-done for now!",
  "upNextDoneSubtitle": "Nice job superstar! You don't have anything left to do! If you're assigned new tasks, you'll get an email!",
  "progress": "Progress",
  "ago": "Ago",
  "dueIn": "Due in",
  "days": "days",
  "resources": "Resources",
  "resourcesSubtitle": "Use this curated list of highlighted resources",
  "yourResources": "Your resources",
  "yourResourcesSubtitle": "Quick access to all of your helpful links",
  "reports": "Reports",
  "signIn": "Sign In",
  "forgotPassword": "Forgot password?",
  "emailAddress": "Email Address",
  "password": "Password",
  "newPassword": "New Password",
  "repeatPassword": "Repeat password",
  "passwordError": "Passwords must match",
  "passwordLengthError": "Password must be at least 8 characters long",
  "resetPassword": "Create New Password",
  "resetPasswordInfo": "Login with temporary password was successful. \nPlease create a permanent password",
  "forgottenPassword": "Forgotten your password?",
  "forgottenPasswordExplanationWithMagicLink": "Enter your email address below and choose between sending a reset password email or a one-time magic link that will sign you in instantly!",
  "forgottenPasswordExplanation": "Enter your email address below to receive a password reset email to regain access to your account.",
  "signInWithGoogle": "Sign In with Google",
  "checkSignInLink": "Email sent, please check your inbox.",
  "next": "Next",
  "login": "Login",
  "sendSignInLink": "Send Sign In link",
  "en-US": "English",
  "en": "English",
  "fr-CA": "Français (Canada)",
  "fr": "Français",
  "es-ES": "Español",
  "es": "Español",
  "pt-BR": "Português",
  "pt": "Português",
  "de-DE": "Deutsch",
  "de": "Deutsch",
  "ja-JP": "日本",
  "ja": "日本",
  "zh-CN": "中文(简体)",
  "zh-TW": "中文(繁体)",
  "timeToComplete": "Time to complete",
  "min": "min",
  "high": "High",
  "poweredBy": "powered by",
  "uploading": "Uploading...",
  "uploadMedia": "Upload Media",
  "admin": "Admin",
  "showWorkflows": "Show Completed",
  "showCompletedWorkflows": "Show completed workflows",
  "infoOnly": "Information only, no action needed to complete",
  "delegate": "Delegate",
  "getOurApp": "Get our App",
  "continueToApp": "Continue to App",
  "promptMessage": "You have unsaved changes, are you sure you want to leave?",
  "onboardingBuddy": "Onboarding Buddy",
  "delegateAssignment": "Delegate Assignment",
  "onboardingBuddyAssignment": "Onboarding Buddy Assignment",
  "assignments": "Assignments",
  "delegateMainCopy": "See below for a list of all your new joiners and the delegate assigned to complete the Hiring Manager onboarding tasks, if selected.",
  "defaultMainCopy": "See below a list of all your new joiners and the selected role assignees.",
  "delegateEmailDescription": "You may use the space below to send a message to the {{roleName}}",
  "newHire": "New Hire",
  "assignedDelegate": "Assigned Delegate",
  "assignedOnboardingBuddy": "Assigned Onboarding Buddy",
  "activeAssignments": "Active Assignments",
  "actions": "Actions",
  "add": "Add",
  "remove": "Remove",
  "taskDelegation": "Task Delegation",
  "delegateConfirmationCopy": "<p>The hiring manager onboarding tasks will now be delegated to the selected individual.</p><p>Please note that performance management tasks are not eligible for delegation.</p>",
  "okay": "Okay",
  "activeAssignmentsCount": "This user currently has {{count}} active assignments.",
  "assign": "Assign",
  "name": "Name",
  "assignDelegate": "Assign Delegate",
  "assignOnboardingBuddy": "Assign Onboarding Buddy",
  "onboardingBuddyModalMainCopy": "See below for a list of all your new joiners and their assigned onboarding buddy, if selected.",
  "removeDelegateUser": "Are you sure you want to delete Delegate for {{userName}}",
  "removeRoleser": "Are you sure you want to delete {{roleName}} for {{userName}}",
  "removeOnboardingUser": "Are you sure you want to delete Onboarding Buddy for {{userName}}",
  "refreshPage": "Refresh page",
  "newAppVersionTitle": "New version of Application",
  "newAppVersionContent": "There is a new version of the Application. To ensure the Application works smoothly, please refresh the page.",
  "commentAdded": "Comment added:",
  "optionSelected": "Option selected:",
  "mediaUploaded": "Media uploaded:",
  "dependedOn": "Depended on",
  "loading": "Loading...",
  "fetchingResults": "We are fetching your results, please wait...",
  "workflows": "Workflows",
  "sections": "Sections",
  "seeAllMatching": "See all {{length}} matching",
  "matches": "{{length}} matches",
  "enterSearchTerm": "Enter search term...",
  "oops": "Oops, something went wrong.",
  "upNextError": "We couldn't load your next tasks. Please try again later.",
  "myTasks": "My Tasks",
  "pastDue": "Past Due",
  "tomorrow": "Tomorrow",
  "dueYesterday": "Due Yesterday",
  "dueTomorrow": "Due Tomorrow",
  "aboutTheOrg": "About the Business",
  "seeAll": "See All",
  "selectLanguage": "Select language:",
  "welcomeToJll": "Welcome to JLL!",
  "welcomeStep1Title": "Welcome to MyOnboarding!",
  "welcomeStep1Description": "To begin your onboarding journey, please select your preferred language and click <strong>next</strong>. You can always change your language in the top menu located on each page.",
  "welcomeStep2Description": "On the <strong>My Tasks</strong> page, you will see the Workflows assigned to you on the left.<br /><br />On the right you will see a list of Tasks that are past due or up next.<br /><br />To help you out, the tab color on the left of the Task matches the tab color of the Workflow.<br /><br />You can click a workflow to see the Sections in that workflow. Sections are a way to divide a large Workflow into smaller chunks.<br /><br />Clicking a Task will take you to the Task Detail page where you can learn how to complete that task.",
  "welcomeStep3Description": "Explore the <strong>Resources</strong> page for a helpful list of JLL's frequent websites and applications to get connected quickly. <br /><br />In the <strong>About the Org</strong> section, learn about our unique business lines and how we help illuminate a brighter path forward for our clients, communities and planet.",
  "welcomeStep4Description": "Use the <strong>Search</strong> feature on the main menu to find current or past Tasks.",
  "welcomeStep5Description": "Designate an Onboarding Buddy for an employee and assign the Onboarding Buddy tasks.",
  "welcomeStep6Description": "Delegate your manager tasks to someone else.",
  "welcomeWizardWelcomeVideoDescription": "We're thrilled you're here. <br /><br />At JLL, we empower you to shape a brighter way. Your onboarding journey helps you access the endless opportunities, knowledge and tools to find a place where you belong and build a meaningful career here.",
  "welcomeCheckmarkUnselected": "Click the checkmark to complete your first task",
  "welcomeCheckmarkSelected": "Congratulations! You've completed your first task!",
  "myRole": "My Role",
  "manager": "Manager",
  "buddy": "Buddy",
  "myTeam": "My Team",
  "seeAllTasks": "See All Tasks",
  "viewWelcomeMessage": "View Welcome Message",
  "calendar": "Calendar",
  "dueInSixMonthsPlus": "Due in 6+ months"
}
