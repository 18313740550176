import React, { useContext, useMemo } from 'react'
import styled from 'styled-components/native'

import COLORS from 'constants/colors'
import Question from './question/Question'
import { QuestionsContext } from './Questions'
import PartialScore from './PartialScore'

const NewQuestions = () => {
  const { questions, selectedQuestions, setSelectedQuestions, errors, survey, scorecard, snippetsMapping } = useContext(QuestionsContext)

  const filledQuestions = useMemo(() => {
    const filledQuestions: any = {}
    for (const key in selectedQuestions) {
      if (selectedQuestions[key].optionsIds?.size != 0) {
        filledQuestions[key] = selectedQuestions[key]
      }
    }

    return filledQuestions
  }, [selectedQuestions])


  return <>
    {!survey && <Title>Get Started</Title>}
    <Subtitle>*Required</Subtitle>
    {scorecard && <PartialScore questions={questions} filledQuestions={filledQuestions} />}
    {questions.map((question, index) => <Question
      key={question.id}
      index={index + 1}
      total={questions.length}
      question={question}
      onOptionSelected={setSelectedQuestions}
      selectedQuestion={selectedQuestions?.[question.id]}
      hasError={errors.some((e) => e == question?.id)}
      snippetsMapping={snippetsMapping}
    />)}
  </>
}

const Title = styled.Text`
  font-size: 21px;
  font-weight: 700;
  line-height: 32px;
  color: ${COLORS.NAVY_BLUE}
`

const Subtitle = styled.Text`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${COLORS.RED}
`

export default NewQuestions