import React from 'react'
import styled from 'styled-components/native'
import { Icon } from 'components/Index'
import { ICON_NAMES } from 'constants/icons'

const QuestionRequiredError = () =>
  <Required>
    <Icon
      name={ICON_NAMES.ERROR_OUTLINE}
      size={18}
      style={{ marginRight: 6 }}
    />
    This is a required question
  </Required>

const Required = styled.Text`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #E30613;
`

export default QuestionRequiredError