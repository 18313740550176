import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-native'
import COLORS from 'constants/colors'
import { Ionicons } from '@expo/vector-icons'
import { useTranslation } from 'react-i18next'
import useCurrentLocation from 'hooks/useCurrentLocation'
import useUser from 'hooks/useUser'

const Breadcrumbs = ({
  showLocationName = true,
}: {
  showLocationName?: boolean
}) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { user } = useUser()
  const { currentLocation, parentLocation, locationOwner } = useCurrentLocation()
  const owner = locationOwner?.id != user?.id ? locationOwner : null

  const renderBreadcrumb = () => {
    if (!currentLocation) return null

    if (parentLocation.title == '__root__') {
      return <span>{currentLocation.title}</span>
    } else {
      return <span>
        <div className="link-text" onClick={() => history.goBack()}>
          {parentLocation.title}
        </div>
        <span>{' > '}</span>
        <span>{currentLocation.title}</span>
      </span>
    }
  }

  if (!currentLocation) return null

  return (
    <StyledBreadcrumbs showLocationName={showLocationName}>
      {showLocationName && owner && (
        <div className="tasks">
          {t('tasksFor')}{' '}
          <span className="employee-name">{owner.fullName}</span>
        </div>
      )}
      {showLocationName ? (
        <div className="bread-crumbs">
          {renderBreadcrumb()}
        </div>
      ) : (
        <Ionicons
          name="arrow-back"
          size={24}
          color="black"
          onPress={() => history.goBack()}
        />
      )}
    </StyledBreadcrumbs>
  )
}

const StyledBreadcrumbs = styled.div<{
  showLocationName: boolean
}>`
  margin-left: ${(props) => (props.showLocationName ? '24px' : '0px')};
  margin-right: ${(props) => (props.showLocationName ? '24px' : '0px')};
  margin-top: 24px;
  margin-bottom: 16px;
  font-size: 18px;
  color: ${COLORS.NAVY_BLUE};

  .home {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
  .link-text {
    text-decoration: underline;
    color: ${COLORS.DARK_TURQUOISE};
    cursor: pointer;
  }

  .employee-name {
    font-weight: 600;
  }

  .bread-crumbs {
    overflow: hidden;
  }
`

export default Breadcrumbs
