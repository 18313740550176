import React, { useEffect } from 'react'
import { useHistory } from 'react-router-native'

import useClientConfig from 'hooks/useClientConfig'
import HomeScreen from './HomeScreen'
import { ROUTE_NAMES } from 'navigation/constants'
import PageFrame from 'components/common/PageFrame'
import { GeneralActivityIndicator } from 'components/Index'

const HomeScreenContainer = () => {
  const history = useHistory()
  const { loading, enableNewHome } = useClientConfig()

  useEffect(() => {
    if (!loading && !enableNewHome) {
      history.push({
        pathname: ROUTE_NAMES.LOCATIONS,
      })
    }
  }, [enableNewHome, loading])

  if (enableNewHome) {
    return <HomeScreen />
  }

  return <PageFrame>
    <GeneralActivityIndicator size="large" />
  </PageFrame>
}

export default HomeScreenContainer