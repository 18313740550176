import React, { Fragment, useEffect, useState, useRef } from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { ApolloClient } from 'apollo-client'
import AppLoading from 'expo-app-loading'
import * as SplashScreen from 'expo-splash-screen'
import EStyleSheet from 'react-native-extended-stylesheet'
import 'react-native-gesture-handler'
import * as Sentry from 'sentry-expo'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useQuery } from '@apollo/react-hooks'
import { parse } from 'query-string'

import ErrorBoundary from 'components/common/ErrorBoundary'
import DeprecatedInfo from 'components/common/DeprecatedInfo'
import NavigatorScreens from 'components/navigator/NavigatorScreens'
import { GLOBAL_STYLE } from 'constants/es-style'
import { IS_ANDROID, IS_WEB } from 'constants/static'
import { ENVIRONMENT, SENTRY_DSN } from 'constants/env'
import { FETCH_POLICIES } from 'constants/api'
import { CACHE_KEYS } from 'constants/types'
import { GET_CLIENT_CONFIG, GET_CURRENT_USER } from 'graphql/queries'
import { HeaderContext } from 'utils/header-context'
import {
  useFonts,
  Poppins_100Thin,
  Poppins_300Light,
  Poppins_400Regular,
  Poppins_500Medium,
  Poppins_600SemiBold,
  Poppins_700Bold,
} from '@expo-google-fonts/poppins'
import {
  SourceSansPro_300Light,
  SourceSansPro_400Regular,
  SourceSansPro_600SemiBold,
} from '@expo-google-fonts/source-sans-pro'
import client from 'utils/init'
import { captureException } from 'utils/sentry'
import * as serviceWorkerRegistration from 'serviceWorkerRegistration'
import i18n from 'i18n/setup'
import AppVersionModal from 'components/app-version/AppVersionModal'
import ThemeProvider from 'theme/ThemeProvider'
import createCustomMuiTheme from 'theme/mui/createCustomMuiTheme'
import {
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import useExpoUpdates from './src/hooks/useExpoUpdates';
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey(
  '041767b467da12cc1ecfa850e185cfb9Tz04NTk2NCxFPTE3NDE0MzMwNzcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
)

const muiTheme = createCustomMuiTheme()
// create navigator for root pages
EStyleSheet.build(GLOBAL_STYLE)
const platform = IS_WEB ? 'web' : IS_ANDROID ? 'android' : 'ios'
const isDevEnv = ENVIRONMENT === 'development'
Sentry.init({
  dsn: SENTRY_DSN,
  environment: isDevEnv ? platform + '-dev' : platform,
  debug: false,
  enableInExpoDevelopment: false, // Sentry will try to print out useful debugging information if something goes wrong with sending an event. Set this to `false` in production.
})

// initial effect
const getSetLoadingComplete = (
  setLoadingComplete: DispatchType<boolean>,
  setApolloClient: DispatchType<ApolloClient<any>>,
) =>
  async function loadResourcesAndDataAsync() {
    try {
      await SplashScreen.preventAutoHideAsync()
      // init apollo client
      setApolloClient(client)
    } catch (e) {
      captureException(e)
    } finally {
      setLoadingComplete(true)
      await SplashScreen.hideAsync()
    }
  }

// main component
const App = () => {
  const [isLoadingComplete, setLoadingComplete] = useState(false)
  const [apolloClient, setApolloClient] = useState(null)
  const [options, setOptions] = useState({
    title: '',
    headerRight: () => { },
    showBack: false,
    hideHeader: false,
  })
  const [fontsLoaded, fontError] = useFonts({
    Poppins_100Thin,
    Poppins_300Light,
    Poppins_400Regular,
    Poppins_500Medium,
    Poppins_600SemiBold,
    Poppins_700Bold,
    SourceSansPro_300Light,
    SourceSansPro_400Regular,
    SourceSansPro_600SemiBold,
  })
  const { onFetchUpdateAsync } = useExpoUpdates()

  // close window after succesful signature on DocuSign
  useEffect(() => {
    if (!window?.location?.search) {
      return
    }
    const event = parse(window.location.search)['event']
    if (event === 'signing_complete') {
      window.close()
    }
  }, [window?.location?.search])

  const { data: clientConfigData } = useQuery(GET_CLIENT_CONFIG, {
    client: client,
    fetchPolicy: FETCH_POLICIES.CACHE_ONLY,
  })
  const { data: currentUserData } = useQuery<{
    user: User
  }>(GET_CURRENT_USER, {
    client: client,
    fetchPolicy: FETCH_POLICIES.CACHE_ONLY,
  })

  useEffect(() => {
    const showLocale = clientConfigData?.clientConfig?.showLocale
    const preferredLocale = currentUserData?.user?.preferredLocale

    if (IS_WEB && showLocale && preferredLocale) {
      i18n.changeLanguage(preferredLocale)
      AsyncStorage.setItem(CACHE_KEYS.SELECTED_LANGUAGE, preferredLocale)
    }
  }, [currentUserData, clientConfigData])

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function executeAsync() {
      await onFetchUpdateAsync()
    }

    executeAsync()
    getSetLoadingComplete(setLoadingComplete, setApolloClient)()
  }, [])

  if (!isLoadingComplete || (!IS_WEB && !fontsLoaded && !fontError)) {
    return <AppLoading />
  }

  return (
    <Fragment>
      {!IS_WEB && <DeprecatedInfo />}
      {!apolloClient && <AppLoading />}
      {apolloClient && (
        <ApolloProvider client={apolloClient}>
          <HeaderContext.Provider
            value={{ options, setOptions }}
          >
            <ErrorBoundary>
              <ThemeProvider>
                <MuiThemeProvider theme={muiTheme}>
                  <GestureHandlerRootView style={{ flex: 1 }}>
                    <NavigatorScreens />
                    <AppVersionModal />
                  </GestureHandlerRootView>
                </MuiThemeProvider>
              </ThemeProvider>
            </ErrorBoundary>
          </HeaderContext.Provider>
        </ApolloProvider>
      )}
    </Fragment>
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

export default App
