import React from 'react'
import { ListItemButton } from '@mui/material'
import UserHeaderItem from '../common/UserHeaderItem'

const MyTeamSidebarListButton = ({ reportee, selected, onClick }:
  { reportee: User, selected: boolean, onClick: () => void }) => {

  return <ListItemButton
    onClick={onClick}
    selected={selected}
    sx={{
      padding: '16px 36px 16px 36px',
      height: '70px',
    }}
  >
    <UserHeaderItem reportee={reportee} selected={selected} />
  </ListItemButton>
}

export default MyTeamSidebarListButton