import { useLazyQuery } from '@apollo/react-hooks'
import AsyncStorage from '@react-native-async-storage/async-storage'
import GeneralActivityIndicator from 'components/common/GeneralActivityIndicator'
import GetAppModal from 'components/pop-ups/GetAppModal.web'
import { FETCH_POLICIES } from 'constants/api'
import { IS_WEB } from 'constants/static'
import { CACHE_KEYS } from 'constants/types'
import { GET_CURRENT_USER } from 'graphql/queries'
import { ROUTE_NAMES } from 'navigation/constants'
import { default as React, useEffect, useRef, useState } from 'react'
import { Animated, KeyboardAvoidingView, Platform, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { useHistory } from 'react-router-native'
import { percentScreenHeight } from 'utils/animations'
import { useHeaderContext } from 'utils/header-context'
import { useFocusEffect } from 'utils/navigation'
import Sentry, { captureException } from 'utils/sentry'
import LeftSide from '../common/sign-in/LeftSide'
import RightSide from '../common/sign-in/RightSide'
import LoginForm from './form/LoginForm'

// config, next page after login
const nextPageNavigate = ROUTE_NAMES.HOME

// gets authenticate callback
const authenticate =
  (setShowLoginForm: DispatchType<boolean>, getCurrentUser: any, loginFormInvisible: DispatchType<boolean>) => () => {
    async function authenticateAsync() {
      const token = await AsyncStorage.getItem(CACHE_KEYS.TOKEN)
      if (!token) {
        setShowLoginForm(true)
      } else {
        getCurrentUser()
      }
    }
    authenticateAsync()
  }

const SMALLER_MARGIN = percentScreenHeight(20)
const LARGER_MARGIN = percentScreenHeight(35)

// login screen
export default function LoginScreen({ route }: { route: any }) {
  const history = useHistory()
  const [showLoginForm, setShowLoginForm] = useState<boolean>(false)
  const [loginFormInvisible, setLoginFromInvisible] = useState<boolean>(false)
  const [animationFinished, setAnimationFinished] = useState<boolean>(false)
  const [loginError, setLoginError] = useState('')
  const [infoMessage, setInfoMessage] = useState('')

  const fresh = route?.params?.fresh
  const [getCurrentUser, { data: currentUserData }] = useLazyQuery(
    GET_CURRENT_USER,
    {
      onError: () => {
        setShowLoginForm(true)
      },
      fetchPolicy: FETCH_POLICIES.CACHE_AND_NETWORK,
    },
  )
  const { setOptions } = useHeaderContext()

  useFocusEffect(history, () => {
    if (fresh) {
      setShowLoginForm(fresh)
    }
  })

  useEffect(() => {
    if (fresh) {
      setShowLoginForm(fresh)
    }
  }, [route, fresh])

  useEffect(() => {
    if (currentUserData?.user) {
      // Navigate only after user data is loaded
      history.push({ pathname: nextPageNavigate })
    }
  }, [currentUserData])

  useEffect(() => {
    setOptions({ hideHeader: true })
    authenticate(setShowLoginForm, getCurrentUser, setLoginFromInvisible)()

    return () => {
      setOptions({ hideHeader: false })
    }
  }, [])

  const logoMarginTop = useRef(new Animated.Value(SMALLER_MARGIN)).current

  useEffect(() => {
    Animated.timing(logoMarginTop, {
      toValue: showLoginForm ? SMALLER_MARGIN : LARGER_MARGIN,
      duration: 300,
      useNativeDriver: true,
    }).start(() => setAnimationFinished(true))
  }, [showLoginForm])

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === 'ios' ? 'padding' : null}
    >
      {IS_WEB && <GetAppModal />}

      <LeftSide>
        {(!showLoginForm || loginFormInvisible) && <GeneralActivityIndicator size="large" />}
        {(showLoginForm || loginFormInvisible) && animationFinished && (
          <View style={loginFormInvisible ? { height: 0, overflow: 'hidden' } : {}}>
            <LoginForm
              {...{
                setShowLoginForm,
                setLoginFromInvisible,
                nextPageNavigate,
                loginError,
                setLoginError,
                infoMessage,
                setInfoMessage,
              }}
            /></View>
        )}
      </LeftSide>

      {IS_WEB && <RightSide />}
    </KeyboardAvoidingView>
  )
}

const styles = EStyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    marginVertical: 20,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    flexDirection: 'row',
  },
})
