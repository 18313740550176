import React, { useState } from 'react'
import { Button, Modal, Step, StepIconProps, StepLabel, Stepper } from '@mui/material'
import styled from 'styled-components'

import COLORS from 'constants/colors'
import WelcomeWizardStep1 from './steps/WelcomeWizardStep1'
import WelcomeWizardStep2 from './steps/WelcomeWizardStep2'
import WelcomeWizardStep3 from './steps/WelcomeWizardStep3'
import WelcomeWizardStep4 from './steps/WelcomeWizardStep4'
import WelcomeWizardWelcomeVideo from './steps/WelcomeWizardWelcomeVideo'
import WelcomeWizardCheckmark from './steps/WelcomeWizardCheckmark'

const MAX_STEPS = 5

const EmployeeWelcomeWizardModal = ({ isOpen, onDone }: { isOpen: boolean, onDone: () => void }) => {
  const [activeStep, setActiveStep] = useState(0)

  function CustomStepIcon(props: StepIconProps) {
    const { active } = props;

    return <StepIcon completed={active} />
  }

  const _onDone = () => {
    setActiveStep(0)

    onDone()
  }

  return <Modal
    open={isOpen}
  >
    <ModalBody>
      <Row hideScroll={activeStep == MAX_STEPS}>
        {activeStep == 0 && <WelcomeWizardStep1 />}
        {activeStep == 1 && <WelcomeWizardStep2 />}
        {activeStep == 2 && <WelcomeWizardStep3 />}
        {activeStep == 3 && <WelcomeWizardStep4 />}
        {activeStep == 4 && <WelcomeWizardWelcomeVideo />}
        {activeStep == 5 && <WelcomeWizardCheckmark onDone={_onDone} />}
      </Row>

      <Footer>
        <Buttons>
          {activeStep > 0 && activeStep < MAX_STEPS ? <Button variant='outlined' onClick={() => {
            setActiveStep(activeStep - 1)
          }}>Back</Button> : <div></div>}

          {activeStep < MAX_STEPS && <Button variant='outlined' onClick={() => {
            setActiveStep(activeStep + 1)
          }}>Next</Button>}
        </Buttons>

        {activeStep < MAX_STEPS && <Stepper alternativeLabel activeStep={activeStep} connector={null}>
          {Array.from({ length: MAX_STEPS }, (_, index) => (
            <Step key={index + 1}>
              <StepLabel StepIconComponent={CustomStepIcon} />
            </Step>
          ))}
        </Stepper>}
      </Footer>
    </ModalBody>
  </Modal>
}

const Row = styled.div<{ hideScroll?: boolean }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow-x: hidden;
  overflow-y: ${({ hideScroll }) => hideScroll ? 'hidden' : 'auto'};
`


const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 46px;
  margin-top: 12px;
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
`

const StepIcon = styled.div<{ completed: boolean }>`
  width: 55px;
  border-top: 5px solid ${({ completed }) => completed ? COLORS.TURQUOISE : COLORS.LIGHTER_GREY};
`

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 900px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 36px;
  font-family: 'Poppins_400Regular';
  overflow: hidden;
  height: calc(100vh - 140px);
  max-height: 750px;
`

export default EmployeeWelcomeWizardModal
