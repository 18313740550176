import React from 'react'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import QuestionOption from './QuestionOption'
import styled from 'styled-components/native'
import { IS_WEB } from 'constants/static';

const QuestionsSubmittedOptions = ({
  quizType,
  answers,
  isCorrect,
  survey = false,
}: {
  answers: SubmittedQuestionOptionType[]
  quizType: string
  isCorrect: boolean
  survey: boolean
}) => {
  const Trailing = ({ isCorrect = false }) => {
    return <AnswerText isCorrect={isCorrect}>
      {isCorrect ?
        <CheckOutlinedIcon style={{ marginRight: 8 }} /> :
        <CloseOutlinedIcon style={{ marginRight: 8 }} />
      }
      {isCorrect ? 'Correct answer!' : 'Incorrect Answer'}
    </AnswerText>
  }

  const RadioOptions = () => <>
    {answers?.map(option =>
      <QuestionOption
        id={option.id}
        title={option.titleSnapshot}
        isCheckbox={false}
        isSelected={option.answer}
        answer={survey ? null : (option.answer ? isCorrect : null)}
        disabled
        trailing={IS_WEB && !survey && option.answer && <Trailing isCorrect={isCorrect} />}
      />
    )}
  </>

  const CheckboxOptions = () => <>
    {answers?.map(option =>
      <QuestionOption
        id={option.id}
        title={option.titleSnapshot}
        isCheckbox={true}
        isSelected={option.answer}
        answer={survey ? null : (option.answer ? isCorrect : null)}
        disabled
        trailing={IS_WEB && !survey && option.answer && <Trailing isCorrect={isCorrect} />}
      />
    )}
  </>


  return quizType == 'MULTIPLE_CHOICE' ? <RadioOptions /> : <CheckboxOptions />

}

const AnswerText = styled.Text<{ isCorrect: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: ${({ isCorrect }) => isCorrect ? '#18635E' : '#930000'};
  white-space: nowrap;
`

export default QuestionsSubmittedOptions