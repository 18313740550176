import Feedback from 'components/common/Feedback'
import COLORS from 'constants/colors'
import { STYLE_CONSTANTS } from 'constants/es-style'
import { IS_WEB } from 'constants/static'
import { ROUTE_NAMES } from 'navigation/constants'
import React, { useCallback } from 'react'
import { Text, View } from 'react-native'
import { Card } from '@rneui/themed'
import EStyleSheet from 'react-native-extended-stylesheet'
import { useHistory } from 'react-router'
import LocationProgressCircle from './LocationProgressCircle'
import { useTranslation } from 'react-i18next'
import useCurrentLocation from 'hooks/useCurrentLocation'
import ExpiredLabel from './ExpiredLabel'

const LocationElement = ({
  completed,
  ...location
}: LocationInterface) => {
  const {
    id: locationHierarchyId,
    title,
    clientCustomerId,
    clientAddress,
    children,
    tasks,
    notApplicable,
    cantComplete,
    numberInfoTasks,
    color,
  } = location
  const history = useHistory()
  const { t } = useTranslation()
  const { locationOwner } = useCurrentLocation()

  const numberOfCompletedTasks = completed + notApplicable + cantComplete
  const hasChildLocations = children?.length > 0

  const changeToFolder = useCallback(() => {
    history.push({
      pathname: hasChildLocations ? ROUTE_NAMES.LOCATIONS : ROUTE_NAMES.TASKS_TODAY,
      search: `?locationHierarchyId=${locationHierarchyId}`
    })
  }, [hasChildLocations, locationHierarchyId, locationOwner])

  const addressOrClientCustomerId = clientAddress || clientCustomerId

  function renderLocationSubtitle() {
    let tasksText
    let infoText

    if (!tasks) {
      tasksText = null
    } else if (tasks == numberOfCompletedTasks) {
      tasksText = t('allTasksComplete', { tasks })
    } else if (numberOfCompletedTasks == 0) {
      tasksText = t(tasks == 1 ? 'taskToDo' : 'tasksToDo', { tasks })
    } else {
      tasksText = t('someTasksComplete', {
        numberOfCompletedTasks,
        numberOfTasks: tasks,
      })
    }

    if (!numberInfoTasks) {
      infoText = null
    } else {
      infoText = t(numberInfoTasks == 1 ? 'infoTaskToDo' : 'infoTasksToDo', { numberInfoTasks })
    }

    return <>
      <Text style={styles.locationNameSubtitle}>{tasksText}</Text>
      <Text style={styles.locationNameSubtitle}>{infoText}</Text>
    </>
  }

  return (
    <Feedback onPress={changeToFolder}>
      <Card
        key={locationHierarchyId}
        containerStyle={[
          styles.cardContainer,
          IS_WEB
            ? {
              shadowColor: 'rgba(0, 0, 0, 0.04)',
              shadowOffset: { width: 0, height: 1 },
              shadowSpread: 1,
              shadowRadius: 1,
            }
            : {},
          {
            borderLeft: `16px solid ${color ?? '#2E4785'}`,
            borderTopLeftRadius: 16,
            borderBottomLeftRadius: 16,
          }
        ]}
      >
        <View style={styles.listEntry}>
          <View style={styles.locationLabelling}>
            <View style={styles.locationContent}>
              <Text style={styles.locationName} numberOfLines={2}>
                {title} {location.isExpired && location.percent !== 100 && <ExpiredLabel />}
              </Text>
              <View>
                {renderLocationSubtitle()}
              </View>
              {addressOrClientCustomerId && (
                <Text style={styles.taskSubtitle}>
                  {'\n'}
                  {addressOrClientCustomerId}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.progressCircleWrapper}>
            <LocationProgressCircle
              numberOfTasks={tasks}
              numberOfCompletedTasks={numberOfCompletedTasks}
              fontColor={COLORS.GRAYDARK3}
            />
          </View>
        </View>
      </Card>
    </Feedback>
  )
}

const IMAGE_SIZE = 60

// style
const styles = EStyleSheet.create({
  cardContainer: {
    padding: 0,
    marginLeft: IS_WEB ? 0 : 3,
    marginRight: IS_WEB ? 12 : 3,
    marginTop: 6,
    marginBottom: 6,
    height: 108,
    justifyContent: 'center',
    borderWidth: 0,
    borderRadius: 16,
  },
  listEntry: {
    alignItems: 'center',
    borderBottomColor: 'white',
    borderBottomWidth: 1,
    flexDirection: 'row',
    borderRadius: 10,
    padding: 0,
  },
  imageWrapper: {
    ...STYLE_CONSTANTS.FLEX_CENTER,
    marginLeft: -15,
  },
  imageContainer: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    borderBottomLeftRadius: 10,
    borderTopLeftRadius: 10,
    overflow: 'hidden',
  },
  imageStyle: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  },
  locationLabelling: {
    flexDirection: 'row',
    marginLeft: 15,
    alignItems: 'center',
    flex: 1,
  },
  locationContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 12,
    maxWidth: IS_WEB ? '100%' : '100% - 200',
  },
  locationName: {
    fontSize: 16,
    color: COLORS.GRAYDARK3,
    fontWeight: '600',
    flexDirection: 'row',
  },
  locationNameSubtitle: {
    color: COLORS.GRAYDARK3,
    fontSize: 14,
    fontWeight: '400',
  },
  numberTasks: {
    fontSize: '$fontSmall',
  },
  locationDetails: {
    marginTop: 3,
    flexDirection: 'row',
  },
  Filler: {
    flex: 1,
  },
  toggleSessionButton: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 5,
    paddingRight: 5,
  },
  toggleSessionButtonTitle: {
    marginLeft: 5,
    fontSize: '$fontMediumSmall',
  },
  rightIconWrapper: {
    marginRight: 20,
    minWidth: IS_WEB ? 60 : 52,
    display: 'flex',
    alignItems: 'flex-end',
  },
  progressCircleWrapper: {
    marginLeft: 12,
    marginRight: 12,
  },
  taskSubtitle: {
    color: COLORS.SECONDARY,
    fontSize: 10,
  },
})

export default LocationElement
