import React, { useState, ChangeEvent, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useApolloClient, useQuery } from '@apollo/react-hooks'

import { debounce } from 'utils/helpers/debounce'
import { ISODayString } from 'utils/date'
import useCreateEvent from 'components/task-complete/hooks/useCreateEvent'
import { useAppContext } from 'utils/app-context'
import COLORS from 'constants/colors'
import { EVENT_TYPES } from 'constants/types'

import { useTranslation } from 'react-i18next'
import { Prompt } from 'react-router-native'
import { GET_TASKS_VIRTUAL } from 'graphql/queries'
import { getDefaultQueryVariables } from 'utils/apollo'

type Props = {
  taskId: string
  sessionUuid: string
  locationHierarchyId: string
  initialNotes?: string
}

const TaskNotes = ({
  taskId,
  locationHierarchyId,
  initialNotes
}: Props) => {
  const client = useApolloClient()
  const appContextValue = useAppContext()

  const [isLoading, setIsLoading] = useState(false)
  const [text, setText] = useState(() => initialNotes || '')
  const { t } = useTranslation()
  const variables = getDefaultQueryVariables(appContextValue)

  const createEvent = useCreateEvent({
    taskId,
    locationHierarchyId,
  })


  const textComplete = (text: string) => {
    setIsLoading(true)
    createEvent({ eventType: EVENT_TYPES.TASK_TEXT, createEventData: { text } })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const debouncedSave = useCallback(
    debounce((nextValue) => textComplete(nextValue), 500),
    [],
  )

  const overwriteTaskTextCache = (text: string) => {
    // apollo client reads from cache before writing to it
    const tasksVirtualData = client.readQuery({ query: GET_TASKS_VIRTUAL, variables: { ...variables, locationHierarchyId: locationHierarchyId } })
    const tasksVirtual = tasksVirtualData.tasksVirtual.map((task: TaskInterface) => {
      if (task.id === taskId) {
        return { ...task, text }
      }

      return task
    })

    client.writeQuery({
      query: GET_TASKS_VIRTUAL,
      variables: { ...variables, locationHierarchyId: locationHierarchyId },
      data: { tasksVirtual: tasksVirtual },
    })
  }

  const onChangeText = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => {
    overwriteTaskTextCache(value)
    setText(value)
    debouncedSave(value)
  }

  return (
    <>
      <Prompt
        when={isLoading}
        message={t('promptMessage')}
      />
      <StyledNotes>
        <div className="notes-title">{t('notes')}</div>
        <div className="notes-subtitle">{t('taskNotesDescription')}</div>
        <textarea
          placeholder={t('typeHereToAddText')}
          onChange={onChangeText}
          value={text}
        />
      </StyledNotes>
    </>
  )
}

const StyledNotes = styled.div`
  padding: 0px 30px 0px 45px;
  width: 100%;
  box-sizing: border-box;

  .notes-title {
    color: ${COLORS.NAVY_BLUE};
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }

  .notes-subtitle {
    color: ${COLORS.SIXES_GREY};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 12px;
  }

  textarea {
    outline: 0;
    resize: none;
    border: 2px solid ${COLORS.POWDER_BLUE};
    padding: 20px;
    height: 110px;
    width: 100%;
    height: 110px;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: Poppins_400Regular;
  }

  textarea::placeholder {
    color: ${COLORS.MIDDLE_GREY};
  }
`

export default TaskNotes
