import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

import completeButtonStyles from './constants/completeButtonStyles'
import {
  CheckmarkIcon,
} from 'components/common/custom-icons'
import useCompleteElementStates from './hooks/useCompleteElementStates'

const TaskQuestionToComplete = (props: CompleteElementProps) => {
  const { completedEvent, completed } = props

  const {
    completeState,
  } = useCompleteElementStates({
    completedEvent,
  })

  const renderIcon = () => {
    const ICON_SIZE = 36

    if (!(completeState || completed)) {
      return (
        <CheckmarkIcon height={ICON_SIZE} width={ICON_SIZE} opacity={0.16} />
      )
    }

    return <CheckmarkIcon height={ICON_SIZE} width={ICON_SIZE} />
  }

  return <View style={styles.container}>
    <TouchableOpacity style={styles.iconContainer}>
      {renderIcon()}
    </TouchableOpacity>
  </View>

}

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    ...completeButtonStyles.iconContainer,
  },
})

export default TaskQuestionToComplete