import React from 'react'
import styled from 'styled-components'
import { Show } from '../SearchModal'
import SearchModalResultHeader from './SearchModalResultHeader'
import UpNextCard from 'components/locations/up-next/UpNextCard.web'
import { useTranslation } from 'react-i18next'

const SearchModalTasksResult = ({
  type,
  show,
  setShow,
  tasks,
  loading,
  onClose,
  refetch,
}: {
  type: Show,
  show: Show,
  setShow: (value: Show) => void
  tasks: TaskUncompleteType[]
  loading: boolean
  onClose: () => void
  refetch: () => void
}) => {
  const { t } = useTranslation()

  const Content = () => {
    if (loading) {
      return <span>{t('fetchingResults')}</span>
    }

    return <>
      {tasks
        .slice(0, show == null ? 3 : tasks.length)
        .map(task =>
          <UpNextCard key={task.id} task={task} refetch={refetch} onClick={onClose} darkBackground />
        )}
    </>
  }

  return <Results>
    <SearchModalResultHeader
      type={type} show={show} length={tasks.length} onClick={() => setShow(type)}
    />
    <ResultsContent>
      <Content />
    </ResultsContent>
  </Results>
}

const Results = styled.div`
  margin-bottom: 30px;
  margin-right: 10px;
`

const ResultsContent = styled.div`
  display: flex;
  flex-direction: column;
`

export default SearchModalTasksResult