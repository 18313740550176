import React from 'react'
import { TextField } from '@mui/material';
import COLORS from 'constants/colors'

const QuestionComment = ({
  questionId,
  comment,
  onOptionSelected,
}: {
  questionId?: string
  comment?: string
  onOptionSelected?: (currentState: any) => void
}) => {

  return <TextField
    sx={{
      backgroundColor: 'white',
      borderColor: COLORS.POWDER_BLUE,
      borderWidth: '2px',
      borderRadius: '6px',
    }}
    disabled={!questionId}
    defaultValue={comment}
    fullWidth
    multiline
    minRows={2}
    onChange={(event) => {
      onOptionSelected((currentState: any) => {
        currentState[questionId].comment = event.target.value;

        return {
          ...currentState
        };
      });
    }
    }
  />
}

export default QuestionComment