import React from 'react'
import { View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { Icon } from 'components/Index'
import completeButtonStyles from './constants/completeButtonStyles'
import COLORS from 'constants/colors'
import { ICON_NAMES } from 'constants/icons'
import useCompleteElementStates from './hooks/useCompleteElementStates'

import {
  NopeIcon,
  NotApplicableIcon,
} from 'components/common/custom-icons'

const TaskScormCourseComplete = (props: CompleteElementProps) => {
  const { completedEvent, completed } = props

  const {
    isNotApplicableCompletedEvent,
    isCantComplete,
    completeState,
    taskDone,
  } = useCompleteElementStates({
    completedEvent,
  })


  const renderIcon = () => {
    const ICON_SIZE = 36

    if (!(completeState || completed)) {
      return (
        <Icon
          name={ICON_NAMES.SCORM_COURSE}
          size={ICON_SIZE}
          style={{ color: COLORS.TURQUOISE, opacity: 0.2 }} />
      )
    } else if (isCantComplete) {
      return <NopeIcon height={ICON_SIZE} width={ICON_SIZE} />
    } else if (isNotApplicableCompletedEvent) {
      return <NotApplicableIcon height={ICON_SIZE} width={ICON_SIZE} />
    }

    return (<Icon
      name={ICON_NAMES.SCORM_COURSE}
      size={ICON_SIZE}
      style={{ color: COLORS.TURQUOISE, opacity: 1 }} />)
  }


  return (
    <View style={styles.container}>
      {renderIcon()}
    </View>
  )
}

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    ...completeButtonStyles.iconContainer,
  },
})

export default TaskScormCourseComplete
