import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'
import { MODEL_TYPES } from 'constants/types'

const LAST_QUESTION_SUBMISSION = `
lastQuestionsSubmission {
  id
  percentage
  score
  totalScore
  updatedAt
  questions {
    templateTaskQuestion {
      id
    }
    id
    isCorrect
    titleSnapshot
    requiredSnapshot
    descriptionSnapshot
    explanationSnapshot
    quizTypeSnapshot
    answers {
      templateTaskQuestionOption {
        id
      }
      id
      answer
      titleSnapshot
      correctSnapshot
      descriptionSnapshot
      scoreSnapshot
      taxonomyIdSnapshot
      dateTypeIdSnapshot
      roleIdSnapshot
      propertyNameSnapshot
      comment
      mediaUrls
    }
  }
  questionsWithAnswers
}
`

const commonTaskFields = `
  id
  title
  description
  taskType
  mediaUrl
  frequencyType
  dueDate
  showNotApplicableOption
  estimateInMins
  important
  docusignEnvelopeId
  options {
    id
    option
  }
  allowMultiSelect
  dependsOnTaskId
  questions {
    id
    title
    required
    quizType
    description
    explanation
    options {
      id
      title
      description
      score
      taxonomyId
      dateTypeId
      roleId
      propertyName
    }
  }
  videoUrl
  scormCourseUrl
  doceboCourseUrl
  videoPercentageComplete
  ${LAST_QUESTION_SUBMISSION}
`

export const TASK_COMPLETE_FIELDS = `
  id
  taskId
  imageUrl
  mediaUrls
  text
  cantCompleteText
  notApplicableText
  eventType
  createdAt
  completedBy {
    fullName
  }
  ${LAST_QUESTION_SUBMISSION}
`

export const FRAGMENT_LOCATION_HIERARCHY_FIELDS = gql`
  fragment LocationHierarchyFields on LocationHierarchyWithAnalytics {
    id
    title
    numberTasks
    numberInfoTasks
    clientCustomerId
    clientAddress
    invoiceFileUrls
    logoUrl
    children {
      id
    }
    notes
    owner {
      id
      fullName
      startDate
      email
      avatarUrl
    }
    tasks
    completed
    notApplicable
    cantComplete
    isExpired
    parentId
    color
    colorBrightness
    progressColor
    position
    createdAt
  }
`

export const FRAGMENT_TASK_FIELDS = gql`
  fragment TaskFields on Task {
    ${commonTaskFields}
  }
`

export const FRAGMENT_TASK_VIRTUAL_FIELDS = gql`
  fragment TaskVirtualFields on TaskVirtual {
    ${commonTaskFields}
    locationHierarchyId
    text
  }
`

export const TASK_VIRTUAL_COMPLETE_FIELD_FRAGMENT = gql`
  fragment CompleteField${MODEL_TYPES.TASK_VIRTUAL} on ${MODEL_TYPES.TASK_VIRTUAL} {
    complete
  }
`
export const TASK_VIRTUAL_LAST_IMAGE_URL_FIELD_FRAGMENT = gql`
  fragment LastImageUrlField${MODEL_TYPES.TASK_VIRTUAL} on ${MODEL_TYPES.TASK_VIRTUAL} {
    lastImageUrl
  }
`

export const FRAGMENT_EVENT_FIELDS = gql`
  fragment EventFields on Event {
    id
    eventType
    imageUrl
    createdAt
    mediaUrls
    user {
      fullName
    }
  }
`

export const FRAGMENT_SESSION_FIELDS = gql`
  fragment SessionFields on Session {
    uuid
    day
    locationHierarchyId
  }
`

export const FRAGMENT_LOCATION_TEXT_FIELDS = gql`
  fragment LocationTextFields on LocationText {
    id
    text
  }
`

export const FRAGMENT_LOCATION_PHOTO_FIELDS = gql`
  fragment LocationPhotoFields on LocationPhoto {
    imageUrl
    id
  }
`
export const FRAGMENT_TASK_TEXT_FIELDS = gql`
  fragment TaskTextFields on TaskText {
    id
    text
  }
`

export const FRAGMENT_TASK_PHOTO_FIELDS = gql`
  fragment TaskPhotoFields on TaskPhoto {
    imageUrl
    id
  }
`

export const FRAGMENT_USER_OWNER_FIELDS = gql`
  fragment UserOwnerFields on User {
    id
    firstName
    lastName
    fullName
    email
    workEmail
    personalEmail
    homeAddress
    avatarUrl
    taxonomyTerms {
      title
      id
      taxonomy {
        name
        slug
      }
    }
    startDate
    userDatesWithNames
    phoneNumber
    manager {
      firstName
      lastName
      email
      fullName
      avatarUrl
    }
  }
`

export const FRAGMENT_PAGINATION_META_FIELDS = gql`
  fragment PaginationMetaFields on PaginationMeta {
    page
    perPage
    totalPages
    totalCount
    nextPage
    prevPage
  }
`

export const FRAGMENT_USER_AUTOCOMPLETE_WITH_ASSIGNMENTS_FIELDS = gql`
  fragment UserAutocompleteFields on User {
    email
    firstName
    lastName
    fullName
    externalId
    userTemplateRolesAssignments {
      id
      templateRoleId
    }
  }
`

export const FRAGMENT_EXTERNAL_USER_AUTOCOMPLETE_WITH_ASSIGNMENTS_FIELDS = gql`
  fragment UserAutocompleteFields on ExternalUser {
    email
    firstName
    lastName
    fullName
    externalId
    userId
  }
`

export const FRAGMENT_USER_WITH_ASSIGNEES_FIELDS = gql`
  fragment UserFields on User {
    id
    email
    personalEmail
    firstName
    lastName
    fullName
    avatarUrl
    jobTitle
    startDate
    terminationDate
    userTemplateRolesAssignees {
      id
      assignee {
        id
        email
        firstName
        lastName
        fullName
      }
      templateRole {
        id
        name
      }
    }
  }
`

export const UPNEXT_TASK_FIELDS = `
  id
  title
  description
  dueDate
  taskType
  mediaUrl
  important
  locationHierarchyId
  showNotApplicableOption
  frequencyType
  options {
    id
    option
  }
  allowMultiSelect
  startAt
  section {
    id
    title
    owner {
      id
      fullName
    }
  }
  workflow {
    id
    title
    owner {
      id
      fullName
    }
  }
`
