import { useQuery } from "@apollo/react-hooks"
import { FETCH_POLICIES } from "constants/api"
import { TASKS_SCREEN_QUERY } from "graphql/queries"
import { getDefaultQueryVariables, getTaskCompleteVariables } from "utils/apollo"
import { useAppContext } from "utils/app-context"
import { CACHE_KEYS } from "constants/types"
import { ApolloError } from "apollo-client"

const useTasks = (currentLocationHierarchyId: string): {
  tasks: TaskInterface[]
  completedTaskEvents: TaskCompleteType[]
  loading: boolean
  error: ApolloError
  refetch: () => void
} => {
  const appContextValue = useAppContext()
  const variables = getDefaultQueryVariables(appContextValue)
  const taskCompleteVariables = getTaskCompleteVariables(
    currentLocationHierarchyId,
    appContextValue,
  )

  const { data, loading, error, refetch } = useQuery(
    TASKS_SCREEN_QUERY,
    {
      variables: { ...variables, ...taskCompleteVariables },
      skip: !currentLocationHierarchyId,
    },
  )

  const tasks = data?.[CACHE_KEYS.TASK_VIRTUAL] || []
  const completedTaskEvents = data?.[CACHE_KEYS.TASKS_COMPLETE] || []

  return {
    tasks,
    completedTaskEvents,
    loading: loading || !currentLocationHierarchyId,
    error,
    refetch,
  }
}

export default useTasks