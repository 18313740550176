import React, { useState, useEffect, useCallback } from 'react'
import {
  Platform,
  SafeAreaView,
  Text,
  View,
} from 'react-native'
import { SearchBar } from '@rneui/themed'
import EStyleSheet from 'react-native-extended-stylesheet'
import { useMutation } from '@apollo/react-hooks'
import { FontAwesome } from '@expo/vector-icons';
import { Loader } from 'components/common/Loader'
import { IS_WEB } from 'constants/static'
import COLORS from 'constants/colors'
import { useTranslation } from 'react-i18next'
import { Switch } from 'react-native-paper'
import { UPDATE_USER_PREFERENCES } from 'graphql/mutations'
import useUser from 'hooks/useUser'
import useClientConfig from 'hooks/useClientConfig'
import LocationElements from './LocationElements'
import useCurrentLocation from 'hooks/useCurrentLocation'
import { debounce } from 'lodash'

const LocationsList = () => {
  const { t } = useTranslation()
  const [search, setSearch] = useState<String>('')
  const [debouncedSearchTerm, _setDebouncedSearchTerm] = useState('')
  const debouncedSetSearchTerm = useCallback(debounce(_setDebouncedSearchTerm, 500, { trailing: true }), [])
  const { currentLocationHierarchyId } = useCurrentLocation()

  const [hideCompletedWorkflows, setHideCompletedWorkflows] = useState(false)
  const { user, loading: userLoading, refetch } = useUser()
  const { iconColor } = useClientConfig()
  const [updateUserPreferences] = useMutation(UPDATE_USER_PREFERENCES)

  useEffect(() => {
    const showCompletedWorkflow = user?.preferences?.showCompletedWorkflow
    if (user?.preferences) {
      setHideCompletedWorkflows(
        !showCompletedWorkflow
      )
    }
  }, [user])

  const isManager = user?.isManager || false

  const handleSearch = (text: string) => {
    setSearch(text)
    debouncedSetSearchTerm(text)
  }

  const renderLocationElements = () => {
    if (userLoading) {
      return <Loader />
    }

    return (
      <LocationElements
        parentId={currentLocationHierarchyId}
        hideCompletedWorkflows={hideCompletedWorkflows}
        searchTerm={debouncedSearchTerm}
      />
    )
  }

  const onShowCompletedChange = async (value: boolean) => {
    await updateUserPreferences({
      variables: { attributes: { showCompletedWorkflow: !!value } },
    })
    setHideCompletedWorkflows(!value)
    await refetch()
  }

  return (
    <SafeAreaView style={styles.container}>
      {!currentLocationHierarchyId && isManager && !IS_WEB && (
        <View style={styles.locationSearchWrapper}>
          <SearchBar
            lightTheme={true}
            platform={Platform.OS}
            placeholder={t('search')}
            onChangeText={handleSearch}
            value={search}
            searchIcon={(
              <FontAwesome name="search" size={24} color={COLORS.NAVY_BLUE} />
            )}
            clearIcon={
              null
            }
            containerStyle={{
              backgroundColor: 'transparent',
              width: '100%',
              borderBottomWidth: 0,
              borderTopWidth: 0,
              paddingHorizontal: 0,
              paddingTop: 18,
              paddingBottom: 18,
            }}
            inputContainerStyle={{
              backgroundColor: 'white',
              borderRadius: 16,
              shadowColor: 'rgba(0, 0, 0, 0.08)',
            }}
            inputStyle={[
              {
                fontSize: IS_WEB ? 18 : 14,
                paddingLeft: 6,
                color: COLORS.MIDDLE_GREY,
              },
              IS_WEB ? { outline: 'none' } : {},
            ]}

          />
        </View>
      )}

      <View style={styles.switchContainer}>
        <Switch
          color={iconColor || COLORS.TURQUOISE}
          value={!hideCompletedWorkflows}
          onValueChange={onShowCompletedChange}
        />
        <Text style={styles.switchText}>{t('showCompletedWorkflows')}</Text>
      </View>

      {renderLocationElements()}
    </SafeAreaView>
  )
}

// styles
const styles = EStyleSheet.create({
  container: {
    paddingTop: 0,
    flex: 1,
    paddingBottom: IS_WEB ? 0 : 10,
  },
  locationSearchWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  switchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 5,
    paddingVertical: 10,
  },
  switchText: {
    paddingLeft: 5,
    fontWeight: '600',
  },
})

export default LocationsList
