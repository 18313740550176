import { useQuery } from "@apollo/react-hooks"
import { DEFAULT_CATEGORY_COLORS } from "constants/colors"
import { LOCATION_SCREEN_QUERY } from "graphql/queries"
import { groupBy, orderBy } from "lodash"
import { useEffect, useMemo } from "react"
import useUser from "./useUser"
import useClientConfig, { CategoryColor } from "./useClientConfig"
import { useState } from "react"
const sortGroupByMostRecent = (
  a: LocationInterface[],
  b: LocationInterface[],
) => {
  if (!a[0]?.owner?.startDate) {
    return 1
  }
  if (!b[0]?.owner?.startDate) {
    return -1
  }

  return (
    new Date(b[0].owner.startDate) -
    new Date(a[0].owner.startDate)
  )
}

const getUserLocations = (locations: LocationInterface[], user?: User) => {
  return locations.filter(
    (location) => !location.owner || location.owner.id == user?.id,
  )
}

const getLocationPerEmployee = (locations: LocationInterface[], user?: User) => {
  const managerLocations = locations.filter(
    (location) => location.owner && location.owner.id !== user?.id,
  )
  const groupedLocations = groupBy(managerLocations, 'owner.id')
  const locationsPerEmployee: LocationInterface[][] = Object.values(
    groupedLocations,
  ).sort(sortGroupByMostRecent)

  return locationsPerEmployee
}

export function getColorByIndex(colorArray: CategoryColor[], index: number) {
  const colorIndex: number = index % (colorArray.length);
  const color = colorArray[colorIndex]

  return color
}

type UseLocationOptions = {
  enablePagination?: boolean
  searchTerm?: string
  skip?: boolean
  showReporteesLocationsOnly?: boolean
}

const useLocations = (parentId?: string, hideCompletedWorkflows = true, options: UseLocationOptions = {
  enablePagination: false,
  searchTerm: '',
  skip: false,
  showReporteesLocationsOnly: false,
}) => {
  const { user } = useUser()
  const { categoryColors } = useClientConfig()
  const [locations, setLocations] = useState<LocationInterface[]>([])

  const { data, loading, error, refetch } = useQuery(LOCATION_SCREEN_QUERY, {
    variables: {
      parentId,
      pagination: options?.enablePagination ? { page: 1, perPage: 30 } : undefined,
      locationTitle: options?.searchTerm ? options.searchTerm : undefined,
      showReporteesLocationsOnly: options?.showReporteesLocationsOnly ? options.showReporteesLocationsOnly : undefined
    },
    skip: options.skip,
  })

  useEffect(() => {

    if (data?.locationHierarchiesNew?.data) {
      setLocations((prevLocations) => {
        const newLocations = data.locationHierarchiesNew.data.map((location: LocationInterface) => {
          const completedTasksCount =
            location.completed +
            location.cantComplete +
            location.notApplicable;
          const percent =
            location.tasks > 0
              ? Math.round((completedTasksCount / location.tasks) * 100)
              : 0;
          const isComplete = percent == 100;

          return {
            completedTasksCount,
            percent,
            isComplete,
            ...location,
          };
        });
        const newLocationsIds = new Set(newLocations.map(location => location.id));

        const uniqueNewPreviousLocations = prevLocations.filter((location: any) => !newLocationsIds.has(location.id));

        return orderBy([
          ...(!options.enablePagination && locations.length > 0 ? [] : uniqueNewPreviousLocations), // Don't append locations if pagination is disabled
          ...newLocations,
        ],
          [location => new Date(location.createdAt), 'position'], ['desc', 'asc']);
      });
    }
  }, [data?.locationHierarchiesNew?.data])

  const paginationMeta = useMemo(() => {
    return data?.locationHierarchiesNew?.paginationMeta
  }, [data?.locationHierarchiesNew])

  const fetchNextPage = () => {
    if (paginationMeta?.nextPage) {
      refetch({
        parentId,
        pagination: {
          page: paginationMeta.nextPage,
          perPage: paginationMeta.perPage,
        },
      })
    }
  }

  // Makes sure we only use valid locations (that have a valid parent)
  const roots = useMemo(() => locations.filter(location => !location.parentId), [locations])
  const rootIds = useMemo(() => roots.map(location => location.id), [roots])
  const workflows = useMemo(() => orderBy(locations
    .filter(location => rootIds.includes(location.parentId))
    .map((location) => {
      const index = parseInt(location.id) % (categoryColors?.length || DEFAULT_CATEGORY_COLORS.length)

      return {
        ...location,
        ...(location.color ? [] : getColorByIndex(categoryColors || DEFAULT_CATEGORY_COLORS, index)),
      }
    })
    .filter(location => !(hideCompletedWorkflows && (location.isComplete || location.isExpired))),
    ['position']),
    [rootIds, locations, hideCompletedWorkflows])
  const workflowIds = useMemo(() => workflows.map(workflow => workflow.id), [workflows])
  const sections = useMemo(() => orderBy(
    locations
      .filter(location => workflowIds.includes(location.parentId))
      .filter(location => !(hideCompletedWorkflows && (location.isComplete || location.isExpired)))
      .map(section => {
        const workflow = workflows.find(workflow => workflow.id == section.parentId)
        return ({
          ...section,
          color: workflow.color,
          colorBrightness: workflow.colorBrightness,
          progressColor: workflow.progressColor,
        })
      }),
    ['position', 'id']
  ),
    [workflowIds, locations, hideCompletedWorkflows])
  const filteredLocations = useMemo(() => [...roots, ...workflows, ...sections], [roots, workflows, sections])
  const myLocations = useMemo(() => {
    let locationsToRender = [] as LocationInterface[]

    if (!parentId) {
      locationsToRender = workflows
    } else {
      locationsToRender = sections.filter((section) => section.parentId == parentId)
    }

    return getUserLocations(locationsToRender, user) || []
  }, [filteredLocations, user, parentId])

  const myTeamLocations = useMemo(() => {
    const myTeamLocations = getLocationPerEmployee(filteredLocations, user).map((locations) => {
      return {
        owner: locations[0].owner,
        color: locations[0].color,
        colorBrightness: locations[0].colorBrightness,
        progressColor: locations[0].progressColor,
        locations,
        percent: Math.round(locations.reduce((prev, curr) => {
          return (prev + curr.percent);
        }, 0) / locations.length),
      }
    })

    return orderBy(myTeamLocations, 'percent')
  }, [filteredLocations, user])
  const myTeam = useMemo(() => {
    return myTeamLocations.map(({ owner, color, colorBrightness, progressColor }) => ({ owner, color, colorBrightness, progressColor })) || []
  }, [myTeamLocations])

  return {
    locations: filteredLocations,
    myLocations,
    myTeamLocations,
    myTeam,
    loading,
    error,
    refetch,
    paginationMeta,
    fetchNextPage,
  }
}

export default useLocations
