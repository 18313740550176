import { Platform } from 'react-native'

export const ROOT_FOLDER_NAME = '__root__'

const PLATFORM_ANDROID = 'android'

export const IS_ANDROID = Platform.OS === PLATFORM_ANDROID
export const IS_WEB = Platform.OS === 'web'

export const SUPPORTED_VIDEO_TYPES = {
  MP4: 'mp4',
  MOV: 'mov',
}

export const SUPPORTED_IMAGE_TYPES = {
  JPG: 'jpg',
  PNG: 'png',
}

export const TASK_TYPE_TO_NAME_MAP = {
  TOGGLE: 'Complete',
  SIGN: 'E-Sign',
  DOCUSIGN: 'DocuSign',
  TEXT: 'Comment',
  MULTIPLE_OPTION: 'Multiple Choice',
  UPLOAD_MEDIA: 'Upload Media',
  INFO: 'Info',
  QUIZ: 'Quiz',
  SURVEY: 'Survey',
  SEND_SAFELY: 'Send Safely',
  VIDEO: 'Video',
  SCORM_COURSE: 'Scorm Course',
  DOCEBO: 'Docebo',
  SCORECARD: 'Scorecard',
}
