import React from 'react'

import ScreenWrapper from 'components/common/ScreenWrapper'
import { default as MobileTaskTodayScreen } from './TaskTodayScreen'
import useUser from 'hooks/useUser'

const TaskTodayScreenWeb = () => {
  const { user } = useUser()

  return (
    <ScreenWrapper
      currentUser={user}
      menuComponent={<MobileTaskTodayScreen />}
    />
  )
}

export default TaskTodayScreenWeb
