import React from 'react'
import { Avatar, Box, Link } from '@mui/material'
import styled from 'styled-components'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import COLORS from 'constants/colors'
import { getDateWithoutTimezone } from 'components/my-team/utils/getDateWithoutTimezone';
import format from 'utils/date-fns'
import { useTranslation } from 'react-i18next';

const ReporteeHeader = ({ reportee }: { reportee: User }) => {
  const { t, i18n } = useTranslation()

  const monthDayYear = format(
    getDateWithoutTimezone(new Date(reportee.startDate)),
    t('monthDayYearDateFormat'),
    i18n.resolvedLanguage,
  )

  return <Container>
    <Avatar
      src={reportee?.avatarUrl}
      sx={{
        backgroundColor: `${reportee.color || COLORS.TURQUOISE} !important`,
        color: reportee.colorBrightness == 'light' ? 'white !important' : ' black !important',
        width: '72px',
        height: '72px',
        fontSize: '28px',
        fontWeight: '500',
      }}

    >
      {reportee.firstName[0] + reportee.lastName[0]}
    </Avatar>

    <Text>
      <Title>{reportee.fullName}</Title>
      {reportee.jobTitle && <Subtitle>{reportee.jobTitle}</Subtitle>}

      <Row>
        <Subtitle>
          <CalendarTodayIcon fontSize="small" sx={{ marginRight: '4px' }} />
          Started {monthDayYear}</Subtitle>
        <Box mr={3} />
        <Subtitle>
          <MailOutlineIcon fontSize="small" sx={{ marginRight: '4px' }} />
          <Link href={`mailto:${reportee.email}`} color="inherit">
            {reportee.email}
          </Link>
        </Subtitle>
      </Row>
    </Text>
  </Container>
}



const Subtitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 300;
  color: ${COLORS.SIXES_GREY};
`

const Title = styled.div`
  color: ${COLORS.NAVY_BLUE};
`

const Text = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin-left: 12px;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 36px 24px 36px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export default ReporteeHeader