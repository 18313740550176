import React from 'react'
import { Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import EStyleSheet from 'react-native-extended-stylesheet'
import format from 'utils/date-fns'
import { isToday } from 'utils/date'
import { EVENT_TYPES } from 'constants/types'
import COLORS from 'constants/colors'
import RowView from 'components/common/fragments/RowView'

const TaskModalSubHeader = ({
  completedEvent,
  task,
}: {
  completedEvent: TaskCompleteType
  task: TaskInterface
}) => {
  const { t, i18n } = useTranslation()
  const isCompleted = !!completedEvent?.createdAt
  const dueDate = getDateFromString(
    isCompleted ? completedEvent.createdAt : task.dueDate,
  )
  const dueDateText = format(
    dueDate,
    t('monthDayYearDateFormat'),
    i18n.resolvedLanguage,
  )
  const isDueToday = isToday(dueDate)
  const shouldShowDate = !!task.dueDate || isCompleted

  function getDateFromString(date: string) {
    if (date?.length == 10) {
      // then it is in YYYY-MM-DD format, so we add the time
      return new Date(date.replace(/-/g, '/'))
    }

    return new Date(date)
  }

  function renderEventText() {
    const { eventType } = completedEvent

    switch (eventType) {
      case EVENT_TYPES.ADD_CANT_TEXT:
        return t('markedCantComplete')
      case EVENT_TYPES.NOT_APPLICABLE:
        return t('markedNotApplicable')
      default:
        return t('completed')
    }
  }

  if (shouldShowDate) {
    return (
      <View style={styles.title}>
        <Text style={styles.titleDue}>
          {isCompleted
            ? renderEventText()
            : isDueToday
            ? t('dueToday')
            : t('due')}
        </Text>
        <RowView>
          <Text style={styles.titleDate}>{dueDateText}</Text>
          {completedEvent?.completedBy && (
            <Text style={styles.titleDate}>{` ${t('by')} ${
              completedEvent?.completedBy.fullName
            }`}</Text>
          )}
        </RowView>
      </View>
    )
  }

  return null
}

const styles = EStyleSheet.create({
  title: {
    flexDirection: 'column',
    color: COLORS.FIVES_GREY,
    fontSize: 14,
    lineHeight: 21,
    alignItems: 'baseline',
  },
  titleDue: {
    fontWeight: '600',
    marginRight: 4,
  },
  titleDate: {
    fontWeight: '300',
  },
})

export default TaskModalSubHeader
