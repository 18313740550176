import React, { useEffect, useMemo } from 'react'

import styled from 'styled-components'
import COLORS from 'constants/colors'
import format from 'utils/date-fns'
import { isToday } from 'date-fns'
import { useHistory } from 'react-router'
import { useApolloClient, useLazyQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next'
import ImportantLabel from 'components/common/ImportantLabel'
import { captureException } from 'utils/sentry'
import { replaceSnippetsInText } from 'utils/helpers/snippets-helper'
import { GET_USER_SNIPPET_VALUES } from 'graphql/queries'
import useUser from 'hooks/useUser'
import UpNextRawCard from './UpNextRawCard.web'
import { Avatar } from '@mui/material'
import DueDateText, { DueDateTextMode } from 'components/common/DueDateText'
import { ROUTE_NAMES } from 'navigation/constants'
import { Image } from 'react-native'

const defaultTaskImage = require('assets/images/png/default_task_image.png')

const UpNextCardLite = ({
  task,
  onClick,
  disableClick = false,
  darkBackground = false,
  smallIcon = false,
}: {
  task: TaskUncompleteType
  onClick?: () => void
  disableClick?: boolean
  darkBackground?: boolean
  smallIcon?: boolean
}) => {
  const history = useHistory()
  const client = useApolloClient()
  const { i18n } = useTranslation()
  const { user } = useUser()

  const section = useMemo(() => task.section, [task])
  const workflow = useMemo(() => task.workflow, [task])
  const owner = useMemo(() => workflow?.owner || section?.owner, [section, workflow])
  const [getUserSnippetValues, { data: userSnippetValuesData }] = useLazyQuery(GET_USER_SNIPPET_VALUES)
  const snippetsMapping = useMemo(() => userSnippetValuesData?.userSnippetValues || {}, [userSnippetValuesData])
  const hasSnippet = useMemo(() => /.*{{.*}}.*/.test(task.title), [task])
  useEffect(() => {
    if (owner?.id && hasSnippet) {
      getUserSnippetValues({ variables: { userId: owner.id } })
    }
  }, [owner])

  function renderStartAt() {
    const startAt = new Date(task.startAt)
    let text = ''

    if (isToday(startAt)) {
      text = `Today, ${format(
        startAt,
        'h:mm a',
        i18n.resolvedLanguage,
      )}`
    } else {
      text = format(
        startAt,
        'LLLL d, y h:mm a',
        i18n.resolvedLanguage,
      )
    }


    return <StartAtText pastDue={false}>{text}</StartAtText>
  }

  function goToTasksToday() {
    if (disableClick) return

    // set selected task
    client.writeData({
      data: {
        appData: {
          taskDescriptionModalId: task.id,
          __typename: 'AppData',
        },
      },
    })

    history.push({
      pathname: ROUTE_NAMES.TASKS_TODAY,
      search: `?locationHierarchyId=${task.locationHierarchyId}`,
    })

    if (onClick) onClick()
  }

  const handleMissingSnippet = useMemo(
    () => (key: string) => {
      console.error(`Missing snippet: ${key}, taskId: ${task.id}`)
    },
    [task],
  )

  const titleWithSnippets = useMemo(
    () => {
      if (!snippetsMapping?.['employee.work_email']) // If the snippets are not loaded yet
        return task.title

      return replaceSnippetsInText(
        task.title,
        snippetsMapping,
        handleMissingSnippet,
        i18n.resolvedLanguage
      )
    },
    [task.title, snippetsMapping],
  )

  function stringAvatar(name: string, color?: string) {
    return {
      sx: {
        bgcolor: color || COLORS.TURQUOISE,
        width: '36px',
        height: '36px',
        fontSize: '14px',
        fontWeight: '600',
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  const Icon = () => {
    if (owner && user?.id !== owner.id) {
      return <Avatar  {...stringAvatar(owner?.fullName, workflow?.progressColor)} src={owner?.avatarUrl}></Avatar>
    }

    return <TaskIcon>
      <Image style={{ height: smallIcon ? 24 : 36, width: smallIcon ? 24 : 36 }} source={task.mediaUrl ?? defaultTaskImage} />
    </TaskIcon>
  }

  return (
    <UpNextRawCard tagColor={workflow?.color} onClick={goToTasksToday} darkBackground={darkBackground}>
      <Left>
        <Icon />
        <LeftText>
          {(owner && user?.id !== owner.id) && <OwnerTitle>{owner.fullName}</OwnerTitle>}
          <TaskTitle>{titleWithSnippets} {task.important && <ImportantLabel withSeparator fontSize={11} />}</TaskTitle>
        </LeftText>
      </Left>
      <Right>
        {!!task.dueDate && !task.startAt && <StyledDueDateLiteText date={task.dueDate} textMode={DueDateTextMode.SMALL} />}
        {!!task.startAt && renderStartAt()}
      </Right>
    </UpNextRawCard>
  )
}

const Left = styled.div`
  display: flex;
  align-items: center;
`

const LeftText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
  margin-left: 12px;
`

const OwnerTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.NAVY_BLUE};
`

const TaskTitle = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${COLORS.FIVES_GREY};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
`

const Right = styled.div`
  display: flex;
  margin-left: 10px;
`

const TaskIcon = styled.div`
  display: flex;
  align-items: center;
`

const StartAtText = styled.div<{ pastDue: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  color: ${({ pastDue }) => pastDue ? COLORS.RED : 'black'};
`

const StyledDueDateLiteText = styled(DueDateText)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default UpNextCardLite
