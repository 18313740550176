import { useQuery } from "@apollo/react-hooks";
import useUser from "./useUser";
import { GET_USERS } from "graphql/queries";
import { FETCH_POLICIES } from "constants/api";
import { getColorByIndex } from "./useLocations";
import { DEFAULT_CATEGORY_COLORS } from "constants/colors";
import useClientConfig from "./useClientConfig";
import { useMemo } from "react";

const useReportees = (searchTerm?: string) => {
  const { user } = useUser()
  const { categoryColors } = useClientConfig()

  const {
    data: usersData,
    refetch,
    loading,
  } = useQuery<{
    users: { data: User[]; paginationMeta: PaginationMeta }
  }>(GET_USERS, {
    variables: {
      query: searchTerm ? searchTerm : '',
      pagination: { page: 0, perPage: 100 },
      filterModel: {
        items: [
          {
            field: 'TEMPLATE_ROLE_ASSIGNEE.MANAGER',
            operator: 'equals',
            id: parseInt(user?.id),
          },
        ],
        logicOperator: 'and',
      },
    },
    skip: !user,
  })

  const reportees = useMemo(() => {
    let reportees = usersData?.users?.data || []
    reportees = reportees.map((reportee, index) => ({
      ...reportee,
      ...(getColorByIndex(categoryColors || DEFAULT_CATEGORY_COLORS, index)),
    }))
      .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))

    return reportees
  }, [usersData, categoryColors])

  return {
    reportees,
    refetch,
    loading,
  }
}

export default useReportees
