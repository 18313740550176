import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { FETCH_POLICIES } from 'constants/api'
import { GET_ONBOARDING_ANALYTICS_URL } from 'graphql/queries'
import PageFrame from 'components/common/PageFrame'

function ReportsScreen() {
  const { data: onboardingAnalyticsData } = useQuery(
    GET_ONBOARDING_ANALYTICS_URL,
    {
      fetchPolicy: FETCH_POLICIES.NETWORK_ONLY,
    },
  )

  const activityUrl =
    onboardingAnalyticsData?.employeeOnboardingAnalytics?.activityUrl

  return <PageFrame>
    <iframe
      src={activityUrl}
      frameBorder="0"
      width="100%"
      height="100%"
      allowTransparency
    ></iframe>
  </PageFrame>
}

export default ReportsScreen
