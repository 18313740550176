import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const ResourceIcon = (props: SvgProps) => (
  <Svg viewBox="0 0 31 37" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M22.808.324H1.942C.967.324.176 1.086.176 2.026v32.932c0 .94.79 1.702 1.766 1.702h26.56c.975 0 1.766-.762 1.766-1.702V7.513l-7.46-7.19z"
      fill="#BFD9FF"
    />
    <Path
      d="M22.808.324v5.77c0 .784.66 1.42 1.473 1.42h5.987l-7.46-7.19z"
      fill="#6376A7"
    />
    <Path d="M4.965 8.248h6.417v6.184H4.965V8.248z" fill="#243162" />
    <Path d="M4.965 17.025h6.417v6.184H4.965v-6.184z" fill="#50CEA2" />
    <Path d="M4.965 25.802h6.417v6.184H4.965v-6.184z" fill="#FFF4DA" />
    <Path
      d="M13.806 9.063h12.732v1.366H13.806V9.063zM13.806 12.251h6.366v1.365h-6.366v-1.365zM13.806 17.84h12.732v1.365H13.806V17.84zM13.806 21.028h6.366v1.365h-6.366v-1.365zM13.806 26.617h12.732v1.365H13.806v-1.365zM13.806 29.805h6.366v1.365h-6.366v-1.365z"
      fill="#6376A7"
    />
    <Path
      d="m10.668 10.429-.709-.683-2.279 2.197-1.292-1.245-.708.682 1.292 1.245-.005.005.709.682.004-.004.005.004.708-.682-.004-.005 2.279-2.196z"
      fill="#EDEDED"
    />
  </Svg>
)

export default ResourceIcon
