import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/native'
import format from 'utils/date-fns'
import COLORS from 'constants/colors'
import DefaultButton from '../buttons/DefaultButton'

const Score = (
  {
    score,
    totalScore,
    submittedAt,
    onRetake,
  }: {
    score: number
    totalScore: number
    submittedAt: string
    onRetake?: () => void
  }
) => {
  const { t, i18n } = useTranslation()
  const monthDayYear = format(
    new Date(submittedAt),
    t('monthDayYearDateFormat'),
    i18n.resolvedLanguage,
  )

  return <Container>
    <SubmitContainer>
      <Container>
        <Subtitle>Submitted {monthDayYear}</Subtitle>
        <Percentage>{score}/{totalScore} points</Percentage>
      </Container>
      {!!onRetake && <DefaultButton title={"Retake"} onPress={onRetake} />}
    </SubmitContainer>
  </Container>
}

const Container = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const SubmitContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Title = styled.Text`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #5F5F5F;
  margin-bottom: 13.5px;
`

const Subtitle = styled.Text`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #8C8C8C;
`

const Percentage = styled.Text<{ isDone: boolean }>`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.NAVY_BLUE};
`

export default Score