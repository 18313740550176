import React from 'react'

import { useHistory } from 'react-router-native'
import { IS_WEB } from 'constants/static'
import { TEAM_METRICS_URL } from 'constants/env'
import { useHeaderContext } from 'utils/header-context'
import { ROUTE_NAMES } from 'navigation/constants'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { CACHE_KEYS } from 'constants/types'
import { clearErrorData } from 'utils/error-handling'
import { useApolloClient } from '@apollo/react-hooks';
import Sentry from 'utils/sentry'
import { ChevronDown } from 'components/common/custom-icons'
import { Platform } from 'react-native'
import { useTranslation } from 'react-i18next'
import { GET_TEMPLATE_ROLES } from 'graphql/queries'
import { FETCH_POLICIES } from 'constants/api'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { LOGIN_WITH_WORKOS_REDIRECT_URL } from 'graphql/mutations'
import useClientConfig from 'hooks/useClientConfig'
import useCopyByRole from 'components/user-role-assignees/useCopyByRole'
import useUser from 'hooks/useUser'
import { Menu, MenuItem } from '@mui/material'

const GoToAdminButton = ({ email }: { email: string }) => {
  const { t } = useTranslation()
  const [getSSORedirectUrl] = useMutation(LOGIN_WITH_WORKOS_REDIRECT_URL, {
    onError: (err) => {
      console.error(err)
    },
  })

  const handleWorkOSflow = async (variables: { attributes: any }) => {
    const redirectUrlResponse = await getSSORedirectUrl({ variables })

    const workosAuthUrl =
      redirectUrlResponse?.data?.workosRedirectUrl?.redirectUrl

    if (!workosAuthUrl) {
      window.location = TEAM_METRICS_URL as any
      return
    }

    // redirect website tab
    if (Platform.OS === 'web') {
      window.location = workosAuthUrl
    }
  }

  const goToAdmin = async () => {
    const data = {
      attributes: {
        email,
        isMobile: false,
        isAdminApp: true,
      },
    }
    const result = await handleWorkOSflow(data)
  }
  return <MenuItem onClick={goToAdmin}>{t('admin')}</MenuItem>
}

const UserRoleAssigneesButtons = () => {
  const history = useHistory()
  const { copyByRole } = useCopyByRole()
  const { data: templateRolesData } = useQuery<{
    templateRoles: TemplateRole[]
  }>(GET_TEMPLATE_ROLES, {
    variables: { showHidden: true, onlyAssignable: true },
    fetchPolicy: FETCH_POLICIES.CACHE_AND_NETWORK,
  })

  const goToAdminUserRoleAssignees = (roleId: string) => {
    history.push({
      pathname: ROUTE_NAMES.USER_ROLE_ASSIGNEES,
      search: `?roleId=${roleId}`,
    })
  }

  if (!templateRolesData?.templateRoles?.length) {
    return <></>
  }

  return (
    <>
      {templateRolesData.templateRoles.map((templateRole) => (
        <MenuItem
          onClick={() => goToAdminUserRoleAssignees(templateRole.id)}
        >{copyByRole(templateRole.name, 'roleAssignment')}</MenuItem>
      ))}
    </>
  )
}

const OptionsMenu = () => {
  const history = useHistory()
  const { showUserTemplateRoleAssignment } = useClientConfig()
  const client = useApolloClient()
  const { t } = useTranslation()
  const { user } = useUser()

  const isAdmin = user?.isAdmin
  const isManager = user?.isManager
  const email = user?.email

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const logout = async () => {
    await AsyncStorage.removeItem(CACHE_KEYS.TOKEN)

    history.push(ROUTE_NAMES.LOGIN)

    clearErrorData(client)
    client.clearStore()
    Sentry.configureScope((scope: any) => scope.setUser(null))
  }

  return (
    <>
      <div
        data-testid="options-menu"
        onClick={handleClick}
        style={{
          cursor: 'pointer',
        }}
      >
        <ChevronDown />
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {isAdmin && IS_WEB && <GoToAdminButton email={email} />}
        {showUserTemplateRoleAssignment && isManager && IS_WEB && (
          <UserRoleAssigneesButtons />
        )}
        <MenuItem onClick={logout}>{t('logout')}</MenuItem>
      </Menu>
    </>
  )
}

export default OptionsMenu
