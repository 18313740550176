import React from 'react'

import { Button } from '@rneui/themed'
import COLORS from 'constants/colors'
import commonButtonStyle from './commonStyles'
import { useTranslation } from 'react-i18next'

const RemoveButton = ({
  selected = false,
  hover = false,
  onPress,
}: {
  selected?: boolean
  hover?: boolean
  onPress: { (): void }
}) => {
  const { t } = useTranslation()

  return (
    <Button
      buttonStyle={[commonButtonStyle, styles.standard]}
      titleStyle={styles.standardTitle}
      title={'Remove'}
      type="outline"
      titleProps={{
        allowFontScaling: false,
      }}
      onPress={onPress}
    />
  )
}

const styles = {
  standard: {
    borderColor: COLORS.RED,
  },
  standardTitle: {
    color: COLORS.RED,
    fontWeight: '600',
    fontSize: 14,
  },
  icon: {
    marginRight: 6,
  },
}

export default RemoveButton
