import { useQuery } from "@apollo/react-hooks";
import { GET_RESOURCES_HIERARCHY } from "graphql/queries";
import { useMemo } from "react";

const useResources = () => {
  const { data, loading } = useQuery(GET_RESOURCES_HIERARCHY)
  const resourcesHierarchy = data?.resourcesHierarchy || []
  const sections: ResourceSectionInterface[] = useMemo(getResourcesLevelTree, [resourcesHierarchy])
  const favorites: ResourceInterface[] = useMemo(extractFavorites, [resourcesHierarchy])

  function getResourcesLevelTree() {
    let level1Sections = resourcesHierarchy.filter(
      (resourceSection) => resourceSection.children.length > 0,
    )

    level1Sections = level1Sections.map((level1Section) => {
      let level2Sections = level1Section.children.map((level2Section) =>
        resourcesHierarchy.find((resource) => resource.id == level2Section.id),
      )
      level2Sections = level2Sections.filter(
        (level2Section) =>
          !!level2Section && level2Section.resources.length > 0,
      )
      level1Section.children = level2Sections
      return level1Section
    })

    return level1Sections.filter(
      (level1Section) => level1Section.children.length > 0,
    )
  }

  function extractFavorites() {
    const allResources = resourcesHierarchy.map(resourceSection => resourceSection.resources).flat()

    return allResources.filter(resource => resource.favorite) || []
  }

  return { sections, favorites, loading };
};

export default useResources
