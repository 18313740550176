import React from 'react'
import { Text } from 'react-native'
import { captureException } from 'utils/sentry'

type Props = { fallback?: any, children: any }
type State = { hasError: boolean }

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    captureException(error)
    console.error(error)
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback
      }

      return <Text>Loading failed! Please reload.</Text>
    }

    return this.props.children
  }
}

export default ErrorBoundary
