import React from 'react'
import styled from 'styled-components'
import COLORS from 'constants/colors'
import useUser from 'hooks/useUser'
import { useTranslation } from 'react-i18next'
import { Avatar, AvatarGroup } from '@mui/material'
import useReportees from 'hooks/useReportees'

function stringAvatar(name: string, small = false, color?: string) {
  return {
    sx: {
      bgcolor: color || COLORS.TURQUOISE,
      width: small ? '36px' : '144px',
      height: small ? '36px' : '144px',
      fontSize: small ? '14px' : '40px',
      fontWeight: '600',
      border: small ? null : '8px solid white',
      marginRight: small ? '6px' : null,
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const Roles = () => {
  const { user } = useUser()
  const { t } = useTranslation()
  const { reportees } = useReportees()

  return <Container>
    {user?.jobTitle && <RoleRow>
      <RoleColumn>
        <RoleTitle>{t('myRole')}</RoleTitle>
      </RoleColumn>
      <div>
        <RoleValue>{user?.jobTitle}</RoleValue>
      </div>
    </RoleRow>}
    {!user?.isManager && <RoleRow>
      <RoleColumn>
        <RoleTitle>{t('manager')}</RoleTitle>
      </RoleColumn>
      <div>
        <RoleValue>{user?.manager?.fullName ?? 'N/A'}</RoleValue>
      </div>
    </RoleRow>}
    {user?.buddy && <RoleRow>
      <RoleColumn>
        <RoleTitle>{t('buddy')}</RoleTitle>
      </RoleColumn>
      <div>
        <RoleValue>{user?.buddy?.fullName ?? 'N/A'}</RoleValue>
      </div>
    </RoleRow>}
    {user?.isManager && reportees?.length > 0 && <RoleRow>
      <RoleColumn>
        <RoleTitle>{t('myTeam')}</RoleTitle>
      </RoleColumn>
      <div>
        <RoleValue>
          <Row>
            <AvatarGroup max={10}>
              {reportees?.map(reportee => <Avatar  {...stringAvatar(reportee.fullName?.toUpperCase(), true, reportee.color)} src={reportee.avatarUrl} ></Avatar>)}
            </AvatarGroup>
          </Row>
        </RoleValue>
      </div>
    </RoleRow>}
  </Container>
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const RoleRow = styled(Row)`
  align-items: center;
  height: 44px;
`

const RoleColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100px;
  margin-right: 30px;
`

const RoleValue = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${COLORS.FIVES_GREY};
  margin-bottom: 8px;
`

const RoleTitle = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: ${COLORS.SIXES_GREY};
  margin-bottom: 4px;
`

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 12px;
  background: #F5F7FB;
  border-radius: 22px;
  padding: 24px;
`

export default Roles