import React, { useCallback, useEffect, useRef, useState } from 'react'

const VideoSource = ({ src, onEnded, completedEvent, completeAtPercentage }: { src: string, onEnded: () => void, completedEvent: TaskCompleteType, completeAtPercentage: number }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [hasVideoBeenViewed, setHasVideoBeenViewed] = useState(false);
  let supposedCurrentTime = 0;

  const onTimeUpdateListener = useCallback(() => {
    const video = videoRef.current;

    if (!video) return;

    if (!video.seeking) {
      supposedCurrentTime = video.currentTime;

      if (!hasVideoBeenViewed && video.currentTime >= video.duration * (completeAtPercentage / 100)) {
        setHasVideoBeenViewed(true);
        onEnded();
      }
    }
  }, [hasVideoBeenViewed])

  const onSeekingListener = useCallback(() => {
    const video = videoRef.current;

    if (!video) return;

    const delta = video.currentTime - supposedCurrentTime;
    if (delta > 0.01) {
      video.currentTime = supposedCurrentTime;
    }
  }, [supposedCurrentTime])

  const onEndedListener = useCallback(() => {
    // reset state in order to allow for rewind
    supposedCurrentTime = 0;
  }, [])

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      // Remove existing event listeners
      video.removeEventListener('timeupdate', onTimeUpdateListener);
      video.removeEventListener('seeking', onSeekingListener);
      video.removeEventListener('ended', onEndedListener);

      if (!completedEvent) {
        // Add event listeners
        video.addEventListener('timeupdate', onTimeUpdateListener);
        video.addEventListener('seeking', onSeekingListener); // prevent user from seeking forward
        video.addEventListener('ended', onEndedListener);
      }
    }

    // Cleanup function to remove event listeners when the component unmounts or dependencies change
    return () => {
      if (video) {
        video.removeEventListener('timeupdate', onTimeUpdateListener);
        video.removeEventListener('seeking', onSeekingListener);
        video.removeEventListener('ended', onEndedListener);
      }
    };
  }, [completedEvent, videoRef, onTimeUpdateListener, onSeekingListener, onEndedListener])

  return <video id="video" ref={videoRef} controls>
    <source src={src} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
}

export default VideoSource