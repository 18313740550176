import React, { useContext, Dispatch, SetStateAction } from 'react'

export const HeaderContext = React.createContext(
  {} as {
    options: {
      title?: [string]
      headerLeft?: () => JSX.Element
      headerRight?: () => JSX.Element
      showBack?: boolean
      hideHeader?: boolean
    }
    setOptions: Dispatch<
      SetStateAction<{
        title?: [string]
        headerRight?: () => JSX.Element
        headerLeft?: () => JSX.Element
        showBack?: boolean
        hideHeader?: boolean
      }>
    >
  },
)

export function useHeaderContext() {
  return useContext(HeaderContext)
}
