import React from 'react'

import styled from 'styled-components'
import COLORS from 'constants/colors'
import useClientConfig from 'hooks/useClientConfig'
import { Avatar, Popover } from '@mui/material'
import { groupBy } from 'lodash'
import GroupMarkerCard from './GroupMarkerCard'

const LinearProgressGroupBar = ({ markers, color }: { markers: { completedTasksCount: number, tasksCount: number, progress: number, owner: User }[], color?: string }) => {
  const { iconColor } = useClientConfig()

  // groups of 5%
  const groupedMarkers = groupBy(markers, marker => Math.floor(marker.progress / 5) * 5);

  return (
    <StyledLinearProgressBar>
      <svg width="100%" height="12" fill="none">
        <rect width="100%" height="12" rx="8" fill="#F5F7FB" />
        <rect
          width='100%'
          height="12"
          rx="8"
          fill={color || iconColor || COLORS.TURQUOISE}
        />
      </svg>
      {Object.keys(groupedMarkers).map((progress) => {
        const markers = groupedMarkers[progress]

        return <Marker markers={markers} progress={progress} />
      })}
    </StyledLinearProgressBar>
  )
}

const Marker = ({ markers, progress }: { markers: { completedTasksCount: number, tasksCount: number, progress: number, owner: User }[], progress: number }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const MarkerAvatar = () => {
    if (markers.length == 1) {
      const marker = markers[0]
      return <StyledCircleMarker
        src={marker.owner?.avatarUrl}
        sx={{
          bgcolor: marker.owner?.color || COLORS.TURQUOISE,
          width: '24px',
          height: '24px',
          fontSize: '12px',
          fontWeight: '600',
        }}
      >
        {marker.owner?.firstName[0]}{marker.owner?.lastName[0]}
      </StyledCircleMarker>
    }

    return <StyledCircleMarker
      sx={{
        bgcolor: COLORS.GRAYDARK3,
        width: '24px',
        height: '24px',
        fontSize: '12px',
        fontWeight: '600',
      }}
    >
      {markers.length}
    </StyledCircleMarker>
  }


  return <StyledProgressMarker
    onMouseEnter={handlePopoverOpen}
    onMouseLeave={handlePopoverClose}
    percentage={progress}
  >
    <MarkerAvatar />

    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      slotProps={{
        paper: {
          sx: {
            borderRadius: '16px',
          }
        }
      }}
    >
      <GroupMarkerCard markers={markers} />
    </Popover>
  </StyledProgressMarker>
}


const StyledLinearProgressBar = styled.div`
  position: relative;
`

const StyledProgressMarker = styled.div`
  position: absolute;
  left: ${(props: { percentage: number }) =>
    `calc(${props.percentage}% - 12px)`}; // minus half the circle marker width + border size
  top: 0px;
  height: 16px;
  display: flex;
  justify-content: left;
`

const StyledCircleMarker = styled(Avatar)`
  width: 16px;
  height: 16px;
  border: 4px solid white;
  border-radius: 50%;
  margin-top: -8px;
`

export default LinearProgressGroupBar
