import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-native'
import { Avatar } from '@mui/material'
import { useTranslation } from 'react-i18next'

import format from 'utils/date-fns'
import useUser from 'hooks/useUser'
import AboutTheOrg from 'components/resources/components/AboutTheOrg.web'
import PageFrame from 'components/common/PageFrame'
import { ROUTE_NAMES } from 'navigation/constants'
import UpNextContainer from 'components/locations/up-next/UpNextContainer'
import COLORS from 'constants/colors'
import MyTasks from './MyTasks.web'
import FavoriteResources from './FavoriteResources.web'
import MyTeam from './MyTeam.web'
import useLocations from 'hooks/useLocations'
import Roles from './Roles.web'
import WelcomeWizard from 'components/welcome-wizard/WelcomeWizard'

import useScreenSize from 'hooks/useScreenSize'

const HomeScreen = () => {
  const { user } = useUser()
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const dayOfTheWeek = format(new Date(), 'EEEE', i18n.resolvedLanguage)
  const monthDayYear = format(
    new Date(),
    t('monthDayYearDateFormat'),
    i18n.resolvedLanguage,
  )
  const isManager = user?.isManager
  const { myLocations } = useLocations(null, false, {
    enablePagination: true,
  })
  const screenSize = useScreenSize()
  const isSmallScreen = screenSize.width < 1500

  function stringAvatar(name: string, small = false, color?: string) {
    return {
      sx: {
        bgcolor: color || COLORS.TURQUOISE,
        width: small ? '36px' : '144px',
        height: small ? '36px' : '144px',
        fontSize: small ? '14px' : '50px',
        fontWeight: '600',
        border: small ? null : '8px solid white',
        marginRight: small ? '6px' : null,
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  const renderUpNext = useMemo(() => {
    return <UpNextContainer
      showCalendar
      compact
    />
  }, [])

  const TopLeft = () => {
    return <>
      <WelcomeContainer>
        {user?.fullName && <Avatar  {...stringAvatar(user?.fullName, false)} src={user?.avatarUrl}></Avatar>}
        <WelcomeColumn>
          <WelcomeGreeting>{t('welcome', { name: user?.firstName })}</WelcomeGreeting>
          <DateContainer>
            <DayOfTheWeek>{dayOfTheWeek}</DayOfTheWeek>
            <MonthDayYear>{monthDayYear}</MonthDayYear>
          </DateContainer>
        </WelcomeColumn>
      </WelcomeContainer>
      {(!isSmallScreen || isManager) && <RolesContainer><Roles /></RolesContainer>}
    </>
  }

  const TopRight = () => {
    if (isManager) {
      return renderUpNext
    }

    if (isSmallScreen) {
      return <Roles />
    }

    return <QuickLaunchRow>
      <AboutTheOrgWrapper>
        <AboutTheOrg liteVersion />
      </AboutTheOrgWrapper>

      <FavoriteResources />
    </QuickLaunchRow>
  }

  const BottomLeft = () => {
    if (myLocations?.length == 0) return null

    return <LeftColumn>
      <MyTasks />
    </LeftColumn>
  }


  const BottomRight = () => {
    if (isManager) {
      return <MyTeam />
    }

    return <>
      <Link onClick={() => {
        history.push({
          pathname: ROUTE_NAMES.LOCATIONS,
        })
      }}>{t('seeAllTasks')}</Link>
      {renderUpNext}
    </>
  }

  return <PageFrame>
    <TopRow>
      <LeftColumn>
        <TopLeft />
      </LeftColumn>

      <RightColumn>
        <TopRight />
      </RightColumn>

    </TopRow>
    <BottomRow>
      <BottomLeft />

      <RightColumn>
        <BottomRight />
      </RightColumn>
    </BottomRow>
    <WelcomeWizard />
  </PageFrame>
}

const AboutTheOrgWrapper = styled.div`
  display: flex;
  max-width: 372px;
  margin-right: 24px;
`


const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
`

const WelcomeGreeting = styled.div`
  font-size: 54px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  color: ${COLORS.GRAYDARK3};
  white-space: pre-line;
`

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
`

const DayOfTheWeek = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.GRAYDARK3};
`

const MonthDayYear = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${COLORS.GRAYDARK3};
  margin-left: 8px;
`

const Link = styled.div`
  display: flex;
  align-self: end;
  cursor: pointer;
  font-size: 18px;
  color: ${COLORS.NAVY_BLUE};
  margin-bottom: 12px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const TopRow = styled(Row)`
  max-height: 408px;
  margin-bottom: 36px;
`

const BottomRow = styled(Row)`
  align-items: end;
  min-height: 458px;
`

const QuickLaunchRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`

const LeftColumn = styled(Column)`
  margin-right: 48px;
`

const RightColumn = styled(Column)`
  height: 100%;
`

const RolesContainer = styled.div`
  margin-top: -24px;
`

const WelcomeColumn = styled(Column)`
  margin-left: 12px;
  margin-top: -12px;
`

export default HomeScreen