import React, { useMemo } from 'react'
import styled from 'styled-components/native'

import QuestionHeader from './components/QuestionHeader'
import QuestionExplanation from './components/QuestionExplanation'
import QuestionsSubmittedOptions from './components/QuestionSubmittedOptions'
import QuestionContainer from './components/QuestionContainer'
import QuestionSubmittedUpload from './components/QuestionSubmittedUpload'
import QuestionComment from './components/QuestionComment'
import { IS_WEB } from 'constants/static'
import QuestionScore from './components/QuestionScore'
import QuestionSubmittedUpdateEmployee from './components/QuestionSubmittedUpdateEmployee'

const SubmittedQuestion = ({
  question,
  index,
  total,
  survey,
  snippetsMapping,
}: {
  question?: SubmittedQuestionType
  index: number
  total: number
  survey: boolean
  snippetsMapping?: StringMap
}) => {
  const showExplanation = useMemo(() => !survey && question.explanationSnapshot && question.isCorrect, [question])

  return <QuestionContainer>
    <QuestionHeader
      index={index}
      total={total}
      title={question.titleSnapshot}
      description={question.descriptionSnapshot}
      required={question.requiredSnapshot}
      survey={survey}
      snippetsMapping={snippetsMapping}
    />

    <Content>
      {question.quizTypeSnapshot == 'UPDATE_EMPLOYEE' && <QuestionSubmittedUpdateEmployee
        answers={question.answers}
      />}

      {question.quizTypeSnapshot == 'SCORECARD' &&
        <QuestionScore
          answers={question.answers}
        />
      }

      {question.quizTypeSnapshot == 'COMMENT' &&
        <QuestionComment
          comment={question.answers[0]?.comment}
        />
      }

      {question.quizTypeSnapshot == 'UPLOAD' &&
        <QuestionSubmittedUpload
          mediaUrls={question.answers[0]?.mediaUrls}
        />
      }

      {(question.quizTypeSnapshot === 'MULTIPLE_CHOICE' || question.quizTypeSnapshot === 'CHECKBOX') &&
        <QuestionsSubmittedOptions
          answers={question.answers}
          quizType={question.quizTypeSnapshot}
          isCorrect={question.isCorrect}
          survey={survey}
        />
      }

      {showExplanation && <>
        <Divider />
        <QuestionExplanation explanation={question.explanationSnapshot} />
      </>}
    </Content>

  </QuestionContainer>
}

const Content = styled.View`
  display: ${IS_WEB ? 'block' : 'flex'};
`

const Divider = styled.View`
  margin: 22px 0px 22px 0px;
  border: 1px solid #CBD5E1;
`

export default SubmittedQuestion
