import React, { useMemo } from 'react'
import styled from 'styled-components/native'

import COLORS from 'constants/colors'
import Results from './Results'
import SubmittedQuestion from './question/SubmittedQuestion'
import Score from './Score'

const SubmittedQuestions = ({
  lastSubmission,
  survey = false,
  scorecard = false,
  setState,
  snippetsMapping,
}: {
  lastSubmission: QuestionSubmissionType
  survey?: boolean
  scorecard?: boolean
  setState?: (state: "NEW_QUIZ" | "SUBMITTED") => void
  snippetsMapping?: StringMap
}) => {
  const name = useMemo(() => {
    if (scorecard) {
      return 'Scorecard'
    } else if (survey) {
      return 'Survey'
    } else {
      return 'Quiz'
    }
  }, [scorecard, survey])
  const onRetakeQuiz = !!setState ? () => setState('NEW_QUIZ') : null
  const hasRequiredQuestions = lastSubmission.questions.some(question => question.requiredSnapshot)

  return <>
    <Title>{name} results</Title>
    {scorecard && <Score
      totalScore={lastSubmission?.totalScore}
      score={lastSubmission?.score}
      submittedAt={lastSubmission?.updatedAt}
      onRetake={onRetakeQuiz}
    />}
    {!scorecard && <Results
      percentage={lastSubmission?.percentage}
      submittedAt={lastSubmission?.updatedAt}
      onRetakeQuiz={onRetakeQuiz}
      hidePercentage={!hasRequiredQuestions}
      survey={survey}
    />}
    {lastSubmission?.questions.map((question, index) =>
      <SubmittedQuestion
        key={question.id}
        index={index + 1}
        total={lastSubmission.questions.length}
        question={question}
        survey={survey}
        snippetsMapping={snippetsMapping}
      />)}
  </>
}

const Title = styled.Text`
  font-size: 21px;
  font-weight: 700;
  line-height: 32px;
  color: ${COLORS.NAVY_BLUE}
`

export default SubmittedQuestions