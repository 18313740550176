import React, { useEffect, useState } from 'react'
import useUser from 'hooks/useUser'
import useClientConfig from 'hooks/useClientConfig'
import EmployeeWelcomeWizardModal from './EmployeeWelcomeWizardModal.web'
import ManagerWelcomeWizardModal from './ManagerWelcomeWizardModal.web'

const WelcomeWizard = () => {
  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false)
  const [isManagerModalOpen, setIsManagerModalOpen] = useState(false)
  const { enableNewHome, enableWelcomeModal } = useClientConfig()
  const { user } = useUser()

  useEffect(() => {
    if (enableNewHome && enableWelcomeModal) {
      if (user && !user.preferredLocale) {
        if (user.isManager) {
          setIsManagerModalOpen(true)
        } else {
          setIsEmployeeModalOpen(true)
        }
      }
    }
  }, [user])

  return <>
    <EmployeeWelcomeWizardModal isOpen={isEmployeeModalOpen} onDone={() => setIsEmployeeModalOpen(false)} />
    <ManagerWelcomeWizardModal isOpen={isManagerModalOpen} onDone={() => setIsManagerModalOpen(false)} />
  </>

}

export default WelcomeWizard
