import React from 'react'
import { Avatar } from '@mui/material'
import styled from 'styled-components'

import COLORS from 'constants/colors'

const GroupMarkerCard = ({ markers }: { markers: { completedTasksCount: number, tasksCount: number, progress: number, owner: User }[] }) => {
  return <GroupMarkerContainer>
    <Column>
      {markers.map(marker => <MarkerRow>
        <MarkerUserNameRow>
          <Avatar
            src={marker.owner?.avatarUrl}
            sx={{
              bgcolor: marker.owner.color || COLORS.TURQUOISE,
              width: '24px',
              height: '24px',
              fontSize: '12px',
              fontWeight: '600',
            }}
          >
            {marker.owner.firstName[0]}{marker.owner.lastName[0]}
          </Avatar>
          <MarkerUserName>{marker.owner.firstName} {marker.owner.lastName}</MarkerUserName>
        </MarkerUserNameRow>
      </MarkerRow>)}
    </Column>

    <Column>
      {markers.map(marker => <MarkerRow>
        <MarkerCount>{marker.completedTasksCount}/{marker.tasksCount}</MarkerCount>
        <MarkerProgress>{marker.progress}%</MarkerProgress>
      </MarkerRow>)}
    </Column>

  </GroupMarkerContainer>
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const MarkerUserNameRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 24px;
`

const MarkerUserName = styled.div`
  margin-left: 8px;
`

const MarkerCount = styled.div`
  margin-right: 12px;
`

const MarkerProgress = styled.div`
  font-weight: 600;
`

const MarkerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  color: ${COLORS.NAVY_BLUE};
  font-size: 16px;
  font-weight: 300;
`

const GroupMarkerContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px 24px 12px 24px;
  font-family: Poppins_400Regular;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  color: ${COLORS.NAVY_BLUE};
`

export default GroupMarkerCard