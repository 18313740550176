import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { FETCH_POLICIES } from 'constants/api'
import COLORS from 'constants/colors'
import { GET_ACTIVE_ASSIGNMENTS, GET_USERS_WITH_ROLE_ASSIGNMENTS } from 'graphql/queries'
import React, { useEffect, useMemo, useState } from 'react'
import { ActivityIndicator, View } from 'react-native'
import { Text } from '@rneui/themed'

import { DataTable } from 'react-native-paper'

import EStyleSheet from 'react-native-extended-stylesheet'
import UserTemplateRoleAssigneeModal from './UserTemplateRoleAssigneeModal.web'
import RemoteAssignmentModal from './RemoteAssignmentModal.web'
import { useTranslation } from 'react-i18next'
import useCopyByRole from './useCopyByRole'
import useUser from 'hooks/useUser'


const UserRoleAssigneesScreen = ({
  selectedTemplateRole,
  query,
  templateRoleCopiesMap,
  filterByHasRoleAssignments = false,
}: {
  selectedTemplateRole: TemplateRole | null
  query: string,
  templateRoleCopiesMap: StringMap,
  filterByHasRoleAssignments?: boolean
}) => {
  const [page, setPage] = React.useState<number>(0)
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [selectedAssignmentId, setSelectedAssignmentId] = useState<
    string | null
  >(null)
  const [selectedDeleteUser, setSelectedDeleteUser] = useState<User | null>(
    null,
  )
  const { user } = useUser()
  const { t } = useTranslation()
  const { copyByRole } = useCopyByRole()

  const [fetchActiveAssignments, { data: activeAssignmentsData }] = useLazyQuery(GET_ACTIVE_ASSIGNMENTS)

  const {
    data: usersData,
    refetch,
    loading,
  } = useQuery<{
    users: { data: User[]; paginationMeta: PaginationMeta }
  }>(GET_USERS_WITH_ROLE_ASSIGNMENTS, {
    fetchPolicy: FETCH_POLICIES.NETWORK_ONLY,
    variables: {
      query,
      pagination: { page: page + 1, perPage: 50 },
      filterModel: {
        items: [
          {
            field: 'TEMPLATE_ROLE_ASSIGNEE.MANAGER',
            operator: 'equals',
            id: parseInt(user?.id),
          },
          ...(filterByHasRoleAssignments
            ? [{
              field: 'has_role_assignments',
              operator: 'equals',
              id: parseInt(selectedTemplateRole?.id),
            }]
            : [])
        ],
        logicOperator: 'and',
      },
    },
    skip: !user || selectedTemplateRole == null,
  })

  const activeAssignments = useMemo(() => {
    if (!activeAssignmentsData) {
      return {}
    }

    return activeAssignmentsData.activeAssignments
  }, [activeAssignmentsData])

  const getCurrentActiveAssignmentsCount = (user: User) => {
    if (!activeAssignments || !user) {
      return 0
    }

    return activeAssignments[user.id] || 0
  }

  const users = useMemo<
    { user: User; assignee: User; count: number; assignmentId: string }[]
  >(() => {
    if (!usersData?.users?.data) {
      return []
    }

    return usersData?.users?.data
      .filter((user) => !user.isTerminated)
      .map((user) => {
        const assignment =
          user?.userTemplateRolesAssignees.find(
            ({ templateRole }) => templateRole.id == selectedTemplateRole?.id,
          ) || null
        const assignee = assignment?.assignee

        return {
          user,
          assignee: assignee,
          assignmentId: assignment?.id,
          count: getCurrentActiveAssignmentsCount(assignee),
        }
      })
  }, [usersData, selectedTemplateRole, activeAssignments])

  useEffect(() => {
    if (selectedTemplateRole && users.length > 0) {
      fetchActiveAssignments({
        variables: {
          roleId: selectedTemplateRole.id,
          userIds: users.filter((user) => !!user.assignee).map(({ assignee }) => assignee.id),
        },
      })
    }
  }, [users, selectedTemplateRole])



  const pageCount = useMemo<number>(() => {
    if (!usersData?.users?.data) {
      return 0
    }

    return usersData?.users?.paginationMeta.totalPages || 0
  }, [usersData])


  return (
    <View>
      <ActivityIndicator animating={loading} />
      <DataTable>
        <DataTable.Header style={styles.tableHeader}>
          <DataTable.Title style={styles.tableHeaderCell}>
            <Text style={styles.tableHeaderCell}>{t('newHire')}</Text>
          </DataTable.Title>
          <DataTable.Title>
            <Text style={styles.tableHeaderCell}>
              {selectedTemplateRole?.name &&
                copyByRole(selectedTemplateRole.name, 'assigned')}
            </Text>
          </DataTable.Title>
          <DataTable.Title>
            <Text style={styles.tableHeaderCell}>{t('activeAssignments')}</Text>
          </DataTable.Title>
          <DataTable.Title>
            <Text style={styles.tableHeaderCell}>{t('actions')}</Text>
          </DataTable.Title>
        </DataTable.Header>
        {users.map(({ user, assignee, count, assignmentId }, index) => (
          <DataTable.Row
            pointerEvents='auto'
            style={
              index % 2 == 1
                ? [styles.tableRow, styles.tableRowColored]
                : [styles.tableRow]
            }
          >
            <DataTable.Cell style={styles.tableCell}>
              {user.fullName?.trim().length ? user.fullName : user.email}
            </DataTable.Cell>
            <DataTable.Cell style={styles.tableCell} onPress={() => {
              setSelectedUser(user)
            }}>
              {!!assignee ? (
                <Text style={styles.text}>
                  {assignee.fullName?.trim().length
                    ? assignee.fullName
                    : assignee.email}
                </Text>
              ) : (
                <Text style={styles.text} >
                  + {t('add')}
                </Text>
              )}
            </DataTable.Cell>
            <DataTable.Cell style={styles.tableCell}>{count}</DataTable.Cell>
            <DataTable.Cell style={styles.tableCell} onPress={() => {
              setSelectedAssignmentId(assignmentId)
              setSelectedDeleteUser(user)
            }}>
              {!assignee ? (
                ''
              ) : (
                <Text
                  style={styles.text}
                >
                  {t('remove')}
                </Text>
              )}
            </DataTable.Cell>
          </DataTable.Row>
        ))}

        <DataTable.Pagination
          page={page}
          numberOfPages={pageCount}
          onPageChange={(page) => setPage(page)}
          selectPageDropdownLabel={'Rows per page'}
          showFastPaginationControls
          label={`1-${users.length} of ${usersData?.users?.paginationMeta.totalCount}`}
        />
      </DataTable>
      {!!selectedUser && (
        <UserTemplateRoleAssigneeModal
          openModal={!!selectedUser}
          title={copyByRole(selectedTemplateRole.name, 'assignRole')}
          selectedUser={selectedUser}
          templateRoleId={selectedTemplateRole?.id}
          templateRoleName={selectedTemplateRole?.name}
          currentUser={user}
          templateRoleCopiesMap={templateRoleCopiesMap}
          onClose={(refetchUsers?: boolean) => {
            setSelectedUser(null)

            if (refetchUsers) {
              refetch()
            }
          }}
        />
      )}
      {!!selectedDeleteUser && (
        <RemoteAssignmentModal
          isVisible={!!selectedDeleteUser}
          onClose={(refetchUsers?: boolean) => {
            setSelectedDeleteUser(null)

            if (refetchUsers) {
              refetch()
            }
          }}
          assignmentId={selectedAssignmentId}
          roleName={selectedTemplateRole?.name}
          userFullName={selectedDeleteUser?.fullName}
        />
      )}
    </View>
  )
}

const styles = EStyleSheet.create({
  text: {
    color: COLORS.TURQUOISE,
  },

  tableHeader: {
    fontSize: 12,
    textTransform: 'uppercase',
    fontFamily: 'Poppins_600SemiBold',
    fontWeight: '600',
  },
  tableRow: {
    border: 'none',
  },
  tableRowColored: {
    backgroundColor: '#F9F9F9',
  },
  tableCell: {
    fontColor: '#000',
    cursor: 'pointer !important',
  },
  tableHeaderCell: {
    fontSize: 12,
    fontFamily: 'Poppins_600SemiBold',
    fontWeight: '600',
  },
})

export default UserRoleAssigneesScreen
