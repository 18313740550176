import React from 'react'
import { TouchableOpacity, View, Text } from 'react-native'
import Modal from 'react-native-modal'
import { Icon } from 'components/Index'
import { ICON_NAMES } from 'constants/icons'
import { stylesGlobal } from 'constants/es-style'
import EStyleSheet from 'react-native-extended-stylesheet'
import styled from 'styled-components'
import RemoveButton from 'components/task-list/buttons/RemoveButton'
import { useMutation } from '@apollo/react-hooks'
import { DELETE_USER_TEMPLATE_ROLE_ASSIGNEE } from 'graphql/mutations'
import { Loader } from 'components/common/Loader'
import { useTranslation } from 'react-i18next'
import useCopyByRole from 'components/user-role-assignees/useCopyByRole'

const LoadingOverlay = () => {
  return (
    <View
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 100,
        backgroundColor: 'rgba(0,0,0,0.1)',
      }}
    >
      <Loader></Loader>
    </View>
  )
}
function RemoteAssignmentModal({
  isVisible,
  onClose,
  roleName,
  userFullName,
  assignmentId,
}: {
  isVisible: boolean
  onClose: (refetch?: boolean) => void
  roleName: string
  userFullName: string
  assignmentId: string
}) {
  const { t } = useTranslation()
  const { copyByRole } = useCopyByRole()
  const [deleteUserTemplateRoleAssignee, { loading }] = useMutation(
    DELETE_USER_TEMPLATE_ROLE_ASSIGNEE,
    {
      onCompleted: () => {
        onClose(true)
      },
    },
  )

  const onDelete = () => {
    deleteUserTemplateRoleAssignee({
      variables: { attributes: { id: assignmentId } },
    })
  }

  return (
    <Modal isVisible={isVisible} style={styles.modal}>
      <View style={[stylesGlobal.modalContent, styles.container]}>
        {loading && <LoadingOverlay></LoadingOverlay>}
        <View style={styles.headerContainer}>
          <Text
            style={stylesGlobal.modalContentHeaderText}
            numberOfLines={3}
            ellipsizeMode="tail"
          >
            {t('remove')} {copyByRole(roleName, 'roleName')}?
          </Text>
          <View style={styles.closeButtonContainer}>
            <TouchableOpacity onPress={() => onClose()}>
              <Icon name={ICON_NAMES.CLOSE} size={28} />
            </TouchableOpacity>
          </View>
        </View>
        <View>
          <Text style={styles.infoMessage}>
            {copyByRole(roleName, 'removeUser', { userName: userFullName })}
          </Text>
        </View>
        <View>
          <ButtonsWrap>
            <RemoveButton onPress={() => onDelete()}></RemoveButton>
          </ButtonsWrap>
        </View>
      </View>
    </Modal>
  )
}
const ButtonsWrap = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  margin-top: 20px;
`

const styles = EStyleSheet.create({
  modal: {
    width: '100%',
    maxWidth: 600,
    alignSelf: 'center',
  },
  button: {
    paddingHorizontal: 24,
    paddingVertical: 6,
  },
  container: {
    padding: 46,
    backgroundColor: 'white',
    maxHeight: '100%',
    fontFamily: 'Poppins_400Regular',
    maxWidth: 600,
  },
  headerContainer: {
    flexDirection: 'row',
    alignContent: 'space-between',
    paddingLeft: 8,
    width: '100%',
  },
  infoMessage: {
    paddingTop: 40,
    paddingBottom: 40,
    textAlign: 'left',
    fontWeight: 500,
  },
  closeButtonContainer: {
    flex: 1,
    alignItems: 'end',
  },
})

export default RemoteAssignmentModal
