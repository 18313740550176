import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translations from 'i18n/languages'
import * as Localization from 'expo-localization'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { CACHE_KEYS } from 'constants/types'

i18n.use(initReactI18next).init({
  resources: {
    'en': {
      translation: translations['en-US'],
    },
    'en-US': {
      translation: translations['en-US'],
    },
    'fr-CA': {
      translation: translations['fr-CA'],
    },
    fr: {
      translation: translations['fr'],
    },
    'pt': {
      translation: translations['pt-BR'],
    },
    'pt-BR': {
      translation: translations['pt-BR'],
    },
    'es': {
      translation: translations['es-ES'],
    },
    'es-ES': {
      translation: translations['es-ES'],
    },
    'de': {
      translation: translations['de-DE'],
    },
    'de-DE': {
      translation: translations['de-DE'],
    },
    'ja': {
      translation: translations['ja-JP'],
    },
    'ja-JP': {
      translation: translations['ja-JP'],
    },
    'zh-CN': {
      translation: translations['zh-CN'],
    },
    'zh-TW': {
      translation: translations['zh-TW'],
    },
  },
  lng: Localization.locale,
  fallbackLng: 'en-US',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
})

AsyncStorage.getItem(CACHE_KEYS.SELECTED_LANGUAGE).then((language) => {
  if (!!language) {
    i18n.changeLanguage(language)
  }
})

export default i18n
