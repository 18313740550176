import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Modal } from '@mui/material'
import useLocations from 'hooks/useLocations'
import SearchModalHeader from './components/SearchModalHeader'
import SearchModalLocationResult from './components/SearchModalLocationResult'
import { useLazyQuery } from '@apollo/react-hooks'
import { USER_TASKS } from 'graphql/queries'
import { debounce } from 'lodash'
import SearchModalTasksResult from './components/SearchModalTasksResult'
import { useAppContext } from 'utils/app-context'
import useUser from 'hooks/useUser'
import useResources from 'hooks/useResources'
import SearchModalResourcesResult from './components/SearchModalResourcesResult'
import { useTranslation } from 'react-i18next'
import useContentPages from 'hooks/useContentPages'

export type Show = 'workflows' | 'sections' | 'tasks' | 'resources' | null

const contains = (title: string, searchTerm: string) => title.toLowerCase().indexOf(searchTerm) != -1 ? true : false

const SearchModal = ({ onClose }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, _setDebouncedSearchTerm] = useState('')
  const { user } = useUser()
  const { locations: rawLocations, loading } = useLocations(null, false, {
    enablePagination: true,
    searchTerm: debouncedSearchTerm,
    skip: searchTerm.length == 0,
  })
  const { t } = useTranslation()
  const { sections: resourcesHierarchy } = useResources()
  const { selectedDay } = useAppContext()
  const [fetchTasks, { data: tasksData, loading: tasksLoading, refetch: refetchTasks }] = useLazyQuery(USER_TASKS, {
    notifyOnNetworkStatusChange: true
  })
  const { contentPages } = useContentPages()

  const [show, setShow] = useState<Show>(null)
  const [includeCompleted, setIncludeCompleted] = useState(false)

  const root = useMemo(() => rawLocations.find(location => location.title == '__root__'), [rawLocations])
  const locations = useMemo(() => rawLocations.filter(location => !(!includeCompleted && location.isComplete)), [rawLocations, includeCompleted])
  const workflowsIds = useMemo(() => root?.children.map(child => child.id) || [], [root])
  const workflows = useMemo(() => {
    return locations.filter(location => {
      if (!includeCompleted && location.isComplete) return false

      return workflowsIds.includes(location.id)
    })
  }, [locations])

  const sections = useMemo(() => {
    return locations
      .filter(location => {
        if (!includeCompleted && location.isComplete) return false

        return !workflowsIds.includes(location.id) && location.title != '__root__'
      })
      .map(section => {
        const parent = rawLocations.find(location => location.id == section.parentId)

        let title = ''
        if (parent.owner && user?.id !== parent.owner.id) {
          title += `${parent.owner.fullName} > `
        } else {
          title += `${parent?.title} > `
        }
        title += section.title

        return {
          ...section,
          title
        }
      })
  }, [locations])

  const tasks = useMemo(() => {
    const tasks = tasksData?.userTasks || []

    if (!includeCompleted) {
      return tasks.filter(task => !task.taskCompleted)
    }

    return tasks
  }, [tasksData, includeCompleted])

  const resources = useMemo(() => {
    let newChildren: ResourceInterface[] = []

    resourcesHierarchy.forEach(resourceHierarchy => {
      resourceHierarchy.children.forEach(child => {
        child.resources.forEach(resource => {
          newChildren.push({
            ...resource,
            title: `${resourceHierarchy.title} > ${child.title} > ${resource.title}`,
          })
        })
      })
    })

    return newChildren
  }, [resourcesHierarchy])


  const filteredWorkflows = useMemo(() => searchTerm.length > 0 ? workflows.filter(workflow => contains(workflow.title, searchTerm)) : [], [workflows, searchTerm])
  const filteredSections = useMemo(() => searchTerm.length > 0 ? sections.filter(section => contains(section.title, searchTerm)) : [], [sections, searchTerm])
  const filteredTasks = useMemo(() => searchTerm.length > 0 ? tasks : [], [tasks, searchTerm])
  const filteredResources = useMemo(() => searchTerm.length > 0 ? resources.filter(resource => contains(resource.title, searchTerm)) : [], [resources, searchTerm])
  const filteredContentPages = useMemo(() => searchTerm.length > 0 ? contentPages.filter(contentPage => contains(contentPage.title, searchTerm)) : [], [contentPages, searchTerm])

  const searchTasks = (searchTerm: string) => {
    fetchTasks({
      variables: {
        startDate: selectedDay,
        searchTerm
      },
    })
  }

  const debouncedSearchTasks = useCallback(debounce(searchTasks, 500, { trailing: true }), [])

  const debouncedSetSearchTerm = useCallback(debounce(_setDebouncedSearchTerm, 500, { trailing: true }), [])

  useEffect(() => {
    if (searchTerm.length > 0 && (show == null || show == 'tasks')) {
      debouncedSearchTasks(searchTerm)
      debouncedSetSearchTerm(searchTerm)
    }
  }, [searchTerm])

  return <Modal open={true} onClose={onClose}>
    <ModalBody>
      <SearchModalHeader
        searchTerm={searchTerm} setSearchTerm={setSearchTerm}
        show={show} setShow={setShow}
        includeCompleted={includeCompleted} setIncludeCompleted={setIncludeCompleted}
        onClose={onClose}
      />

      {loading && <div>{t('loading')}</div>}

      {!loading && <ModalContent>
        {(show == null || show == 'workflows') &&
          <SearchModalLocationResult type="workflows" show={show} setShow={setShow} locations={filteredWorkflows} onClose={onClose} />
        }

        {(show == null || show == 'sections') &&
          <SearchModalLocationResult type="sections" show={show} setShow={setShow} locations={filteredSections} onClose={onClose} />
        }

        {(show == null || show == 'resources') &&
          <SearchModalResourcesResult type="resources" show={show} setShow={setShow} resources={filteredResources} contentPages={filteredContentPages} />
        }

        {(show == null || show == 'tasks') &&
          <SearchModalTasksResult type="tasks" show={show} setShow={setShow} tasks={filteredTasks} loading={tasksLoading} onClose={onClose} refetch={refetchTasks} />
        }
      </ModalContent>}
    </ModalBody>
  </Modal>
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 1000px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  padding 25px 36px 25px 36px;
  border-radius: 36px;
  font-family: 'Poppins_400Regular';
  overflow: hidden;
  height: calc(100vh - 140px);
`

export default SearchModal
