import React from 'react'

import styled from 'styled-components'
import COLORS from 'constants/colors'
import useClientConfig from 'hooks/useClientConfig'
import { Tooltip } from '@mui/material'

const LinearProgressBar = ({ percentage = 0, color, height }: { percentage: number, color?: string, height?: string }) => {
  const { iconColor } = useClientConfig()

  return (
    <StyledLinearProgressBar>
      <svg width="100%" height={height || "16"} fill="none">
        <rect width="100%" height={height || "16"} rx={(parseInt(height) || 16) / 2} fill="#F5F7FB" />
        <rect
          width={`${percentage}%`}
          height={height || "16"}
          rx={(parseInt(height) || 16) / 2}
          fill={color || iconColor || COLORS.TURQUOISE}
        />
      </svg>
      <Tooltip title={`${percentage}%`} placement="left">
        <StyledProgressMarker percentage={percentage}>
          <StyledCircleMarker
            markerColor={color || iconColor || COLORS.TURQUOISE}
          />
        </StyledProgressMarker>
      </Tooltip>
    </StyledLinearProgressBar>
  )
}

const StyledLinearProgressBar = styled.div`
  position: relative;
`

const StyledProgressMarker = styled.div`
  position: absolute;
  left: ${(props: { percentage: number }) =>
    `calc(${props.percentage}% - 12px)`}; // minus half the circle marker width + border size
  top: 0px;
  height: 16px;
  display: flex;
  justify-content: left;
`

const StyledCircleMarker = styled.div`
  width: 16px;
  height: 16px;
  border: 4px solid white;
  border-radius: 50%;
  background-color: ${(props: { markerColor: string }) =>
    `${props.markerColor}`};
  margin-top: -2px; // minus half the border, to align center of circle with progress bar
`

export default LinearProgressBar
