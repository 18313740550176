import React from 'react'
import styled from 'styled-components/native'
import { sortBy } from 'lodash'
import Level2ResourcesSection from './Level2ResourcesSection'
import COLORS from 'constants/colors'
import { View } from 'react-native'
import { IS_WEB } from 'constants/static'

const Level1ResourcesSection = ({
  children,
  title,
}: {
  children: [ResourceSectionInterface]
  title: string
}) => {
  return (
    <View>
      <Title>{title}</Title>
      <Body>
        {sortBy(children, ['position']).map((child) => (
          <Level2ResourcesSection
            key={child.id}
            resources={child.resources}
            title={child.title}
          />
        ))}
      </Body>
    </View>
  )
}

const Title = styled.Text`
  font-size: 24px;
  font-weight: 300;
  color: ${COLORS.GRAYDARK3};
  margin: 24px 0px 24px 0px;
`

const Body = styled.View`
  display: flex;
  ${IS_WEB ? 'flex-direction: row' : 'flex-direction: column'};
  flex-wrap: wrap;
  column-gap: 33.66px;
  row-gap: 24px;
`

export default Level1ResourcesSection
