import React from 'react'
import styled from 'styled-components'
import ManagerLocation from 'components/locations/ManagerLocation'
import { Show } from '../SearchModal'
import SearchModalResultHeader from './SearchModalResultHeader'

const SearchModalLocationResult = ({
  type,
  show,
  setShow,
  locations,
  onClose,
}: {
  type: Show,
  show: Show,
  setShow: (value: Show) => void
  locations: LocationInterface[]
  onClose: () => void
}) => {
  return <Results>
    <SearchModalResultHeader
      type={type} show={show} length={locations.length} onClick={() => setShow(type)}
    />
    <ResultsContent>
      {locations
        .slice(0, show == null ? 3 : locations.length)
        .map(workflow =>
          <ManagerLocation key={workflow.id} location={workflow} onLocationChange={onClose} />
        )}
    </ResultsContent>
  </Results>
}

const Results = styled.div`
  margin-bottom: 30px;
  margin-right: 10px;
`

const ResultsContent = styled.div`
  display: flex;
  flex-direction: column;
`

export default SearchModalLocationResult