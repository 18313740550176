import { useEffect } from 'react'
import {
  makeRedirectUri,
  useAuthRequest,
  DiscoveryDocument,
} from 'expo-auth-session'
import { useMutation } from '@apollo/react-hooks'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useHistory } from 'react-router-native'
import { getOktaToken } from 'utils/requests'
import { LOGIN_WITH_OKTA_TOKEN } from 'graphql/mutations'
import { CACHE_KEYS } from 'constants/types'
import { IS_WEB } from 'constants/static'
import { ROUTE_NAMES } from 'navigation/constants'


interface OktaLoginHookProps {
  clientId: string
  email?: string
  setLoginError: DispatchType<string>
  setTriggerOktaLogin: DispatchType<boolean>
  discovery: DiscoveryDocument
  triggerOktaLogin: boolean
}

const useOktaLogin = ({
  clientId,
  email,
  setLoginError,
  discovery,
  triggerOktaLogin,
  setTriggerOktaLogin,
}: OktaLoginHookProps) => {
  const history = useHistory()

  const redirectUri = makeRedirectUri({
    native: 'teamapp://LOGIN'
  })

  const [authRequest, authRequestResponse, promptAsync] = useAuthRequest(
    {
      clientId,
      scopes: ['openid', 'profile', 'email'],
      redirectUri,
      state: email,
    },
    discovery,
  )

  const [oktaTokenLogin] = useMutation(LOGIN_WITH_OKTA_TOKEN, {
    onCompleted: (data) => {
      const { authenticationToken } = data.oktaTokenLogin
      checkTokenAndNavigate(authenticationToken)
    },
    onError: (err) => {
      if (err.graphQLErrors[0]?.message) {
        setLoginError(err.graphQLErrors[0].message)
      }
    },
  })

  const checkTokenAndNavigate = async (token: string) => {
    if (!!token) {
      await AsyncStorage.setItem(CACHE_KEYS.TOKEN, token)
      history.push(ROUTE_NAMES.HOME)
    } else {
      setLoginError('Invalid credentials')
    }
  }

  const exchangeCodeForToken = async ({ code, codeVerifier, requestUrl, redirectUri, clientId, email }: { code: string, codeVerifier: string | undefined | null, redirectUri: string, requestUrl: string, clientId: string, email: string }) => {
    const tokenResponseData = await getOktaToken({
      requestUrl,
      redirectUri,
      code,
      codeVerifier,
      clientId,
    })

    if (tokenResponseData.error) {
      setLoginError('Okta error: ' + tokenResponseData.error_description)
      return
    }
    const oktaAccessToken = tokenResponseData?.access_token
    const oktaIdToken = tokenResponseData?.id_token

    // login user internaly via OKTA token
    oktaTokenLogin({
      variables: {
        attributes: {
          accessToken: oktaAccessToken,
          email,
          idToken: oktaIdToken,
        },
      },
    })
  }

  useEffect(() => {
    if (authRequestResponse?.type === 'success') {
      const { code } = authRequestResponse.params
      const { codeVerifier, redirectUri } = authRequest!!

      exchangeCodeForToken(
        {
          code,
          codeVerifier,
          redirectUri,
          clientId,
          requestUrl: discovery.tokenEndpoint!!,
          email
        })
    } else if (authRequestResponse?.type === 'error') {
      setLoginError('Okta error: ' + authRequestResponse?.error?.description)
    }
  }, [authRequestResponse, email])

  useEffect(() => {
    if (
      !authRequest ||
      !triggerOktaLogin
    ) {
      return
    }

    setTriggerOktaLogin(false)
    if (IS_WEB) {
      AsyncStorage.setItem(CACHE_KEYS.SSO_CODE_VERIFIER, authRequest?.codeVerifier || '')
      window.location = authRequest.url as any
    } else {
      promptAsync()
    }

  }, [authRequest, promptAsync, triggerOktaLogin])

  return { exchangeCodeForToken }
}

export default useOktaLogin
