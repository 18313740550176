import * as Updates from 'expo-updates';
import { Platform } from 'react-native'
import { captureException } from 'utils/sentry'

const useExpoUpdates = () => {
    const onFetchUpdateAsync = async () => {
        if (Platform.OS === 'web') {
            return
        }

        try {
            const update = await Updates.checkForUpdateAsync();
            if (update.isAvailable) {
                await Updates.fetchUpdateAsync();
                await Updates.reloadAsync();
            }
        } catch (error) {
            captureException(`Error fetching latest Expo update: ${error}`);
        }
    }

    return {
        onFetchUpdateAsync: onFetchUpdateAsync
    }
}

export default useExpoUpdates;