import React from 'react'

import styled from 'styled-components'
import { WelcomeIcon } from 'components/common/custom-icons'
import format from 'utils/date-fns'
import COLORS from 'constants/colors'
import { useTranslation } from 'react-i18next'
import useUser from 'hooks/useUser'
import useClientConfig from 'hooks/useClientConfig'

const WelcomeMessage = () => {
  const { user } = useUser()
  const { welcomeIconUrl, fontColor, primaryColor } = useClientConfig()
  const { t, i18n } = useTranslation()
  const dayOfTheWeek = format(new Date(), 'EEEE', i18n.resolvedLanguage)
  const monthDayYear = format(
    new Date(),
    t('monthDayYearDateFormat'),
    i18n.resolvedLanguage,
  )


  function renderIcon() {
    if (welcomeIconUrl) {
      return <img src={welcomeIconUrl}></img>
    }

    return <WelcomeIcon />
  }

  return (
    <StyledWelcome
      fontColor={fontColor}
      backgroundColor={primaryColor}
    >
      <div className="welcome">
        <div className="welcome-left">
          <div className="welcome-date">
            <span className="day-of-the-week">{dayOfTheWeek}</span>
            <span className="month-day-year">{monthDayYear}</span>
          </div>
          <div className="welcome-greeting">
            {t('welcome', { name: user?.firstName })}
          </div>
        </div>
        <div className="welcome-right">{renderIcon()}</div>
      </div>
    </StyledWelcome>
  )
}

const StyledWelcome = styled.div<{
  fontColor?: string
  backgroundColor?: string
}>`
  display: flex;
  flex-direction: column;
  color: ${({ fontColor }) => fontColor ?? 'white'};
  margin-bottom: 25px;
  .welcome {
    display: flex;
    flex-direction: row;
    height: fit-content;
    justify-content: space-between;
    background-color: ${({ backgroundColor }) =>
    backgroundColor ?? COLORS.NAVY_BLUE};
    border-radius: 32px;
    padding: 36px;
  }
  .welcome-right {
    img {
      width: 144px;
      height: 144px;
    }
  }
  .welcome-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 24px;
    & > div {
      font-family: 'Poppins_500Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      padding: 0px 16px;
      color: #243162;
      cursor: pointer;
    }
  }
  .welcome-date {
    font-size: 18px;
    line-height: 27px;
    margin: 4px;
    .day-of-the-week {
      font-weight: 600;
      margin-right: 8px;
    }
    .month-day-year {
      font-weight: 300;
    }
  }
  .welcome-greeting {
    font-weight: 800;
    font-size: 44px;
    line-height: 54px;
    white-space: pre-line;
  }
`

export default WelcomeMessage