import React, { useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

import MultipleOptionsModal from 'components/task-list/MultipleOptionsModal'
import { STYLE_CONSTANTS } from 'constants/es-style'
import MESSAGES from 'constants/messages'
import { EVENT_TYPES } from 'constants/types'
import completeButtonStyles from './constants/completeButtonStyles'
import useCreateEvent from './hooks/useCreateEvent'
import {
  NotApplicableIcon,
  NopeIcon,
  OptionIcon,
  OptionOutlineIcon,
} from 'components/common/custom-icons'
import useCompleteElementStates from './hooks/useCompleteElementStates'
import SelectButton from 'components/task-list/buttons/SelectButton'

// photo icon to take picture for task completition
const TaskMultipleOptionToComplete = (props: CompleteElementProps) => {
  const { id: taskId, sessionUuid, locationHierarchyId, completedEvent, completed } = props

  const [multipleOptionsModalOpen, setMultipleOptionsModalOpen] =
    useState(false)

  const {
    isNotApplicableCompletedEvent,
    isCantComplete,
    completeState,
    taskDone,
  } = useCompleteElementStates({
    completedEvent,
  })

  const createEvent = useCreateEvent({
    taskId,
    locationHierarchyId,
  })

  const openMultipleOptionsModal = () => {
    setMultipleOptionsModalOpen(true)
  }

  const handleOptionPress = (options: string[]) => {
    const { closeModal } = props

    if (closeModal) {
      closeModal(true)
    }

    createEvent({
      eventType: EVENT_TYPES.MULTIPLE_OPTION,
      createEventData: { text: options.toString() },
    }).then(() => {
      if (props.onComplete) {
        props.onComplete()
      }
    })

    setMultipleOptionsModalOpen(false)
  }

  const renderIcon = () => {
    const ICON_SIZE = 36

    if (!(completeState || completed)) {
      return <OptionIcon height={ICON_SIZE} width={ICON_SIZE} opacity={0.16} />
    } else if (isCantComplete) {
      return <NopeIcon height={ICON_SIZE} width={ICON_SIZE} />
    } else if (isNotApplicableCompletedEvent) {
      return <NotApplicableIcon height={ICON_SIZE} width={ICON_SIZE} />
    }

    return <OptionIcon height={ICON_SIZE} width={ICON_SIZE} />
  }

  return (
    <>
      {!props.showAsButton ? (
        <View style={styles.container}>
          <TouchableOpacity
            onPress={openMultipleOptionsModal}
            style={styles.buttonHolder}
          >
            {renderIcon()}
          </TouchableOpacity>
        </View>
      ) : (
        <View style={styles.buttonContainer}>
          <SelectButton
            onPress={openMultipleOptionsModal}
            selected={taskDone}
          />
        </View>
      )}
      <MultipleOptionsModal
        openModal={multipleOptionsModalOpen}
        onClose={() => setMultipleOptionsModalOpen(false)}
        title={props.title}
        allowMultiSelect={props.allowMultiSelect}
        options={props.options}
        onOptionPress={handleOptionPress}
        selectedOptionValue={
          !!props.completedEvent?.text ? props.completedEvent?.text : ''
        }
      />
    </>
  )
}

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  imageModalThumbnailWrapper: {
    ...STYLE_CONSTANTS.FLEX_CENTER_HORIZ,
  },
  offlineImageTextWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  offlineImageText: {
    fontSize: 8,
  },
  imageModalThumbnail: {
    width: 30,
    height: 40,
  },
  image: {
    width: 30,
    height: 40,
    marginRight: 5,
  },
  buttonHolder: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    width: 60,
  },
  buttonContainer: {
    ...completeButtonStyles.buttonContainer,
  },
  completeButtonTitle: {
    ...completeButtonStyles.completeButtonTitle,
  },
  completeButton: {
    ...completeButtonStyles.completeButton,
  },
  buttonIcon: {
    marginRight: 10,
  },
})

export default TaskMultipleOptionToComplete
