import React from 'react'

import { useTranslation } from 'react-i18next'
import {
  DocuSignIcon,
  DocuSignOutlinedIcon,
} from 'components/common/custom-icons'
import DefaultButton from './DefaultButton'

const DocuSignButton = ({
  selected,
  loading,
  onPress,
}: {
  selected: boolean
  loading: boolean
  onPress: { (): void }
}) => {
  const { t } = useTranslation()

  return (
    <DefaultButton
      title={t(loading ? 'loading' : 'DocuSign')}
      icon={<DocuSignIcon style={{ marginRight: 6 }} height={24} width={24} />}
      hoverIcon={
        <DocuSignOutlinedIcon
          style={{ marginRight: 6 }}
          height={24}
          width={24}
        />
      }
      selected={selected}
      onPress={loading ? () => { } : onPress}
      disabled={loading}
    />
  )
}

export default DocuSignButton
