import React, { useState, useCallback, useEffect } from 'react'
import {
  View,
  Text,
  Platform,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useApolloClient } from '@apollo/react-hooks';
import { useMutation } from '@apollo/react-hooks'
import * as WebBrowser from 'expo-web-browser'
import { fetchDiscoveryAsync, DiscoveryDocument, makeRedirectUri } from 'expo-auth-session'
import { useHistory } from 'react-router-native'
import COLORS from 'constants/colors'
import MESSAGES from 'constants/messages'
import { LOGIN_BY_CREDS, OKTA_CONFIGURATION } from 'graphql/mutations'
import { CACHE_KEYS } from 'constants/types'
import { ROUTE_NAMES } from 'navigation/constants'
import { writeErrorData } from 'utils/error-handling'
import useOktaLogin from './hooks/useOktaLogin'
import useWorkOSLogin from './hooks/useWorkOSLogin'
import ForgotPasswordButton from './components/ForgotPasswordButton'
import ForgotPasswordExplanation from './components/ForgotPasswordExplanation'
import GoBackButton from './components/GoBackButton'
import SignInWithGoogle from './components/SignInWithGoogle'
import ForgotPasswordActions from './components/ForgotPasswordActions'
import Separator from './components/Separator'
import InfoModal from './components/InfoModal'
import EmailField from '../../common/fields/EmailField'
import PasswordField from '../../common/fields/PasswordField'
import LoginButton from '../../common/sign-in/LoginButton'
import ClientsSelector from './components/ClientsSelector'
import useNetworkStatus from 'components/common/hooks/useNetworkStatus'
import { IS_WEB, IS_ANDROID } from 'constants/static'
import { useTranslation } from 'react-i18next'
import * as Linking from 'expo-linking'
import Sentry from 'utils/sentry'

// serves for web only
const maybeCompleteResult = WebBrowser.maybeCompleteAuthSession({ skipRedirectCheck: true })
Sentry.captureEvent({ message: 'LoginForm maybeCompleteResult', extra: maybeCompleteResult })

const isMobile = ['ios', 'android'].includes(Platform.OS)

// login form
const LoginForm = ({
  setShowLoginForm,
  setLoginFromInvisible,
  nextPageNavigate,
  loginError,
  setLoginError,
  infoMessage,
  setInfoMessage,
}: {
  setShowLoginForm: DispatchType<boolean>
  setLoginFromInvisible: DispatchType<boolean>
  nextPageNavigate: string
  loginError: string
  setLoginError: DispatchType<string>
  infoMessage: string
  setInfoMessage: DispatchType<string>
}) => {
  const client = useApolloClient()
  const { isConnected: online } = useNetworkStatus()
  const history = useHistory()
  const { t } = useTranslation()
  const [oktaConfiguration] = useMutation(OKTA_CONFIGURATION)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [useSSOSignIn, setUseSSOSignIn] = useState(true)
  const [triggerOktaLogin, setTriggerOktaLogin] = useState(false)
  const [oktaClientId, setOktaClientId] = useState('')
  const [oktaDiscovery, setOktaDiscovery] = useState<DiscoveryDocument>(null)
  const [loadingGoogleOauth, setLoadingGoogleOauth] = useState(false)
  const [showForgotPasswordStep, setShowForgotPasswordStep] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [clientId, setClientId] = useState<string | null>(null)
  const [clients, setClients] = useState<{ id: string; label: string }[]>([])

  const url = Linking.useURL()

  const setErrorAndShowForm = (error: string) => {
    setLoginError(error)
    setShowLoginForm(true)
    setLoginFromInvisible(false)
  }

  const [login] = useMutation(LOGIN_BY_CREDS, {
    onCompleted: (data) => {
      const token = data?.login?.authenticationToken
      const isTemporaryPassword = data?.login?.isTemporaryPassword
      const resetPasswordToken = data?.login?.resetPasswordToken

      const clients = data?.login?.clients || []

      if (clients.length > 1) {
        setClients(
          clients.map((client: any) => ({ id: client.id, label: client.name })),
        )
        setLoginFromInvisible(false)
        return
      }

      checkTokenAndNavigate(token, isTemporaryPassword, resetPasswordToken)
    },
    onError: (err) => {
      if (err.graphQLErrors[0]?.message) {
        setErrorAndShowForm(err.graphQLErrors[0].message)
      }
    },
  })

  const { exchangeCodeForToken: exchangeOktaCodeForToken } = useOktaLogin({
    clientId: oktaClientId,
    discovery: oktaDiscovery,
    email,
    setLoginError: setErrorAndShowForm,
    triggerOktaLogin,
    setTriggerOktaLogin,
  })

  const checkTokenAndNavigate = async (
    token: string,
    isTemporaryPassword = false,
    resetPasswordToken?: string,
  ) => {
    if (!!token) {
      await AsyncStorage.setItem(CACHE_KEYS.TOKEN, token)
      if (isTemporaryPassword) {
        history.push(
          `${ROUTE_NAMES.RESET_PASSWORD}?token=${resetPasswordToken}`,
        )
      } else {
        history.push(nextPageNavigate)
      }
    } else {
      writeErrorData(client, { message: 'invalid credentials' })
      setShowLoginForm(true)
      setLoginFromInvisible(false)
    }
  }

  const { handleWorkOSflow, validateSSOTokenAndNavigate: exchangeWorkOSCodeForToken } = useWorkOSLogin({
    setInfoMessage,
    setLoginError: setErrorAndShowForm,
    setUseSSOSignIn,
    setShowLoginForm,
    checkTokenAndNavigate,
  })

  const handleGoogleOauthLogin = async () => {
    setLoadingGoogleOauth(true)
    const data = {
      attributes: {
        isMobile,
        isGoogleOauth: true,
      },
    }
    const result = await handleWorkOSflow(data)
    if (!result) {
      setLoadingGoogleOauth(false)
      setLoginError('Error signing in using Google Auth')
    }
  }

  const handleSSOLogin = async () => {
    const data = {
      attributes: {
        email,
        isMobile,
      },
    }
    const result = await handleWorkOSflow(data)

    if (!result) {
      setUseSSOSignIn(false)
    }
  }

  // handle Okta login
  const handleOktaLogin = async () => {
    setLoginError('')
    setInfoMessage('')

    const oktaConfigurationResult = await oktaConfiguration({
      variables: { email },
    })

    const clientOktaConfig =
      oktaConfigurationResult?.data?.oktaConfiguration || {}

    if (!clientOktaConfig.mobileOktaClientId) {
      await handleSSOLogin()
      return
    }

    setOktaClientId(clientOktaConfig.mobileOktaClientId)

    const discovery = await fetchDiscoveryAsync(clientOktaConfig.issuer)
    setOktaDiscovery(discovery)
    setTriggerOktaLogin(true)
  }
  // handle login
  const handleLogin = useCallback(async () => {
    if (!online) {
      alert(MESSAGES.ERROR_FIRST_LOGIN)
      return
    }
    setLoginFromInvisible(true)
    const attributes = { email, password, clientId }
    login({ variables: { attributes } })
  }, [email, password, online, clientId])

  const handleOnSubmit = ({
    nativeEvent: { key },
  }: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (key === 'Enter' && !!email) {
      !useSSOSignIn ? handleLogin() : handleOktaLogin()
    }
  }

  const handleEmailChange = (text: string) => {
    setLoginError('')
    setEmail(text.trim())
  }

  const handleForgottenPassword = () => {
    setShowForgotPasswordStep(true)
    setUseSSOSignIn(false)
  }

  const handleGoBack = () => {
    setUseSSOSignIn(true)
    setShowForgotPasswordStep(false)
    setClients([])
    setClientId(null)
    setPassword('')
    setEmail('')
    setLoginError('')
    setInfoMessage('')
  }

  const closeModal = () => setModalOpen(false)

  useEffect(() => {
    setModalOpen(!!infoMessage)
  }, [infoMessage])

  const validateEmail = (state: string) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(state);
  }

  const exchangeCodeAndNavigate = async (code: string, state?: string) => {
    // Clean the code from the URL
    if (IS_WEB) {
      window.history.replaceState({}, document.title, window.location.pathname);
    }

    // try workos login
    setShowLoginForm(false)

    await exchangeWorkOSCodeForToken(code)

    // try okta login
    if (state) {
      const isStateSSOIdentifier = !validateEmail(state)
      let variables = {}
      if (isStateSSOIdentifier) {
        variables = { ssoIdentifier: state }
      } else {
        variables = { email: state }
      }
      const oktaConfigurationResult = await oktaConfiguration({
        variables: variables,
      })

      const clientOktaConfig =
        oktaConfigurationResult?.data?.oktaConfiguration || {}

      if (!clientOktaConfig.mobileOktaClientId) {
        setLoginError('Error signing in using Okta')
      }

      const discovery = await fetchDiscoveryAsync(clientOktaConfig.issuer)
      const codeVerifier = await AsyncStorage.getItem(CACHE_KEYS.SSO_CODE_VERIFIER)
      exchangeOktaCodeForToken({ code, codeVerifier, redirectUri: makeRedirectUri(), clientId: clientOktaConfig.mobileOktaClientId, requestUrl: discovery.tokenEndpoint, email: state })
    }

  }

  useEffect(() => {
    if (!url) {
      return
    }

    const { queryParams } = Linking.parse(url)
    const code = queryParams?.code as string
    const state = queryParams?.state as string
    if (!IS_ANDROID && !IS_WEB) {
      WebBrowser.dismissBrowser()
    }
    console.log("queryParams: ", queryParams)
    if (code) {
      exchangeCodeAndNavigate(code, state)
    }
  }, [url])

  const isFirstStep = useSSOSignIn // First step shows only email field
  const isSecondStep = !useSSOSignIn && !showForgotPasswordStep // Second step shows email and password
  const forgotPasswordStep = !useSSOSignIn && showForgotPasswordStep

  return (
    <View style={styles.loginForm}>
      {showForgotPasswordStep && <ForgotPasswordExplanation />}
      <EmailField
        value={email}
        setValue={handleEmailChange}
        editable={isFirstStep || forgotPasswordStep}
        handleOnSubmit={handleOnSubmit}
        testID="email-login"
      />

      {isSecondStep && (
        <>
          <PasswordField
            value={password}
            setValue={setPassword}
            handleOnSubmit={handleOnSubmit}
            testID="password-login"
          />
        </>
      )}

      {clients.length > 0 && (
        <ClientsSelector clients={clients} clientId={clientId} setClientId={setClientId} />
      )}

      {(isFirstStep || isSecondStep) && (
        <ForgotPasswordButton onPress={handleForgottenPassword} />
      )}

      <View>
        <Text style={styles.errorMessage} testID="login-invalid">
          {loginError}
        </Text>
      </View>


      {!showForgotPasswordStep ? (
        <LoginButton
          disabled={!email || (isSecondStep && !password)}
          onPress={!useSSOSignIn ? handleLogin : handleOktaLogin}
          text={!useSSOSignIn ? t('login') : t('next')}
          showNextArrow={isFirstStep}
        />
      ) : (
        <ForgotPasswordActions email={email} clientId={clientId} />
      )}

      {IS_WEB && isFirstStep && (
        <View>
          <Separator />

          <SignInWithGoogle
            onPress={handleGoogleOauthLogin}
            loading={loadingGoogleOauth}
          />
        </View>
      )}

      {(isSecondStep || forgotPasswordStep) && (
        <GoBackButton onPress={handleGoBack} />
      )}

      <InfoModal
        isVisible={modalOpen}
        onClose={closeModal}
        message={infoMessage}
      />
    </View>
  )
}

const styles = EStyleSheet.create({
  loginForm: {
    width: IS_WEB ? 424 : 300,
    alignItems: 'center',
    marginTop: 10,
  },
  errorMessage: {
    marginTop: 20,
    color: COLORS.SECONDARY,
    fontSize: 14,
    textAlign: 'center',
    margin: 'auto',
    fontFamily: 'Poppins_400Regular',
  },
  container: {
    marginBottom: 8,
    marginTop: 32,
    fontFamily: 'Poppins_400Regular',
  },
})

export default LoginForm
