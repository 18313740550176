import { useQuery } from '@apollo/react-hooks'
import Feedback from 'components/common/Feedback'
import RowView from 'components/common/fragments/RowView'
import { default as React, useEffect, useMemo, useState } from 'react'
import { Image as NativeImage, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { useApolloClient } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next'

import GeneralActivityIndicator from 'components/common/GeneralActivityIndicator'
import { FETCH_POLICIES } from 'constants/api'
import COLORS from 'constants/colors'
import { IS_WEB } from 'constants/static'
import { APP_DATA, TASK_PHOTOS_QUERY } from 'graphql/queries'
import { useAppContext } from 'utils/app-context'
import { ISODayString } from 'utils/date'
import TaskListModalWeb from './TaskListModalWeb'
import { isSupportedVideo } from 'utils/phone'
import TaskCompletionElement from './TaskCompletionElement'
import { TaskListModal } from './TaskListModal'
import TaskTitle from './TaskTitle'
import Image from 'components/common/Image'
import { replaceSnippetsInText } from 'utils/helpers/snippets-helper'
import { captureException } from 'utils/sentry'
import TaskListCard from './TaskListCard'
import { GET_DEPENDENT_TASK_COMPLETED_INFO } from 'graphql/queries'
import { EVENT_TYPES } from 'constants/types'

const defaultTaskImage = require('assets/images/png/default_task_image.png')
const defaultVideoImage = require('assets/images/jpg/video_preview_default.jpg')
const IMAGE_SIZE = IS_WEB ? 32 : 60

export enum TaskAnimationTypes {
  Green01,
  Green02,
  Rainbow01,
  Rainbow02,
}

export const TaskElementContext = React.createContext<{
  isAnimating: boolean
  setIsAnimating: (value: boolean) => void
  animationType: TaskAnimationTypes
}>({
  isAnimating: false,
  setIsAnimating: () => { },
  animationType: TaskAnimationTypes.Green01
})

const ANIMATION_ARRAY = [
  TaskAnimationTypes.Green01,
  TaskAnimationTypes.Green01,
  TaskAnimationTypes.Green01,
  TaskAnimationTypes.Green01,
  TaskAnimationTypes.Green02,
  TaskAnimationTypes.Green02,
  TaskAnimationTypes.Green02,
  TaskAnimationTypes.Green02,
  TaskAnimationTypes.Rainbow01,
  TaskAnimationTypes.Rainbow02,
]

const mapQuestionAnswers = (question: SubmittedQuestionType) => {
  const answer = question?.answers?.find(answer => answer.answer)
  const comment = answer?.comment
  return `<li>
    <div><strong>${question.titleSnapshot}:</strong> ${question?.answers?.find(answer => answer.answer)?.titleSnapshot}<div>
    ${comment ? `<div style="white-space: pre-line;"><strong>Feedback:<br /></strong> ${comment}</div>` : ''}
  </li>`
}

const mapQuestionsToHtml = (questions: SubmittedQuestionWithAnswerType[]) => {
  return (
    `<ul>
      ${questions.map((question) => {
      return `<div style="white-space: pre-line;"><strong>${question.title}:<br /></strong> ${question.type == 'UPLOAD' ? `<a href="${question.answer}" target="_blank">${question.answer}</a>` : question.answer}</div>`
    }).join('')}
    </ul>`
  )
}

const mapScorecardAnswersToHtml = (lastQuestionSubmission: QuestionSubmissionType) => {
  const totalScore = `${lastQuestionSubmission.score} / ${lastQuestionSubmission.totalScore}`
  return (
    `<div>
      <p><strong>Score:</strong> ${totalScore}</p>
      <p><strong>Score Details:</strong></p>
      <ul>
        ${lastQuestionSubmission.questions.map((question) => mapQuestionAnswers(question)).join('')}
      </ul>
    </div>`
  )
}

// row in tasklist
const TaskListElement = ({
  locationHierarchyId,
  completedEvent,
  snippetsMapping,
  // @ts-ignore
  ...task
}: TaskEntryPropsType) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [isAnimating, setIsAnimating] = useState(false)

  const client = useApolloClient()
  const appContextValue = useAppContext()
  const { i18n } = useTranslation()
  const {
    id: taskId,
    title,
    taskType,
    mediaUrl,
    frequencyType,
    important,
    estimateInMins,
    text,
    dueDate,
  } = task
  const fileUrlRaw = mediaUrl || ''
  const isVideo = isSupportedVideo(fileUrlRaw)
  const dayISO = ISODayString(appContextValue.selectedDay)
  let previewSource = mediaUrl
    ? { uri: mediaUrl }
    : !IS_WEB
      ? NativeImage.resolveAssetSource(defaultTaskImage)
      : { uri: defaultTaskImage }

  const { data } = useQuery<{
    appData: AppDataType
  }>(APP_DATA)

  const { data: dependentTaskCompletedInfoData } = useQuery<{ dependentTaskCompletedInfo: DependentTaskInfo }>(GET_DEPENDENT_TASK_COMPLETED_INFO, {
    variables: {
      dependentTaskId: task.dependsOnTaskId
    },
    skip: !task.dependsOnTaskId
  })

  const handleMissingSnippet = useMemo(
    () => (key: string) => {
      console.error(`Missing snippet: ${key}, taskId: ${taskId}`, 'WARNING')
    },
    [taskId],
  )

  const snippets = useMemo(() => {

    const completedTaskInfo = dependentTaskCompletedInfoData?.dependentTaskCompletedInfo

    if (!completedTaskInfo) {
      return snippetsMapping
    }

    const newSnippets = { ...snippetsMapping }
    switch (completedTaskInfo.eventType) {
      case EVENT_TYPES.MULTIPLE_OPTION:
      case EVENT_TYPES.ADD_TEXT:
        newSnippets['main_task.answer'] = completedTaskInfo.text
        break
      case EVENT_TYPES.UPLOAD_MEDIA:
        const attachments = completedTaskInfo.mediaUrls.map((url) => {
          const filename = url.split('/').pop()
          return `<li><a href="${completedTaskInfo.mediaUrls[0]}">${filename}</a></li>`
        })
        newSnippets['main_task.answer'] = `<ul>${attachments.join('')}</ul>`;
        break
      case EVENT_TYPES.SCORECARD_SUBMITTED:
        newSnippets['main_task.answer'] = mapScorecardAnswersToHtml(completedTaskInfo.lastQuestionsSubmission)
        break
      case EVENT_TYPES.QUIZ_COMPLETED:
        newSnippets['main_task.answer'] = mapQuestionsToHtml(completedTaskInfo.lastQuestionsSubmission?.questionsWithAnswers)
        break
      case EVENT_TYPES.SURVEY_SUBMITTED:
        newSnippets['main_task.answer'] = mapQuestionsToHtml(completedTaskInfo.lastQuestionsSubmission?.questionsWithAnswers)
        break
    }

    return newSnippets
  }, [snippetsMapping, dependentTaskCompletedInfoData, task])

  const titleWithSnippets = useMemo(
    () => {
      if (!snippets?.['employee.work_email']) // If the snippets are not loaded yet
        return task.title

      return replaceSnippetsInText(
        title,
        snippets,
        handleMissingSnippet,
        i18n.resolvedLanguage
      )
    },
    [title, snippets],
  )

  const { data: taskPhotosData } = useQuery(TASK_PHOTOS_QUERY, {
    fetchPolicy: FETCH_POLICIES.CACHE_AND_NETWORK,
    variables: { taskId: task.id, date: dayISO },
    skip: IS_WEB,
  })

  if (isVideo) {
    previewSource = defaultVideoImage
  }

  const openModal = () => {
    client.writeData({
      data: {
        appData: {
          taskDescriptionModalId: task.id,
          __typename: 'AppData',
        },
      },
    })
    setModalOpen(true)
  }

  const closeModal = (removeModalFromCache = false) => {
    if (IS_WEB) {
      return
    }

    if (removeModalFromCache) {
      client.writeData({
        data: {
          appData: {
            taskDescriptionModalId: '',
            __typename: 'AppData',
          },
        },
      })
    }
    setModalOpen(false)
  }

  useEffect(() => {
    if (data?.appData?.taskDescriptionModalId === String(task.id)) {
      setModalOpen(true)
    } else {
      setModalOpen(false)
    }
  }, [data])

  const getAnimationType = () => {
    const max = 9
    const min = 0
    const randomNumber = Math.floor(Math.random() * ((max + 1) - min) + min)

    return ANIMATION_ARRAY[randomNumber]
  }

  return (
    <TaskElementContext.Provider value={{
      isAnimating,
      setIsAnimating,
      animationType: getAnimationType()
    }}>
      <View key={taskId} style={{ height: IS_WEB ? 72 : 90, marginRight: '6px' }}>
        <TaskListCard
          modalOpen={modalOpen}
        >
          <RowView style={styles.content}>
            <Feedback onPress={openModal} style={styles.feedback}>
              <View style={styles.imageAndTitleWrapper}>
                <Image
                  containerStyle={styles.filePreviewContainer}
                  style={styles.imageStyle}
                  source={previewSource}
                  PlaceholderContent={<GeneralActivityIndicator />}
                />
                <View style={styles.taskTitleWrapper}>
                  <TaskTitle
                    {...{
                      title: titleWithSnippets,
                      taskType,
                      frequencyType,
                      additionalNotesText: text,
                      important,
                      estimateInMins,
                      dueDate,
                      completed: !!completedEvent,
                    }}
                  />
                </View>
              </View>
            </Feedback>
            <View>
              {/*TaskCompletionElement can be of one of the types - check, photo, sign etc.
              Defined in this file above: TaskCompletionElement*/}
              <TaskCompletionElement
                {...task}
                {...{
                  locationHierarchyId,
                  completedEvent,
                  title: titleWithSnippets,
                }}
              />
            </View>
          </RowView>

          {modalOpen && (
            <>
              {IS_WEB ? (
                <TaskListModalWeb>
                  <TaskListModal
                    isOpen={modalOpen}
                    hasTextOrPhotos={
                      taskPhotosData?.taskPhotos?.length ||
                      !!text
                    }
                    {...{
                      closeModal,
                      task: { ...task, title: titleWithSnippets },
                      completedEvent,
                      locationHierarchyId,
                      snippetsMapping: snippets,
                    }}
                  />
                </TaskListModalWeb>
              ) : (
                <TaskListModal
                  isOpen={modalOpen}
                  hasTextOrPhotos={
                    taskPhotosData?.taskPhotos?.length ||
                    !!text
                  }
                  {...{
                    closeModal,
                    task: { ...task, title: titleWithSnippets },
                    completedEvent,
                    locationHierarchyId,
                    snippetsMapping: snippets,
                  }}
                />
              )}
            </>
          )}
        </TaskListCard>
      </View>
    </TaskElementContext.Provider>
  )
}

const styles = EStyleSheet.create({
  content: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  feedback: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    height: '100%',
    alignItems: 'center',
  },
  imageAndTitleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    height: '100%',
    alignItems: 'center',
  },
  filePreviewContainer: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    borderRadius: 6,
    overflow: 'hidden',
    marginRight: IS_WEB ? 16 : 5,
    marginLeft: IS_WEB ? 16 : 7,
  },
  imageStyle: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  },
  taskTitleWrapper: {
    flexGrow: 1,
    flexShrink: 1,
    marginRight: 10,
    height: '100%',
  },
  taskNotUploadedWrapper: {
    position: 'absolute',
    right: 21,
    bottom: 0,
  },
  task: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    position: 'relative',
    flexGrow: 1,
  },
  taskTexts: {
    marginLeft: 10,
  },
  taskIcons: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    left: 10,
    bottom: 0,
  },
  taskIcon: {
    marginRight: 5,
    color: COLORS.YELLOW,
  },
  taskDate: {
    fontSize: '$fontSmall',
    flexDirection: 'row',
    marginLeft: 2,
  },
  taskTypeIconView: {
    marginLeft: 5,
  },
  taskTypeNameLabel: {
    fontSize: '$fontSmall',
    marginRight: 1,
  },
  Filler: {
    flex: 1,
  },
  taskRemoveElement: {
    marginRight: 10,
  },
})

export default TaskListElement
