import React, { useEffect, useState } from 'react'
import { TouchableOpacity, View, Image as NativeImage } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

import { EventTypesUnion, EVENT_TYPES } from 'constants/types'
import completeButtonStyles from './constants/completeButtonStyles'
import useCreateEvent from './hooks/useCreateEvent'
import {
  CheckmarkIcon,
  NopeIcon,
  NotApplicableIcon,
} from 'components/common/custom-icons'
import useCompleteElementStates from './hooks/useCompleteElementStates'
import { TaskAnimationTypes, TaskElementContext } from 'components/task-list/TaskListElement'
import { useTranslation } from 'react-i18next'
import CompleteButton from 'components/task-list/buttons/CompleteButton'
import { S3_BUCKET_URL } from 'constants/api'

const ICON_SIZE = 36
const PRELOADED_BUTTON_GIF = new Image()
const PRELOADED_CHECKMARK_GIF = new Image()
const PRELOADED_COMPLETE_BUTTON_FINAL = new Image()
const PRELOADED_COMPLETE_BUTTON_HOVER = new Image()
const PRELOADED_COMPLETE_BUTTON_NORMAL = new Image()
const PRELOADED_CHECHMARK = new Image()
const PRELOADED_CHECHMARK_OPAQUE = new Image()

// toggle component to finish a task
const TaskToggleToComplete = (props: CompleteElementProps) => {
  const { locationHierarchyId, id: taskId, completedEvent, completed } = props
  const { isAnimating, setIsAnimating, animationType } = React.useContext(TaskElementContext)
  const [isHovering, setIsHovering] = useState(false)
  const { i18n } = useTranslation()
  const [buttonUrl, setButtonUrl] = useState(() => taskDone ? PRELOADED_COMPLETE_BUTTON_FINAL.src : PRELOADED_COMPLETE_BUTTON_NORMAL.src)
  const [failedToLoad, setFailedToLoad] = useState(false)
  const {
    isNotApplicableCompletedEvent,
    isCantComplete,
    completeState,
    setCompleteState,
    taskDone,
    iconColor,
  } = useCompleteElementStates({
    completedEvent,
  })

  const createEvent = useCreateEvent({
    taskId,
    locationHierarchyId,
  })

  const toggleComplete = (eventType: EventTypesUnion) => {
    return createEvent({ eventType, createEventData: { eventType } })
  }

  const onValueChange = () => {
    const value =
      isCantComplete || isNotApplicableCompletedEvent ? true : !completeState
    const eventType = value
      ? EVENT_TYPES.COMPLETE_TASK
      : EVENT_TYPES.UNCOMPLETE_TASK
    if (!completeState && setIsAnimating) {
      setIsAnimating(true)
      setTimeout(() => {
        setIsAnimating(false)
        randomizeImageAnimation()
      }, 1000)
    }

    setCompleteState(value)
    toggleComplete(eventType).then(() => {
      if (props.onComplete) {
        props.onComplete()
      }
    })

    if (props.showAsButton) {
      props.closeModal()
    }
  }

  const getButtonUrl = () => {
    switch (animationType) {
      case TaskAnimationTypes.Rainbow02:
        return `${S3_BUCKET_URL}/public/Completed_Button_Animation_2.gif?time=${Date.now()}`
      case TaskAnimationTypes.Green01:
        return `${S3_BUCKET_URL}/public/Completed_Button_Animation_3.gif?time=${Date.now()}`
      case TaskAnimationTypes.Green02:
        return `${S3_BUCKET_URL}/public/Completed_Button_Animation_4.gif?time=${Date.now()}`
      default:
        return `${S3_BUCKET_URL}/public/Completed_Button_Animation.gif?time=${Date.now()}`
    }
  }

  const getCheckmarkUrl = () => {
    switch (animationType) {
      case TaskAnimationTypes.Rainbow02:
        return `${S3_BUCKET_URL}/public/Checkmark_Animation_2.gif?time=${Date.now()}`
      case TaskAnimationTypes.Green01:
        return `${S3_BUCKET_URL}/public/Checkmark_Animation_3.gif?time=${Date.now()}`
      case TaskAnimationTypes.Green02:
        return `${S3_BUCKET_URL}/public/Checkmark_Animation_4.gif?time=${Date.now()}`
      default:
        return `${S3_BUCKET_URL}/public/Checkmark_Animation.gif?time=${Date.now()}`
    }
  }

  const randomizeImageAnimation = () => {
    PRELOADED_BUTTON_GIF.src = getButtonUrl()
    PRELOADED_CHECKMARK_GIF.src = getCheckmarkUrl()
  }

  useEffect(() => {
    PRELOADED_COMPLETE_BUTTON_FINAL.src = `${S3_BUCKET_URL}/public/Complete_Button_Final_State.png`
    PRELOADED_COMPLETE_BUTTON_HOVER.src = `${S3_BUCKET_URL}/public/Complete_Button_Hover_State.png`
    PRELOADED_COMPLETE_BUTTON_NORMAL.src = `${S3_BUCKET_URL}/public/Complete_Button_Normal_State.png`
    PRELOADED_CHECHMARK.src = `${S3_BUCKET_URL}/public/Checkmark.png`
    PRELOADED_CHECHMARK_OPAQUE.src = `${S3_BUCKET_URL}/public/Checkmark_Opaque.png`

    randomizeImageAnimation()
  }, [])

  useEffect(() => {
    if (isAnimating) {
      return setButtonUrl(PRELOADED_BUTTON_GIF.src)
    }
    if (taskDone) {
      return setButtonUrl(PRELOADED_COMPLETE_BUTTON_FINAL.src)
    }
    return isHovering ? setButtonUrl(PRELOADED_COMPLETE_BUTTON_HOVER.src) : setButtonUrl(PRELOADED_COMPLETE_BUTTON_NORMAL.src)
  }, [isHovering, taskDone, isAnimating])

  const Icon = ({ url }: { url: string }) =>
    <NativeImage
      style={{ height: ICON_SIZE, width: ICON_SIZE }}
      source={{ uri: url }}
      onError={(error) => {
        // It may fail with 404 before we set the src
        if (url) setFailedToLoad(true)
      }}
    />

  const renderIcon = () => {
    if (!(completeState || completed)) {
      if (failedToLoad) return <CheckmarkIcon height={ICON_SIZE} width={ICON_SIZE} opacity={0.16} />

      return <Icon url={PRELOADED_CHECHMARK_OPAQUE.src} />
    } else if (isCantComplete) {
      return <NopeIcon height={ICON_SIZE} width={ICON_SIZE} />
    } else if (isNotApplicableCompletedEvent) {
      return <NotApplicableIcon height={ICON_SIZE} width={ICON_SIZE} />
    }

    if (failedToLoad) return <CheckmarkIcon height={ICON_SIZE} width={ICON_SIZE} />
    if (isAnimating) {
      return <img height={ICON_SIZE} width={ICON_SIZE} src={PRELOADED_CHECKMARK_GIF.src} data-testid="checkmark-icon" />
    }

    return <Icon url={PRELOADED_CHECHMARK.src} />
  }

  const OurButton = () => {
    if (failedToLoad || i18n.resolvedLanguage != 'en-US') {
      return <CompleteButton selected={taskDone} onPress={onValueChange} />
    }

    return <TouchableOpacity onPress={onValueChange} disabled={isAnimating}>
      <div onMouseEnter={() => !taskDone && setIsHovering(true)}
        onMouseLeave={() => !taskDone && setIsHovering(false)}>
        <NativeImage source={{ uri: buttonUrl }}
          data-testid="complete-button-img"
          style={{ height: 56, width: 'auto' }}
          onError={(error) => {
            setFailedToLoad(true)
          }}
        />
      </div>
    </TouchableOpacity>
  }

  return !props.showAsButton ? (
    <View style={styles.container} data-testid="complete-button">
      <TouchableOpacity style={styles.iconContainer} onPress={onValueChange} disabled={isAnimating}>
        {renderIcon()}
      </TouchableOpacity>
    </View>
  ) : (
    <View style={styles.buttonContainer} data-testid="complete-button">
      <OurButton />
    </View>
  )
}

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonContainer: {
    ...completeButtonStyles.buttonContainer,
  },
  iconContainer: {
    ...completeButtonStyles.iconContainer,
  },
})

export default TaskToggleToComplete
