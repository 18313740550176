import React, { useCallback } from 'react'
import COLORS from 'constants/colors'
import { ArrowRightIcon, CheckmarkIcon } from 'components/common/custom-icons'
import { ROUTE_NAMES } from 'navigation/constants'
import { useHistory } from 'react-router'
import { View, Text, TouchableOpacity } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { useTranslation } from 'react-i18next'
import ExpiredLabel from './ExpiredLabel'


const ManagerLocation = ({
  location,
  onLocationChange,
}: {
  location: LocationInterface & { percent: number; completedTasksCount: number }
  onLocationChange?: () => void
}) => {
  const history = useHistory()
  const { t } = useTranslation()
  const hasChildLocations = location.children?.length > 0
  const locationHierarchyId = location.id

  const changeToFolder = useCallback(() => {
    if (hasChildLocations) {
      goToLocationFolder()
    } else {
      goToTasksToday()
    }

    if (onLocationChange) {
      onLocationChange()
    }
  }, [hasChildLocations, locationHierarchyId, onLocationChange])

  const goToTasksToday = () => {
    history.push({
      pathname: ROUTE_NAMES.TASKS_TODAY,
      search: `?locationHierarchyId=${location.id}`,
    })
  }

  const goToLocationFolder = () => {
    history.push({
      pathname: ROUTE_NAMES.LOCATIONS,
      search: `?locationHierarchyId=${locationHierarchyId}`,
    })
  }

  const renderTaskNumber = () => {
    if (location.percent == 0) {
      if (location.tasks == 0) return null

      const taskString = location.tasks == 1 ? t('task') : t('tasks')

      return `${location.tasks || 0} ${taskString}`
    }

    return `${location.completedTasksCount} / ${location.tasks}`
  }

  const renderInfoTaskNumber = () => {
    const numberInfoTasks = location.numberInfoTasks

    if (numberInfoTasks) {
      return t(numberInfoTasks == 1 ? 'infoTask' : 'infoTasks', { numberInfoTasks })
    }
  }

  return (
    <TouchableOpacity onPress={changeToFolder}>
      <View style={styles.container}>
        <>
          <Text style={styles.locationLeft}>{location.title} {location.isExpired && location.percent !== 100 && (<ExpiredLabel />)}</Text>
          <View style={styles.locationRight}>
            <View style={styles.locationTasksNumber}>
              <Text style={styles.locationTaskNumberText}>{renderTaskNumber()}</Text>
              <Text style={styles.locationTaskNumberText}>{renderInfoTaskNumber()}</Text>
            </View>
            {location.tasks != 0 && <Text style={styles.locationTasksPercentage}>{location.percent}%</Text>}
            <View>
              {location.percent == 100 ? (
                <CheckmarkIcon height={24} width={24} />
              ) : (
                <ArrowRightIcon height={24} width={24} />
              )}
            </View>
          </View>
        </>
      </View>
    </TouchableOpacity>
  )
}

const styles = EStyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 12,
    padding: 12,
    backgroundColor: '#f5f7fb',
    borderRadius: 8,
  },
  locationLeft: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 24,
  },
  locationRight: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 12,
    lineHeight: 24,
  },
  locationTasksNumber: {
    flex: 1,
    minWidth: 50,
    minHeigh: 100,
  },
  locationTaskNumberText: {
    fontWeight: '400',
    color: COLORS.SIXES_GREY,
  },
  locationTasksPercentage: {
    marginRight: 5,
    fontWeight: '600',
    fontSize: 14,
    color: COLORS.DARK_TURQUOISE,
  },
})

export default ManagerLocation
