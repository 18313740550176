import React, { Fragment, useEffect, useMemo } from 'react'

import styled from 'styled-components'
import COLORS, { DEFAULT_CATEGORY_COLORS } from 'constants/colors'
import format from 'utils/date-fns'
import { isToday, isBefore, differenceInDays } from 'date-fns'
import { useHistory } from 'react-router'
import { useApolloClient, useLazyQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next'
import TaskCompletionElement from 'components/task-list/TaskCompletionElement'
import ImportantLabel from 'components/common/ImportantLabel'
import { captureException } from 'utils/sentry'
import { replaceSnippetsInText } from 'utils/helpers/snippets-helper'
import { GET_USER_SNIPPET_VALUES } from 'graphql/queries'
import useUser from 'hooks/useUser'
import UpNextRawCard from './UpNextRawCard.web'
import { ROUTE_NAMES } from 'navigation/constants'
import { Image } from 'react-native'
import { getColorByIndex } from 'hooks/useLocations'
import useClientConfig from 'hooks/useClientConfig'

const defaultTaskImage = require('assets/images/png/default_task_image.png')

const UpNextCard = ({
  task,
  refetch,
  onClick,
  disableClick = false,
  darkBackground = false,
}: {
  task: TaskUncompleteType
  refetch: () => void
  onClick?: () => void
  disableClick?: boolean
  darkBackground?: boolean
}) => {
  const history = useHistory()
  const client = useApolloClient()
  const { t, i18n } = useTranslation()
  const { user } = useUser()
  const { categoryColors } = useClientConfig()

  const section = useMemo(() => task.section, [task])
  const workflow = useMemo(() => task.workflow, [task])
  const owner = useMemo(() => workflow?.owner || section?.owner, [section, workflow])
  const [getUserSnippetValues, { data: userSnippetValuesData }] = useLazyQuery(GET_USER_SNIPPET_VALUES)
  const snippetsMapping = useMemo(() => userSnippetValuesData?.userSnippetValues || {}, [userSnippetValuesData])
  const hasSnippet = useMemo(() => /.*{{.*}}.*/.test(task.title), [task])
  useEffect(() => {
    if (owner?.id && hasSnippet) {
      getUserSnippetValues({ variables: { userId: owner.id } })
    }
  }, [owner])


  function renderDueDate() {
    const today = new Date()
    let dueDate
    if (task.dueDate?.length === 10) {
      dueDate = new Date(task.dueDate.replace(/-/g, '/'))
    } else {
      dueDate = new Date(task.dueDate)
    }


    const dueDateText = format(
      dueDate,
      t('monthDayYearDateFormat'),
      i18n.resolvedLanguage,
    )

    let dueDayText = ''
    let pastDue = false

    if (isToday(dueDate)) {
      dueDayText = t('dueToday')
    } else if (isBefore(dueDate, today)) {
      const dueIn = differenceInDays(today, dueDate)
      pastDue = true
      if (dueIn <= 1) {
        dueDayText = `${t('dueYesterday')}`
      } else {
        dueDayText = `${t('due')} ${dueIn} ${t('days')} ${t('ago')}`
      }
    } else if (differenceInDays(dueDate, today) < 1) {
      dueDayText = t('dueTomorrow')
    } else {
      const dueIn = differenceInDays(dueDate, today)
      dueDayText = `${t('dueIn')} ${dueIn} ${t('days')}`
    }

    return (
      <Fragment>
        <DueDateSubtitle pastDue={pastDue}>{dueDayText}</DueDateSubtitle>
        <DueDateTitle pastDue={pastDue}>{dueDateText}</DueDateTitle>
      </Fragment>
    )
  }

  function goToTasksToday() {
    if (disableClick) return

    // set selected task
    client.writeData({
      data: {
        appData: {
          taskDescriptionModalId: task.id,
          __typename: 'AppData',
        },
      },
    })

    history.push({
      pathname: ROUTE_NAMES.TASKS_TODAY,
      search: `?locationHierarchyId=${task.locationHierarchyId}`,
    })

    if (onClick) onClick()
  }

  function renderBreadcrumb() {
    let text = ''

    if (section) {
      if (owner && user?.id !== owner.id) {
        text += `${owner.fullName} > `
      }

      if (workflow.title == '__root__') {
        text += section.title
      } else {
        text += `${workflow.title} > ${section.title}`
      }

      return text
    }
  }

  function renderTaskIcon() {
    return (
      <TaskCompletionElement
        {...task}
        completed={task.taskCompleted}
        isFromUpNext={true}
        {...{
          locationHierarchyId: task.locationHierarchyId,
          sessionUuid: null,
          completedEvent: null,
          onComplete: refetch,
          title: titleWithSnippets,
        }}
      />
    )
  }

  const handleMissingSnippet = useMemo(
    () => (key: string) => {
      console.error(`Missing snippet: ${key}, taskId: ${task.id}`, 'WARNING')
    },
    [task],
  )

  const titleWithSnippets = useMemo(
    () => {
      if (!snippetsMapping?.['employee.work_email']) // If the snippets are not loaded yet
        return task.title

      return replaceSnippetsInText(
        task.title,
        snippetsMapping,
        handleMissingSnippet,
        i18n.resolvedLanguage
      )
    },
    [task.title, snippetsMapping],
  )

  const tagColor = useMemo(() => {
    if (workflow.color) {
      return workflow.color
    }

    const location = workflow?.title == '__root__' ? section : workflow

    const index = parseInt(location?.id) % (categoryColors?.length || DEFAULT_CATEGORY_COLORS.length)
    const color = getColorByIndex(categoryColors || DEFAULT_CATEGORY_COLORS, index)
    return color?.color
  }, [workflow, categoryColors])

  return (
    <UpNextRawCard onClick={goToTasksToday} darkBackground={darkBackground} tagColor={tagColor} >
      <Left>
        <TaskIcon>
          <Image style={{ height: 36, width: 36 }} source={task.mediaUrl ?? defaultTaskImage} />
        </TaskIcon>
        <LeftText>
          <TaskSubtitle>{renderBreadcrumb()}</TaskSubtitle>
          <TaskTitle>{titleWithSnippets} {task.important && <ImportantLabel withSeparator fontSize={11} />}</TaskTitle>
        </LeftText>
      </Left>
      <Right>
        <RightText>
          {!!task.dueDate && renderDueDate()}
        </RightText>
        <ActionTaskIcon>{renderTaskIcon()}</ActionTaskIcon>
      </Right>
    </UpNextRawCard>
  )
}

const Left = styled.div`
  display: flex;
`

const LeftText = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  justify-content: center;
`

const TaskTitle = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-weight: 400;
  color: ${COLORS.NAVY_BLUE};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TaskSubtitle = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${COLORS.SIXES_GREY};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Right = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
`

const RightText = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
`

const TaskIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`

const ActionTaskIcon = styled.div`
  display: flex;
  align-items: center;
  width: 36px;
  height: 36px;
  margin-right: 12px;
`

const DueDateTitle = styled.div<{ pastDue: boolean }>`
  font-size: 18px;
  font-weight: 400;
  color: ${({ pastDue }) => pastDue ? COLORS.RED : COLORS.SIXES_GREY}};
`

const DueDateSubtitle = styled.div<{ pastDue: boolean }>`
  font-size: 14px;
  font-weight: 300;
  color: ${({ pastDue }) => pastDue ? COLORS.RED : COLORS.SIXES_GREY}};
`

export default UpNextCard
