import React from 'react'

import { NopeIcon, NotApplicableIcon } from 'components/common/custom-icons'
import Icon from 'components/common/Icon'
import { ICON_NAMES } from 'constants/icons'
import COLORS from 'constants/colors'

const UploadIcon = ({ isComplete = false, isCantComplete = false, isNotApplicable = false }) => {
  const ICON_SIZE = 36

  if (!isComplete) {
    return (
      <Icon
        name={ICON_NAMES.CLOUD_UPLOAD}
        size={ICON_SIZE}
        color={COLORS.TURQUOISE}
        style={{
          opacity: 0.16,
        }}
      />
    )
  } else if (isCantComplete) {
    return <NopeIcon height={ICON_SIZE} width={ICON_SIZE} />
  } else if (isNotApplicable) {
    return <NotApplicableIcon height={ICON_SIZE} width={ICON_SIZE} />
  }

  return (
    <Icon
      name={ICON_NAMES.CLOUD_UPLOAD}
      size={ICON_SIZE}
      color={COLORS.TURQUOISE}
    />
  )
}

export default UploadIcon