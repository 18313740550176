import React, { useMemo } from 'react'
import Flags from 'country-flag-icons/react/3x2'
import { defaultLanguages } from 'i18n/languages'
import useClientConfig from './useClientConfig'
import { useTranslation } from 'react-i18next'

const useLanguages = () => {
  const { t } = useTranslation()
  const { availableLanguages, showLocale, loading } = useClientConfig()

  const languages = useMemo(() => {
    if (!showLocale || loading) {
      return []
    }

    if (availableLanguages != null && availableLanguages.length > 0) {
      return availableLanguages.map((language: any) => language.value)
    }

    return defaultLanguages
  }, [availableLanguages, showLocale, loading])

  return languages.map((language: string) => {
    return {
      language,
      flag: getFlag(language),
      title: t(language),
    }
  })
}

const getFlag = (language: string) => {
  let flag

  switch (language) {
    case 'en':
    case 'en-US':
      flag = (
        <>
          <Flags.GB
            title="Britain"
            style={{
              width: '30px',
              marginLeft: '5px',
              marginRight: '5px',
            }}
          />
          <Flags.US
            title="United States"
            style={{
              width: '30px',
            }}
          />
        </>
      )
      break
    case 'fr-CA':
      flag = (
        <>
          <Flags.CA
            title="Canada"
            style={{
              marginLeft: '5px',
              width: '30px',
            }}
          />
        </>
      )
      break
    case 'fr':
      flag = (
        <>
          <Flags.FR
            title="France"
            style={{
              marginLeft: '5px',
              width: '30px',
            }}
          />
        </>
      )
      break
    case 'es':
    case 'es-ES':
      flag = (
        <>
          <Flags.MX
            title="Mexico"
            style={{
              width: '30px',
              marginLeft: '5px',
              marginRight: '5px',
            }}
          />
          <Flags.ES
            title="Spain"
            style={{
              width: '30px',
            }}
          />
        </>
      )
      break
    case 'pt-BR':
    case 'pt-PT':
    case 'pt':
      flag = (
        <>
          <Flags.BR
            title="Brazil"
            style={{
              width: '30px',
              marginLeft: '5px',
              marginRight: '5px',
            }}
          />
          <Flags.PT
            title="Portugal"
            style={{
              width: '30px',
            }}
          />
        </>
      )
      break
    case 'de-DE':
    case 'de':
      flag = (
        <Flags.DE
          title="Germany"
          style={{
            marginLeft: '5px',
            width: '30px',
          }}
        />
      )
      break
    case 'ja-JP':
    case 'ja':
      flag = (
        <Flags.JP
          title="Japan"
          style={{
            marginLeft: '5px',
            width: '30px',
          }}
        />
      )
      break
    case 'zh-CN':
    case 'zh-TW':
      flag = (
        <Flags.CN
          title="China"
          style={{
            marginLeft: '5px',
            width: '30px',
          }}
        />
      )
      break
  }

  return <div style={{ display: 'flex' }}>{flag}</div>
}

export default useLanguages