import React from 'react'
import styled from 'styled-components/native'

import QuestionHeader from './components/QuestionHeader'
import QuestionRequiredError from './components/QuestionRequiredError'
import QuestionsOptions from './components/QuestionOptions'
import QuestionContainer from './components/QuestionContainer'
import QuestionComment from './components/QuestionComment'
import QuestionUpload from './components/QuestionUpload'
import { IS_WEB } from 'constants/static'
import QuestionScore from './components/QuestionScore'
import QuestionUpdateEmployee from './components/QuestionUpdateEmployee'

const Question = ({
  question,
  index,
  total,
  selectedQuestion,
  onOptionSelected,
  hasError,
  snippetsMapping,
}: {
  question?: QuestionType
  index: number
  total: number
  selectedQuestion: any
  onOptionSelected: (currentState: any) => void
  hasError: boolean
  snippetsMapping?: StringMap,
}) => {
  return <QuestionContainer hasError={hasError}>
    <QuestionHeader
      index={index}
      total={total}
      title={question.title}
      description={question.description}
      required={question.required}
      snippetsMapping={snippetsMapping}
    />

    <Content>
      {question.quizType == 'UPDATE_EMPLOYEE' && <QuestionUpdateEmployee
        questionId={question.id}
        onOptionSelected={onOptionSelected}
        options={question.options}
        comment={selectedQuestion.comment}
      />}

      {question.quizType == 'SCORECARD' && <QuestionScore
        questionId={question.id}
        options={question.options}
        onOptionSelected={onOptionSelected}
        selectedOptions={selectedQuestion.optionsIds}
        comment={selectedQuestion.comment}
      />}

      {question.quizType == 'COMMENT' &&
        <QuestionComment
          questionId={question.id}
          onOptionSelected={onOptionSelected}
          comment={selectedQuestion.comment}
        />
      }
      {question.quizType == 'UPLOAD' &&
        <QuestionUpload
          questionId={question.id}
          media={Array.from(selectedQuestion.media)}
          onOptionSelected={onOptionSelected}
        />
      }
      {(question.quizType === 'MULTIPLE_CHOICE' || question.quizType === 'CHECKBOX') &&
        <QuestionsOptions
          questionId={question.id}
          quizType={question.quizType}
          options={question.options}
          selectedOptions={selectedQuestion.optionsIds}
          onOptionSelected={onOptionSelected}
        />
      }
    </Content>

    {hasError && <QuestionRequiredError />}
  </QuestionContainer>
}

const Content = styled.View`
  display: ${IS_WEB ? 'block' : 'flex'};
`

export default Question
