import React from 'react'
import ResetPasswordForm from './ResetPasswordForm'
import LeftSide from '../common/sign-in/LeftSide'
import EStyleSheet from 'react-native-extended-stylesheet'
import { View } from 'react-native'
import { IS_WEB } from 'constants/static'
import { useTranslation } from 'react-i18next'

const ResetPasswordScreen = () => {
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <LeftSide title={t('resetPassword')} info={t('resetPasswordInfo')}>
        <ResetPasswordForm />
      </LeftSide>
    </View>
  )
}

const styles = EStyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    marginVertical: 20,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    flexDirection: 'row',
  },
})

export default ResetPasswordScreen
