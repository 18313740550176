import React, { useContext, useMemo, useState } from 'react'
import styled from 'styled-components'
import COLORS from 'constants/colors'
import { CircularProgress, Divider } from '@mui/material'
import { MyTeamContext } from 'components/my-team/MyTeamContext'

import WorkflowsFilter from '../common/WorkflowsFilter'
import OverallWorkflowProgress from './OverallWorkflowProgress'


const OverallProgress = () => {
  const { reporteesWorkflows, loading } = useContext(MyTeamContext)
  const [showInactiveWorkflows, setShowInactiveWorkflows] = useState(true);
  const [selectedTemplatesIds, setSelectedTemplatesIds] = useState<any>([])

  const allTemplatesCounter = useMemo(() => {
    const templates: any = {}
    for (const userId in reporteesWorkflows) {
      reporteesWorkflows[userId]
        .forEach((workflow: any) => {
          if (templates[workflow.templateCategoryId]) {
            templates[workflow.templateCategoryId].count += 1
            templates[workflow.templateCategoryId].isCompleteByAll = templates[workflow.templateCategoryId].isCompleteByAll && workflow.isComplete
            templates[workflow.templateCategoryId].isExpiredToAll = templates[workflow.templateCategoryId].isExpiredToAll && workflow.isExpired
          } else {
            templates[workflow.templateCategoryId] = {
              id: workflow.templateCategoryId,
              title: workflow.title,
              count: 1,
              color: workflow.color,
              isExpiredToAll: workflow.isExpired,
              isCompleteByAll: workflow.isComplete,
            }
          }
        })
    }

    return templates
  }, [reporteesWorkflows, showInactiveWorkflows])

  const filteredTemplatesCounter = useMemo(() => {
    const templates: any = {}

    if (selectedTemplatesIds.length === 0) {
      if (showInactiveWorkflows) {
        return allTemplatesCounter
      } else {
        for (const templateId in allTemplatesCounter) {
          if (!(allTemplatesCounter[templateId].isExpiredToAll)) {
            templates[templateId] = allTemplatesCounter[templateId]
          }
        }
      }
    }

    for (const templateId of selectedTemplatesIds) {
      templates[templateId] = allTemplatesCounter[templateId]
    }

    return templates
  }, [allTemplatesCounter, selectedTemplatesIds])

  if (loading) return <LoadingContainer>
    <CircularProgress />
  </LoadingContainer>

  return <Container>
    <Header>
      Overall Progress
    </Header>

    <Divider />

    <Body>
      <WorkflowsFilter
        showInactiveWorkflows={showInactiveWorkflows}
        setShowInactiveWorkflows={setShowInactiveWorkflows}
        allTemplates={Object.values(allTemplatesCounter)}
        selectedTemplatesIds={selectedTemplatesIds}
        setSelectedTemplatesIds={setSelectedTemplatesIds}
      />

      <OverallWorkflowProgress
        reporteesWorkflows={reporteesWorkflows}
        allTemplatesCounter={filteredTemplatesCounter}
      />
    </Body>
  </Container>
}



const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 36px 24px 36px;
`

const Header = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${COLORS.NAVY_BLUE};
  padding: 24px 36px 24px 36px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-weight: 300;
  font-size: 14px;
  color: ${COLORS.FIVES_GREY};
  height: 345px;
  border-radius: 16px;
  box-shadow: 0px 12px 24px -4px #919EAB1F;
  background-color: #FFFFFF;
`

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`

export default OverallProgress