import styled from 'styled-components'

const UpNextRawCard = styled.div<{ darkBackground: boolean, tagColor?: string }>`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  margin-top: 12px;
  margin-right: 6px;
  border-radius: 8px;
  background-color: ${props => props.darkBackground ? '#F5F7FB' : 'white'};
  cursor: pointer;
  box-shadow: 0px 4px 12px 0px #0000000F;
  border-left: 8px solid ${({ tagColor }) => tagColor ?? '#2E4785'};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`

export default UpNextRawCard