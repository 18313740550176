import React from 'react'
import { useTranslation } from 'react-i18next'
import { isToday, isBefore, differenceInDays } from 'date-fns'

import format from 'utils/date-fns'
import COLORS from 'constants/colors'
import { Text, View } from 'react-native'

// SMALL
// Past Due
// Due Today
// Tomorrow
// {date}

// MEDIUM
// Past Due
// Due Today
// Due Tomorrow
// Due {date}

export enum DueDateTextMode {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}

const DueDateText = ({
  date,
  textMode = DueDateTextMode.MEDIUM
}: {
  date?: string,
  textMode?: DueDateTextMode
}) => {
  const { t, i18n } = useTranslation()

  if (!date) return null

  const pastDueText = () => {
    return t('pastDue')
  }

  const todayText = () => {
    return t('dueToday')
  }

  const tomorrowText = () => {
    if (textMode == DueDateTextMode.SMALL) {
      return t('tomorrow')
    }

    return t('dueTomorrow')
  }

  const sixMonthsPlusText = () => {
    return t('dueInSixMonthsPlus')
  }

  const dueText = (date: string) => {
    if (textMode == DueDateTextMode.SMALL) {
      return date
    }

    return `${t('due')} ${date}`
  }

  const today = new Date()
  const dueDate = new Date(date)
  let dueDateText = dueText(format(
    dueDate,
    t('monthDayDateFormat'),
    i18n.resolvedLanguage,
  ))
  let pastDue = false

  if (isToday(dueDate)) {
    // Today
    dueDateText = todayText()
  } else if (isBefore(dueDate, today)) {
    // Past Due
    dueDateText = pastDueText()
    pastDue = true;
  } else if (differenceInDays(dueDate, today) < 1) {
    // Tomorrow
    dueDateText = tomorrowText()
  } else if (differenceInDays(dueDate, today) >= (6 * 30)) {
    // 6+ months
    dueDateText = sixMonthsPlusText()
  }

  return <View>
    <Text style={{
      color: pastDue ? COLORS.RED : '',
      fontSize: 12,
      fontWeight: '300',
    }}>{dueDateText}
    </Text>
  </View>

}

export default DueDateText