import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const ArrowRightRounded = (props: SvgProps) => (
  <Svg fill="none" viewBox="0 0 24 18" {...props}>
    <Path
      d="M2 9h20M15 16l7-7-7-7"
      stroke={props.color || "#50CEA2"}
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
    />
  </Svg>
)

export default ArrowRightRounded
