import React, { useContext, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { Divider } from '@mui/material'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import {
  GridColDef,
} from '@mui/x-data-grid-pro';

import COLORS from 'constants/colors'
import { MyTeamContext } from 'components/my-team/MyTeamContext';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_MY_TEAM_TODO_TASKS } from 'graphql/queries';
import { getDateWithoutTimezone } from 'components/my-team/utils/getDateWithoutTimezone';
import UserHeaderItem from '../common/UserHeaderItem';
import StyledDataGrid from '../common/StyledDataGrid';
import { orderBy } from 'lodash';

const MyTeamProgress = () => {
  const { reportees, reporteesWorkflows, loading: overallLoading, setSelectedReportee } = useContext(MyTeamContext)

  const reporteesIds = useMemo(() => reportees.map(reportee => reportee.id), [reportees])
  const reporteesWorkflowsIds = useMemo(() => {
    const locationIds: string[] = []

    for (const [userId, workflows] of Object.entries(reporteesWorkflows)) {
      workflows.forEach((workflow: any) => {
        if (workflow.children.length > 0) {
          locationIds.push(...workflow.children.map((child: any) => child.id))
        } else {
          locationIds.push(workflow.id)
        }
      })
    }

    return locationIds
  }, [reporteesWorkflows])

  const [getMyTeamToDoTasks, { data, loading }] = useLazyQuery(GET_MY_TEAM_TODO_TASKS, {
    variables: {
      userIds: reporteesIds,
      locationIds: reporteesWorkflowsIds,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (reporteesIds.length > 0 && reporteesWorkflowsIds.length > 0) {
      getMyTeamToDoTasks()
    }
  }, [reporteesIds, reporteesWorkflowsIds])

  const rows = useMemo(() => {
    if (!data) return []

    const rows = orderBy(reportees.map(reportee => {
      return {
        ...reportee,
        amountOfTasksToDo: data?.myTeamToDoTasks[reportee.id]?.todo || 0,
        amountOfTasksOverdue: data?.myTeamToDoTasks[reportee.id]?.overdue || 0,
      }
    }).filter(reportee => reportee.amountOfTasksToDo > 0 || reportee.amountOfTasksOverdue > 0), ['amountOfTasksToDo'], ['desc'])

    return rows
  }, [data, reportees])

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'fullName',
      headerName: 'Employee',
      flex: 3,
      renderCell: (params) => {
        return <EmployeeColumn onClick={() => {
          setSelectedReportee(params.row)
        }}>
          <UserHeaderItem reportee={params.row} />
        </EmployeeColumn>
      }
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      flex: 1,
      type: 'date',
      valueGetter: (value: any) => {
        if (value === null) return null

        return getDateWithoutTimezone(new Date(value))
      },
    },
    {
      field: 'amountOfTasksOverdue',
      headerName: 'Overdue Tasks',
      type: 'number',
      flex: 1,
      renderCell: (params) => {
        if (params.value === 0) {
          return <NumberContainer>-</NumberContainer>
        }
        return <NumberContainer style={{ color: COLORS.RED }}>{params.value}</NumberContainer>
      }
    },
    {
      field: 'amountOfTasksToDo',
      headerName: 'Outstanding Tasks',
      type: 'number',
      flex: 1,
      renderCell: (params) => {
        return <NumberContainer>{params.value}</NumberContainer>
      }
    },
  ], [])

  return (
    <Container>
      <Header>
        <PeopleOutlineIcon sx={{ color: COLORS.TURQUOISE, marginRight: '16px' }} />
        My Team's Progress
      </Header>
      <Divider />

      <Body>
        <Table>
          <StyledDataGrid
            loading={loading || overallLoading}
            columns={columns}
            rows={rows}
          />
        </Table>
      </Body>
    </Container>
  )
}

const NumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const EmployeeColumn = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
`

const Table = styled.div`
  height: 266px;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 24px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: ${COLORS.NAVY_BLUE};
  padding: 24px 36px 24px 36px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-weight: 300;
  font-size: 14px;
  color: ${COLORS.FIVES_GREY};
  height: 345px;
  border-radius: 16px;
  box-shadow: 0px 12px 24px -4px #919EAB1F;
  background-color: #FFFFFF;
  margin-top: 36px;
`

export default MyTeamProgress