import React from 'react'

import QuestionOption from './QuestionOption'

const QuestionsOptions = ({
  questionId,
  quizType,
  options,
  selectedOptions,
  onOptionSelected,
}: {
  questionId: string
  options: QuestionOptionType[]
  quizType: string
  selectedOptions: Set<string>
  onOptionSelected: (currentState: any) => void
}) => {
  const handleRadioChange = (id: string) => {
    onOptionSelected((currentState: any) => {
      currentState[questionId].optionsIds = new Set([id])

      return {
        ...currentState
      }
    })
  }

  const handleCheckboxChange = (id: string) => {
    onOptionSelected((currentState: any) => {
      if (currentState[questionId].optionsIds.has(id)) {
        currentState[questionId].optionsIds.delete(id)
      } else {
        currentState[questionId].optionsIds.add(id)
      }

      return {
        ...currentState
      }
    })
  }

  const RadioOptions = () => <>
    {options?.map(option =>
      <QuestionOption
        key={option.id}
        isCheckbox={false}
        isSelected={selectedOptions?.has(option.id)}
        title={option.title}
        id={option.id}
        handleChange={handleRadioChange}
      />
    )}
  </>



  const CheckboxOptions = () => <>
    {options?.map(option =>
      <QuestionOption
        key={option.id}
        isCheckbox={true}
        isSelected={selectedOptions?.has(option.id)}
        title={option.title}
        id={option.id}
        handleChange={handleCheckboxChange}
      />
    )}
  </>

  return quizType == 'MULTIPLE_CHOICE' ? <RadioOptions /> : <CheckboxOptions />
}

export default QuestionsOptions