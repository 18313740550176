import React, { useEffect, useMemo, useState } from 'react'
import { Text, StyleSheet, View } from 'react-native';

import { IS_WEB } from 'constants/static';
import COLORS from 'constants/colors'
import styled from 'styled-components/native';
import QuestionRatingDescription from './QuestionRatingDescription';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { RadioButton } from 'react-native-paper';
import QuestionComment from './QuestionComment';

const QuestionScore = ({
  questionId,
  options,
  answers,
  onOptionSelected,
  selectedOptions,
  comment,
}: {
  questionId?: string
  options?: QuestionOptionType[]
  answers?: SubmittedQuestionOptionType[]
  onOptionSelected?: (currentState: any) => void
  selectedOptions?: Set<string>
  comment?: string
}) => {
  const defaultSelectedOption = useMemo(() => {
    if (selectedOptions) {
      return Array.from(selectedOptions)[0]
    }

    return undefined
  }, [selectedOptions])

  const labels = useMemo(() => {
    if (answers?.length > 0) {
      return answers.map((option, index) => ({ id: option.id, title: option.titleSnapshot || index + 1, score: option.scoreSnapshot }));
    }

    return options.map((option, index) => ({ id: option.id, title: option.title || index + 1, score: option.score }));
  }, [answers, options])

  const ratings = useMemo(() => {
    if (answers?.length > 0) {
      return answers.map((option) => ({ description: option.descriptionSnapshot, score: option.scoreSnapshot, label: option.titleSnapshot }));
    }

    return options.map((option) => ({ description: option.description, score: option.score, label: option.title }));
  }, [options])

  const selectedAnswer = useMemo(() => answers?.find(answer => answer.answer), [answers])

  const [selectedOption, setSelectedOption] = useState(defaultSelectedOption)

  useEffect(() => {
    if (onOptionSelected) {
      onOptionSelected((currentState: any) => {
        console.log('onOptionSelected', selectedOption)
        currentState[questionId].optionsIds = new Set(selectedOption ? [selectedOption] : [])

        return {
          ...currentState
        }
      })
    }
  }, [selectedOption])

  const renderRadioGroup = () => {
    if (IS_WEB) {
      return <RadioGroup
        value={selectedAnswer?.id || selectedOption}
        onChange={(_, value) => {
          if (value) {
            setSelectedOption(value);
          }
        }}
        row
      >
        {labels?.map(({ id, title, score }) => <Column key={id}>
          <FormControlLabel
            disabled={!questionId}
            value={id}
            control={<Radio color='primary' />}
            label={title}
            labelPlacement='bottom'
            sx={{
              maxWidth: '325px'
            }}
          />

          <ScoreText>+ {score} pts</ScoreText>
        </Column>)}
      </RadioGroup>
    }

    return <RadioButton.Group
      onValueChange={(value) => setSelectedOption(value)}
      value={selectedAnswer?.id || selectedOption}
    >
      {labels?.map(({ id, title, score }) => (
        <View key={id} style={{ maxWidth: 325 }}>
          <RadioButton.Android
            value={id}
            disabled={!questionId}
          />

          <Text>{title} (+ {score} pts)</Text>
        </View>
      ))}
    </RadioButton.Group>
  }

  return <View>
    <Row>
      {renderRadioGroup()}

    </Row>

    <QuestionRatingDescription ratings={ratings} />

    <TitleWithMargin>Feedback</TitleWithMargin>

    <QuestionComment
      questionId={questionId}
      comment={selectedAnswer?.comment || comment}
      onOptionSelected={onOptionSelected}
    />
  </View>
}

const styles = StyleSheet.create({
  input: {
    maxHeight: 400,
    borderColor: COLORS.POWDER_BLUE,
    borderWidth: 2,
    borderRadius: 6,
    padding: 16,
    backgroundColor: 'white',
  },
})

const Divider = styled.View`
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #B4C5E8;
  width: 100%;
`;

const Title = styled.Text`
  font-weight: 600;
  font-size: 14px;
  color: ${COLORS.NAVY_BLUE};
`

const TitleWithMargin = styled(Title)`
  margin: 8px 0px 8px 0px;
`

const Row = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`

const Column = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 8px;
`

const ScoreText = styled.Text`
  font-size: 10px;
  color: ${COLORS.NAVY_BLUE};
  margin-top: 4px;
`

export default QuestionScore