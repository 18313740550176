import { useMutation } from '@apollo/react-hooks'
import { CREATE_EVENT } from 'graphql/mutations'
import { GET_TASKS_COMPLETE, LOCATION_SCREEN_QUERY } from 'graphql/queries'

import {
  CreateEventProps,
  buildCreateEventMutationVariables,
} from '../helpers/task-complete-helpers'
import { useAppContext } from 'utils/app-context'
import { deleteFile } from 'utils/phone'
import useCurrentLocation from 'hooks/useCurrentLocation'

interface CreateEventHookProps {
  locationHierarchyId: string
  taskId: string
}

function useCreateEvent(props: CreateEventHookProps) {
  const { locationHierarchyId, taskId } = props
  const appContextValue = useAppContext()
  const selectedDay = appContextValue.selectedDay
  const [createEvent] = useMutation(CREATE_EVENT)
  const { currentLocationHierarchyId } = useCurrentLocation()

  return async (data: CreateEventProps) => {
    const { eventType, createEventData } = data
    const mutationVariables = await buildCreateEventMutationVariables(
      eventType,
      { taskId, ...createEventData },
    )

    return createEvent({
      variables: {
        attributes: mutationVariables,
      },
      refetchQueries: [
        {
          query: GET_TASKS_COMPLETE,
          variables: {
            locationHierarchyId,
            date: selectedDay,
          },
        },
        {
          query: LOCATION_SCREEN_QUERY,
          variables: {
            parentId: currentLocationHierarchyId,
          },
        },
      ],
      update: () => {
        if (mutationVariables.imageFile) {
          deleteFile(mutationVariables.imageFile.uri)
        }
      },
    })
  }
}

export default useCreateEvent
