import React, { useEffect, useState } from 'react'
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Keyboard,
} from 'react-native'
import Modal from 'react-native-modal'

import Icon from 'components/common/Icon'

import { stylesGlobal } from 'constants/es-style'
import { ICON_NAMES } from 'constants/icons'
import COLORS from 'constants/colors'
import { CheckBox } from '@rneui/themed'
import CompleteButton from './buttons/CompleteButton'
import { IS_WEB } from 'constants/static'

type MultipleOptionsModal = {
  openModal: boolean
  onClose: () => void
  title: string
  allowMultiSelect?: boolean
  options: Array<MultipleOption> | null
  onOptionPress: (options: string[]) => void
  selectedOptionValue: string
}

const MultipleOptionsModal = ({
  openModal,
  onClose,
  title,
  allowMultiSelect,
  options,
  onOptionPress,
  selectedOptionValue,
}: MultipleOptionsModal) => {
  const getDefaultSet = () => new Set<string>(
    !selectedOptionValue.length ? [] : selectedOptionValue.split(','),
  )

  const [selectedOptions, setSelectedOptions] = useState(
    getDefaultSet()
  )

  useEffect(() => {
    setSelectedOptions(getDefaultSet())
  }, [selectedOptionValue])

  return (
    <Modal isVisible={openModal} style={stylesGlobal.modal}>
      <TouchableWithoutFeedback
        onPress={() => {
          if (!IS_WEB) {
            Keyboard.dismiss()
          }
        }}
      >
        <View style={[stylesGlobal.modalContent, styles.container]}>
          <View style={styles.headerContainer}>
            <Text
              style={stylesGlobal.modalContentHeaderText}
              numberOfLines={3}
              ellipsizeMode="tail"
            >
              {title}
            </Text>
            <View style={styles.closeButtonContainer}>
              <TouchableOpacity onPress={() => onClose()}>
                <Icon name={ICON_NAMES.CLOSE} size={28} />
              </TouchableOpacity>
            </View>
          </View>
          <ScrollView contentContainerStyle={styles.scrollContainer}>
            {!!options &&
              options.map((item) => {
                const isChecked = selectedOptions.has(item.option)

                return (
                  <CheckBox
                    key={item.id}
                    onPress={(_e) => {
                      if (allowMultiSelect) {
                        if (selectedOptions.has(item.option)) {
                          selectedOptions.delete(item.option)
                        } else {
                          selectedOptions.add(item.option)
                        }

                        setSelectedOptions(new Set(selectedOptions))
                      } else {
                        onOptionPress([item.option])
                      }
                    }}
                    title={item.option}
                    checked={isChecked}
                    checkedColor={COLORS.TURQUOISE}
                    uncheckedColor={COLORS.TURQUOISE}
                    checkedIcon={allowMultiSelect ? undefined : 'dot-circle-o'}
                    uncheckedIcon={allowMultiSelect ? undefined : 'circle-o'}
                    containerStyle={{
                      borderColor: COLORS.TURQUOISE,
                      borderWidth: isChecked ? 2 : 1,
                      borderRadius: 8,
                      backgroundColor: isChecked
                        ? COLORS.LIGHT_TURQUOISE
                        : 'white',
                    }}
                    textStyle={{
                      fontFamily: 'Poppins_400Regular',
                      color: COLORS.NAVY_BLUE,
                      fontSize: 18,
                      fontWeight: isChecked ? '600' : '400',
                    }}
                  />
                )
              })}
            {allowMultiSelect && (
              <View style={styles.completeContainer}>
                <CompleteButton
                  selected={false}
                  onPress={
                    selectedOptions.size == 0
                      ? null
                      : () => {
                        onOptionPress(Array.from(selectedOptions))
                      }
                  }
                />
              </View>
            )}
          </ScrollView>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 46,
    backgroundColor: 'white',
    maxHeight: '100%',
  },
  headerContainer: {
    flexDirection: 'row',
    alignContent: 'space-between',
    paddingLeft: 8,
    width: '100%',
  },
  titleText: {
    paddingTop: 12,
    flex: 1,
    flexWrap: 'wrap',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 20,
  },
  scrollContainer: {
    marginTop: 15,
    flexGrow: 1,
  },
  closeButtonContainer: {
    flex: 1,
    alignItems: 'flex-end',
  },
  completeContainer: {
    alignItems: 'flex-end',
    margin: 15,
  },
})

export default MultipleOptionsModal
