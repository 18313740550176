import { Box, CircularProgress, Divider, Link, Tab, Tabs, ThemeProvider, Tooltip } from '@mui/material'
import { MyTeamContext } from 'components/my-team/MyTeamContext'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import { GET_TASKS, GET_USER_SNIPPET_VALUES } from 'graphql/queries'
import { groupBy, isEmpty } from 'lodash'
import {
  GridColDef,
} from '@mui/x-data-grid-pro';
import { getDateWithoutTimezone } from 'components/my-team/utils/getDateWithoutTimezone'
import { addDays, isBefore } from 'date-fns'
import WorkflowsProgress from '../common/WorkflowsProgress'
import WorkflowsFilter from '../common/WorkflowsFilter'
import StyledDataGrid from '../common/StyledDataGrid'
import { DateRange } from '@mui/x-date-pickers-pro/models'
import { Dayjs } from 'dayjs';
import createCustomMuiTheme from 'theme/mui/createCustomMuiTheme'
import COLORS from 'constants/colors'
import ReporteeHeader from './ReporteeHeader'
import { replaceSnippetsInText } from 'utils/helpers/snippets-helper'
import { useTranslation } from 'react-i18next'
import ReporteeProgressBar from './ReporteeProgressBar'
import { TASK_TYPE_TO_NAME_MAP } from 'constants/static'
import { TASK_TYPES } from 'constants/types'
import { ROUTE_NAMES } from 'navigation/constants'
import { useHistory } from 'react-router-native'

const countPastDue = (tasks: any) => {
  return tasks.filter((task: any) => task.pastDue).length
}

const filterTasks = ({ tasks, filteredWorkflows, taskSearchQuery, workflowId, dateRange }: {
  tasks: TaskInterface[], filteredWorkflows: any, taskSearchQuery: string, workflowId: string, dateRange: DateRange<Dayjs>
}) => {
  return tasks
    .filter((task: any) => !!task?.id)
    .map((task: any) => ({
      ...task,
      workflowTitle: filteredWorkflows.find((workflow: any) => workflow.id == workflowId)?.title,
      pastDue: !task.lastEvent && task.taskType != TASK_TYPES.INFO && isBefore(addDays(new Date(task.dueDate?.replace(/-/g, '/')), 1), new Date())
    }))
    .filter((task: any) => taskSearchQuery.length === 0 || task.title?.toLowerCase().includes(taskSearchQuery.toLowerCase()))
    .filter((task: any) => {
      if (!dateRange[0] || !dateRange[1]) {
        return true
      }

      const dueDate = getDateWithoutTimezone(new Date(task.dueDate?.replace(/-/g, '/')))

      const isWithinDateRange =
        dueDate >= new Date(dateRange[0]!.toDate()) &&
        dueDate <= new Date(dateRange[1]!.toDate())

      return isWithinDateRange
    })
}

const ReporteeTasks = () => {
  const { i18n } = useTranslation()
  const history = useHistory()
  const { selectedReportee, reporteesWorkflows } = useContext(MyTeamContext)
  const [showInactiveWorkflows, setShowInactiveWorkflows] = useState(true)
  const [selectedTemplatesIds, setSelectedTemplatesIds] = useState<any>([])
  const [taskSearchQuery, setTaskSearchQuery] = useState('')
  const [dateRange, setDateRange] = React.useState<DateRange<Dayjs>>([
    null,
    null,
  ])
  const [tasksByRole, setTasksByRole] = useState<any>({})
  const [selectedTab, setSelectedTab] = useState('Employee')
  const [tasksGroupedByWorkflow, setTasksGroupedByWorkflow] = useState<any>({})

  const thisReporteeWorkflows = useMemo(() => reporteesWorkflows[selectedReportee?.id], [selectedReportee, reporteesWorkflows])
  const locationHierarchyIds = useMemo(() => {
    const ids: string[] = []

    for (const workflow of thisReporteeWorkflows) {
      if (workflow.children?.length > 0) {
        ids.push(...workflow.children.map((child: any) => child.id))
      } else {
        ids.push(workflow.id)
      }
    }

    return ids
  }, [thisReporteeWorkflows])
  const allTemplates = useMemo(() => thisReporteeWorkflows.map((workflow: any) => ({ id: workflow.templateCategoryId, title: workflow.title })), [thisReporteeWorkflows])
  const filteredWorkflows = useMemo(() => {
    const filteredByInactive = showInactiveWorkflows ? thisReporteeWorkflows : thisReporteeWorkflows.filter((workflow: any) => !(workflow.isExpired))

    if (selectedTemplatesIds.length === 0) {
      return filteredByInactive
    }

    return filteredByInactive.filter((workflow: any) => selectedTemplatesIds.includes(workflow.templateCategoryId))
  }, [thisReporteeWorkflows, selectedTemplatesIds, showInactiveWorkflows])
  const filteredTasks = useMemo(() => {
    return tasksByRole[selectedTab] || []
  }, [tasksByRole, selectedTab])

  const { data, loading } = useQuery(GET_TASKS, {
    variables: {
      locationHierarchyIds,
    },
    fetchPolicy: 'network-only',
  })
  const { data: userSnippetValuesData, loading: userSnippetValuesLoading } = useQuery(GET_USER_SNIPPET_VALUES, {
    variables: { userId: selectedReportee.id },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    setShowInactiveWorkflows(true)
    setSelectedTemplatesIds([])
  }, [selectedReportee])

  useEffect(() => {
    if (!loading && !userSnippetValuesLoading && data) {
      const tasksGroupedByLocation = groupBy(data?.tasksFromLocations, 'locationHierarchyId')

      const sectionsGroupedByWorkflows: { [key: string]: string[]; } = {}

      for (const workflow of filteredWorkflows) {
        if (workflow.children?.length > 0) {
          sectionsGroupedByWorkflows[workflow.id] = [...workflow.children.map((child: any) => child.id)]
        } else {
          sectionsGroupedByWorkflows[workflow.id] = []
        }
      }

      const tasksGroupedByWorkflow: any = {}
      for (const [workflowId, sections] of Object.entries(sectionsGroupedByWorkflows)) {
        if (sections.length == 0) {
          tasksGroupedByWorkflow[workflowId] = filterTasks(
            {
              tasks: tasksGroupedByLocation[workflowId],
              filteredWorkflows,
              taskSearchQuery,
              workflowId,
              dateRange,
            }
          )
          continue
        }

        tasksGroupedByWorkflow[workflowId] = filterTasks(
          {
            tasks: sections.map((section: any) => tasksGroupedByLocation[section]).flat(),
            filteredWorkflows,
            taskSearchQuery,
            workflowId,
            dateRange,
          }
        )
      }
      setTasksGroupedByWorkflow(tasksGroupedByWorkflow)

      const tasksByRole: { [key: string]: any[] } = {}
      const allTasks: TaskInterface[] = Object.values(tasksGroupedByWorkflow).flat() as TaskInterface[]
      for (const task of allTasks) {
        for (const role of (task.templateRoles || [])) {
          if (!tasksByRole[role.name]) {
            tasksByRole[role.name] = []
          }

          tasksByRole[role.name].push({
            ...task,
            title: replaceSnippetsInText(
              task.title,
              userSnippetValuesData?.userSnippetValues || {},
              (key) => console.log(`Snippet ${key} not found`),
              i18n.resolvedLanguage,
            )
          })
        }
      }

      if (tasksByRole['Manager']) {
        setSelectedTab('Manager')
      } else {
        setSelectedTab('Employee')
      }

      setTasksByRole(tasksByRole)
    }
  }, [data, loading, userSnippetValuesLoading, userSnippetValuesData, filteredWorkflows, taskSearchQuery, dateRange])

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'title',
      headerName: 'Task Name',
      flex: 2,
      renderCell: (params) => {
        return <Tooltip title={params.value}>
          {selectedTab == 'Manager' ? <Link
            component="button" onClick={() => {
              history.push({
                pathname: ROUTE_NAMES.TASKS_TODAY,
                search: `?locationHierarchyId=${params.row.locationHierarchyId}&taskId=${params.row.id}`
              })
            }}>{params.value}</Link> : params.value}
        </Tooltip>
      }
    },
    {
      field: 'taskType',
      headerName: 'Type',
      flex: 1,
      renderCell: (params) => {
        const value: any = TASK_TYPE_TO_NAME_MAP[params.value]

        return value || params.value
      }
    },
    {
      field: 'workflowTitle',
      headerName: 'Workflow',
      flex: 2,
      renderCell: (params) => {
        return <Tooltip title={params.value}>{params.value}</Tooltip>
      }
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      flex: 1,
      type: 'date',
      valueGetter: (value: any, row: any) => {
        if (value === null || row.taskType == TASK_TYPES.INFO) return null

        return getDateWithoutTimezone(new Date(value))
      },
    },
    {
      field: 'lastEvent',
      headerName: 'Status',
      flex: 1,
      valueGetter: (value: any, row: any) => {
        if (row.taskType == TASK_TYPES.INFO) return 'N/A'
        if (value === null && row.pastDue) return 'Past Due'
        if (value === null) return ''

        switch (value.eventType) {
          case 'ADD_CANT_TEXT':
            return "Can't Complete"
          case 'NOT_APPLICABLE':
            return "Not Applicable"
          case 'TASK_READ':
            return "Task Read"
          case 'QUIZ_SUBMITTED':
            return `${value.percentage}%`
          case 'SCORECARD_SUBMITTED':
            return `${value.score}/${value.totalScore}`
          default:
            return 'Completed'
        }
      },
      renderCell: (params) => {
        return <Tooltip title={params.value}>{params.value}</Tooltip>
      }
    },
  ], [selectedTab])

  const theme = createCustomMuiTheme()
  theme.palette.primary.main = COLORS.NAVY_BLUE;
  theme.components.MuiInput = {
    styleOverrides: {
      root: {
        color: COLORS.NAVY_BLUE,
        fontSize: '14px',
      },
    },
  }

  if (loading) return <LoadingContainer>
    <CircularProgress />
  </LoadingContainer>

  return (
    <Container>
      <ReporteeHeader reportee={selectedReportee} />

      <Divider />

      <Body>
        <Filters>
          <FiltersBody>
            <WorkflowsFilter
              allTemplates={allTemplates}
              showInactiveWorkflows={showInactiveWorkflows}
              setShowInactiveWorkflows={setShowInactiveWorkflows}
              selectedTemplatesIds={selectedTemplatesIds}
              setSelectedTemplatesIds={setSelectedTemplatesIds}
              setTaskSearchQuery={setTaskSearchQuery}
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </FiltersBody>
        </Filters>

        <ThemeProvider theme={theme}>
          {!isEmpty(tasksByRole) && <>
            <Tabs
              value={selectedTab}
              onChange={(_, newValue) => { setSelectedTab(newValue); }}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              {Object.keys(tasksByRole)
                .sort((a, b) => (a === 'Employee' ? -1 : b === 'Employee' ? 1 : 0))
                .sort((a, b) => (a === 'Manager' ? -1 : b === 'Manager' ? 1 : 0))
                .map((role) => {
                  const label = role == 'Manager' ? `My Tasks (${tasksByRole[role].length})` : `${role} (${tasksByRole[role].length})`;

                  return <Tab key={role} value={role} label={label} />;
                })}
            </Tabs>
            <Divider sx={{ marginBottom: '24px' }} /></>}
        </ThemeProvider>

        {filteredWorkflows?.length > 0 && <WorkflowsProgress
          titles={filteredWorkflows.slice(0, 3).map((workflow: any) => workflow.title)}
          bars={filteredWorkflows.slice(0, 3).map((workflow: any) => {
            return <ProgressBar>
              <ReporteeProgressBar
                percentage={workflow.percent}
                color={workflow.color}
                completedTasksCount={workflow.completedTasksCount}
                pastDueTasksCount={countPastDue(tasksGroupedByWorkflow[workflow.id] || [])}
                tasksCount={workflow.tasks}
                height='12'
              />
            </ProgressBar>
          })}
        />}

        <Table>
          <StyledDataGrid
            hideFooter
            columns={columns}
            rows={filteredTasks}
          />
        </Table>
      </Body>
    </Container>
  )
}

const Filters = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;
`

const FiltersBody = styled.div`
  display: flex;
  flex-direction: row;
  color: #2E4785;

  .MuiFormControlLabel-label {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 36px 24px 36px;
  flex: 1;
`

const ProgressBar = styled.div`
  padding-bottom: 10px;
`

const Table = styled.div`
  height: 580px;
  margin-top: 50px;
`

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 16px;
  box-shadow: 0px 12px 24px -4px #919EAB1F;
  background-color: #FFFFFF;
`

export default ReporteeTasks