import React, { useState } from 'react'
import { View, TouchableOpacity, Text } from 'react-native'
import ImageModal from 'react-native-image-modal'
import useNetworkStatus from 'components/common/hooks/useNetworkStatus'
import EStyleSheet from 'react-native-extended-stylesheet'

import COLORS from 'constants/colors'
import SignatureModal from 'components/task-complete/SignatureModal'
import { STYLE_CONSTANTS } from 'constants/es-style'
import { checkIsLocalFile } from 'utils/phone'
import completeButtonStyles from './constants/completeButtonStyles'
import {
  SignatureIcon,
  NopeIcon,
  NotApplicableIcon,
} from 'components/common/custom-icons'
import useCompleteElementStates from './hooks/useCompleteElementStates'
import SignatureButton from 'components/task-list/buttons/SignatureButton'

const ImageVisibleWhenOnline = ({
  online,
  isLocalImage,
}: {
  online: boolean
  isLocalImage: boolean
}) =>
  !online &&
  !isLocalImage && (
    <View style={styles.offlineImageTextWrapper}>
      <Text style={styles.offlineImageText}>image</Text>
      <Text style={styles.offlineImageText}>visible</Text>
      <Text style={styles.offlineImageText}>once</Text>
      <Text style={styles.offlineImageText}>online</Text>
    </View>
  )

// photo icon to take picture for task completition
const TaskSignToComplete = (props: CompleteElementProps) => {
  const [signatureModalOpen, setSignatureModalOpen] = useState(false)
  const { isConnected: online } = useNetworkStatus()
  const {
    sessionUuid,
    id: taskId,
    locationHierarchyId,
    closeModal,
    completedEvent,
    completed
  } = props

  const {
    isNotApplicableCompletedEvent,
    isCantComplete,
    completeState,
    taskDone,
  } = useCompleteElementStates({
    completedEvent,
  })

  const imageUrl = props?.completedEvent?.mediaUrls?.[0]
  const isLocalImage = checkIsLocalFile(imageUrl)

  const openSignCanvas = () => {
    if (props.onNavigate) {
      props.onNavigate()
    }

    setSignatureModalOpen(true)
  }

  const closeSignatureModal = () => {
    setSignatureModalOpen(false)
    if(props.onComplete) {
      props.onComplete()
    }
  }

  const renderIcon = () => {
    const ICON_SIZE = 36

    if (!(completeState || completed)) {
      return (
        <SignatureIcon height={ICON_SIZE} width={ICON_SIZE} opacity={0.16} />
      )
    } else if (isCantComplete) {
      return <NopeIcon height={ICON_SIZE} width={ICON_SIZE} />
    } else if (isNotApplicableCompletedEvent) {
      return <NotApplicableIcon height={ICON_SIZE} width={ICON_SIZE} />
    }

    return <SignatureIcon height={ICON_SIZE} width={ICON_SIZE} />
  }

  const renderSignButton = () => {
    if (!props.showAsButton) {
      return (
        <View style={styles.container}>
          {!!imageUrl?.length && (
            <View style={styles.imageModalThumbnailWrapper}>
              {(online || isLocalImage) && (
                <ImageModal
                  swipeToDismiss={false}
                  resizeMode="contain"
                  imageBackgroundColor={COLORS.TRANSPARENT}
                  style={styles.imageModalThumbnail}
                  source={{ uri: imageUrl }}
                />
              )}
              <ImageVisibleWhenOnline {...{ online, isLocalImage }} />
            </View>
          )}
          <TouchableOpacity
            onPress={openSignCanvas}
            style={styles.iconContainer}
          >
            {renderIcon()}
          </TouchableOpacity>
        </View>
      )
    } else {
      return <SignatureButton selected={taskDone} onPress={openSignCanvas} />
    }
  }

  return (
    <>
      {renderSignButton()}
      <SignatureModal
        isOpen={signatureModalOpen}
        sessionUuid={sessionUuid}
        taskId={taskId}
        locationHierarchyId={locationHierarchyId}
        closeTaskListModal={closeModal}
        onClose={closeSignatureModal}
      />
    </>
  )
}

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    ...completeButtonStyles.iconContainer,
  },
  imageModalThumbnailWrapper: {
    ...STYLE_CONSTANTS.FLEX_CENTER_HORIZ,
  },
  offlineImageTextWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  offlineImageText: {
    fontSize: 8,
  },
  imageModalThumbnail: {
    width: 30,
    height: 40,
  },
  image: {
    width: 30,
    height: 40,
    marginRight: 5,
  },
})

export default TaskSignToComplete
