import React, { useState } from 'react'
import styled from 'styled-components/native'
import DefaultButton from '../buttons/DefaultButton'
import { TouchableOpacity, View } from 'react-native'
import Modal from 'react-native-modal'
import { ICON_NAMES } from 'constants/icons'
import Icon from 'components/common/Icon'

interface DoceboCourseTaskProps {
  task: TaskInterface,
  locationHierarchyId: string,
  completedEvent: TaskCompleteType
}

const DoceboCourseTask = ({ task }: DoceboCourseTaskProps) => {
  const [showCourse, setShowCourse] = useState(false)

  return <Container>
    <CourseLaunchContainer>
      <DefaultButton title="Launch Course" onPress={() => {
        setShowCourse(true)
      }}></DefaultButton>
    </CourseLaunchContainer>

    <Modal isVisible={showCourse} style={{ width: '100%', height: '100%', margin: 0, }}>
      <View style={{ backgroundColor: 'white', position: 'absolute', left: 12, top: 12, right: 10, bottom: 10, borderRadius: 10, overflow: 'hidden' }}>
        <View style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: 44, zIndex: 2, boxShadow: '0px 4px 16px 0px #0000001F' }}>
          <TouchableOpacity onPress={
            () => setShowCourse(false)
          }
            style={{ position: 'absolute', right: 10, top: 8 }}>
            <Icon name={ICON_NAMES.CLOSE} size={20} onPress={() => setShowCourse(false)} />
          </TouchableOpacity>
        </View>
        <View style={{ position: 'absolute', top: 44, right: 0, bottom: 0, left: 0, overflow: 'hidden' }}>

          {task?.doceboCourseUrl ? <iframe src={task?.doceboCourseUrl} width="100%" height="100%" style={{ border: 'none' }} /> : <div>loading</div>}
        </View>
      </View>
    </Modal>
  </Container>
}

const Container = styled.View`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`

const CourseLaunchContainer = styled.View`
  display: flex;
  width: 100%;
  padding: 40px 98px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #F1F5F9;
`

export default DoceboCourseTask
