import React from 'react'
import styled from 'styled-components'

import COLORS from 'constants/colors'
import { Trans, useTranslation } from 'react-i18next'

const WelcomeWizardWelcomeVideo = () => {
  const { t } = useTranslation()

  return <>
    <Column>
      <TextContainer>
        <Title>{t('welcomeToJll')}</Title>

        <Description>{<Trans i18nKey='welcomeWizardWelcomeVideoDescription' />}</Description>
      </TextContainer>

      <StyledVideo controls >
        <source src="https://tangelo-prod.s3.amazonaws.com/public/One_JLL_Welcome_video_007.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </StyledVideo>
    </Column>
  </>
}

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
  padding-left: 50px;
`

const StyledVideo = styled.video`
  width: 600px;
  align-self: center;
  border-radius: 12px;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.06);
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  font-size: 32px;
  font-weight: 600;
  color: ${COLORS.NAVY_BLUE}
`

const Description = styled.div`
  margin-top: 24px;
  font-size: 18px;
  font-weight: 400;
  color: ${COLORS.FIVES_GREY};
`

export default WelcomeWizardWelcomeVideo