import React, { useMemo, useState } from 'react'

import COLORS from 'constants/colors'
import styled from 'styled-components/native'
import { TouchableOpacity, Text } from 'react-native';
import { IS_WEB } from 'constants/static';
import { Icon } from 'components/Index';
import { ICON_NAMES } from 'constants/icons';


type RatingType = {
  description: string,
  score: number,
  label: string
}

const QuestionRatingDescription = ({ ratings }: { ratings: RatingType[] }) => {
  const [isOpen, setIsOpen] = useState(false)
  const hasRatings = useMemo(() => ratings?.some(rating => rating?.description?.length > 0), [ratings])

  if (!hasRatings) return null

  return <>
    <Divider />

    <RatingDescription >
      <TouchableOpacity onPress={() => setIsOpen(!isOpen)}>
        <Row>
          <Title>Rating Description</Title>
          <Icon
            name={isOpen ? ICON_NAMES.CHEVRON_UP : ICON_NAMES.CHEVRON_DOWN}
            size={24}
            color={COLORS.NAVY_BLUE}
          />
        </Row>
      </TouchableOpacity>

      {isOpen && <Descriptions>
        <RatingHeader>
          <RatingNumber>
            <Text style={{ color: '#334155', fontWeight: 500 }}>RATING</Text>
          </RatingNumber>
          <RatingText>
            <Text style={{ color: '#334155', fontWeight: 500, marginLeft: '0px' }}>DESCRIPTION</Text>
          </RatingText>
          <RatingPoints>
            <Text style={{ color: '#334155', fontWeight: 500 }}>POINTS</Text>
          </RatingPoints>
        </RatingHeader>

        {ratings.map(({ description, score, label }, index) => <>
          <Rating>
            <RatingNumber>
              {label}
            </RatingNumber>
            <RatingText>
              {description}
            </RatingText>
            <RatingPoints>
              {score}
            </RatingPoints>
          </Rating>

          {index < ratings.length - 1 && <SmallDivider />}
        </>)}
      </Descriptions>}
    </RatingDescription>

    <Divider />
  </>
}

const Divider = styled.View`
  border: 1px solid #CBD5E1;
  marginTop: 15px;
  marginBottom: 15px;
`

const SmallDivider = styled.View`
  border: 1px solid #CBD5E1;
  marginTop: 8px;
  marginBottom: 8px;
`

const Title = styled.Text`
  font-weight: 600;
  font-size: 14px;
  color: ${COLORS.NAVY_BLUE};
  margin: 4px 0px 4px 0px;
`

const Descriptions = styled.View`
  margin-top: 10px;
  border: 0.5px solid #B4C5E8;
  border-radius: 10px;
  background-color: white;
`

const RatingPoints = styled.Text`
  display: flex;
  justify-content: center;
  width: 60px;
  font-size: 14px;
  font-weight: 600;
  color: #64748B;
`

const RatingNumber = styled.Text`
  width: ${IS_WEB ? '125px' : '50px'};
  word-break: break-word;
  flex-direction: column;
  font-size: 14px;
  font-weight: 700;
  color: ${COLORS.NAVY_BLUE};
`

const RatingText = styled.Text`
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  color: #475569;
  margin-left: 20px;
  margin-right: 10px;
`

const Rating = styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
  margin-left: 10px;
`

const RatingHeader = styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
  background-color: #F0F5FF;
  padding: 6px 10px 6px 10px;
  border-radius: 10px;
`

const Row = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

const RatingDescription = styled.View`
`

export default QuestionRatingDescription
