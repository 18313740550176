import React from 'react'

import COLORS from 'constants/colors'
import styled from 'styled-components'

const DefaultButton = ({
  selected = false,
  title,
  onPress,
  icon,
  iconRight = false,
  hoverIcon,
  fullWidth = false,
  disabled = false
}: {
  selected?: boolean
  title: string
  icon?: any
  hoverIcon?: any
  onPress: { (): void }
  iconRight?: boolean
  fullWidth?: boolean
  disabled?: boolean
}) => {
  return (
    <StyledDefaultButton
      selected={selected}
      iconRight={iconRight}
      onClick={onPress}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      <div className="button-content">
        <span className="button-icon">{selected ? icon : hoverIcon}</span>
        {title}
      </div>
    </StyledDefaultButton>
  )
}

const StyledDefaultButton = styled.button`
  width: ${({ fullWidth }) => fullWidth ? '100%' : '192px'};
  height: 56px;
  background-color: ${({ selected }) =>
    selected ? COLORS.LIGHT_TURQUOISE : 'white'};

  color: ${COLORS.DARK_TURQUOISE};
  font-weight: 600;
  font-size: 16px;

  border: ${({ selected }) => (selected ? '2px' : '1px')} solid
    ${COLORS.TURQUOISE};
  box-sizing: border-box;
  border-radius: 8px;

  .button-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: ${({ iconRight }) => (iconRight ? 'row-reverse' : 'row')};
  }

  .button-icon {
    margin-left: ${({ iconRight }) => (iconRight ? '6px' : '0px')};
    display: ${({ selected }) => (selected ? 'flex' : 'none')};
  }

  &:hover .button-icon {
    display: flex;
  }

  &:hover {
    cursor: pointer;
    border-width: 2px;
  }
`

export default DefaultButton
