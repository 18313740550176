import React from 'react'
import styled from 'styled-components'
import { sortBy } from 'lodash'
import Level1ResourcesSection from './components/Level1ResourcesSection.web'
import { useTranslation } from 'react-i18next'

import useResources from 'hooks/useResources'
import COLORS from 'constants/colors'
import AboutTheOrg from './components/AboutTheOrg.web'
import PageFrame from 'components/common/PageFrame'

const ResourcesScreenWeb = () => {
  const { sections } = useResources()
  const { t } = useTranslation()

  return <PageFrame>
    <Container>
      <Title>{t('resources')}</Title>
      <AboutTheOrg />
      <div>
        {!!sections &&
          sortBy(sections, ['position']).map((section) => (
            <Level1ResourcesSection
              key={section.id}
              title={section.title}
              children={section.children}
            />
          ))}
      </div>
    </Container>
  </PageFrame>
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  font-weight: 600;
  font-size: 36px;
  color: ${COLORS.GRAYDARK3};
  margin-bottom: 24px;
`

export default ResourcesScreenWeb
