import {
  SimpleLineIcons,
  MaterialIcons,
  EvilIcons,
  AntDesign,
  FontAwesome,
  Feather,
  MaterialCommunityIcons,
  Foundation,
  FontAwesome5,
  Ionicons,
  Entypo,
} from '@expo/vector-icons'
import { TASK_TYPES } from 'constants/types'

// icon libraries
export const ICON_LIBS: ObjectStringKeyType = {
  SIMPLE_LINE_ICONS: SimpleLineIcons,
  MATERIAL: MaterialIcons,
  MATERIAL_COMMUNITY: MaterialCommunityIcons,
  EVIL_ICONS: EvilIcons,
  ANT_DESIGN: AntDesign,
  IONICONS: Ionicons,
  FEATHER: Feather,
  FOUNDATION: Foundation,
  FONT_AWESOME: FontAwesome,
  FONT_AWESOME_5: FontAwesome5,
  ENTYPO: Entypo,
}

export const ICON_SIZES = {
  LIST_LEFT: 30,
  TASK_LEFT: 25,
  HEADER_ICON: 20,
}

// contant icon names
export const ICON_NAMES = {
  ARROW_RIGHT: 'ARROW_RIGHT',
  DEFAULT: 'DEFAULT',
  LIST_ALL: 'LISTALL',
  CHECK: 'CHECK',
  STICKY_NOTE: 'STICKY_NOTE',
  TASK: 'TASK',
  TODAY: 'TODAY',
  GO_BACK: 'GO_BACK',
  TASK_LIST: 'TASK_LIST',
  LOCATION: 'LOCATION',
  USE_IMAGE: 'USE_IMAGE',
  DONT_USE_IMAGE: 'DONT_USE_IMAGE',
  SPINNER: 'SPINNER',
  CAMERA: 'CAMERA',
  ADD_A_PHOTO: 'ADD_A_PHOTO',
  SIGN: 'SIGN',
  MESSAGE: 'MESSAGE',
  TRASH: 'TRASH',
  START_SESSION: 'START_SESSION',
  END_SESSION: 'END_SESSION',
  HOME: 'HOME',
  DATE: 'DATE',
  GO_TO_PARENT: 'GO_TO_PARENT',
  FUTURE: 'FUTURE',
  CLOSE: 'CLOSE',
  LOGOUT: 'LOGOUT',
  INFO: 'INFO',
  CLOUD_OFF: 'CLOUD_OFF',
  ONE_X: 'ONE_X',
  OPTIONS: 'OPTIONS',
  FLASH: 'FLASH',
  FLASH_OFF: 'FLASH_OFF',
  EMPTY_CIRCLE: 'EMPTY_CIRCLE',
  CLOUD_UPLOAD: 'CLOUD_UPLOAD',
  EXCLAMATION: 'EXCLAMATION',
  ARROW_EXPAND: 'ARROW_EXPAND',
  ERROR_OUTLINE: 'ERROR_OUTLINE',
  CHEVRON_RIGHT: 'CHEVRON_RIGHT',
  CHEVRON_UP: 'CHEVRON_UP',
  CHEVRON_DOWN: 'CHEVRON_DOWN',
  VIDEO: 'VIDEO',
  SCORM_COURSE: 'SCORM_COURSE',
  DOCEBO: 'DOCEBO',
  MY_TASKS: 'MY_TASKS',
  RESOURCES: 'RESOURCES',
  EXPIRED: 'EXPIRED',
}

export type IconNamesUnion = (typeof ICON_NAMES)[keyof typeof ICON_NAMES]

// constant icon config
export const ICONS = {
  [ICON_NAMES.DEFAULT]: {
    lib: ICON_LIBS.ANT_DESIGN,
    name: 'question',
  },
  [ICON_NAMES.LIST_ALL]: {
    lib: ICON_LIBS.SIMPLE_LINE_ICONS,
    name: 'list',
  },
  [ICON_NAMES.STICKY_NOTE]: {
    lib: ICON_LIBS.MATERIAL_COMMUNITY,
    name: 'note-text',
  },
  [ICON_NAMES.ARROW_RIGHT]: {
    lib: ICON_LIBS.ANT_DESIGN,
    name: 'right',
  },
  [ICON_NAMES.TODAY]: {
    lib: ICON_LIBS.MATERIAL,
    name: 'today',
  },
  [ICON_NAMES.CLOUD_OFF]: {
    lib: ICON_LIBS.MATERIAL,
    name: 'cloud-off',
  },
  [ICON_NAMES.ONE_X]: {
    lib: ICON_LIBS.MATERIAL,
    name: 'filter-1',
  },
  [ICON_NAMES.GO_BACK]: {
    lib: ICON_LIBS.FEATHER,
    name: 'arrow-left',
  },
  [ICON_NAMES.TASK_LIST]: {
    lib: ICON_LIBS.MATERIAL,
    name: 'playlist-add-check',
  },
  [ICON_NAMES.USE_IMAGE]: {
    lib: ICON_LIBS.FONT_AWESOME,
    name: 'thumbs-up',
  },
  [ICON_NAMES.DONT_USE_IMAGE]: {
    lib: ICON_LIBS.FONT_AWESOME,
    name: 'thumbs-down',
  },
  [ICON_NAMES.LOCATION]: {
    lib: ICON_LIBS.MATERIAL_COMMUNITY,
    name: 'map-marker',
  },
  [ICON_NAMES.START_SESSION]: {
    lib: ICON_LIBS.MATERIAL,
    name: 'playlist-play',
  },
  [ICON_NAMES.END_SESSION]: {
    lib: ICON_LIBS.MATERIAL_COMMUNITY,
    name: 'playlist-check',
  },
  [ICON_NAMES.SPINNER]: {
    lib: ICON_LIBS.FONT_AWESOME,
    name: 'spinner',
  },
  [ICON_NAMES.CAMERA]: {
    lib: ICON_LIBS.FONT_AWESOME,
    name: 'camera',
  },
  [ICON_NAMES.MESSAGE]: {
    lib: ICON_LIBS.MATERIAL,
    name: 'message',
  },
  [ICON_NAMES.SIGN]: {
    lib: ICON_LIBS.MATERIAL,
    name: 'create',
  },
  [ICON_NAMES.TRASH]: {
    lib: ICON_LIBS.EVIL_ICONS,
    name: 'trash',
  },
  [TASK_TYPES.PHOTO]: {
    lib: ICON_LIBS.EVIL_ICONS,
    name: 'camera',
  },
  [TASK_TYPES.TOGGLE]: {
    lib: ICON_LIBS.EVIL_ICONS,
    name: 'check',
  },
  [ICON_NAMES.CHECK]: {
    lib: ICON_LIBS.IONICONS,
    name: 'checkmark-circle-outline',
  },
  [ICON_NAMES.TASK]: {
    lib: ICON_LIBS.MATERIAL_COMMUNITY,
    name: 'format-list-checks',
  },
  [ICON_NAMES.HOME]: {
    lib: ICON_LIBS.ANT_DESIGN,
    name: 'home',
  },
  [ICON_NAMES.INFO]: {
    lib: ICON_LIBS.ANT_DESIGN,
    name: 'info',
  },
  [ICON_NAMES.DATE]: {
    lib: ICON_LIBS.FONT_AWESOME,
    name: 'calendar',
  },
  [ICON_NAMES.GO_TO_PARENT]: {
    lib: ICON_LIBS.IONICONS,
    name: 'ios-arrow-up',
  },
  [ICON_NAMES.FUTURE]: {
    lib: ICON_LIBS.MATERIAL_COMMUNITY,
    name: 'clock-fast',
  },
  [ICON_NAMES.ADD_A_PHOTO]: {
    lib: ICON_LIBS.MATERIAL_COMMUNITY,
    name: 'add-a-photo',
  },
  [ICON_NAMES.CLOSE]: {
    lib: ICON_LIBS.ANT_DESIGN,
    name: 'close',
  },
  [ICON_NAMES.LOGOUT]: {
    lib: ICON_LIBS.SIMPLE_LINE_ICONS,
    name: 'logout',
  },
  [ICON_NAMES.OPTIONS]: {
    lib: ICON_LIBS.SIMPLE_LINE_ICONS,
    name: 'options-vertical',
  },
  [ICON_NAMES.FLASH]: {
    lib: ICON_LIBS.IONICONS,
    name: 'flash',
  },
  [ICON_NAMES.FLASH_OFF]: {
    lib: ICON_LIBS.IONICONS,
    name: 'flash-off',
  },
  [ICON_NAMES.EMPTY_CIRCLE]: {
    lib: ICON_LIBS.IONICONS,
    name: 'ellipse-outline',
  },
  [ICON_NAMES.CLOUD_UPLOAD]: {
    lib: ICON_LIBS.MATERIAL,
    name: 'cloud-upload',
  },
  [ICON_NAMES.EXCLAMATION]: {
    lib: ICON_LIBS.FONT_AWESOME,
    name: 'exclamation-circle',
  },
  [ICON_NAMES.ARROW_EXPAND]: {
    lib: ICON_LIBS.MATERIAL_COMMUNITY,
    name: 'arrow-expand',
  },
  [ICON_NAMES.ERROR_OUTLINE]: {
    lib: ICON_LIBS.MATERIAL_COMMUNITY,
    name: 'alert-circle-outline',
  },
  [ICON_NAMES.CHEVRON_RIGHT]: {
    lib: ICON_LIBS.MATERIAL_COMMUNITY,
    name: 'chevron-right',
  },
  [ICON_NAMES.CHEVRON_UP]: {
    lib: ICON_LIBS.MATERIAL_COMMUNITY,
    name: 'chevron-up',
  },
  [ICON_NAMES.CHEVRON_DOWN]: {
    lib: ICON_LIBS.MATERIAL_COMMUNITY,
    name: 'chevron-down',
  },
  [ICON_NAMES.VIDEO]: {
    lib: ICON_LIBS.MATERIAL_COMMUNITY,
    name: 'video-check',
  },
  [ICON_NAMES.SCORM_COURSE]: {
    lib: ICON_LIBS.MATERIAL_COMMUNITY,
    name: 'school',
  },
  [ICON_NAMES.DOCEBO]: {
    lib: ICON_LIBS.MATERIAL_COMMUNITY,
    name: 'school',
  },
  [ICON_NAMES.MY_TASKS]: {
    lib: ICON_LIBS.MATERIAL_COMMUNITY,
    name: 'check-circle-outline',
  },
  [ICON_NAMES.RESOURCES]: {
    lib: ICON_LIBS.MATERIAL_COMMUNITY,
    name: 'bookmark',
  },
  [ICON_NAMES.EXPIRED]: {
    lib: ICON_LIBS.MATERIAL_COMMUNITY,
    name: 'clock-alert-outline',
  },
}

export default ICONS
