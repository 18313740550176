{
  "monthDayYearDateFormat": "LLLL d, y",
  "monthDayDateFormat": "LLLL d",
  "welcome": "Herzlich Willkommen,\n{{name}}!",
  "clickOnTasksSection": "Um zu beginnen, klicke bitte auf die Aufgabe",
  "search": "Suchen",
  "typeHereToAddText": "Hier eingeben...",
  "complete": "Abgeschlossen",
  "completed": "Vervollständigt",
  "cantComplete": "Kann nicht abgeschlossen werden",
  "cantCompleteTask": "Vervollständigen der Aufgabe nicht möglich",
  "notes": "Aufgabennotizen",
  "back": "Zurück",
  "sessionNotes": "Kursnotizen",
  "confirm": "Bestätigen",
  "notApplicable": "Nicht anwendbar",
  "logout": "Ausloggen",
  "teamMetrics": "Team-Metriken",
  "taskNotesDescription": "Verwende diesen Bereich, um sich kursbezogene Notizen zu machen",
  "comment": "Kommentar",
  "pleaseSelect": "Bitte auswählen...",
  "eSignature": "Digitale Unterschrift",
  "takePicture": "Foto aufnehmen",
  "accept": "Bestätigen",
  "home": "Heim",
  "tasksFor": "Aufgaben für",
  "due": "Fällig",
  "dueToday": "Heute fällig",
  "tasks": "Aufgaben",
  "task": "Aufgabe",
  "tasksToDo": "{{tasks}} Aufgaben zu bearbeiten",
  "taskToDo": "{{tasks}} Aufgabe zu bearbeiten",
  "infoTasksToDo": "{{numberInfoTasks}} Nur-Info-Aufgaben",
  "infoTaskToDo": "{{numberInfoTasks}} Nur-Info-Aufgabe",
  "infoTasks": "{{numberInfoTasks}} Info-Aufgaben",
  "infoTask": "{{numberInfoTasks}} Info-Aufgabe",
  "by": "von",
  "markedCantComplete": "Markierte können nicht vervollständigt werden",
  "markedNotApplicable": "Markierte können nicht angewandt werden",
  "markedRead": "Lesen",
  "tasksComplete": "Aufgaben vervollständigt",
  "allTasksComplete": "Alle {{tasks}} Aufgaben vervollständigt",
  "someTasksComplete": "{{numberOfCompletedTasks}} von {{numberOfTasks}} Aufgaben vervollständigt",
  "upNextTitle": "Nächster Punkt",
  "upNextSubtitle": "Erhalte ganz einfach Zugang zu deinen nächsten Aufgaben durch deine Arbeitsabläufe",
  "upNextDoneTitle": "Du musst ganz schön erschöpft sein!",
  "upNextDoneSubtitle": "Super gemacht! Du hast keine weiteren Aufgaben zum Bearbeiten! Sobald es neue Aufgaben für dich gibt, wirst du per E-Mail darüber benachrichtigt!",
  "progress": "Ablauf",
  "ago": "her",
  "dueIn": "Fällig in",
  "days": "Tagen",
  "resources": "Ressourcen",
  "resourcesSubtitle": "Verwende bitte diese erstellte Liste markierter Ressourcen",
  "yourResources": "Ihre Ressourcen",
  "yourResourcesSubtitle": "Schneller Zugriff auf all Ihre hilfreichen Links",
  "reports": "Berichte",
  "signIn": "Einloggen",
  "forgotPassword": "Passwort vergessen?",
  "emailAddress": "E-Mail-Adresse",
  "password": "Passwort",
  "newPassword": "Neues Passwort",
  "repeatPassword": "Passwort wiederholen",
  "passwordError": "Passwörter müssen übereinstimmen",
  "passwordLengthError": "Passwort muss mindestens 8 Zeichen lang sein",
  "resetPassword": "Neues Passwort erstellen",
  "resetPasswordInfo": "Anmeldung mit temporärem Passwort war erfolgreich. \nBitte erstellen Sie ein permanentes Passwort",
  "forgottenPassword": "Passwort vergessen?",
  "forgottenPasswordExplanationWithMagicLink": "Geben Sie unten Ihre E-Mail-Adresse ein und wählen Sie zwischen dem Senden einer E-Mail zum Zurücksetzen des Passworts oder einem einmaligen magischen Link, der Sie sofort anmeldet!",
  "forgottenPasswordExplanation": "Geben Sie unten Ihre E-Mail-Adresse ein, um eine E-Mail zum Zurücksetzen des Passworts zu erhalten und wieder Zugriff auf Ihr Konto zu erhalten.",
  "signInWithGoogle": "Mit Google einloggen",
  "checkSignInLink": "Correo electrónico enviado, por favor revise su bandeja de entrada",
  "next": "Weiter",
  "login": "Einloggen",
  "sendSignInLink": "Anmeldelink zusenden",
  "en-US": "English",
  "en": "English",
  "fr-CA": "Français (Canada)",
  "fr": "Français",
  "es-ES": "Español",
  "es": "Español",
  "pt-BR": "Português",
  "pt": "Português",
  "de-DE": "Deutsch",
  "de": "Deutsch",
  "ja-JP": "日本",
  "ja": "日本",
  "zh-CN": "中文(简体)",
  "zh-TW": "中文(繁体)",
  "timeToComplete": "Zeit zum Abschließen",
  "min": "min",
  "high": "Hoch",
  "poweredBy": "zur Verfügung gestellt von",
  "uploading": "Hochladen......",
  "uploadMedia": "Medien hochladen",
  "admin": "Admin",
  "showWorkflows": "Show abgeschlossen",
  "showCompletedWorkflows": "Abgeschlossene Workflows anzeigen",
  "infoOnly": "Nur Informationen, zum Abschließen ist keine Aktion erforderlich",
  "delegate": "Delegieren",
  "getOurApp": "Holen Sie sich unsere App",
  "continueToApp": "Weiter zur App",
  "promptMessage": "Sie haben noch nicht gespeicherte Änderungen. Möchten Sie wirklich fortfahren?",
  "onboardingBuddy": "Onboarding Buddy",
  "delegateAssignment": "Zuweisung delegieren",
  "onboardingBuddyAssignment": "Onboarding Buddy-Zuweisung",
  "assignments": "Zuweisungen",
  "delegateMainCopy": "Nachfolgend findest du eine Liste deiner Neueinsteiger und der zugewiesenen Vertreter für die Durchführung der Hiring Manager-Onboarding-Aufgaben, falls festgelegt.",
  "delegateEmailDescription": "Du kannst über das Feld unten eine Nachricht über die Aufgabendelegierung senden.",
  "newHire": "Neueinstellungen",
  "assignedDelegate": "Zugewiesener Delegieren",
  "assignedOnboardingBuddy": "Zugewiesener Onboarding Buddy",
  "activeAssignments": "Aktive Zuweisungen",
  "actions": "Aktionen",
  "add": "Hinzufügen",
  "remove": "Entfernen",
  "taskDelegation": "Aufgaben delegieren",
  "delegateConfirmationCopy": "<p>Die Hiring Manager-Onboarding-Aufgaben werden nun an die ausgewählte Person delegiert.</p><p>Beachte bitte, dass du keine leistungsbezogenen Aufgaben delegieren kannst.</p>",
  "okay": "Ok",
  "activeAssignmentsCount": "Dieser Benutzer besitzt derzeit {{count}} aktive Zuweisungen.",
  "assign": "Zuweisen",
  "name": "Name",
  "assignDelegate": "Vertreter Zuweisen",
  "assignOnboardingBuddy": "Onboarding Buddy zuweisen",
  "onboardingBuddyModalMainCopy": "Nachfolgend findest du eine Liste deiner Neueinsteiger und der zugewiesenen Vertreter für die Durchführung der Hiring Manager-Onboarding-Aufgaben, falls festgelegt.",
  "removeDelegateUser": "Möchten Sie den Delegierten für {{userName}} wirklich löschen?",
  "removeOnboardingUser": "Möchten Sie den Onboarding-Buddy für {{userName}} wirklich löschen?",
  "refreshPage": "Seite aktualisieren",
  "newAppVersionTitle": "Neue Version der Anwendung",
  "newAppVersionContent": "Es gibt eine neue Version der Anwendung. Um sicherzustellen, dass die Anwendung reibungslos funktioniert, aktualisieren Sie bitte die Seite.",
  "commentAdded": "Kommentar hinzugefügt:",
  "optionSelected": "Option ausgewählt:",
  "mediaUploaded": "Medien hochgeladen:",
  "dependedOn": "Abhängig von",
  "loading": "Laden...",
  "fetchingResults": "Wir rufen Ihre Ergebnisse ab, bitte warten...",
  "workflows": "Workflows",
  "sections": "Abschnitte",
  "seeAllMatching": "Alle {{length}}-Übereinstimmungen anzeigen",
  "matches": "{{length}} Übereinstimmungen",
  "enterSearchTerm": "Suchbegriff eingeben...",
  "oops": "Ups, da ist etwas schiefgelaufen.",
  "upNextError": "Wir konnten deine nächsten Aufgaben nicht laden. Bitte versuche es später noch einmal.",
  "myTasks": "Meine Aufgaben",
  "pastDue": "Überfällig",
  "tomorrow": "Morgen",
  "dueYesterday": "Fällig Gestern",
  "dueTomorrow": "Fällig Morgen",
  "aboutTheOrg": "Über das Unternehmen",
  "seeAll": "Alle anzeigen",
  "myRole": "Meine Rolle",
  "manager": "Manager",
  "buddy": "Buddy",
  "myTeam": "Mein Team",
  "seeAllTasks": "Alle Aufgaben anzeigen",
  "viewWelcomeMessage": "Willkommensnachricht anzeigen",
  "calendar": "Kalender",
  "dueInSixMonthsPlus": "Fällig in mehr als 6 Monaten"
}