import React, { createContext, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components/native'
import { SUBMIT_QUESTIONS } from 'graphql/mutations'
import { GET_TASKS_COMPLETE, LOCATION_SCREEN_QUERY } from 'graphql/queries'
import { useMutation } from '@apollo/react-hooks'
import {
  getTaskCompleteVariables,
} from 'utils/apollo'
import { useAppContext } from 'utils/app-context'
import NewQuestions from './NewQuestions'
import DefaultButton from '../buttons/DefaultButton'
import SubmittedQuestions from './SubmittedQuestions'
import { View } from 'react-native'
import useCurrentLocation from 'hooks/useCurrentLocation'
import { IS_WEB } from 'constants/static'
import { captureException } from 'utils/sentry'
import COLORS from 'constants/colors'

export const QuestionsContext = createContext<{
  questions: QuestionType[],
  lastSubmission: QuestionSubmissionType,
  errors: string[]
  state: 'NEW_QUIZ' | 'SUBMITTED'
  setState: (value: 'NEW_QUIZ' | 'SUBMITTED') => void,
  setSelectedQuestions: (value: {}) => void,
  selectedQuestions: any,
  survey: boolean,
  scorecard: boolean,
  snippetsMapping?: StringMap,
}>({
  questions: [],
  lastSubmission: null,
  errors: [],
  state: 'NEW_QUIZ',
  setState: () => { },
  setSelectedQuestions: () => { },
  selectedQuestions: {},
  scorecard: false,
  survey: false,
})

const validateEmail = (state: string) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(state);
}

const Questions = ({
  questions,
  lastSubmission,
  taskId,
  survey = false,
  scorecard = false,
  snippetsMapping,
}: {
  questions: QuestionType[]
  lastSubmission?: QuestionSubmissionType
  taskId: string
  survey?: boolean
  scorecard?: boolean
  snippetsMapping?: StringMap
}) => {
  const { currentLocation } = useCurrentLocation()
  const appContextValue = useAppContext()
  const taskCompleteVariables = getTaskCompleteVariables(
    currentLocation?.id,
    appContextValue,
  )
  const defaultState: 'NEW_QUIZ' | 'SUBMITTED' = useMemo(() => lastSubmission ? 'SUBMITTED' : 'NEW_QUIZ', [lastSubmission])

  const defaultSelectedQuestions = useMemo(() => {
    const hasTemplatesChanged = lastSubmission?.questions.some(question => !question.templateTaskQuestion)

    let _selectedQuestions = questions.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.id]: {
          optionsIds: new Set([]),
          comment: '',
          media: [],
        }
      }
    }, {})

    if (lastSubmission && !hasTemplatesChanged) {
      return lastSubmission.questions.reduce((acc, cur) => {
        const answers = cur.answers.filter(answer => answer.answer != false)
        return {
          ...acc,
          [cur.templateTaskQuestion.id]: {
            optionsIds: new Set(answers.map(answer => answer.templateTaskQuestionOption.id)),
            comment: answers[0]?.comment || '',
            media: answers[0]?.mediaUrls || [],
          }
        }
      }, _selectedQuestions)
    }

    if (IS_WEB && !hasTemplatesChanged) {
      const saved = JSON.parse(localStorage.getItem('questions') || '{}');
      if (saved[taskId]) {
        return Object.entries(saved[taskId]).reduce((acc, [key, value]) => {
          // If the question is not in the current task, remove it
          if (!questions.find(question => question.id == key)) return acc

          return {
            ...acc,
            [key]: {
              optionsIds: new Set(value.optionsIds),
              comment: value.comment,
              media: [] // Don't restore media from local storage to avoid issues with file uploads
            }
          }
        }, _selectedQuestions)
      }
    }

    return _selectedQuestions
  }, [])

  const [submitQuestions, { loading }] = useMutation(SUBMIT_QUESTIONS, {
    refetchQueries: [
      {
        query: GET_TASKS_COMPLETE,
        variables: { ...taskCompleteVariables },
      },
      {
        query: LOCATION_SCREEN_QUERY,
        variables: {
          startDate: taskCompleteVariables.date,
          endDate: taskCompleteVariables.date,
        },
      },
    ]
  })
  const scrollRef = useRef(null)
  const [state, setState] = useState<'NEW_QUIZ' | 'SUBMITTED'>(defaultState)
  const [newLastSubmission, setNewLastSubmission] = useState(lastSubmission)
  const [errors, setErrors] = useState([])
  const [selectedQuestions, setSelectedQuestions] = useState(defaultSelectedQuestions)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!IS_WEB) return

    const selectedQuestionsMapped = Object.entries(selectedQuestions).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: {
          optionsIds: Array.from(value.optionsIds),
          comment: value.comment,
          media: value.media,
        }
      }
    }, {})

    const saved = JSON.parse(localStorage.getItem('questions') || '{}');
    const toBeSaved = {
      ...saved,
      [taskId]: {
        ...selectedQuestionsMapped
      },
    }
    localStorage.setItem('questions', JSON.stringify(toBeSaved));
  }, [selectedQuestions]);

  const questionHasError = (id: string) => {
    if (!selectedQuestions[id]) return true

    const question = questions.find(question => question.id == id)

    if (question.quizType == 'MULTIPLE_CHOICE' || question.quizType == 'CHECKBOX' || question.quizType == 'SCORECARD') {
      return selectedQuestions[id]?.optionsIds.size == 0
    }

    if (question.quizType == 'COMMENT' || question.quizType == 'UPDATE_EMPLOYEE') {
      if (question.quizType == 'UPDATE_EMPLOYEE' &&
        ['email', 'personal_email'].includes(question.options[0].propertyName)) {
        return !validateEmail(selectedQuestions[id]?.comment)
      }

      return selectedQuestions[id]?.comment.length == 0
    }

    if (question.quizType == 'UPLOAD') {
      return selectedQuestions[id]?.media.length == 0
    }

    return false
  }

  const validate = () => {
    const newErrors = questions.reduce((acc, question) => {
      if (question.required && questionHasError(question.id)) {
        acc.push(question.id)
      }

      return acc
    }, [])

    setErrors(newErrors)

    return newErrors.length == 0
  }

  const onSubmit = async () => {
    if (validate()) {
      const payload = []
      for (const [key, value] of Object.entries(selectedQuestions)) {
        payload.push({
          questionId: key,
          optionsIds: Array.from(value.optionsIds),
          comment: value.comment,
          media: value.media,
        })
      }

      try {
        const results = await submitQuestions({
          variables: {
            attributes: {
              answers: payload,
              taskId: taskId,
            }
          }
        })
        setNewLastSubmission(results.data.submitQuestions)
        setState('SUBMITTED')
        setError(null)
        scrollRef.current.scrollIntoView()
      } catch (e) {
        const error = {
          message: 'Unable to submit questions',
          payload,
          actualError: e,
        }

        console.error(error)
        captureException(error)
        setError(error)
      }
    }
  }

  if (questions.length === 0) return null

  return <Container>
    <QuestionsContext.Provider
      value={{
        questions,
        lastSubmission: newLastSubmission,
        errors,
        state,
        setState,
        setSelectedQuestions,
        selectedQuestions,
        survey,
        scorecard,
        snippetsMapping,
      }}
    >
      <View ref={scrollRef} />

      {state == 'SUBMITTED' ? <SubmittedQuestions
        lastSubmission={newLastSubmission}
        survey={survey}
        scorecard={scorecard}
        setState={setState}
        snippetsMapping={snippetsMapping}
      /> : <NewQuestions />}

      {state == 'NEW_QUIZ' && <View style={{ marginTop: 42 }}>
        <DefaultButton
          title={loading ? 'Submitting, please wait...' : 'Submit'}
          onPress={onSubmit}
          disabled={loading}
          fullWidth
        />

        {error && <ErrorMessage>Unable to submit, please try again later.</ErrorMessage>}
      </View>}
    </QuestionsContext.Provider>
  </Container>
}

const ErrorMessage = styled.Text`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${COLORS.RED}
  margin-top: 20px;
`


const Container = styled.View`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
`

export default Questions
