import { useQuery } from '@apollo/react-hooks'
import useCreateEvent from 'components/task-complete/hooks/useCreateEvent'
import { FETCH_POLICIES } from 'constants/api'
import COLORS from 'constants/colors'
import { EVENT_TYPES } from 'constants/types'
import { LOCATION_TEXT_QUERY, TASK_TEXT_QUERY } from 'graphql/queries'
import React, { useCallback, useEffect, useState } from 'react'
import {
  TextInput,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native'
import { Button, Divider, Icon } from '@rneui/themed'
import EStyleSheet from 'react-native-extended-stylesheet'
import { useHistory } from 'react-router'
import { useAppContext } from 'utils/app-context'
import { ISODayString } from 'utils/date'
import { useHeaderContext } from 'utils/header-context'
import { debounce } from 'utils/helpers/debounce'

const styles = EStyleSheet.create({
  container: {
    flex: 1,
  },
  cancelIconWrapper: {
    alignItems: 'flex-end',
    paddingRight: 10,
  },
  inputContainer: {
    margin: 10,
    padding: 10,
    paddingBottom: 0,
  },
  input: {
    minHeight: 40,
    borderColor: COLORS.TURQUOISE,
    borderWidth: 1,
    borderRadius: 20,
    padding: 20,
  },
  doneButton: {
    margin: 10,
    marginTop: 0,
    backgroundColor: COLORS.TURQUOISE,
  },
  divider: {
    marginBottom: 10,
  },
})

// camera and options to use or retake an image
export default function LocationTextAndPhotos({
  route,
  location,
}: {
  route: any
  location: any
}) {
  const history = useHistory()
  const appContextValue = useAppContext()
  const params: CompleteElementProps = location.state
  const dayISO = ISODayString(appContextValue.selectedDay)
  const { height } = useWindowDimensions()

  const sessionUuid = params?.sessionUuid
  const isTaskLevel = Boolean(params?.isTaskLevel)

  const taskId = params?.id
  const locationHierarchyId = params?.locationHierarchyId

  const eventType = isTaskLevel
    ? EVENT_TYPES.TASK_TEXT
    : EVENT_TYPES.LOCATION_TEXT

  const { data: locationTextData } = useQuery(LOCATION_TEXT_QUERY, {
    fetchPolicy: FETCH_POLICIES.CACHE_AND_NETWORK,
    variables: { locationHierarchyId, date: dayISO },
    skip: isTaskLevel,
  })
  const { data: taskTextData } = useQuery(TASK_TEXT_QUERY, {
    fetchPolicy: FETCH_POLICIES.CACHE_AND_NETWORK,
    variables: { taskId, date: dayISO },
    skip: !isTaskLevel,
  })
  const createEvent = useCreateEvent({
    taskId,
    locationHierarchyId,
  })

  const [text, setText] = useState<string>('')
  const textComplete = (text: string) => {
    createEvent({ eventType, createEventData: { text } })
  }
  const { setOptions } = useHeaderContext()
  const debouncedSave = useCallback(
    debounce((nextValue) => textComplete(nextValue), 300),
    [],
  )

  const onDone = () => {
    history.goBack()
  }

  const onCancel = () => {
    history.goBack()
  }

  const saveText = (newText: string) => {
    if (newText) {
      debouncedSave(newText)
    }
  }

  const onChangeText = (textObject: any) => {
    setText(textObject.text)
    saveText(textObject.text)
  }

  useEffect(() => {
    if (!locationTextData) {
      return
    }

    const { locationText } = locationTextData

    setText(locationText[0]?.text)
  }, [locationTextData])

  useEffect(() => {
    if (!taskTextData) {
      return
    }

    const { taskText } = taskTextData

    setText(taskText[0]?.text)
  }, [taskTextData])

  useEffect(() => {
    setOptions({ hideHeader: true })

    return () => {
      setOptions({ hideHeader: false })
    }
  }, [])

  return (
    <View style={styles.container}>
      {Boolean(onCancel) && (
        <TouchableOpacity style={styles.cancelIconWrapper} onPress={onCancel}>
          <Icon name="close" size={40} />
        </TouchableOpacity>
      )}

      <View style={styles.inputContainer}>
        <TextInput
          style={{
            ...styles.input,
            height: height * 0.25,
          }}
          multiline
          numberOfLines={10}
          placeholder="Type here to add text..."
          onChangeText={(newValue) => onChangeText({ text: newValue })}
          value={text}
        />
      </View>
      <Divider style={styles.divider} />
      <View>
        <Button title="Done" buttonStyle={styles.doneButton} onPress={onDone} />
      </View>
    </View>
  )
}
