import React from 'react'
import { Avatar } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import COLORS from 'constants/colors'
import useLocations from 'hooks/useLocations'
import format from 'utils/date-fns'
import { ROUTE_NAMES } from 'navigation/constants'
import { useHistory } from 'react-router-native'
import { GeneralActivityIndicator } from 'components/Index'

function stringAvatar(name: string, color: string) {
  return {
    sx: {
      bgcolor: color || '#0019FF',
      width: '36px',
      height: '36px',
      fontSize: '14px',
      fontWeight: '600',
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const MyTeam = () => {
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const { myTeamLocations, loading } = useLocations(null, false, {
    showReporteesLocationsOnly: true,
  })

  return <Column>
    <Row>
      <Title>{t('myTeam')}</Title>
      <Link onClick={() => {
        history.push({
          pathname: ROUTE_NAMES.LOCATIONS,
        })
      }}>{t('seeAllTasks')}</Link>
    </Row>

    <Container>
      <Team>
        {loading && <GeneralActivityIndicator size="large" />}

        {myTeamLocations.map((team) => {
          const startDate = format(
            new Date(team.owner?.startDate),
            'MM/dd/yyyy', // use i18n
            i18n.resolvedLanguage
          )

          return <Employee
            onClick={() => history.push({
              pathname: ROUTE_NAMES.MY_TEAM,
              search: `?reporteeId=${team.owner?.id}`,
            })}
            color={team.color}
            fontColor={team.colorBrightness == 'light' ? 'white' : ' black'}
          >
            <EmployeeLeft>
              <Avatar {...stringAvatar(team.owner?.fullName, team.progressColor)} src={team.owner?.avatarUrl} />
              <Name>{team.owner?.fullName}</Name>
            </EmployeeLeft>

            <EmployeeRight>
              <StartDate>Started {startDate}</StartDate>
              <Workflows>{team.locations.length} Workflows {team.percent}%</Workflows>
            </EmployeeRight>
          </Employee>
        })}
      </Team>
    </Container>
  </Column>
}

const Link = styled.div`
  display: flex;
  align-self: end;
  cursor: pointer;
  font-size: 18px;
  color: ${COLORS.NAVY_BLUE};
  margin-bottom: 12px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Workflows = styled.div`
  font-size: 14px;
  font-weight: 600;
`

const StartDate = styled.div`
  font-size: 14px;
  font-weight: 300;
  margin-right: 12px;
`

const Name = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-left: 8px;
`

const EmployeeLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const EmployeeRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Employee = styled.div<{ color: string, fontColor: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ color }) => color ?? '#223C8F'};
  color: ${({ fontColor }) => fontColor ?? 'white'};
  box-shadow: 0px 4px 12px 0px #0000000F;
  padding: 12px 12px 12px 18px;
  border-radius: 8px;
  margin-bottom: 12px;
  cursor: pointer;
`

const Team = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 12px;
`

const Title = styled.div`
  display: flex;
  font-size: 24px;
  font-weight: 600;
  color: ${COLORS.GRAYDARK3};
`

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px;
  background-color: ${COLORS.LIGHT_POWDER_BLUE};
  border-radius: 32px;
  max-height: 360px;
`

export default MyTeam