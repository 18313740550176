import React from 'react'
import styled from 'styled-components'

import COLORS from 'constants/colors'

const WelcomeWizardWelcomeMessage = () => {
  return <Column>
    <Title>Welcome to JLL!</Title>

    <Description>
      Growing your team to better serve our clients and business is an exciting time. As a manager you are central to your new team members’ experience at JLL – empowering thrive to grow a meaningful career and find place where they belong.
      <br /><br />
      Your manager onboarding journey is designed to support your new team member's success from day one. Follow the guided steps to help your team member get connected quickly and ready to deliver sustainable solutions for our clients, communities and planet.
    </Description>

    <StyledImage src={require('assets/images/png/OnboardingWelcome.png')} />
  </Column>
}

const StyledImage = styled.img`
  margin-top: 55px;
  width: 674px;
  height: 279.5px;
  align-self: center;
  -webkit-mask-image: linear-gradient(to top,rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 50%);
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 50px 0px 50px;
`

const Title = styled.div`
  font-size: 32px;
  font-weight: 600;
  color: ${COLORS.NAVY_BLUE}
`

const Description = styled.div`
  margin-top: 24px;
  font-size: 18px;
  font-weight: 400;
  color: ${COLORS.FIVES_GREY};
`

export default WelcomeWizardWelcomeMessage