import Camera from 'components/common/Camera'
import Icon from 'components/common/Icon'
import { STYLE_CONSTANTS } from 'constants/es-style'
import { ICON_NAMES } from 'constants/icons'
import React, { useEffect, useState } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import Image from 'components/common/Image'
import EStyleSheet from 'react-native-extended-stylesheet'
import { useHistory } from 'react-router-native'
import { useHeaderContext } from 'utils/header-context'
import { deleteFile } from 'utils/phone'
import useCreateEvent from './hooks/useCreateEvent'

// camera and options to use or retake an image
export default function TaskCompleteByPhotoScreen({
  route,
  location,
}: {
  route: any
  location: any
}) {
  const history = useHistory()
  const { setOptions } = useHeaderContext()
  const [image, setImage] = useState(null)
  const params: CompleteElementProps = location.state || {}
  const { id: taskId, locationHierarchyId, eventType } = params
  const createEvent = useCreateEvent({
    taskId,
    locationHierarchyId,
  })

  const saveImageToState = (img: any) => {
    setImage(img)
  }

  const retakeImage = () => {
    setImage(null)
    deleteFile(image?.uri)
  }

  const onSave = async () => {
    await createEvent({ eventType, createEventData: { image } })
    history.goBack()
  }

  const onCancel = () => {
    history.goBack()
  }

  useEffect(() => {
    setOptions({ hideHeader: true })

    return () => {
      setOptions({ hideHeader: false })
    }
  }, [])

  return (
    <View style={styles.container}>
      {!image && <Camera doWithImage={saveImageToState} onCancel={onCancel} />}
      {image && (
        <View style={styles.container}>
          <Image
            style={getImageStyle(image?.width, image?.height)?.image}
            source={{ uri: image?.uri }}
          />
          <View style={styles.imageButtons}>
            <TouchableOpacity style={styles.iconContainer} onPress={onSave}>
              <Icon style={styles.icon} name={ICON_NAMES.USE_IMAGE} />
              <Text style={styles.iconText}>USE</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.iconContainer}
              onPress={retakeImage}
            >
              <Icon style={styles.icon} name={ICON_NAMES.DONT_USE_IMAGE} />
              <Text style={styles.iconText}>TAKE AGAIN</Text>
            </TouchableOpacity>
          </View>
        </View>
      )}
    </View>
  )
}

// dynamic styles
const getImageStyle = (width: number, height: number) => {
  if (!width || !height) {
    return {}
  }
  return EStyleSheet.create({
    image: {
      height: '100%',
      aspectRatio: width / height,
    },
  })
}

// styles
const styles = EStyleSheet.create({
  container: {
    ...STYLE_CONSTANTS.FILL,
  },
  imageButtons: {
    ...STYLE_CONSTANTS.FLEX_ROW,
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.45)',
    width: '100%',
    justifyContent: 'space-around',
    padding: 20,
  },
  iconContainer: {
    alignItems: 'center',
  },
  icon: {
    color: '#222',
    fontSize: 30,
  },
  iconText: {
    marginTop: 10,
  },
})
