import { IS_WEB } from 'constants/static'
import React from 'react'
import styled from 'styled-components/native'


const QuestionContainer = ({ hasError = false, children }: { hasError?: boolean, children: any }) => {
  return <Container hasError={hasError}>
    {children}
  </Container>
}

const Container = styled.View<{ hasError: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  padding: ${IS_WEB ? '16px 28px 16px 28px' : '8px 14px 8px 14px'};
  background: #F5F7FB;
  border: 1px solid ${({ hasError }) => hasError ? '#E30613' : '#B4C5E8'};;
  border-radius: 9px;
`

export default QuestionContainer