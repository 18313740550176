import React from 'react'
import Background from './Background'
import styled from 'styled-components'
import useUser from 'hooks/useUser'
import Header from 'components/header/Header.web'
import Footer from './Footer.web'

const PageFrame = ({ children, noPadding = false }: { children: any, noPadding?: boolean }) => {
  const { user } = useUser()

  return <Background>
    <Frame>
      <Header currentUser={user} />

      <Content noPadding={noPadding}>{children}</Content>

      <Footer />
    </Frame>
  </Background>
}

const Frame = styled.div`
  @media (max-width: 1400px) {
    width: 1200px;
  }
  @media (min-width: 1400px) {
    width: 1420px;
  }
  height: 100%;
  display: flex;
  align-self: center;
  flex-direction: column;
  font-family: Poppins_400Regular;
`

const Content = styled.div<{ noPadding: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  background: white;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.06);
  padding: ${props => (props.noPadding ? '0' : '20px')};
`

export default PageFrame