import React from 'react'
import { TranslatableRoleNames } from 'constants/types'
import { useTranslation } from 'react-i18next'

export enum CopyOptions {
  main = 'main',
  confirmation = 'confirmation',
  roleName = 'roleName',
  assigned = 'assigned',
  assignRole = 'assignRole',
  roleAssignment = 'roleAssignment',
  removeUser = 'removeUser',
}

const useCopyByRole = (roleName = '') => {
  const { t } = useTranslation()
  const copy = {
    [TranslatableRoleNames.Delegate]: {
      [CopyOptions.main]: t('delegateMainCopy'),
      [CopyOptions.confirmation]: t('delegateConfirmationCopy'),
      [CopyOptions.roleName]: t('delegate'),
      [CopyOptions.assigned]: t('assignedDelegate'),
      [CopyOptions.assignRole]: t('assignDelegate'),
      [CopyOptions.roleAssignment]: t('delegateAssignment'),
      [CopyOptions.removeUser]: (userName: string) =>
        t('removeDelegateUser', { userName }),
    },
    [TranslatableRoleNames['Onboarding Buddy']]: {
      [CopyOptions.main]: t('onboardingBuddyModalMainCopy'),
      [CopyOptions.confirmation]: '',
      [CopyOptions.roleName]: t('onboardingBuddy'),
      [CopyOptions.assigned]: t('assignedOnboardingBuddy'),
      [CopyOptions.assignRole]: t('assignOnboardingBuddy'),
      [CopyOptions.roleAssignment]: t('onboardingBuddyAssignment'),
      [CopyOptions.removeUser]: (userName: string) =>
        t('removeRoleser', { roleName, userName }),
    },
  }

  const defaultCopy = (userName = '', roleName = '') => {
    return {
      [CopyOptions.main]: t('defaultMainCopy'),
      [CopyOptions.confirmation]: '',
      [CopyOptions.roleName]: roleName,
      [CopyOptions.assigned]: `Assigned ${roleName}`,
      [CopyOptions.assignRole]: `Assigned ${roleName}`,
      [CopyOptions.roleAssignment]: `${roleName} Assignment`,
      [CopyOptions.removeUser]: t('removeRoleUser', { userName, roleName }),
    }
  }


  const copyByRole = (
    templateRole: string,
    copyOption: keyof typeof CopyOptions,
    data?: any,
  ) => {
    let roleCopy = copy[templateRole as keyof typeof TranslatableRoleNames]

    // Role translation not supported
    // use default text
    if (!roleCopy) {
      roleCopy = defaultCopy(data?.userName, templateRole)
      return roleCopy[copyOption]
    }

    if (copyOption == CopyOptions.removeUser) {
      return roleCopy[copyOption](data.userName)
    } else {
      return roleCopy[copyOption]
    }
  }

  return { copyByRole }
}

export default useCopyByRole
