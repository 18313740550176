import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useApolloClient } from '@apollo/react-hooks';
import LocationsList from './LocationsList'
import { IS_WEB } from 'constants/static'
import GetAppModal from 'components/pop-ups/GetAppModal.web'
import useCurrentLocation from 'hooks/useCurrentLocation'
import { Loader } from 'components/common/Loader'

// component to render the task list
export default function LocationScreen() {
  const client = useApolloClient()
  const { currentLocation, loading } = useCurrentLocation()

  useEffect(() => {
    // reset selected task
    client.writeData({
      data: {
        appData: {
          taskDescriptionModalId: null,
          __typename: 'AppData',
        },
      },
    })

    return () => { }
  }, [currentLocation])

  if (loading) return <Loader />

  return (
    <View style={{ flex: 1 }}>
      {IS_WEB && <GetAppModal />}

      <LocationsList />
    </View>
  )
}
