{
  "monthDayYearDateFormat": "M月d日, y",
  "monthDayDateFormat": "M月d日",
  "welcome": "歡迎,\n{{name}}!",
  "clickOnTasksSection": "點擊任務部分開始",
  "search": "搜尋",
  "typeHereToAddText": "在這裡輸入...",
  "complete": "完成",
  "completed": "已完成",
  "cantComplete": "無法完成",
  "cantCompleteTask": "無法完成任務",
  "notes": "任務注釋",
  "back": "後退",
  "sessionNotes": "會議注釋",
  "confirm": "確認",
  "notApplicable": "不適用",
  "logout": "登出",
  "teamMetrics": "團隊指標",
  "taskNotesDescription": "使用此空間添加與此任務相關的註釋",
  "comment": "評論",
  "pleaseSelect": "請選擇...",
  "eSignature": "電子簽名",
  "takePicture": "拍照",
  "accept": "接受",
  "home": "主頁",
  "tasksFor": "給...的任務",
  "due": "到期",
  "dueToday": "今日到期",
  "tasks": "任務",
  "task": "任務",
  "tasksToDo": "{{tasks}}项任務需要完成",
  "taskToDo": "{{tasks}}项任務需要完成",
  "infoTasksToDo": "{{numberInfoTasks}} 僅信息任務",
  "infoTaskToDo": "{{numberInfoTasks}} 信息任務",
  "infoTasks": "{{numberInfoTasks}} 信息任务",
  "infoTask": "{{numberInfoTasks}} 信息任务",
  "by": "由",
  "markedCantComplete": "已標記為無法完成",
  "markedNotApplicable": "已標記為不適用",
  "markedRead": "讀",
  "tasksComplete": "任務完成",
  "allTasksComplete": "所有 {{tasks}} 任務已完成",
  "someTasksComplete": "{{numberOfCompletedTasks}}项（共{{numberOfTasks}}项）任務已完成",
  "upNextTitle": "接下来",
  "upNextSubtitle": "從您的工作流程中快速訪問您即將完成的任務",
  "upNextDoneTitle": "現在完成了！",
  "upNextDoneSubtitle": "幹得不錯！所有任務已完成！如收到新的任務分配，我們會給您發送郵件！",
  "progress": "進度",
  "ago": "之前",
  "dueIn": "在...到期",
  "days": "天",
  "resources": "資源",
  "resourcesSubtitle": "使用此精選資源列表",
  "yourResources": "你的資源",
  "yourResourcesSubtitle": "快速訪問所有有用的鏈接",
  "reports": "報告",
  "signIn": "登錄",
  "forgotPassword": "忘記密碼？",
  "emailAddress": "郵箱地址",
  "password": "密碼",
  "newPassword": "新密碼",
  "repeatPassword": "創建新密碼",
  "passwordError": "密碼必須匹配",
  "passwordLengthError": "密碼長度必須至少為 8 個字符",
  "resetPassword": "重置密碼",
  "resetPasswordInfo": "使用臨時密碼登錄成功。\n請創建一個永久密碼",
  "forgottenPassword": "忘記密碼了？",
  "forgottenPasswordExplanationWithMagicLink": "在下方輸入您的電子郵件地址，然後選擇發送重置密碼電子郵件或一次性魔術鏈接，讓您立即登錄！",
  "forgottenPasswordExplanation": "在下方輸入您的電子郵件地址，接收重設密碼的電子郵件並恢復對您帳戶的訪問。",
  "signInWithGoogle": "用谷歌賬號登錄",
  "checkSignInLink": "已發送電子郵件，請檢查您的收件箱",
  "next": "下一個",
  "login": "登錄",
  "sendSignInLink": "發送登錄鏈接",
  "en-US": "English",
  "en": "English",
  "fr-CA": "Français (Canada)",
  "fr": "Français",
  "es-ES": "Español",
  "es": "Español",
  "pt-BR": "Português",
  "pt": "Português",
  "de-DE": "Deutsch",
  "de": "Deutsch",
  "ja-JP": "日本",
  "ja": "日本",
  "zh-CN": "中文(简体)",
  "zh-TW": "中文(繁体)",
  "timeToComplete": "完成時間",
  "min": "min",
  "high": "高的",
  "poweredBy": "由...提供",
  "uploading": "正在上傳...",
  "uploadMedia": "上傳媒體",
  "admin": "Admin",
  "showWorkflows": "顯示完成",
  "showCompletedWorkflows": "顯示已完成的工作流程",
  "infoOnly": "僅供參考，無需完成任何操作",
  "delegate": "代表",
  "getOurApp": "獲取我們的應用程序",
  "continueToApp": "繼續應用",
  "promptMessage": "您有未保存的更改，您確定要離開嗎",
  "onboardingBuddy": "入職夥伴",
  "delegateAssignment": "代表指派",
  "onboardingBuddyAssignment": "入職夥伴指派",
  "assignments": "指派",
  "delegateMainCopy": "請查看以下的所有新進人員名單，以及指派完成「招聘經理」入職工作的代表 (若已選定)。",
  "delegateModalHeader": "請使用下方的下拉式選單，指派一位代表完成「招聘經理」入職工作。",
  "delegateEmailDescription": "Y您可以利用下方空白處傳送有關工作分配的訊息。",
  "newHire": "新人",
  "assignedDelegate": "指定代表",
  "assignedOnboardingBuddy": "指派的入職夥伴",
  "activeAssignments": "進行中指派",
  "actions": "動作",
  "add": "新增",
  "remove": "移除",
  "taskDelegation": "工作分配",
  "delegateConfirmationCopy": "<p>招聘經理入職工作目前將分配給已選定的人員。</p><p>請注意績效管理工作不符合分配條件。</p>",
  "okay": "瞭解",
  "activeAssignmentsCount": "此使用者目前有 {{count}} 個進行中的指派。",
  "assign": "指派",
  "name": "名稱",
  "assignDelegate": "指派代表",
  "assignOnboardingBuddy": "指派入職夥伴",
  "onboardingBuddyModalMainCopy": "請查看以下所有新進人員名單，以及獲指派的入職夥伴 (若已選定)。",
  "removeDelegateUser": "你確定要移除{{userName}}的代表嗎？",
  "removeOnboardingUser": "你確定要移除{{userName}}的入職夥伴嗎？",
  "refreshPage": "刷新頁面",
  "newAppVersionTitle": "應用程序的新版本",
  "newAppVersionContent": "應用有新版本，為保證應用正常運行，請刷新頁面。",
  "commentAdded": "評論已添加：",
  "optionSelected": "選擇的選項：",
  "mediaUploaded": "媒體上傳：",
  "dependedOn": "取決於",
  "loading": "加載中...",
  "fetchingResults": "我們正在獲取您的結果，請稍等...",
  "workflows": "工作流程",
  "sections": "章節",
  "seeAllMatching": "查看所有 {{length}} 匹配項",
  "matches": "{{length}} 匹配",
  "enterSearchTerm": "輸入搜索詞...",
  "oops": "哎呀，出了點問題。",
  "upNextError": "我們無法加載您的下一個任務。請稍後再試。",
  "myTasks": "我的任務",
  "pastDue": "過期",
  "tomorrow": "明天",
  "dueYesterday": "昨天到期",
  "dueTomorrow": "明天到期",
  "aboutTheOrg": "關於公司",
  "seeAll": "查看全部",
  "myRole": "我的角色",
  "manager": "經理",
  "buddy": "夥伴",
  "myTeam": "我的團隊",
  "seeAllTasks": "查看所有任務",
  "viewWelcomeMessage": "查看歡迎消息",
  "calendar": "日曆",
  "dueInSixMonthsPlus": "6個月以上到期"
}