import gql from 'graphql-tag'

const lit = <V extends keyof any>(v: V) => v

export const TASK_TYPES = {
  PHOTO: lit('PHOTO'),
  TOGGLE: lit('TOGGLE'),
  SIGN: lit('SIGN'),
  DOCUSIGN: lit('DOCUSIGN'),
  TEXT: lit('TEXT'),
  MULTIPLE_OPTION: lit('MULTIPLE_OPTION'),
  UPLOAD_MEDIA: lit('UPLOAD_MEDIA'),
  INFO: lit('INFO'),
  QUIZ: lit('QUIZ'),
  SURVEY: lit('SURVEY'),
  SEND_SAFELY: lit('SEND_SAFELY'),
  VIDEO: lit('VIDEO'),
  SCORM_COURSE: lit('SCORM_COURSE'),
  DOCEBO_COURSE: lit('DOCEBO'),
  SCORECARD: lit('SCORECARD'),
} as const

export type TaskTypesUnion = (typeof TASK_TYPES)[keyof typeof TASK_TYPES]

export const EVENT_TYPES = {
  TAKE_PHOTO: lit('TAKE_PHOTO'),
  ADD_TEXT: lit('ADD_TEXT'),
  COMPLETE_TASK: lit('COMPLETE_TASK'),
  UNCOMPLETE_TASK: lit('UNCOMPLETE_TASK'),
  ADD_CANT_TEXT: lit('ADD_CANT_TEXT'),
  START_SESSION: lit('START_SESSION'),
  END_SESSION: lit('END_SESSION'),
  LOCATION_TEXT: lit('LOCATION_TEXT'),
  LOCATION_PHOTO: lit('LOCATION_PHOTO'),
  TASK_TEXT: lit('TASK_TEXT'),
  TASK_PHOTO: lit('TASK_PHOTO'),
  MULTIPLE_OPTION: lit('MULTIPLE_OPTION'),
  NOT_APPLICABLE: lit('NOT_APPLICABLE'),
  UPLOAD_MEDIA: lit('UPLOAD_MEDIA'),
  QUIZ_COMPLETED: lit('QUIZ_COMPLETED'),
  SURVEY_SUBMITTED: lit('SURVEY_SUBMITTED'),
  SEND_SAFELY: lit('SEND_SAFELY'),
  TASK_READ: lit('TASK_READ'),
  VIDEO_WATCHED: lit('VIDEO_WATCHED'),
  SCORECARD_SUBMITTED: lit('SCORECARD_SUBMITTED'),
} as const

export type EventTypesUnion = (typeof EVENT_TYPES)[keyof typeof EVENT_TYPES]

export const MODEL_TYPES = {
  TASK: lit('Task'),
  TASK_VIRTUAL: lit('TaskVirtual'),
  TASK_COMPLETE: lit('TaskComplete'),
  EVENT: lit('Event'),
  LOCATION_HIERARCHY: lit('LocationHierarchy'),
  SESSION: lit('Session'),
  ORDER: lit('Order'),
  LOCATION_TEXT: lit('LocationText'),
  LOCATION_PHOTO: lit('LocationPhoto'),
  TASK_TEXT: lit('TaskText'),
  TASK_PHOTO: lit('TaskPhoto'),
} as const

export type ModelTypesUnion = (typeof MODEL_TYPES)[keyof typeof MODEL_TYPES]

export const CACHE_KEYS = {
  TASK: lit('tasks'),
  TASK_VIRTUAL: lit('tasksVirtual'),
  TASKS_COMPLETE: lit('tasksComplete'),
  LOCATION_HIERARCHIES: lit('locationHierarchiesNew'),
  SESSIONS: lit('sessions'),
  EVENTS: lit('events'),
  USER_ID: lit('e9bf9df8-c923-423a-b085-767a2035190a'),
  NOTIFIED_SERVER_DOWN: lit('e9bf9df8-c923-423a-b085-859df885dd8'),
  TOKEN: lit('e9bf9df8-c923-423a-b085-85684sf85d6'),
  TOKEN_LOGIN: lit('tokenLogin'),
  PERSISTENT_CONTEXT: lit('persistentContext'),
  OFFLINE_STORE: lit('offlineStore'),
  LOGIN: lit('login'),
  LOCATION_TEXT: lit('locationText'),
  LOCATION_PHOTOS: lit('locationPhotos'),
  TASK_TEXT: lit('taskText'),
  TASK_PHOTOS: lit('taskPhotos'),
  CLIENT_CONFIG: lit('clientConfig'),
  SNIPPETS: lit('snippets'),
  LOCATION_HIERARCHY: lit('locationHierarchy'),
  SELECTED_LANGUAGE: lit('selectedLanguage'),
  STAY_ON_WEB: lit('stay_on_web'),
  SSO_CODE_VERIFIER: lit('ssoCodeVerifier'),
} as const

export type CacheKeysUnion = (typeof CACHE_KEYS)[keyof typeof CACHE_KEYS]

export enum SnippetTypeEnum {
  STATIC = 'STATIC',
  DYNAMIC = 'DYNAMIC',
}

export enum TranslatableRoleNames {
  Delegate = 'Delegate',
  'Onboarding Buddy' = 'Onboarding Buddy',
  'Default' = 'Default',
}
