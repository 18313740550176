import { stringify } from 'query-string'

export async function getOktaToken({
  requestUrl,
  redirectUri,
  code,
  codeVerifier,
  clientId,
  scope = 'openid email profile',
  grantType = 'authorization_code',
}: {
  requestUrl: string
  redirectUri: string
  code: string
  codeVerifier: string
  clientId: string
  scope?: string
  grantType?: string
}) {
  const formParams = {
    grant_type: grantType,
    redirect_uri: redirectUri,
    code,
    code_verifier: codeVerifier,
    client_id: clientId,
    scope,
  }
  const options = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'content-type': 'application/x-www-form-urlencoded',
    },
    body: stringify(formParams),
  }
  // exchange code for the token
  const tokenResponse = await fetch(requestUrl, options)
  const tokenResponseData = await tokenResponse.json()

  return tokenResponseData
}
