import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField, InputAdornment, ThemeProvider, Typography, IconButton, Tooltip } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import COLORS from 'constants/colors'
import createCustomMuiTheme from 'theme/mui/createCustomMuiTheme';
import { debounce } from 'lodash'
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';

const WorkflowsFilter = (
  {
    showInactiveWorkflows,
    setShowInactiveWorkflows,
    allTemplates,
    selectedTemplatesIds,
    setSelectedTemplatesIds,
    setTaskSearchQuery,
    dateRange = [null, null],
    setDateRange,
  }: {
    showInactiveWorkflows: boolean,
    setShowInactiveWorkflows: (showInactiveWorkflows: boolean) => void
    showCompletedWorkflows?: boolean,
    setShowCompletedWorkflows?: (showCompletedWorkflows: boolean) => void
    allTemplates: any
    selectedTemplatesIds: any
    setSelectedTemplatesIds: (selectedTemplates: any) => void
    setTaskSearchQuery?: (taskSearchQuery: string) => void
    dateRange?: DateRange<Dayjs>
    setDateRange?: (dateRange: DateRange<Dayjs>) => void
  }
) => {
  const theme = createCustomMuiTheme()
  theme.palette.primary.main = COLORS.NAVY_BLUE;
  theme.components.MuiInput = {
    styleOverrides: {
      root: {
        color: COLORS.NAVY_BLUE,
        fontSize: '14px',
      },
    },
  }
  theme.components.MuiInputLabel = {
    styleOverrides: {
      root: {
        fontWeight: 400,
        fontSize: '14px',
        color: COLORS.NAVY_BLUE,
      },
    },
  }

  const [taskQueryInternal, setTaskQueryInternal] = useState('')
  const setTaskSearchQueryDebounced = setTaskSearchQuery ? useCallback(debounce(setTaskSearchQuery, 500, { trailing: true }), []) : () => { }

  return <OverallFilters>
    <span>Quick Filters</span>

    <OverallFiltersBody>
      <ThemeProvider theme={theme}>
        {setTaskSearchQuery && <FormControl>
          <TextField
            variant="outlined"
            placeholder='Search tasks'
            sx={{
              width: '160px',
              marginRight: 2,
            }}
            InputProps={{
              style: {
                borderRadius: '24px',
                borderColor: COLORS.NAVY_BLUE,
                borderInlineColor: COLORS.NAVY_BLUE,
                color: COLORS.NAVY_BLUE,
                fontSize: '14px',
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{
                    color: COLORS.NAVY_BLUE,
                  }} />
                </InputAdornment>
              ),
              endAdornment: <InputAdornment position="end">
                <IconButton
                  sx={{
                    padding: 0,
                    color: COLORS.NAVY_BLUE,
                  }}
                  onClick={() => {
                    setTaskSearchQueryDebounced('')
                    setTaskQueryInternal('')
                  }}
                >
                  <CloseIcon sx={{ fontSize: '18px' }} />
                </IconButton>
              </InputAdornment>
            }}
            size='small'
            value={taskQueryInternal}
            onChange={(event) => {
              setTaskQueryInternal(event.target.value)
              setTaskSearchQueryDebounced(event.target.value)
            }}
          />
        </FormControl>}

        <FormControl>
          <InputLabel>Filter Workflows</InputLabel>
          <Select
            variant='standard'
            multiple
            value={selectedTemplatesIds}
            onChange={(event) => {
              setSelectedTemplatesIds(event.target.value)

              if (event.target.value.length != 0) {
                setShowInactiveWorkflows(true)
              }
            }}
            sx={{
              width: '160px',
              marginRight: 2,
            }}
            size='small'
          >
            {allTemplates.map((template) => (
              <MenuItem key={template.id} value={template.id}>
                {template.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {setDateRange && <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            value={dateRange}
            onChange={(newValue) => {
              setDateRange(newValue)
            }}
            slots={{ field: SingleInputDateRangeField }}
            slotProps={{ textField: { variant: 'standard', size: 'small', inputProps: { fontSize: '8px' }, sx: { width: '160px', marginRight: 1, } } }}
            sx={{ marginLeft: 1 }}
            formatDensity='dense'
            label='All Dates'
          />
        </LocalizationProvider>}
      </ThemeProvider>

      <Tooltip title="When toggled OFF, will show only workflows that are not expired">
        <FormControlLabel
          control={<Switch
            checked={showInactiveWorkflows}
            onChange={() => {
              setShowInactiveWorkflows(!showInactiveWorkflows)
              setSelectedTemplatesIds([])
            }}
            sx={{ marginLeft: 1 }} />}
          label={<Typography sx={{ fontSize: '14px', fontWeight: 400 }}>Show Expired</Typography>}
          labelPlacement="start"
        />
      </Tooltip>
    </OverallFiltersBody>
  </OverallFilters>
}

const OverallFilters = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  margin-bottom: 16px;
`

const OverallFiltersBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  color: #2E4785;
  margin-top: 16px;
`

export default WorkflowsFilter