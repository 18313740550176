import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import UpNextCard from './UpNextCard.web'
import UpNextCardLite from './UpNextCardLite.web'
import { chunk } from 'lodash'
import COLORS from 'constants/colors'
import { IconButton } from '@mui/material';

const UpNextCarousel = ({
  tasks,
  title,
  compact = false,
  isManager = false,
  limit = 10,
  refetch,
  disableClick = false,
}: {
  tasks: TaskUncompleteType[],
  title: string,
  compact?: boolean
  isManager?: boolean
  limit?: number
  refetch: () => void
  disableClick?: boolean
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const chunks = useMemo(() => chunk(tasks
    .sort(sortByMostOverdue)
    .sort(sortByMostImportant)
    .sort(sortByMostRecentCalendar)
    .slice(0, 10), limit), [tasks, limit])

  const isLeftDisabled = useMemo(() => activeStep === 0, [activeStep])

  const isRightDisabled = useMemo(() => activeStep >= chunks.length - 1, [activeStep, chunks])

  const currentChunk = useMemo(() => {
    if (!chunks || activeStep > chunks.length - 1) return []

    return chunks[activeStep]
  }, [activeStep])

  function sortByMostOverdue(a: TaskUncompleteType, b: TaskUncompleteType) {
    if (!a?.dueDate) {
      return 1
    }
    if (!b?.dueDate) {
      return -1
    }

    return new Date(a.dueDate) - new Date(b.dueDate)
  }

  function sortByMostRecentCalendar(a: TaskUncompleteType, b: TaskUncompleteType) {
    if (!a?.startAt) {
      return 1
    }
    if (!b?.startAt) {
      return -1
    }

    return new Date(a.startAt) - new Date(b.startAt)
  }

  function sortByMostImportant(a: TaskUncompleteType, b: TaskUncompleteType) {
    if (a?.important) {
      return -1
    }

    if (b?.important) {
      return 1
    }

    return 0
  }

  return <Container>
    <StyledCarousel>
      <StyledCarouselHeader>
        <StyledCarouselTitle>{title}</StyledCarouselTitle>
        <StyledButtons>
          <IconButton sx={{ padding: 0 }} disabled={isLeftDisabled} onClick={() => setActiveStep(activeStep - 1)}>
            <ArrowBackIosNewIcon sx={{ width: '24px' }} />
          </IconButton >
          <IconButton sx={{ padding: 0 }} disabled={isRightDisabled} onClick={() => setActiveStep(activeStep + 1)}>
            <ArrowForwardIosIcon sx={{ width: '24px' }} />
          </IconButton>
        </StyledButtons>
      </StyledCarouselHeader>


      {currentChunk.map((task) => {
        if (!compact) {
          return <UpNextCard disableClick={disableClick} key={task.id} task={task} refetch={refetch} />
        }

        return <UpNextCardLite disableClick={disableClick} key={task.id} task={task} smallIcon={!isManager} />
      })}


    </StyledCarousel>

  </Container>
}

const StyledCarousel = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 165px;
`

const StyledCarouselHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledButtons = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledCarouselTitle = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  color: ${COLORS.NAVY_BLUE};
`

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`

export default UpNextCarousel
