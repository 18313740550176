import React, { memo, useEffect, useMemo } from 'react'
import styled from 'styled-components'

import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { LOCATION_SCREEN_QUERY, UP_NEXT_TASKS_QUERY, UP_NEXT_TASKS_WITH_CALENDAR_QUERY } from 'graphql/queries'
import COLORS from 'constants/colors'
import UpNext from './UpNext.web'
import ErrorBoundary from 'components/common/ErrorBoundary'
import UpNextError from './UpNextError.web'
import { useTranslation } from 'react-i18next'
import { GeneralActivityIndicator } from 'components/Index'
import useUser from 'hooks/useUser'
import { Divider } from '@mui/material'
import UpNextDone from './UpNextDone.web'
import UpNextCarousel from './UpNextCarousel.web'

const UpNextContainer = ({
  parentId,
  onlyPast = false,
  onlyFuture = false,
  compact = false,
  maxHeight = 360,
  showCalendar = false,
}: {
  parentId?: string,
  onlyPast?: boolean
  onlyFuture?: boolean
  compact?: boolean
  maxHeight?: number
  showCalendar?: boolean
}) => {
  const { t } = useTranslation()
  const { user } = useUser()

  const [fetchUpNext, {
    data: upNextTasks,
    loading: loadingUpNext,
    refetch: refetchUpNext,
  }] = useLazyQuery(showCalendar ? UP_NEXT_TASKS_WITH_CALENDAR_QUERY : UP_NEXT_TASKS_QUERY)

  const { data, refetch: refetchLocationHierarchies, loading: locationLoading } = useQuery(LOCATION_SCREEN_QUERY, {
    variables: {
      parentId,
    },
    skip: !parentId,
  })
  const locations = useMemo(() => data?.locationHierarchiesNew?.data || [], [data])

  const pastTasks = useMemo(() => upNextTasks?.upNextTasks?.pastDue || [], [upNextTasks])

  const futureTasks = useMemo(() => upNextTasks?.upNextTasks?.due || [], [upNextTasks])

  const calendarTasks = useMemo(() => upNextTasks?.upNextTasks?.calendar || [], [upNextTasks])

  const tasks = useMemo(() => {
    if (onlyPast) {
      return pastTasks
    }
    if (onlyFuture) {
      return futureTasks
    }

    return [...pastTasks, ...futureTasks]
  }, [upNextTasks])
  const isLoading = useMemo(() => loadingUpNext || locationLoading, [loadingUpNext, locationLoading])

  useEffect(() => {
    if (!parentId) {
      fetchUpNext({
        variables: {
          locationIds: []
        }
      })
    } else if (!locationLoading && locations.length > 0) {
      let locationIds = []
      const location = locations.find((location: any) => location.id == parentId)
      if (location) {
        locationIds = location.children.map((location: any) => location.id)
      }

      fetchUpNext({
        variables: {
          locationIds
        }
      })
    }
  }, [locations, locationLoading, parentId])

  const refetch = () => {
    refetchLocationHierarchies()
    refetchUpNext()
  }

  const renderTitle = () => {
    if (onlyPast || onlyFuture) return <StyledTitle>{onlyPast ? t('pastDue') : t('upNextTitle')}</StyledTitle>

    return <StyledSubtitle>{t('upNextTitle')}</StyledSubtitle>
  }

  const renderMyTasks = (limit: number) => {
    return <>
      <UpNextCarousel title={t('myTasks')} tasks={tasks} limit={limit} refetch={refetch} compact={compact} isManager={user?.isManager} />
    </>
  }

  const renderCalendarTasks = (limit: number) => {
    return <>
      <UpNextCarousel title={t('calendar')} disableClick tasks={calendarTasks} limit={limit} refetch={refetch} compact={compact} isManager={user?.isManager} />
    </>
  }

  const renderContent = () => {
    if (isLoading) {
      return <GeneralActivityIndicator size="large" />
    }

    if (tasks.length === 0 && calendarTasks.length === 0) {
      return <UpNextDone />
    }

    if (showCalendar) {
      if (calendarTasks.length > 0 && tasks.length == 0) {
        return <>
          {renderCalendarTasks(4)}
        </>
      }
      if (calendarTasks.length == 0 && tasks.length > 0) {
        return <>
          {renderMyTasks(4)}
        </>
      }

      return <>
        {renderMyTasks(2)}
        <Divider sx={{ marginTop: '15px', marginBottom: '5px' }} />
        {renderCalendarTasks(2)}
      </>
    }

    return <>
      {renderTitle()}
      <UpNext tasks={tasks} refetch={refetch} compact={compact} isManager={user?.isManager} />
    </>
  }

  if (onlyFuture && futureTasks.length == 0 && pastTasks.length > 0) return null
  if (onlyPast && pastTasks.length == 0) return null

  return <StyledUpNext maxHeight={maxHeight}>
    <ErrorBoundary fallback={<UpNextError />}>
      {renderContent()}
    </ErrorBoundary>
  </StyledUpNext>
}

const StyledUpNext = styled.div<{ maxHeight: number }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px;
  background-color: ${COLORS.LIGHT_POWDER_BLUE};
  border-radius: 32px;
  max-height: ${({ maxHeight }) => `${maxHeight || 360}px`};
`

const StyledTitle = styled.div`
  font-size: 28px;
  font-weight: 500;
  color: ${COLORS.NAVY_BLUE};
`

const StyledSubtitle = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: ${COLORS.GRAYDARK3};
`

export default memo(UpNextContainer)