import React from 'react'
import { Linking, Text } from 'react-native'
import COLORS from 'constants/colors'

const QuestionSubmittedUpload = ({
  mediaUrls
}: {
  mediaUrls: string[]
}) => {
  if (!mediaUrls || mediaUrls.length == 0) {
    return 'No files were uploaded'
  }

  return <>
    {mediaUrls?.map(url => {
      const split = url.split('/')
      const fileName = split[split.length - 1]

      return <Text
        style={{
          color: COLORS.TURQUOISE,
          textDecorationLine: 'underline',
        }}
        onPress={() => Linking.openURL(url)}
      >
        {fileName}
      </Text>
    })}
  </>
}

export default QuestionSubmittedUpload