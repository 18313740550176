import { Avatar, AvatarGroup, ListItemButton } from '@mui/material'
import React, { useContext } from 'react'

import { MyTeamContext } from 'components/my-team/MyTeamContext';
import COLORS from 'constants/colors';

const MyTeamSidebarListGroupItem = () => {
  const { reportees, selectedReportee, setSelectedReportee } = useContext(MyTeamContext)

  return <ListItemButton
    selected={selectedReportee === null}
    sx={{
      padding: '16px 36px 16px 36px',
      height: '70px',
    }}
    onClick={() => setSelectedReportee(null)}
  >
    <AvatarGroup
      max={10}
      sx={{
        '.MuiAvatar-root': {
          color: 'white',
          backgroundColor: COLORS.GRAYDARK3,
        },
        '.MuiAvatarGroup-avatar': {
          width: '36px',
          height: '36px',
          fontSize: '12px',
          fontWeight: '600',
        },
      }}
    >
      {reportees?.map(reportee => <Avatar
        key={reportee.fullName}
        src={reportee.avatarUrl}
        sx={{
          backgroundColor: `${reportee.color || COLORS.TURQUOISE} !important`,
          color: reportee.colorBrightness == 'light' ? 'white !important' : ' black !important',
          width: '36px',
          height: '36px',
          fontSize: '12px',
          fontWeight: '600',
          border: `2px solid ${selectedReportee?.id == reportee.id ? 'rgba(80, 206, 162, 0.5)' : 'white'} !important`,
        }}
      >
        {reportee.firstName[0]}{reportee.lastName[0]}
      </Avatar>
      )}
    </AvatarGroup>
  </ListItemButton>
}

export default MyTeamSidebarListGroupItem