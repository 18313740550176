import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'
import {
  FRAGMENT_EVENT_FIELDS,
  FRAGMENT_TASK_FIELDS,
  FRAGMENT_SESSION_FIELDS,
  TASK_COMPLETE_FIELDS,
} from 'graphql/fragments'
import { CACHE_KEYS, MODEL_TYPES, ModelTypesUnion } from 'constants/types'
import { capitalize } from 'utils/calculations'

type CreateMutationOptions = {
  typename: ModelTypesUnion
  fragment?: DocumentNode
  operation?: 'create' | 'update'
  fields?: string
}

const createMutation = ({
  typename,
  fragment,
  operation = 'create',
  fields,
}: CreateMutationOptions) => gql`
  mutation ${capitalize(operation)}${typename}($attributes: ${typename}Input!) {
    ${operation}${typename}(attributes: $attributes) {
      ${fragment ? `...${typename}Fields` : ''}
      ${fields ? fields : ''}
    }
  }
  ${fragment ? fragment : ''}
`

export const CREATE_EVENT = createMutation({
  typename: MODEL_TYPES.EVENT,
  fragment: FRAGMENT_EVENT_FIELDS,
})

export const CREATE_TASK = createMutation({
  typename: MODEL_TYPES.TASK,
  fragment: FRAGMENT_TASK_FIELDS,
})

export const UPDATE_TASK = createMutation({
  typename: MODEL_TYPES.TASK,
  fragment: FRAGMENT_TASK_FIELDS,
  operation: 'update',
})

export const CREATE_TASK_COMPLETE = createMutation({
  typename: MODEL_TYPES.TASK_COMPLETE,
  fields: TASK_COMPLETE_FIELDS,
})

export const DELETE_TASK = gql`
  mutation DeleteTask($id: ID!) {
    deleteTask(id: $id) {
      ...TaskFields
    }
  }
  ${FRAGMENT_TASK_FIELDS}
`

export const DELETE_EVENT = gql`
  mutation DeleteEvent($id: ID!) {
    deleteEvent(id: $id) {
      ...EventFields
    }
  }
  ${FRAGMENT_EVENT_FIELDS}
`

export const LOGIN_BY_CREDS = gql`
  mutation login($attributes: LoginInput!) {
    login(attributes: $attributes) {
      id
      authenticationToken
      isTemporaryPassword
      resetPasswordToken
      clients {
        id
        name
      }
    }
  }
`

export const UPDATE_USER_PASSWORD = gql`
  mutation UpdateUserPassword($id: ID!, $attributes: UpdateUserPasswordInput!) {
    updateUserPassword(id: $id, attributes: $attributes) {
      id
    }
  }
`

export const LOGIN_WITH_OKTA_TOKEN = gql`
  mutation OktaTokenLogin($attributes: OktaTokenInput!) {
    oktaTokenLogin(attributes: $attributes) {
      id
      authenticationToken
    }
  }
`

export const LOGIN_WITH_WORKOS_TOKEN = gql`
  mutation WorkosTokenLogin($attributes: WorkosTokenInput!) {
    workosTokenLogin(attributes: $attributes) {
      id
      authenticationToken
    }
  }
`

export const LOGIN_WITH_WORKOS_REDIRECT_URL = gql`
  mutation WorkosRedirectUrl($attributes: WorkosRedirectUrlInput!) {
    workosRedirectUrl(attributes: $attributes) {
      redirectUrl
    }
  }
`

export const LOGIN_WITH_WORKOS_MAGIC_LINK = gql`
  mutation WorkosMagicLink($attributes: WorkosMagicLinkInput!) {
    workosMagicLink(attributes: $attributes) {
      email
    }
  }
`

export const CREATE_ORDER = gql`
  mutation CreateOrder($attributes: CreateOrderInput!) {
    createOrder(attributes: $attributes) {
      id
    }
  }
`

export const RESET_PASSWORD_VIA_TOKEN = gql`
  mutation ResetPasswordViaToken(
    $token: String!
    $attributes: UpdateUserPasswordInput!
  ) {
    resetPasswordViaToken(token: $token, attributes: $attributes) {
      id
      authenticationToken
    }
  }
`

export const RESET_PASSWORD_INSTRUCTIONS = gql`
  mutation ResetPasswordInstructions(
    $attributes: ResetPasswordInstructionsInput!
  ) {
    resetPasswordInstructions(attributes: $attributes) {
      success
    }
  }
`

export const UPDATE_USER_LOCALE = gql`
  mutation UpdateUserLocale($attributes: UpdateUserLocaleInput!) {
    updateUserLocale(attributes: $attributes) {
      preferredLocale
    }
  }
`

export const UPDATE_USER_TEMPLATE_ROLE_ASSIGNEE = gql`
  mutation UpdateUserTemplateRoleAssignee(
    $attributes: UserTemplateRoleAssigneeUpdateInput!
  ) {
    updateUserTemplateRoleAssignee(attributes: $attributes) {
      id
    }
  }
`

export const CREATE_DOCUSIGN_ENVELOPE = createMutation({
  typename: 'DocusignEnvelope',
  fields: 'url',
})

export const UPDATE_USER_PREFERENCES = gql`
  mutation UpdateUserPreferences($attributes: UpdateUserPreferencesInput!) {
    updateUserPreferences(attributes: $attributes) {
      preferences {
        showCompletedWorkflow
      }
    }
  }
`

export const DELETE_USER_TEMPLATE_ROLE_ASSIGNEE = gql`
  mutation DeleteUserTemplateRoleAssignee(
    $attributes: DeleteUserTemplateRoleAssigneeInput!
  ) {
    deleteUserTemplateRoleAssignee(attributes: $attributes) {
      success
    }
  }
`

export const SUBMIT_QUESTIONS = gql`
  mutation SubmitQuestions($attributes: QuestionSubmissionInput!) {
    submitQuestions(attributes: $attributes) {
      percentage
      score
      totalScore
      updatedAt
      questions {
        templateTaskQuestion {
          id
        }
        id
        isCorrect
        titleSnapshot
        requiredSnapshot
        descriptionSnapshot
        explanationSnapshot
        quizTypeSnapshot
        answers {
          templateTaskQuestionOption {
            id
          }
          id
          answer
          titleSnapshot
          correctSnapshot
          descriptionSnapshot
          scoreSnapshot
          taxonomyIdSnapshot
          dateTypeIdSnapshot
          roleIdSnapshot
          propertyNameSnapshot
          comment
          mediaUrls
        }
      }
    }
  }
`

export const OKTA_CONFIGURATION = gql`
  mutation OktaConfiguration(
    $issuer: String
    $email: String
    $ssoIdentifier: String
  ) {
    oktaConfiguration(
      issuer: $issuer
      email: $email
      ssoIdentifier: $ssoIdentifier
    ) {
      mobileOktaClientId
      issuer
    }
  }
`
