import React, { useMemo, useState } from 'react'
import {
  Linking,
  ScrollView,
  useWindowDimensions,
  View,
} from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { useHistory } from 'react-router-native'
import { useTranslation } from 'react-i18next'

import draftToHtml from 'draftjs-to-html'
import { Button, Text } from '@rneui/themed'
import Modal from 'react-native-modal'

import { captureException } from 'utils/sentry'
import Icon from 'components/common/Icon'
import { TextModal } from 'components/task-complete/TextModal'
import {
  TaskNotes,
} from 'components/task-list/TaskListModalComponents'
import COLORS from 'constants/colors'
import { stylesGlobal } from 'constants/es-style'
import { ICON_NAMES } from 'constants/icons'
import { IS_WEB } from 'constants/static'
import { EVENT_TYPES, TASK_TYPES } from 'constants/types'
import {
  EMPTY_REACT_DRAFT_WYSIWYG_STRING,
  ROUTE_NAMES,
} from 'navigation/constants'
import { percentScreenHeight } from 'utils/animations'
import { useAppContext } from 'utils/app-context'
import { ISODayString } from 'utils/date'
import TaskCompletionElement from './TaskCompletionElement'
import { replaceSnippetsInDescription } from 'utils/helpers/snippets-helper'
import {
  isCantCompleteEvent,
  isNotApplicable,
} from 'components/task-complete/helpers/task-complete-helpers'
import CantCompleteButton from './buttons/CantCompleteButton'
import NotApplicableButton from './buttons/NotApplicableButton'
import TaskModalHeader from './TaskListModalComponents/TaskModalHeader'
import TaskModalSubHeader from './TaskListModalComponents/TaskModalSubHeader'
import Questions from './questions/Questions'
import useClientConfig from 'hooks/useClientConfig'
import VideoTask from './video/VideoTask'
import ScormCourseTask from './scorm-course/ScormCourseTask'
import DoceboCourseTask from './docebo-course/DoceboCourseTask'
import TaskDescription from './TaskDescription'

export type TaskListModalProps = {
  isOpen: boolean
  hasTextOrPhotos: boolean
  closeModal: (removeModalFromCache?: boolean) => void
  task: TaskInterface
  sessionUuid?: string
  locationHierarchyId?: string
  completedEvent?: any
  cantCompleteText?: string
  snippetsMapping?: StringMap
  isAnimating?: Boolean
  setIsAnimating?: (value: boolean) => void
}

const ModalComponent = IS_WEB ? View : Modal

export const TaskListModal = ({
  isOpen,
  hasTextOrPhotos,
  closeModal,
  task,
  sessionUuid,
  locationHierarchyId,
  completedEvent,
  snippetsMapping,
  isAnimating,
  setIsAnimating,
}: TaskListModalProps) => {
  const [cantCompleteModalOpen, setCantCompleteModalOpen] =
    useState<boolean>(false)
  const [notApplicableModalOpen, setNotApplicableModalOpen] = useState(false)
  const history = useHistory()
  const appContextValue = useAppContext()
  const dayISO = ISODayString(appContextValue.selectedDay)
  const notApplicableText = completedEvent?.notApplicableText
  const cantCompleteText = completedEvent?.cantCompleteText
  const { width } = useWindowDimensions()
  const [contentWidth, setContentWidth] = useState(width)
  const { t, i18n } = useTranslation()
  const isCommentTask = task.taskType == TASK_TYPES.TEXT
  const { cantCompleteAnnouncement, disableNotes } = useClientConfig()

  const handleMissingSnippet = useMemo(
    () => (key: string) => {
      console.error(`Missing snippet: ${key}, taskId: ${task.id}`, 'WARNING')
    },
    [task],
  )

  const replaceDocusignSnippets = (html: string) => {
    return html.replace(/{{docusign_signer_signature}}/g, '')
  }

  const showNotes = useMemo(
    () =>
      !isCommentTask &&
      !(task.taskType === TASK_TYPES.QUIZ || task.taskType === TASK_TYPES.SURVEY || task.taskType === TASK_TYPES.SCORECARD) &&
      !disableNotes,
    [isCommentTask, disableNotes],
  )

  const replaceYoutubeLinkSlash = (html: string) => {
    return html.replace(
      '//www.youtube.com/embed/',
      'https://www.youtube.com/embed/',
    )
  }

  const descriptionHtml = useMemo(() => {
    if (!task) {
      return
    }
    const parsedTaskData = parseJson(task)

    if (!parsedTaskData) {
      return
    }

    const html = (typeof parsedTaskData === 'string' || parsedTaskData instanceof String) ?
      parsedTaskData :
      draftToHtml(parsedTaskData)

    return replaceYoutubeLinkSlash(
      replaceDocusignSnippets(
        replaceSnippetsInDescription(
          html,
          snippetsMapping,
          handleMissingSnippet,
          i18n.resolvedLanguage,
        ),
      ),
    )
  }, [task])

  const onAddNotePhotos = () => {
    history.push({
      pathname: ROUTE_NAMES.LOCATION_TEXT_AND_PHOTOS,
      state: {
        sessionUuid,
        uniqueId: `${task.id}:${dayISO}`,
        id: task.id,
        locationHierarchyId,
        isTaskLevel: true,
      },
    })
    closeModal()
  }

  const onCantComplete = () => {
    setCantCompleteModalOpen(true)
  }

  const onNotApplicable = () => {
    setNotApplicableModalOpen(true)
  }

  const cantComplete = () => {
    setCantCompleteModalOpen(false)
    closeModal(true)
  }

  const handleNotApplicableSubmitCallback = () => {
    setNotApplicableModalOpen(false)
    closeModal(true)
  }

  const renderSendSafelyUrl = (mediaUrls: [string]) => mediaUrls.map((url: string) => {
    return (
      <Text
        style={{
          color: COLORS.TURQUOISE,
          textDecorationLine: 'underline',
          marginTop: 30,
        }}
        onPress={() => Linking.openURL(url)}
      >
        Send Safely Package
      </Text>
    )
  })

  const renderMediaUrls = (mediaUrls: [string]) =>
    mediaUrls.map((url: string) => {
      const split = url.split('/')
      const fileName = split[split.length - 1]
      if (split.length === 1) {
        return (
          <Text
            style={{
              color: COLORS.TURQUOISE,
            }}
          >
            Uploading...
          </Text>
        )
      }

      return (
        <Text
          style={{
            color: COLORS.TURQUOISE,
            textDecorationLine: 'underline',
          }}
          onPress={() => Linking.openURL(url)}
        >
          {fileName}
        </Text>
      )
    })

  const showActionButtons = useMemo(() => {
    const ignoredTaskTypes = [TASK_TYPES.INFO, TASK_TYPES.QUIZ, TASK_TYPES.SCORECARD]

    return !ignoredTaskTypes.includes(task.taskType)

  }, [task.taskType])

  const iconColor = hasTextOrPhotos ? COLORS.BLUEDARK : COLORS.GRAYMEDIUM
  const addNotesFlex = IS_WEB ? 'none' : 1

  return (
    <ModalComponent
      style={[
        stylesGlobal.modal,
        IS_WEB
          ? { maxWidth: '100%', paddingTop: 24, alignSelf: 'flex-start' }
          : {},
      ]}
      isVisible={isOpen}
    >
      <View style={[stylesGlobal.modalContent, styles.container, IS_WEB ? { maxWidth: '100%', maxHeight: '100%' } : {}]}>
        <TaskModalHeader
          completedEvent={completedEvent}
          task={task}
          onClose={() => closeModal(true)}
          snippetsMapping={snippetsMapping}
        />
        {!IS_WEB && <TaskModalSubHeader
          completedEvent={completedEvent}
          task={task}
        />}
        {!IS_WEB && <View style={{
          marginTop: 4,
          borderBottomColor: '#E6EAF1',
          borderBottomWidth: 1,
        }} />}
        <ScrollView
          contentContainerStyle={styles.scrollContainer}
          onLayout={(event) => {
            setContentWidth(event.nativeEvent.layout.width)
          }}
        >
          <TaskDescription
            descriptionHtml={descriptionHtml}
            contentWidth={contentWidth}
          />
          {(task.taskType == TASK_TYPES.QUIZ || task.taskType == TASK_TYPES.SURVEY || task.taskType == TASK_TYPES.SCORECARD) &&
            <Questions
              survey={task.taskType == TASK_TYPES.SURVEY || task.taskType == TASK_TYPES.SCORECARD}
              scorecard={task.taskType == TASK_TYPES.SCORECARD}
              questions={task.questions || []}
              lastSubmission={completedEvent?.lastQuestionsSubmission || task.lastQuestionsSubmission}
              taskId={task.id}
              snippetsMapping={snippetsMapping}
            />
          }
          {task.taskType == TASK_TYPES.VIDEO &&
            <VideoTask task={task} locationHierarchyId={locationHierarchyId} completedEvent={completedEvent} />
          }
          {task.taskType == TASK_TYPES.SCORM_COURSE &&
            <ScormCourseTask task={task} locationHierarchyId={locationHierarchyId} completedEvent={completedEvent} />
          }
          {task.taskType == TASK_TYPES.DOCEBO_COURSE &&
            <DoceboCourseTask task={task} locationHierarchyId={locationHierarchyId} completedEvent={completedEvent} />
          }
        </ScrollView>
        {IS_WEB && showNotes && (
          <TaskNotes
            taskId={task.id}
            locationHierarchyId={locationHierarchyId}
            initialNotes={task.text}
          />
        )}
        <View
          style={[
            styles.bottomContainerWrap,
            !task.showNotApplicableOption ? { width: 'auto' } : {},
          ]}
        >
          {showActionButtons &&
            (
              <View style={styles.bottomContainer}>
                {
                  (task.taskType !== TASK_TYPES.VIDEO && task.taskType !== TASK_TYPES.SURVEY) && (
                    <>
                      <View
                        style={{
                          marginRight: 24,
                        }}
                      >
                        <CompleteButton
                          task={task}
                          {...{
                            locationHierarchyId,
                            sessionUuid,
                            completedEvent,
                            styles,
                            closeModal,
                            isAnimating,
                            setIsAnimating,
                          }}
                        />
                      </View>
                      <View
                        style={{
                          marginRight: IS_WEB ? 24 : 0,
                        }}
                      >
                        <CantCompleteButton
                          selected={isCantCompleteEvent(completedEvent)}
                          onPress={onCantComplete}
                        />
                      </View>
                    </>
                  )
                }

                {IS_WEB && task.showNotApplicableOption && (
                  <NotApplicableButton
                    selected={isNotApplicable(completedEvent)}
                    onPress={onNotApplicable}
                  />
                )}
              </View>
            )}
          <View
            style={[styles.bottomContainerSecond, { justifyContent: 'center' }]}
          >
            {!IS_WEB && showNotes && (
              <View
                style={{
                  flex: addNotesFlex,
                  marginRight: 20,
                  maxWidth: IS_WEB ? 200 : 'auto',
                }}
              >
                <Button
                  containerStyle={styles.buttonContainerStyle}
                  buttonStyle={[styles.buttonStyle, styles.addNotePhotosButton]}
                  titleStyle={[
                    styles.buttonTextStyle,
                    styles.addNotePhotosButtonTitle,
                  ]}
                  icon={
                    <Icon
                      name={ICON_NAMES.STICKY_NOTE}
                      size={25}
                      color={iconColor}
                      style={styles.buttonIcon}
                    />
                  }
                  title="Add Notes"
                  type="outline"
                  onPress={onAddNotePhotos}
                  titleProps={{
                    allowFontScaling: false,
                  }}
                />
              </View>
            )}
            {!IS_WEB && task.showNotApplicableOption && (
              <View
                style={{
                  flex: 1,
                  maxWidth: IS_WEB ? 200 : 'auto',
                }}
              >
                <NotApplicableButton
                  selected={isNotApplicable(completedEvent)}
                  onPress={onNotApplicable}
                />
              </View>
            )}
          </View>
        </View>
        {IS_WEB &&
          (!!completedEvent?.cantCompleteText ||
            !!completedEvent?.text ||
            !!completedEvent?.notApplicableText ||
            !!completedEvent?.mediaUrls) && (
            <View
              style={{ paddingLeft: 45, paddingRight: 30, marginBottom: 40 }}
            >
              {!!completedEvent?.cantCompleteText && (
                <Text style={{ color: COLORS.SECONDARY }}>
                  {completedEvent?.cantCompleteText}
                </Text>
              )}
              {!!completedEvent?.notApplicableText && (
                <Text style={{ color: COLORS.ORANGE }}>
                  {completedEvent?.notApplicableText}
                </Text>
              )}
              {!!completedEvent?.text && task.taskType !== TASK_TYPES.SEND_SAFELY && (
                <Text style={{ color: COLORS.NAVY_BLUE }}>
                  {completedEvent?.text}
                </Text>
              )}
              {!!completedEvent?.mediaUrls &&
                task.taskType === TASK_TYPES.SEND_SAFELY && renderSendSafelyUrl(completedEvent.mediaUrls)}
              {!!completedEvent?.mediaUrls &&
                task.taskType === TASK_TYPES.UPLOAD_MEDIA && renderMediaUrls(completedEvent.mediaUrls)}
            </View>
          )}
      </View>
      {cantCompleteModalOpen && (
        <TextModal
          isOpen={true}
          setModalOpen={setCantCompleteModalOpen}
          onSubmit={cantComplete}
          completeText={cantCompleteText}
          eventType={EVENT_TYPES.ADD_CANT_TEXT}
          taskId={task?.id}
          title={t('cantCompleteTask')}
          announcement={cantCompleteAnnouncement}
          {...{ sessionUuid, locationHierarchyId }}
        />
      )}

      {notApplicableModalOpen && (
        <TextModal
          isOpen={true}
          setModalOpen={setNotApplicableModalOpen}
          onSubmit={handleNotApplicableSubmitCallback}
          completeText={notApplicableText}
          eventType={EVENT_TYPES.NOT_APPLICABLE}
          taskId={task?.id}
          title={t('notApplicable')}
          {...{ sessionUuid, locationHierarchyId }}
        />
      )}
    </ModalComponent>
  )
}

const styles = EStyleSheet.create({
  container: {
    paddingHorizontal: IS_WEB ? 0 : 16,
    marginTop: IS_WEB ? 0 : 32,
    marginBottom: IS_WEB ? 0 : 32,
    paddingTop: IS_WEB ? 0 : 8,
    paddingBottom: 16,
    backgroundColor: IS_WEB ? 'transparent' : '#f4f4f4',
    flex: 1,
  },
  closeButton: {
    width: 50,
    height: 50,
    position: 'absolute',
    left: 10,
  },
  imageStyle: {
    width: '80%',
  },
  fileContainer: {
    width: '95%',
    height: IS_WEB ? 'auto' : percentScreenHeight(60),
  },
  descriptionContainer: {
    marginTop: -25,
    marginBottom: -10,
  },
  descriptionStyle: {
    marginTop: 15,
  },
  scrollContainer: {
    flexGrow: 1,
    paddingLeft: IS_WEB ? 45 : 0,
    paddingRight: IS_WEB ? 30 : 0,
    paddingHorizontal: IS_WEB ? 0 : 16,
  },
  bottomContainerWrap: {
    flexDirection: 'column',
    paddingTop: 36,
    width: '100%',
    paddingLeft: IS_WEB ? 45 : 0,
  },
  bottomContainer: {
    width: '100%',
    justifyContent: 'flex-start',
    paddingBottom: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  bottomContainerSecond: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonStyle: {
    borderWidth: 1,
    height: 48,
    backgroundColor: COLORS.TRANSPARENT,
    borderRadius: 6,
  },
  buttonTextStyle: {
    fontSize: IS_WEB ? 16 : 14,
  },
  addNotePhotosButtonTitle: {
    color: COLORS.BLUEDARK,
  },
  addNotePhotosButton: {
    borderColor: COLORS.BLUEDARK,
  },
  buttonIcon: {
    marginRight: 10,
  },
  modalCloseIcon: {
    position: 'absolute',
    top: -4,
    right: 8,
  },
  buttonContainerStyle: {
    width: IS_WEB ? 170 : '100%',
  },
})

const parseJson = (task: TaskInterface) => {
  let descriptionString

  try {
    descriptionString = JSON.parse(task.description)
  } catch (err) {
    // task description is not in json format
    descriptionString = task.description
  }

  return descriptionString
}

const CompleteButton = ({
  task,
  locationHierarchyId,
  sessionUuid,
  completedEvent,
  styles,
  closeModal,
}: any) => (
  <TaskCompletionElement
    {...task}
    {...{
      locationHierarchyId,
      sessionUuid,
      completedEvent,
      showAsButton: true,
      closeModal,
    }}
  />
)
