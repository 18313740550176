import React, { useEffect, useMemo, useState } from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import Modal from 'react-native-modal'

import Icon from 'components/common/Icon'

import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { stylesGlobal } from 'constants/es-style'
import { ICON_NAMES } from 'constants/icons'
import { GET_EXTERNAL_USERS, GET_USER_TEMPLATE_ROLE_ASSIGNMENTS, GET_USERS } from 'graphql/queries'
import { UPDATE_USER_TEMPLATE_ROLE_ASSIGNEE } from 'graphql/mutations'
import useCopyByRole, { CopyOptions } from './useCopyByRole'
import { useTranslation } from 'react-i18next'
import { InputLabel, TextField } from '@mui/material'
import { Loader } from 'components/common/Loader'
import DefaultButton from 'components/task-list/buttons/DefaultButton'
import { FETCH_POLICIES } from 'constants/api'
import COLORS from 'constants/colors'
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js'
import { Editor as DraftEditor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useApolloClient } from '@apollo/react-hooks';
import { StylesConfig } from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'
import styled from 'styled-components'

import draftToHtml from 'draftjs-to-html'
import ConfirmationModal from './ConfirmationModal.web'
import useClientConfig from 'hooks/useClientConfig'

const editorStyle = {
  height: '120px',
  padding: '0 12px',
  fontSize: '14px',
}

const editorWrapperStyle = {
  display: 'flex',
  flexDirection: 'column-reverse',
  borderRadius: '5px',
  border: '1px solid #cccccc',
}

const formatOptionLabel = (
  {
    value,
    label,
    user,
  }: {
    value: string
    label: string
    user: User
  },
  { context }: { context: string },
) => {
  if (context === 'value') {
    return user.fullName
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ color: '#000', fontSize: 14, display: 'flex' }}>
        <div>{user.fullName}</div>
        {user.externalId ? (
          <div style={{ marginLeft: 10 }}>({user.externalId})</div>
        ) : (
          <></>
        )}
      </div>
      <div style={{ color: '#8C8C8C', fontSize: 12 }}>{user.email}</div>
    </div>
  )
}
type UserTemplateRoleAssigneeModalProps = {
  openModal: boolean
  onClose: (refetch?: boolean) => void
  title: string
  templateRoleId?: number
  templateRoleName?: string
  selectedUser?: User
  currentUser?: User
  templateRoleCopiesMap: StringMap
}

const colourStyles: StylesConfig<any, true> = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  container: (styles) => ({ ...styles, zIndex: 9999 }),
  option: (styles, { data }) => ({
    ...styles,
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: '#F0F5FF',
      border: '1px solid #BFD5FF',
      borderRadius: 26,
    }
  },
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    ':hover': {
      cursor: 'pointer',
      backgroundColor: '#F0F5FF',
      borderRadius: 26,
    },
  }),
}

const LoadingOverlay = () => {
  return (
    <View
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 100,
        backgroundColor: 'rgba(0,0,0,0.1)',
      }}
    >
      <Loader></Loader>
    </View>
  )
}

const UserTemplateRoleAssigneeModal = ({
  openModal,
  onClose,
  title,
  selectedUser,
  templateRoleName,
  templateRoleId,
  currentUser,
  templateRoleCopiesMap
}: UserTemplateRoleAssigneeModalProps) => {
  const [page, setPage] = useState(0)
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false)
  const [text, setText] = useState('')
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [selectedUsers, setSelectedUsers] = useState<any[]>([])
  const { copyByRole } = useCopyByRole()
  const { t } = useTranslation()
  const assign = (confirmed?: boolean) => {
    if (!selectedUsers.length) {
      return
    }

    if (copyByRole(templateRoleName, 'confirmation') && !confirmed) {
      setIsConfirmationVisible(true)
      return
    } else if (confirmed) {
      setIsConfirmationVisible(false)
    }

    const rawContentState = convertToRaw(editorState.getCurrentContent())
    const message = draftToHtml(rawContentState)
    const user = selectedUsers[0].user
    const assigneeId = user.userId
    const externalId = user.externalId

    updateUserTemplateRoleAssignee({
      variables: {
        attributes: {
          userId: selectedUser.id,
          templateRoleId,
          ...(assigneeId ? { assigneeId } : { assigneeExternalId: externalId }),
          message,
        },
      },
    })
  }

  const [updateUserTemplateRoleAssignee, { loading }] = useMutation(
    UPDATE_USER_TEMPLATE_ROLE_ASSIGNEE,
    {
      onCompleted: () => {
        onClose(true)
      },
    },
  )

  const [getUserTemplateRoleAssignments, { data: userTemplateRoleAssignments, loading: loadingAssignments }] = useLazyQuery(GET_USER_TEMPLATE_ROLE_ASSIGNMENTS)
  const client = useApolloClient()
  const { useExternalUsersForRoleAssignments, disableRoleAssignmentEmail } = useClientConfig()

  const loadExternalUsers = async (searchText: string, nextPage: any) => {
    const res = await client.query<{
      externalUsers: { data: User[]; paginationMeta: PaginationMeta }
    }>({
      query: GET_EXTERNAL_USERS,
      fetchPolicy: FETCH_POLICIES.NETWORK_ONLY,
      variables: { query: searchText, pagination: { page: nextPage } },
    })

    return res.data.externalUsers;
  }
  const loadUsers = async (searchText: string, nextPage: any) => {
    const res = await client.query<{
      users: { data: User[]; paginationMeta: PaginationMeta }
    }>({
      query: GET_USERS,
      fetchPolicy: FETCH_POLICIES.NETWORK_ONLY,
      variables: { query: searchText, pagination: { page: nextPage } },
    })

    return { ...res.data.users, data: res.data.users.data.map(user => ({ ...user, userId: user.id })) };
  }

  const loadOptions = async (searchText: string, callback: any) => {
    const nextPage = searchText != text ? 1 : page + 1
    setPage(nextPage)
    setText(searchText)



    const users = useExternalUsersForRoleAssignments ? await loadExternalUsers(searchText, nextPage) : await loadUsers(searchText, nextPage)

    const { data, paginationMeta } = users

    return {
      options: data.map((user: User) => ({
        label: user.email,
        value: user.externalId,
        user: user,
      })),
      hasMore: paginationMeta.page < paginationMeta.nextPage,
    }
  }



  const assignmentsCount = useMemo(() => {
    if (!selectedUsers.length || !userTemplateRoleAssignments?.user || !selectedUsers[0].user.userId) {
      return 0
    }

    const assignments = userTemplateRoleAssignments?.user.userTemplateRolesAssignments

    return assignments ? assignments.length : 0
  }, [selectedUsers, userTemplateRoleAssignments])

  useEffect(() => {
    if (selectedUsers.length && selectedUsers[0].user.userId) {
      getUserTemplateRoleAssignments({
        variables: {
          id: selectedUsers[0].user.userId
        },
      })
    }
  }, [selectedUsers])

  const onEditorStateChange = (newEditorState: any) => {
    setEditorState(newEditorState)
  }

  useEffect(() => {
    if (!selectedUsers.length) {
      setEditorState(EditorState.createEmpty())
      return
    }

    const userFullName = selectedUser.fullName
    const managerFullName = currentUser?.fullName

    const newMessage = templateRoleCopiesMap['email']

    const blocksFromHTML = convertFromHTML(
      newMessage
        .replace(/{{employee.fullName}}/g, userFullName)
        .replace(/{{manager.fullName}}/g, managerFullName),
    )
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    )

    setEditorState(EditorState.createWithContent(state))
  }, [selectedUsers])

  return (
    <>
      <Modal isVisible={openModal} style={styles.modal}>
        <View style={[stylesGlobal.modalContent, styles.container]}>
          {loading && <LoadingOverlay></LoadingOverlay>}
          <View style={styles.headerContainer}>
            <Text
              style={stylesGlobal.modalContentHeaderText}
              numberOfLines={3}
              ellipsizeMode="tail"
            >
              {title}
            </Text>
            <View style={styles.closeButtonContainer}>
              <TouchableOpacity onPress={() => onClose()}>
                <Icon name={ICON_NAMES.CLOSE} size={28} />
              </TouchableOpacity>
            </View>
          </View>
          <ScrollView contentContainerStyle={styles.scrollContainer}>
            <p>{templateRoleCopiesMap['modal_header']}</p>
            <div style={{ paddingBottom: 30 }}>
              <StyledLabel>{t('name')}</StyledLabel>
              <AsyncPaginate
                value={selectedUsers}
                styles={colourStyles}
                formatOptionLabel={formatOptionLabel}
                loadOptions={loadOptions}
                debounceTimeout={300}
                onChange={(newValues: any) => {
                  const values = selectedUsers.map((user: any) => user.value)
                  setSelectedUsers(
                    newValues.filter(
                      (newValue: any) => !values.includes(newValue.value),
                    ),
                  )
                }}
                isMulti
              />

              <AssignmentInfo>
                {!!selectedUsers.length && !loadingAssignments ? (
                  <>
                    {assignmentsCount > 3 && (
                      <>
                        <Icon
                          name={ICON_NAMES.EXCLAMATION}
                          size={16}
                          style={{ color: '#D30000' }}
                        />{' '}
                      </>
                    )}
                    {t('activeAssignmentsCount', { count: assignmentsCount })}
                  </>
                ) : (
                  <> </>
                )}
              </AssignmentInfo>
            </div>
            {!disableRoleAssignmentEmail && <div>
              <StyledLabel htmlFor="role-assignment-message">
                {t('delegateEmailDescription', { roleName: templateRoleName })}
              </StyledLabel>
              <DraftEditor
                editorState={editorState}
                wrapperStyle={editorWrapperStyle}
                editorStyle={editorStyle}
                onEditorStateChange={(a) => onEditorStateChange(a)}
                toolbar={{
                  options: ['inline'],
                  inline: {
                    options: ['bold', 'italic', 'underline', 'strikethrough'],
                  },
                }}
              />
            </div>}
            <ButtonsWrap>
              <DefaultButton
                onPress={() => assign()}
                title={t('assign')}
                disabled={!selectedUsers.length}
              ></DefaultButton>
            </ButtonsWrap>
          </ScrollView>
        </View>
      </Modal>
      <ConfirmationModal
        isVisible={isConfirmationVisible}
        onClose={(confirmed) => assign(confirmed)}
        text={copyByRole(templateRoleName, CopyOptions.confirmation)}
      ></ConfirmationModal>
    </>
  )
}

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  margin-top: 20px;
`
const AssignmentInfo = styled.div`
  padding-top: 5px;
  font-size: 14px;
  color: #3f3f3f;
`

const StyledLabel = styled(InputLabel)`
  color: ${COLORS.NAVY_BLUE} !important;
`

const styles = StyleSheet.create({
  modal: {
    width: '100%',
    maxWidth: 1000,
    alignSelf: 'center',
  },
  button: {
    paddingHorizontal: 24,
    paddingVertical: 6,
  },
  container: {
    padding: 46,
    backgroundColor: 'white',
    maxHeight: '100%',
    fontFamily: 'Poppins_400Regular',
    maxWidth: 1000,
  },
  headerContainer: {
    flexDirection: 'row',
    alignContent: 'space-between',
    paddingLeft: 8,
    width: '100%',
  },
  titleText: {
    paddingTop: 12,
    flex: 1,
    flexWrap: 'wrap',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 20,
  },
  scrollContainer: {
    marginTop: 15,
    flexGrow: 1,
    padding: 1,
  },
  closeButtonContainer: {
    flex: 1,
    alignItems: 'end',
  },
  textarea: {
    width: '100%',
  },
  completeContainer: {
    alignItems: 'flex-end',
    margin: 15,
  },
})

export default UserTemplateRoleAssigneeModal
