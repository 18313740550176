import React from 'react'
import { View, TouchableOpacity, Text } from 'react-native'
import { useHistory } from 'react-router-native'
import { IS_WEB } from 'constants/static'
import ImageModal from 'react-native-image-modal'
import useNetworkStatus from 'components/common/hooks/useNetworkStatus'
import EStyleSheet from 'react-native-extended-stylesheet'

import { ROUTE_NAMES } from 'navigation/constants'
import COLORS from 'constants/colors'
import { EVENT_TYPES } from 'constants/types'
import { STYLE_CONSTANTS } from 'constants/es-style'
import { checkIsLocalFile } from 'utils/phone'
import MESSAGES from 'constants/messages'
import completeButtonStyles from './constants/completeButtonStyles'
import {
  CameraIcon,
  NopeIcon,
  NotApplicableIcon,
} from 'components/common/custom-icons'
import useCompleteElementStates from './hooks/useCompleteElementStates'
import CameraButton from 'components/task-list/buttons/CameraButton'

const ImageVisibleWhenOnline = ({
  online,
  isLocalImage,
}: {
  online: boolean
  isLocalImage: boolean
}) =>
  !online &&
  !isLocalImage && (
    <View style={styles.offlineImageTextWrapper}>
      <Text style={styles.offlineImageText}>image</Text>
      <Text style={styles.offlineImageText}>visible</Text>
      <Text style={styles.offlineImageText}>once</Text>
      <Text style={styles.offlineImageText}>online</Text>
    </View>
  )

// photo icon to take picture for task completition
const TaskPhotoToComplete = (props: CompleteElementProps) => {
  const history = useHistory()
  const { isConnected: online } = useNetworkStatus()
  const { sessionUuid, completedEvent } = props

  const {
    isNotApplicableCompletedEvent,
    isCantComplete,
    completeState,
    taskDone,
  } = useCompleteElementStates({
    completedEvent,
  })

  const openCamera = () => {
    if (IS_WEB) {
      alert(MESSAGES.NOT_AVAILABLE)
    } else {
      if (props.onNavigate) {
        props.onNavigate()
      }
      if (props.closeModal) {
        props.closeModal(true)
      }
      history.push({
        pathname: ROUTE_NAMES.CAMERA_COMPLETE_TASK,
        state: {
          ...props,
          ...{ eventType: EVENT_TYPES.TAKE_PHOTO },
        },
      })
    }
  }
  const imageUrl = props?.completedEvent?.imageUrl
  const isLocalImage = checkIsLocalFile(imageUrl)

  const renderIcon = () => {
    const ICON_SIZE = 36

    if (!completeState) {
      return <CameraIcon height={ICON_SIZE} width={ICON_SIZE} opacity={0.16} />
    } else if (isCantComplete) {
      return <NopeIcon height={ICON_SIZE} width={ICON_SIZE} />
    } else if (isNotApplicableCompletedEvent) {
      return <NotApplicableIcon height={ICON_SIZE} width={ICON_SIZE} />
    }

    return <CameraIcon height={ICON_SIZE} width={ICON_SIZE} />
  }

  return !props.showAsButton ? (
    <View style={styles.container}>
      {!!imageUrl?.length && (
        <View style={styles.imageModalThumbnailWrapper}>
          {(online || isLocalImage) && (
            <ImageModal
              swipeToDismiss={false}
              resizeMode="contain"
              imageBackgroundColor={COLORS.TRANSPARENT}
              style={styles.imageModalThumbnail}
              source={{ uri: imageUrl }}
            />
          )}
          <ImageVisibleWhenOnline {...{ online, isLocalImage }} />
        </View>
      )}
      <TouchableOpacity onPress={openCamera} style={styles.iconContainer}>
        {renderIcon()}
      </TouchableOpacity>
    </View>
  ) : (
    <CameraButton selected={taskDone} onPress={openCamera} />
  )
}

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    ...completeButtonStyles.iconContainer,
  },
  imageModalThumbnailWrapper: {
    ...STYLE_CONSTANTS.FLEX_CENTER_HORIZ,
  },
  offlineImageTextWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  offlineImageText: {
    fontSize: 8,
  },
  imageModalThumbnail: {
    width: 30,
    height: 40,
  },
  image: {
    width: 30,
    height: 40,
    marginRight: 5,
  },
})

export default TaskPhotoToComplete
