import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import COLORS from 'constants/colors'
import CompleteButton from 'components/task-list/buttons/CompleteButton'
import { CheckmarkIcon } from 'components/common/custom-icons'
import Checkmark from 'assets/images/png/CheckmarkOpaqueHD.png'
import CheckmarkGif from 'assets/images/gifs/temp.gif'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'
import { S3_BUCKET_URL } from 'constants/api'

const ICON_SIZE = 288
const PRELOADED_CHECKMARK_GIF = new Image()

const WelcomeWizardCheckmark = ({ onDone }: { onDone: () => void }) => {
  const { t } = useTranslation()
  const [isAnimating, setIsAnimating] = useState(false)

  const onSelected = () => {
    setIsAnimating(!isAnimating)

    setTimeout(() => {
      onDone()
    }, 3000);
  }

  const Icon = () => {
    if (isAnimating) {
      return <StyledIcon src={PRELOADED_CHECKMARK_GIF.src} />
    }

    return <StyledIcon src={Checkmark} />
  }

  useEffect(() => {
    PRELOADED_CHECKMARK_GIF.src = `${S3_BUCKET_URL}/public/onboarding_modal_complete.gif?time=${Date.now()}`
  }, [])

  return <Row>
    <Column>
      <TextContainer>
        <Title>{t(isAnimating ? 'welcomeCheckmarkSelected' : 'welcomeCheckmarkUnselected')}</Title>
      </TextContainer>

      <IconContainer onClick={onSelected}>
        <Icon />
      </IconContainer>
      <div style={{ marginTop: 24 }}>
        <CompleteButton selected={isAnimating} onPress={onSelected} />
      </div>
    </Column>
  </Row>
}

const IconContainer = styled.div`
  cursor: pointer;
`

const StyledIcon = styled.img`
  margin-top: -230px;
  z-index: -1;
  margin-bottom: -200px;
  position: relative;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 550px;
  width: 450px;
`

const Title = styled.div`
  font-size: 32px;
  font-weight: 600;
  color: ${COLORS.NAVY_BLUE};
  text-align: center;
`

export default WelcomeWizardCheckmark