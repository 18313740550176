import React, { useEffect } from 'react'
import { View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

import { EVENT_TYPES } from 'constants/types'
import useCreateEvent from './hooks/useCreateEvent'
import completeButtonStyles from './constants/completeButtonStyles'
import { VisibilityIcon } from 'components/common/custom-icons'
import COLORS from 'constants/colors'

const ICON_SIZE = 36

const TaskInfoToComplete = (props: CompleteElementProps) => {
  const { sessionUuid, locationHierarchyId, id: taskId, completedEvent, completed } = props

  const createEvent = useCreateEvent({
    taskId,
    locationHierarchyId,
    sessionUuid,
  })

  useEffect(() => {
    if (completedEvent) { return }

    const timer = setTimeout(() => {
      createEvent({ eventType: EVENT_TYPES.TASK_READ, createEventData: { eventType: EVENT_TYPES.TASK_READ } })
    }, 5000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <View style={styles.container}>
      <VisibilityIcon color={(completedEvent || completed) ? COLORS.INFO_BLUE : COLORS.BLUELIGHT} height={ICON_SIZE} width={ICON_SIZE} />
    </View>
  )
}

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    ...completeButtonStyles.iconContainer,
  },
})

export default TaskInfoToComplete
