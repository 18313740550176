import PageFrame from 'components/common/PageFrame'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import MyTeamSidebar from './components/my-team-sidebar/MyTeamSidebar'
import useReportees from 'hooks/useReportees'
import { MyTeamContext } from './MyTeamContext'
import OverallProgress from './components/overall-progress/OverallProgress'
import ReporteeTasks from './components/reportee-tasks/ReporteeTasks'
import { LOCATION_HIERARCHIES_GROUP } from 'graphql/queries'
import { useLazyQuery } from '@apollo/react-hooks'
import useClientConfig from 'hooks/useClientConfig'
import { getColorByIndex } from 'hooks/useLocations'
import { DEFAULT_CATEGORY_COLORS } from 'constants/colors'
import MyTeamProgress from './components/my-team-progress/MyTeamProgress'
import { addDays, isBefore } from 'date-fns'
import { useHistory } from 'react-router-native'

const MyTeamScreen = () => {
  const history = useHistory()
  const params = new URLSearchParams(location.search);
  const reporteeId = params.get('reporteeId');
  const [selectedReportee, setSelectedReportee] = useState<User | null>(null)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [reporteesWorkflows, setReporteesWorkflows] = useState<any>({})
  const { reportees, loading } = useReportees(searchTerm)
  const [getReport, { data, loading: hierarchiesLoading }] = useLazyQuery(LOCATION_HIERARCHIES_GROUP)
  const { categoryColors } = useClientConfig()

  useEffect(() => {
    if (reporteeId) {
      const reportee = reportees.find(reportee => reportee.id == reporteeId)
      if (reportee) {
        setSelectedReportee(reportee)
      }
    }
  }, [reporteeId])

  useEffect(() => {
    if (selectedReportee) {
      history.push({
        search: `?reporteeId=${selectedReportee.id}`
      })
    }
  }, [selectedReportee])

  useEffect(() => {
    if (loading) return

    getReport({ variables: { userIds: reportees.map(reportee => reportee.id) } })
  }, [reportees])

  const unterminatedReportees = useMemo(() => {
    return reportees.filter(reportee => !reportee.terminationDate || !isBefore(addDays(new Date(reportee.terminationDate.replace(/-/g, '/')), 5), new Date()))
  }, [reportees])

  const filteredReportees = useMemo(() => {
    return unterminatedReportees
      .filter(reportee => reporteesWorkflows[reportee.id]?.length > 0)
  }, [unterminatedReportees, reporteesWorkflows])

  useEffect(() => {
    const reporteesWorkflows: any = {}
    const colorByTemplateId: any = {}

    for (const userId in data?.locationHierarchiesGroup) {
      if (!unterminatedReportees.some(reportee => reportee.id == userId)) {
        continue
      }

      const locations: LocationInterface[] = data?.locationHierarchiesGroup[userId].map(location => {
        const completedTasksCount =
          location.completed +
          location.cantComplete +
          location.notApplicable
        const percent =
          location.tasks > 0
            ? Math.round((completedTasksCount / location.tasks) * 100)
            : 0
        const isComplete = percent == 100

        return {
          completedTasksCount,
          percent,
          isComplete,
          ...location,
        }
      })

      const workflows = locations
        .filter(location => !locations.some(l => l.id == location.parentId))
        .map((location) => {
          let color = colorByTemplateId[location.templateCategoryId]
          if (!color) {
            const index = Object.keys(colorByTemplateId).length
            color = getColorByIndex(categoryColors || DEFAULT_CATEGORY_COLORS, index)
            colorByTemplateId[location.templateCategoryId] = color
          }

          return {
            ...location,
            ...color,
          }
        })

      reporteesWorkflows[userId] = workflows
    }

    setReporteesWorkflows(reporteesWorkflows)
  }, [data, categoryColors, unterminatedReportees])

  const renderRightSide = () => {
    if (filteredReportees.length === 0) {
      return null
    }

    if (selectedReportee) {
      return <ReporteeTasks />
    }

    return <Progress>
      <OverallProgress />

      <MyTeamProgress />
    </ Progress>
  }


  return <PageFrame noPadding>
    <Container>
      <MyTeamContext.Provider value={{
        loading: loading || hierarchiesLoading,
        reportees: filteredReportees,
        selectedReportee: selectedReportee,
        setSelectedReportee: setSelectedReportee,
        searchTerm: searchTerm,
        setSearchTerm: setSearchTerm,
        reporteesWorkflows: reporteesWorkflows,
        setReporteesWorkflows: setReporteesWorkflows,
      }}>
        <MyTeamSidebar />
        <RightSide>
          {renderRightSide()}
        </RightSide>
      </MyTeamContext.Provider>
    </Container>
  </PageFrame>
}

const Progress = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const RightSide = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 24px 36px 24px 36px;
	background-color: #F5F6F7;
  max-height: calc(100vh - 150px);
  overflow: auto;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
	flex: 1;
  overflow: hidden;
`

export default MyTeamScreen