import React, { useState } from 'react'
import styled from 'styled-components'
import { Show } from '../SearchModal'
import SearchModalResultHeader from './SearchModalResultHeader'
import { ArrowRightIcon } from 'components/common/custom-icons'
import AboutTheOrgModal from 'components/resources/components/AboutTheOrgModal.web'

const SearchModalResourcesResult = ({
  type,
  show,
  setShow,
  resources,
  contentPages,
}: {
  type: Show,
  show: Show,
  setShow: (value: Show) => void
  resources: ResourceInterface[]
  contentPages: any[]
}) => {
  const [currentPageId, setCurrentPageId] = useState(null)
  const onClick = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  }

  return <Results>
    <SearchModalResultHeader
      type={type} show={show} length={resources.length} onClick={() => setShow(type)}
    />
    <ResultsContent>
      {resources
        .slice(0, show == null ? 3 : resources.length)
        .map(resource =>
          <ResourceContainer key={resource.id} onClick={() => onClick(resource.url)}>
            {resource.title}
            <ArrowRightIcon height={24} width={24} />
          </ResourceContainer>
        )}
      {contentPages.slice(0, show == null ? 3 : contentPages.length).map(contentPage =>
        <ResourceContainer key={contentPage.id} onClick={() => { setCurrentPageId(contentPage.id) }}>
          {contentPage.title}
          <ArrowRightIcon height={24} width={24} />
        </ResourceContainer>)}
    </ResultsContent>

    <AboutTheOrgModal
      currentPageId={currentPageId}
      isOpen={!!currentPageId}
      onClose={() => setCurrentPageId(null)}
    />
  </Results>
}

const ResourceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  padding: 12px;
  background-color: #f5f7fb;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
`

const Results = styled.div`
  margin-bottom: 30px;
  margin-right: 10px;
`

const ResultsContent = styled.div`
  display: flex;
  flex-direction: column;
`

export default SearchModalResourcesResult