import { useQuery } from "@apollo/react-hooks";
import { GET_CURRENT_USER } from "graphql/queries";
import { useEffect, useMemo } from "react";
import { setSentryUser } from "utils/sentry";

const useUser = () => {
  const { data, loading, refetch } = useQuery<{ user: User }>(GET_CURRENT_USER)
  const user = useMemo(() => data?.user, [data])

  useEffect(() => {
    if (user) {
      setSentryUser(user)
    }
  }, [user])

  return {
    user,
    loading,
    refetch
  };
};

export default useUser