import NetInfo, { NetInfoState } from '@react-native-community/netinfo';
import { useEffect, useState } from 'react'
import {
    IS_WEB,
  } from 'constants/static'
const useNetworkStatus = () => {
    const [networkStatus, setNetworkStatus] = useState({} as NetInfoState)
    useEffect(() => {
        const unsubscribe = NetInfo.addEventListener(state => {
            setNetworkStatus(state)
          });

        return () => {
            unsubscribe()
        }
    }, [])

    return {...networkStatus, isConnected: IS_WEB ? true : networkStatus.isConnected}
}

export default useNetworkStatus