import React from 'react'
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro'
import { styled as muiStyled } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import COLORS from 'constants/colors'
import { TASK_TYPES } from 'constants/types';

const StyledDataGrid = (props: DataGridProProps) => {
  return (
    <StyledDataGridPro
      {...props}
      hideFooter
      getRowClassName={(params) => params.row?.pastDue ? 'customTheme--PastDue' : ''}
      slots={{
        columnSortedAscendingIcon: () => <KeyboardArrowUpIcon sx={{ color: COLORS.TURQUOISE }} />,
        columnSortedDescendingIcon: () => <KeyboardArrowDownIcon sx={{ color: COLORS.TURQUOISE }} />,
      }}
      sx={{
        border: 'none',
        '& .MuiDataGrid-columnHeaderTitle': {
          color: COLORS.FIVES_GREY,
          fontSize: '14px',
          fontWeight: '500',
        },
      }}
    />
  )
}

const StyledDataGridPro = muiStyled(DataGridPro)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '500',
  color: COLORS.NAVY_BLUE,
  '& .customTheme--PastDue': {
    color: "#B10000",
  },
}));

export default StyledDataGrid