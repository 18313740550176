import React, { useEffect, useState } from 'react'
import { TouchableOpacity, View, Text } from 'react-native'
import { Box } from '@mui/material'
import Modal from 'react-native-modal'
import { useTranslation } from 'react-i18next'

import { Icon } from 'components/Index'
import { ICON_NAMES } from 'constants/icons'
import { stylesGlobal } from 'constants/es-style'
import DefaultButton from 'components/task-list/buttons/DefaultButton'

const getBaseUrl = () => window.location.protocol + '//' + window.location.host

const AppVersionModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [appVersion, setAppVersion] = useState<null | string>(null)
  const BASE_URL = getBaseUrl()
  const { t } = useTranslation()

  const loadAppVersion = () => {
    fetch(`${BASE_URL}/build-version.json`)
      .then((response) => response?.json())
      .then((res) => {
        if (appVersion && res.version && appVersion !== res.version) {
          setIsModalVisible(true)
        }

        setAppVersion(res.version)
      })
      .catch(err => console.log("load app version ERROR: ", err))
  }

  const onVisibilityChange = () => {
    if (document.visibilityState == 'hidden') {
      return
    }
    loadAppVersion()
  }

  const refreshPage = () => {
    window.location.reload()
  }

  useEffect(() => {
    loadAppVersion()
  }, [])

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange)

    return () =>
      document.removeEventListener('visibilitychange', onVisibilityChange)
  }, [appVersion])

  const onClose = () => setIsModalVisible(false)

  return (
    <Modal
      style={stylesGlobal.modal}
      isVisible={isModalVisible}
    >
      <View style={stylesGlobal.modalContent}>
        <View style={stylesGlobal.modalContentHeader}>
          <Text style={stylesGlobal.modalContentHeaderText}>
            {t('newAppVersionTitle')}
          </Text>
          <TouchableOpacity onPress={onClose}>
            <Icon name={ICON_NAMES.CLOSE} size={30} onPress={onClose} />
          </TouchableOpacity>
        </View>
        <Box pb={2}>
          {t('newAppVersionContent')}
        </Box>
        <Box>
          <DefaultButton title={t('refreshPage')} onPress={refreshPage} />
        </Box>
      </View>
    </Modal>
  )
}

export default AppVersionModal
