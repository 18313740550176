import { CircularProgress, Divider, List } from '@mui/material'
import React, { Fragment, useContext } from 'react'

import styled from 'styled-components'
import MyTeamSidebarListButton from './MyTeamSidebarListButton';
import { MyTeamContext } from 'components/my-team/MyTeamContext';
import MyTeamSidebarListGroupItem from './MyTeamSidebarListGroupItem';

const MyTeamSidebarList = () => {
  const { loading, reportees, selectedReportee, setSelectedReportee } = useContext(MyTeamContext)

  if (loading) return <LoadingContainer>
    <CircularProgress />
  </LoadingContainer>

  if (reportees.length === 0) return <EmptySearchContainer>
    No reportees found
  </EmptySearchContainer>

  return <Container>
    <List sx={{ padding: 0 }}>
      <MyTeamSidebarListGroupItem />

      {reportees.map((reportee) => (
        <Fragment key={reportee.id}>
          <MyTeamSidebarListButton
            reportee={reportee}
            selected={reportee.id == selectedReportee?.id}
            onClick={() => { setSelectedReportee(reportee) }}
          />
          <Divider />
        </Fragment>
      ))}
    </List>
  </Container>
}

const EmptySearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
`

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`

export default MyTeamSidebarList